import React, { useState,useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./Review.css";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../images/arrow-left.png";
import mastercardLogo from "../../images/mastercard-logo.png";
import americanExpressLogo from "../../images/american-express.png";
import paypalLogo from "../../images/paypal.png";
import visaLogo from "../../images/visa.png";
import CartFooter from "../Cart/CartFooter";
import Table from "react-bootstrap/Table";
import Header from "../LandingPage/Header/Header";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { getPrice } from "../../utils/getPrice";
import isEmpty from "../../validation/is-empty";
import _ from "lodash"
import CurrencyContext from "../../context/currencyContext";
import { displayPrice } from "../../utils/displayPrice";

// function createArtworkData(
//   id,
//   artworkName,
//   artistName,
//   artworkPrice,
//   artworkSubtotalprice,
//   artworkCategory,
//   userShippingData,
//   userBillingData,
//   paymentMethod,
//   displayImgSrc
// ) {
//   return {
//     id,
//     artworkName,
//     artistName,
//     artworkPrice,
//     artworkSubtotalprice,
//     artworkCategory,
//     userShippingData,
//     userBillingData,
//     paymentMethod,
//     displayImgSrc,
//   };
// }

// const originalRows = [
//   createArtworkData(
//     1,
//     "Guernica",
//     "Pablo Picasso",
//     "650000",
//     "900000",
//     "Painting",
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     "paypal",
//     "Pic 2015-01-23 17-42-22.jpg"
//   ),
//   createArtworkData(
//     2,
//     "The Water Lily Pond",
//     "Claude Monet",
//     "1230000",
//     "1500000",
//     "Sculpture",
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     "paypal",
//     "register.jpg"
//   ),
//   createArtworkData(
//     3,
//     "Woman with a Parasol",
//     "Leonardo Da Vinci",
//     "800000",
//     "800000",
//     "Landscape",
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     {
//       name: "John Doe",
//       phoneNumber: "08020010300",
//       address: "1 Adio Street, New Garage Gbagada",
//       state: "Lagos",
//       country: "Nigeria",
//     },
//     "paypal",
//     "register2.jpg"
//   ),
// ];

function Review() {
  const navigate = useNavigate();
  // Functions State and Variiables
  const [originalCartRows, setOriginalCartRows] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);

  const dispatch = useDispatch();
  // Functions, States and Variables
  // const mainUser = useSelector((state) => console.log('llll',state.profile.profile));
  const mainUser2 = useSelector((state) => console.log('llll1',state.order));
  const mainUser = useSelector((state) => state.profile.profile);
  const orderCart = useSelector((state) => state.order.orderCart);
  const currencyContext = useContext(CurrencyContext);
  const [user,setUser] = useState(mainUser);
  const [cart,setCart] = useState(orderCart);
  //   STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondPhoneNumber, setSecondPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  // State, the one with a capital not react state
  const [state, setState] = useState(false);

  // const handleAddSecondPhoneInput = function (e) {
  //   e.preventDefault();
  //   let secondPhoneInputSection = document.querySelector(
  //     ".second-phone-input-section"
  //   );
  //   // Remove the add second number button
  //   e.target.parentElement.classList.add("none");
  //   // Add the second number Input
  //   secondPhoneInputSection.classList.remove("none");
  // };

  // const updateShippingDetails = () => {
  //   if(user.address.split('')[0] !== address){
  //     const fullAddress = address+', '+city+' '+state+' '+country;
  //     const data = {
  //       address: fullAddress,
  //       phone: user && user.phone
  //     }
  //     dispatch(updateAddressPhone(data))
  //   }
  // }

  const calculateTotalShippingFee = (data) => {
    // console.log('kkk',data);
    if(data && data.length){
      let result =  0
      for(let i = 0; i < data.length; i++){
        if(!isEmpty(data[i].weight)){
          result = result + calculateShippingFee(data[i].weight) 
        }
      }
      setShippingFee(result);
    }
  }
  const calculateShippingFee = (data) => {
    let figure;
    // console.log('ooo',currencyContext.rate)
    if (data > 0 && data <= 2){
       figure = 3640;
      // this.setState({
      //   shippingFee: 3640,
      //   sizeCategory: "small",
      //   total: 3640+this.props.products.artwork.price,
      // })
      // return (3640)
    }
    if(data > 2 && data <= 10){
      figure = 8613;
      // this.setState({
      //   shippingFee: 8613,
      //   sizeCategory:"medium",
      //   total: 8613+this.props.products.artwork.price,
      // })
    }
    if (data > 10){
      figure = 15907
      // this.setState({
      //   shippingFee: 15907,
      //   sizeCategory: "large",
      //   total: 15907+this.props.products.artwork.price,
      // })
      // return 15907
    }
    if(data === 0){
      figure = 0
      // this.setState({
      //   shippingFee: 0,
      //   sizeCategory:"unspecified",
      //   total: this.props.products.artwork.price,
      // })
    }
    return (currencyContext.currencySelected === "₦")? figure : figure/currencyContext.rate;
  }

  useEffect(() => {
    if(!_.isEmpty(user)){
      console.log('jj', user)
      let fullAddress = user.shippingAddress.split(',');
      console.log('full', fullAddress)
      setFirstName(user.firstname)
      setLastName(user.lastname)
      setPhoneNumber(user.phone)
      setAddress(fullAddress[0])
      setCity(fullAddress[1])
      setCountry(fullAddress[4])
      setState(fullAddress[3])
    }
  }, [user]);

  useEffect(() => {
    setUser(mainUser);
  }, [mainUser])

  useEffect(() => {
    setCart(orderCart);
  }, [orderCart])

  useEffect(() => {
    if(cart){
      calculateTotalShippingFee(cart)
      let totalCartPriceNum = 0
      cart
        .map((cartRow) => {
          totalCartPriceNum = totalCartPriceNum + (getPrice(cartRow,currencyContext))
        })
  
      setTotalCartPrice(totalCartPriceNum);
      let orderTotal = totalCartPriceNum + shippingFee;
      // console.log('ppp', totalPrice)
      console.log('ppp', totalCartPriceNum)
      console.log('bbb', shippingFee)
      // console.log('ppp', orderTotal)
      setTotalPrice(orderTotal)
    }
    
  }, [cart]);

  // useEffect(() => {
  //   // console.log('jj', user.cart)
  //   if(user && user.cart){
  //     calculateTotalShippingFee(user.cart)
  //     // const totalCartPriceNum = user && user.cart
  //     //   .map((cartRow) => {
  //     //     return parseInt(displayPrice(cartRow,currencyContext).replace(/,/g,'',10));
  //     //   })
  //     //   .reduce((acc, num) => acc + num);

  //     let totalCartPriceNum = 0
  //     user && user.cart
  //       .map((cartRow) => {
  //         totalCartPriceNum = totalCartPriceNum + (getPrice(cartRow,currencyContext))
  //       })
  
  //     setTotalCartPrice(totalCartPriceNum);
  //     let orderTotal = totalCartPriceNum + shippingFee;
  //     // console.log('ppp', totalPrice)
  //     console.log('ppp', totalCartPriceNum)
  //     console.log('bbb', shippingFee)
  //     // console.log('ppp', orderTotal)
  //     setTotalPrice(orderTotal)
  //   }
    
  // }, [user]);

  useEffect(() => {
      let orderTotal = totalCartPrice + shippingFee;
      // console.log('ppp', totalPrice)
      console.log('ppp', totalCartPrice)
      console.log('bbb', shippingFee)
      // console.log('ppp', orderTotal)
      setTotalPrice(orderTotal)
    
  }, [totalCartPrice]);

  //   useEffect
  // useEffect(() => {
  //   const totalCartPriceNum = originalCartRows
  //     .map((cartRow) => {
  //       return parseInt(cartRow.artworkSubtotalprice);
  //     })
  //     .reduce((acc, num) => acc + num);

  //   setTotalCartPrice(totalCartPriceNum);
  // }, []);

  return (
    <div className="review-container">
      <Header />
      {/* Header with Title and Order Progression */}
      <div className="review--header">
        <div className="review--header--inner">
          {/* Title */}
          <div className="review-title">
            <p>Your Order was successfull</p>
          </div>

          {/*Order progression */}
          <div className="order-progression">
            <div className="order-progression--inner review-order-progression">
              {/* Cart */}
              <div className="cart-progression ">
                <div className="progression-title">Cart</div>
              </div>

              {/* Shipping */}
              <div className="shipping-progression">
                <div className="progression-title">Shipping</div>
              </div>

              {/* review */}
              <div className="payment-progression">
                <div className="progression-title">Payment</div>
              </div>

              {/* Review */}
              <div className="review-progression">
                <div className="progression-title">Review</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="review-container--inner">
        {/* Order Review Main Container */}
        <main className="order-review-main-container">
          {/*  */}
          {/* Order review Cart Table / Left main Section  */}
          <div className="order-review-cart-table-container left-review-main-section shopping-cart-table">
            <Table responsive="lg" className="cart-table">
              <thead>
                <tr>
                  {["ITEM(S)", "PRICE"].map((COLUMN, index) => (
                    <th key={index + 1}>{COLUMN}</th>
                  ))}
                </tr>
              </thead>
              <tbody
                style={{
                  borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* {user && user.cart && user.cart.map((cartRow, i) => ( */}
                {cart && cart.map((cartRow, i) => (
                  <tr key={i + 1} className="tr-cart">
                    {/* Items */}
                    <td className="td-items">
                      <div className="td-items--wrapper">
                        {/* Artwork Image */}
                        <div className="items--artwork-image">
                          <img
                             src={cartRow && cartRow.photo}
                            alt="artwork-img"
                          />
                        </div>

                        {/* Artwork Info Details */}
                        <div className="items--artwork-info-details">
                          {/* Artwork name */}
                          <div className="items--artwork-name">
                          {cartRow && cartRow.title}
                          </div>

                          {/* Artist Name */}
                          <div className="items--artist-name">
                            {/* Artist: {cartRow.artistName} */}
                          </div>

                          {/* Artwork Category */}
                          <div className="items--artwork-category">
                          {cartRow && cartRow.category}
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* Price */}
                    <td>
                    <CurrencyContext.Consumer>
                        {(currencyContext) => (
                          <div className="td-price--wrapper">
                            {cartRow && currencyContext.currencySelected}{" "}
                            {cartRow && displayPrice(cartRow, currencyContext)}
                          </div>
                        )}
                      </CurrencyContext.Consumer>
                    </td>

                    {/* Subtotal */}
                    {/* <td>
                      <div className="td-subtotal--wrapper">
                      NGN{totalCartPrice ? numberWithCommas(totalCartPrice): 0}.00
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* total order price summary */}
            <div className="order-review-total-price-summary">
              {/* Artwork subtotal */}
              <div className="artwork-subtotal order-price-parameter-row">
                <div className="artwork-subtotal-title order-price-parameter-title">
                  ARTWORKS SUBTOTAL:
                </div>
                <div className="artwork-subtotal-value order-price-parameter-value">
                  NGN{numberWithCommas(totalCartPrice)}.00
                </div>
              </div>

              {/* Discount */}
              <div className="order-discount order-price-parameter-row">
                <div className="order-discount-title order-price-parameter-title">
                  DISCOUNT:
                </div>
                <div className="order-discount-value order-price-parameter-value">
                  NGN{numberWithCommas("0")}.00
                </div>
              </div>

              {/* Shipping */}
              <div className="shipping-price order-price-parameter-row">
                <div className="shipping-price-title order-price-parameter-title">
                  SHIPPING:
                </div>
                <div className="shipping-price-value order-price-parameter-value">
                  NGN{numberWithCommas(shippingFee)}.00
                </div>
              </div>

              {/* Tax */}
              <div className="tax-inclusivity order-price-parameter-row">
                <div className="tax-inclusivity-title order-price-parameter-title">
                  TAX:
                </div>
                <div className="tax-inclusivity-value order-price-parameter-value">
                  {"Inclusive"}
                </div>
              </div>
            </div>

            {/* Order Total */}
            {/* Total Order price number */}

            <div className="order-review--total-order-price-wrapper">
              <div className="order-review--total-order-price-title">
                TOTAL:
              </div>
              <div className="order-review--total-order-price-value">
                NGN{numberWithCommas(totalCartPrice + shippingFee)}.00
              </div>
            </div>

            {/* Submit Order Button */}
            {/* <div className="submit-order-btn-container">
              <button onClick={handleSubmitOrder} type="submit">
                SUBMIT ORDER
              </button>
            </div> */}
          </div>
          {/* Address and Payment method container / Right Order Main Section */}
          <div className="order-review-address-payment-method-container right-order-main-section">
            <div className="order-review-address-payment-method-container--inner">
              {/* Order review Shipping box */}
              <div className="order-review-shipping-address-box">
                {/* Box Title */}
                <div className="order-review-box-title order-review-shipping-box-title">
                  SHIPPING ADDRESS
                </div>

                {/* Order User Shipping Data*/}
                <div className="order-review-box-user-data">
                  {/* User name */}
                  <div className="order-review-user-name">
                    {firstName} {lastName}
                  </div>
                  {/* User Phone number */}
                  <div className="order-review-user-phone">
                    {phoneNumber}
                  </div>
                  {/* User Phone number */}
                  <div className="order-review-user-address">
                    {address}
                  </div>
                  {/* User State and Country */}
                  <div className="order-review-user-state-country">
                    {city}
                  </div>
                  <div className="order-review-user-state-country">
                    {state}
                  </div>
                </div>

                {/* Change Shipping Address link */}
                {/* <div className="change-address-link change-shipping-address-link">
                  <a href="/shipping">Change shipping address</a>
                </div> */}
              </div>

              {/* Order review Billing box */}
              {/* <div className="order-review-billing-address-box"> */}
                {/* Box Title */}
                {/* <div className="order-review-box-title order-review-billing-box-title">
                  BILLING ADDRESS
                </div> */}

                {/* Order User Billing Data*/}
                {/* <div className="order-review-box-user-data"> */}
                  {/* User name */}
                  {/* <div className="order-review-user-name">
                    {originalCartRows[0].userBillingData.name}
                  </div> */}
                  {/* User Phone number */}
                  {/* <div className="order-review-user-phone">
                    {originalCartRows[0].userBillingData.phoneNumber}
                  </div> */}
                  {/* User Phone number */}
                  {/* <div className="order-review-user-address">
                    {originalCartRows[0].userBillingData.address}
                  </div> */}
                  {/* User State and Country */}
                  {/* <div className="order-review-user-state-country">
                    {originalCartRows[0].userBillingData.state},{" "}
                    {originalCartRows[0].userBillingData.country}.
                  </div>
                </div> */}

                {/* Change Billing Address link */}
                {/* <div className="change-address-link change-billing-address-link">
                  <a href="/payment#billing-address-form">
                    Change billing address
                  </a>
                </div>
              </div> */}

              {/* Order review payment method */}
              <div className="order-review-payment-method-box">
                {/* Box Title */}
                {/* <div className="order-review-box-title order-review-payment-method-box-title">
                  PAYMENT METHOD
                </div> */}

                {/* Order User Billing Data*/}
                <div className="order-review-box-payment-method-image">
                  {/* {paymentMethod === "paypal" ? (
                    // If payment method is PAYPAL
                    <div className="order-payment-method-image-wrapper">
                      <img src={paypalLogo} alt="paypal-logo" />
                    </div>
                  ) : (
                    // if payment methos is CARD
                    <div className="order-payment-method-image-wrapper order-card-payment-wrapper">
                      <img src={mastercardLogo} alt="card-logo" />
                      <img src={visaLogo} alt="card-logo" />
                      <img src={americanExpressLogo} alt="card-logo" />
                    </div>
                  )} */}
                </div>

                {/* Change Billing Address link */}
                {/* <div className="change-address-link change-payment-method-link">
                  <a href="/payment">Change payment method</a>
                </div> */}
              </div>

              {/* Order review delivery information  */}
              <div className="order-review-delivery-time-info-box">
                {/* Box Title */}
                <p>
                  Delivery time will vary depending on country of origin and
                  destination.
                </p>
              </div>
            </div>
          </div>
        </main>
        {/* Lower Actions Link and buttons */}
        <div className="lower-actions-buttons-section">
          <div className="lower-actions-buttons-section--inner">
            {/* Continue Shopping Link */}
            <a href="/artworks" className="action-continue-shopping-wrapper">
              <img src={leftArrow} alt="left-arrow" />
              <p>Continue Shopping</p>
            </a>
          </div>
        </div>
      </div>

      <CartFooter />
    </div>
  );
}

export default Review;
