import axios from "axios";
import { api } from "../../api/config";


export const sendMessageToAdmin = (data, setIsLoading, setErrorHandlerObj, setSuccessHandlerObj,) => dispatch => {
    setIsLoading(true)
    axios
        .post(api + 'snowafrica/contact', data)
        .then(res => {
            setIsLoading(false)

            //  Set the success handler state
            setSuccessHandlerObj({
                isSuccess: true,
                message: "Your message has been successfully sent to the SnowAfrica Team!",
            });


        })
        .catch(err => {
            console.log('ERROR', err);

            setIsLoading(false)
            // Set the error handler state
            setErrorHandlerObj({
                hasError: true,
                message:
                    err?.response?.data ||
                    "Something Went Wrong. Please Check your Connection and try again",
            });
        });
};