import { 
    ADDED_TO_CART, 
    ADDED_ORDER_ITEM_TO_ORDER_CART, 
    DELETED_FROM_CART, 
    GET_ME,
    CLICKED_ORDER,
    GET_ALL_ORDER,
    ORDER_CREATED,
    DELETED_ORDER,
    CREATING_ORDER
} from "../Constants";


const initialState = {
    orders: [],
    cart: [],
    orderCart: [],
    clickedOrder: false,
    allOrder: [],
    updatedOrder: [],
    justCreated: [],
    isFetching: true
}

export default (state = initialState, action) => {
    switch(action.type){
        case GET_ME:
            return {
                ...state,
                orders: action.payload
            }
        case ADDED_TO_CART:
            return {
                ...state,
                cart: action.payload
            }
        case ADDED_ORDER_ITEM_TO_ORDER_CART:
            return {
                ...state,
                orderCart: action.payload
            }
        case DELETED_FROM_CART:
            return {
                ...state,
                cart: action.payload
            }
        case CLICKED_ORDER:
            return {
                ...state,
                clickedOrder: true
            }
        case GET_ALL_ORDER:
            return {
                ...state,
                allOrder: action.payload,
                isFetching: false,
                updatedOrder: action.payload
            }
        case ORDER_CREATED:
            return {
                ...state,
                isFetching: false,
                justCreated: action.payload
            }
        case CREATING_ORDER:
            return {
                ...state,
                isFetching: true
            }
        // case DELETED_ORDER:
        //     return {
        //     }
        default:
            return state
    }
}
