import React, { useState } from "react";
import "./AccountDetails.css";

function AccountDetails() {
  // Functions, States and Variables

  //   STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [accountDetailsPassword, setAccountDetailsPassword] = useState("");
  const [changePasswordOldPassword, setChangePasswordOldPassword] =
    useState("");
  const [changePasswordNewPassword, setChangePasswordNewPassword] =
    useState("");
  const [
    changePasswordNewConfirmPassword,
    setChangePasswordNewConfirmPassword,
  ] = useState("");

  // Functions
  // Handle Profile Form Save
  const handleProfileDataSave = function (e) {
    const profileChangeSuccessMessageWrapper = document.querySelector(
      ".profile-change-success-message"
    );

    if (firstName !== "" && lastName !== "") {
      e.preventDefault();

      const resultData = {
        firstName,
        lastName,
      };
      profileChangeSuccessMessageWrapper.classList.remove("none");
    }

    // Remove the success message after 10 seconds
    setTimeout(() => {
      profileChangeSuccessMessageWrapper.classList.add("none");
    }, 10000);
  };

  // Handle Account Details Form Save
  const handleAccountDetailsDataSave = function (e) {
    if (oldEmail !== "" && newEmail !== "") {
      e.preventDefault();

      const accountDetailsChangeSuccessMessageWrapper = document.querySelector(
        ".account-details-change-success-message"
      );
      const accountDetailsChangFailureMessageWrapper = document.querySelector(
        ".account-details-change-failure-message"
      );

      const resultData = {
        oldEmail,
        newEmail,
        password: accountDetailsPassword,
      };

      // IF password does not match the one on the database
      if (accountDetailsPassword === "") {
        // Hide the change success message if it is opened and ddisplay the change failure message
        accountDetailsChangFailureMessageWrapper.classList.remove("none");
        accountDetailsChangeSuccessMessageWrapper.classList.add("none");
      } else {
        // Hide the change failure message if it is opened and ddisplay the change success message
        accountDetailsChangFailureMessageWrapper.classList.add("none");
        accountDetailsChangeSuccessMessageWrapper.classList.remove("none");
      }

      // Remove the success message after 10 seconds
      setTimeout(() => {
        accountDetailsChangeSuccessMessageWrapper.classList.add("none");
      }, 10000);
    }
  };

  // Handle Change Password Form Save
  const handleChangePasswordDataSave = function (e) {
    // IF the values are not empty
    if (
      changePasswordOldPassword !== "" &&
      changePasswordNewPassword !== "" &&
      changePasswordNewConfirmPassword !== ""
    ) {
      e.preventDefault();
      const passwordNotMatchingMessageWrapper = document.querySelector(
        ".password-not-matching-message"
      );
      const passwordChangeSuccessMessageWrapper = document.querySelector(
        ".password-change-success-message"
      );
      const oldPasswordWrongMessageWrapper = document.querySelector(
        ".old-password-wrong-message"
      );

      // If the new passwords don't match
      if (changePasswordNewPassword !== changePasswordNewConfirmPassword) {
        passwordNotMatchingMessageWrapper.classList.remove("none");
      } else {
        passwordNotMatchingMessageWrapper.classList.add("none");

        // Display success message.
        passwordChangeSuccessMessageWrapper.classList.remove("none");
      }
      // Remove the success message after 10 seconds
      setTimeout(() => {
        passwordChangeSuccessMessageWrapper.classList.add("none");
      }, 10000);
    }
  };

  return (
    <div className="account-details-container">
      <div className="account-details-container--inner">
        {/*Profile Section */}
        <div className="account-details-info--col profile-section">
          <div className="section-title">Profile</div>
          <div className="horizontal-line"></div>

          {/* Account Details Profile form */}
          <div className="profile-section--form">
            <form>
              {/* First Name */}
              <div className="form-group mb-3">
                <label htmlFor="first-name" className="form-label">
                  FIRST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="first-name"
                  aria-describedby="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>

              {/* Last Name */}
              <div className="form-group mb-3">
                <label htmlFor="last-name" className="form-label">
                  LAST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="last-name"
                  aria-describedby="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
              {/* Save Button */}
              <div className="save-button">
                <button onClick={handleProfileDataSave}>SAVE</button>
              </div>

              {/* Success Message Container */}
              <div className="profile-change-success-message change-success-message none">
                <p>You have successfully changed your billing info!</p>
              </div>
            </form>
          </div>
        </div>

        {/* Account Email Section*/}
        <div className="account-details-info--col profile-section account-email-section">
          <div className="section-title">Account Email</div>
          <div className="horizontal-line"></div>

          {/* Account Email Section form */}
          <div className="account-email-section--form profile-section--form">
            <form>
              {/* Old Email Input */}
              <div className="form-group mb-3">
                <label htmlFor="old-email-input" className="form-label">
                  EMAIL:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="old-email-input"
                  aria-describedby="oldEmailInput"
                  value={oldEmail}
                  onChange={(e) => setOldEmail(e.target.value)}
                  required
                />
              </div>
              {/* New Email Input */}
              <div className="form-group mb-3">
                <label htmlFor="new-email-input" className="form-label">
                  NEW EMAIL:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="new-email-input"
                  aria-describedby="newEmailInput"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  required
                />
              </div>

              {/* Password Input */}
              <div className="form-group mb-3">
                <label htmlFor="password-input" className="form-label">
                  ENTER PASSWORD:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password-input"
                  aria-describedby="passwordInput"
                  value={accountDetailsPassword}
                  onChange={(e) => setAccountDetailsPassword(e.target.value)}
                />
              </div>
              <div className="change-failure-message account-details-change-failure-message none">
                <p>Oops! The password was incorrect.</p>
              </div>

              <div className="save-button">
                <button onClick={handleAccountDetailsDataSave}>SAVE</button>
              </div>

              <div className="account-details-change-success-message change-success-message none">
                <p>You have successfully changed your billing info!</p>
              </div>
            </form>
          </div>
        </div>

        {/* Change Password Section*/}
        <div className="account-details-info--col change-password-section">
          <div className="section-title">Change Password</div>
          <div className="horizontal-line"></div>

          {/* Change Password Section form */}
          <div className="change-passoword-section--form profile-section--form">
            <form>
              {/* Old Password Input */}
              <div className="form-group mb-3">
                <label htmlFor="old-password-input" className="form-label">
                  OLD PASSWORD:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="old-password-input"
                  aria-describedby="oldPasswordInput"
                  value={changePasswordOldPassword}
                  onChange={(e) => setChangePasswordOldPassword(e.target.value)}
                  required
                />
              </div>

              <div className="reset-password-wrapper">
                Don't recall old password? <a href="#">Reset</a>
              </div>

              {/* New Password Input */}
              <div className="form-group mb-3">
                <label htmlFor="new-password-input" className="form-label">
                  NEW PASSWORD:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="new-password-input"
                  aria-describedby="newPasswordInput"
                  value={changePasswordNewPassword}
                  onChange={(e) => setChangePasswordNewPassword(e.target.value)}
                  required
                />
              </div>

              {/* Confirm New Password Input */}
              <div className="form-group mb-3">
                <label
                  htmlFor="confirm-new-password-input"
                  className="form-label"
                >
                  RE-TYPE PASSWORD:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirm-new-password-input"
                  aria-describedby="confirmNewPasswordInput"
                  value={changePasswordNewConfirmPassword}
                  onChange={(e) =>
                    setChangePasswordNewConfirmPassword(e.target.value)
                  }
                  required
                />
              </div>
              <div className="password-not-matching-message none">
                <p>Password doesn't match...yet</p>
              </div>

              <div className="save-button">
                <button onClick={handleChangePasswordDataSave}>SAVE</button>
              </div>

              <div className="change-success-message password-change-success-message none">
                <p>You have successfully changed your password!</p>
              </div>

              <div className="old-password-wrong-message none">
                <p>Oops! You typed the old password wrong.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDetails;
