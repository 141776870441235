import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../../images/Snowafrica.png";
import { Link, useNavigate } from "react-router-dom";
import navBurger from "../../../images/landing-page/nav-burger.svg";
import navClose from "../../../images/landing-page/nav-close.svg";
import register from "../../../images/register.jpg";
import {
  loginUser,
  logoutUser,
  registerUser,
} from "../../../redux/actions/authActions";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import cartIcon from "../../../images/cart-icon.svg";
import { getMe } from "../../../redux/actions/profilesAction";
import ErrorHandler from "../../error/ErrorHandler";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";

function createArtworkData(
  id,
  artworkName,
  artistName,
  artworkPrice,
  artworkSubtotalprice,
  artworkCategory,
  artworkSpecs,
  displayImgSrc
) {
  return {
    id,
    artworkName,
    artistName,
    artworkPrice,
    artworkSubtotalprice,
    artworkCategory,
    artworkSpecs,
    displayImgSrc,
  };
}

const cartRows = [
  createArtworkData(
    1,
    "Guernica",
    "Pablo Picasso",
    "650000",
    "900000",
    "Painting",
    {
      width: "20",
      height: "18",
      inches: "0.4",
      frame: "wooden",
      others: "Canvas print",
    },
    "Pic 2015-01-23 17-42-22.jpg"
  ),
  createArtworkData(
    2,
    "The Water Lily Pond",
    "Claude Monet",
    "1230000",
    "1500000",
    "Sculpture",
    {
      width: "32",
      height: "28",
      inches: "0.3",
      frame: "wooden",
      others: "Canvas print",
    },
    "register.jpg"
  ),
  createArtworkData(
    3,
    "Woman with a Parasol",
    "Leonardo Da Vinci",
    "800000",
    "800000",
    "Landscape",
    {
      width: "25",
      height: "28",
      inches: "0.5",
      frame: "wooden",
      others: "Canvas print",
    },
    "register2.jpg"
  ),
];
function Top() {
  // Function, States and variables
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.profile.profile);
  const [show, setShow] = useState(false);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [userData, setUserData] = useState({
  // firstName: "Daniel",
  // lastName: "Adeneye",
  // cart: cartRows,
  // });
  const [showRegister, setRegisterShow] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });
  const [checked, setChecked] = useState(false);
  const [isRegisterFormFilled, setIsRegisterFormFilled] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "user",
    phone: "",
    state: "",
    address: "",
  });

  const allState = [
    { Name: "Adamawa" },
    { Name: "Akwa Ibom" },
    { Name: "Anambra" },
    { Name: "Bauchi" },
    { Name: "Bayelsa" },
    { Name: "Benue" },
    { Name: "Borno" },
    { Name: "Cross River" },
    { Name: "Delta" },
    { Name: "Ebonyi" },
    { Name: "Edo" },
    { Name: "Ekiti" },
    { Name: "Enugu" },
    { Name: "Gombe" },
    { Name: "Imo" },
    { Name: "Jigawa" },
    { Name: "Kaduna" },
    { Name: "Kano" },
    { Name: "Katsina" },
    { Name: "Kebbi" },
    { Name: "Kogi" },
    { Name: "Kwara" },
    { Name: "Lagos" },
    { Name: "Nasarawa" },
    { Name: "Niger" },
    { Name: "Ogun" },
    { Name: "Ondo" },
    { Name: "Osun" },
    { Name: "Oyo" },
    { Name: "Plateau" },
    { Name: "Rivers" },
    { Name: "Sokoto" },
    { Name: "Taraba" },
    { Name: "Yobe" },
    { Name: "Zamfara" },
    { Name: "FCT" },
  ];
  //   Functions

  const handleClose = () => {
    setShow(false);
    setErrorHandlerObj({ hasError: false, message: "" });
  };
  const handleShow = () => setShow(true);

  const handleRegisterClose = () => {
    setRegisterShow(false);
    setErrorHandlerObj({ hasError: false, message: "" });
    setIsRegisterFormFilled(false);
    setNewUser({ ...user, password: "", confirmPassword: "" });
    setChecked(false);
  };
  const handleRegisterShow = () => {
    setRegisterShow(true);
    console.log(newUser);
  };

  const onSubmit = (e) => {
    setErrorHandlerObj({ hasError: false, message: "" });

    if (user.email !== "" && user.password !== "") {
      e.preventDefault();
      const userData = {
        email: user.email,
        password: user.password,
      };
      dispatch(loginUser(userData, navigate, setErrorHandlerObj, setIsLoading));
    }
  };

  const onRegisterSubmit = (e) => {
    e.preventDefault();
    setErrorHandlerObj({ hasError: false, message: "" });
    const registerNewUser = {
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      email: newUser.email,
      password: newUser.password,
      usertype: newUser.userType,
      phone: newUser.phone,
      shippingAddress: newUser.address,
    };

    if (comparePasswords(newUser.password, newUser.confirmPassword) === false) {
      return;
    }
    // dispatch(registerUser(registerNewUser, navigate));
    dispatch(
      registerUser(registerNewUser, navigate, setErrorHandlerObj, setIsLoading)
    );
  };

  const comparePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      const message = {
        message: "Passwords do not match",
      };
      setErrorHandlerObj({
        hasError: true,
        message: "Passwords do not match!",
      });
      return false;
    }
  };

  // Handle Open Top Navbar In <=Tablet View
  const handleTopNavbarOpen = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon");
    const openNavbarIcon = document.querySelector(".navburger-icon");
    const topNavbarModal = document.querySelector(
      ".header--top--right--tablet-modal"
    );

    // Toggle Navbar Icons
    closeNavbarIcon.classList.remove("none");
    openNavbarIcon.classList.add("none");

    // handle top navbar modal open
    topNavbarModal.classList.remove("none");
    //
    //
    //
    // Close the bottom Navbar modal
    const closeBottomNavbarIcon = document.querySelector(
      ".navclose-icon--bottom"
    );
    const openBottomNavbarIcon = document.querySelector(
      ".navburger-icon--bottom"
    );
    const bottomNavbarTabletModal = document.querySelector(
      ".header--bottom--left--tablet-modal"
    );
    // Toggle Navbar Icons
    closeBottomNavbarIcon.classList.add("none");
    openBottomNavbarIcon.classList.remove("none");

    // handle top navbar modal open
    bottomNavbarTabletModal.classList.add("none");
  };

  // Handle Close Top Navbar In <=Tablet View
  const handleTopNavbarClose = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon");
    const openNavbarIcon = document.querySelector(".navburger-icon");
    const topNavbarModal = document.querySelector(
      ".header--top--right--tablet-modal"
    );
    // Toggle Navbar Icons
    closeNavbarIcon.classList.add("none");
    openNavbarIcon.classList.remove("none");
    // handle top navbar modal open
    topNavbarModal.classList.add("none");
  };

  useEffect(() => {
    if (errorHandlerObj.hasError) {
      //scroll to page top
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj]);

  useEffect(() => {
    const topNavbarModal = document.querySelector(
      ".header--top--right--tablet-modal"
    );

    topNavbarModal.classList.add("none");
    if (localStorage.jwtToken) {
      dispatch(getMe());
    }
  }, [location]);

  useEffect(() => {
    if (
      newUser.firstname !== "" &&
      newUser.lastname !== "" &&
      newUser.email !== "" &&
      newUser.password !== "" &&
      newUser.confirmPassword !== "" &&
      checked !== false
    ) {
      setIsRegisterFormFilled(true);
    }
  }, [newUser, checked]);
  return (
    <div className="header--top">
      <div className="header--top--inner">
        {/* Main-top-left */}
        <div className="header--top--left">
          <div className="header--top--left-inner">
            <div className="galleries-link">
              {/* <Link to="/gallery">Galleries</Link> */}
              <Link to="/gallery/coming-soon" state={{ page: "Gallery" }}>
                Galleries
              </Link>
            </div>
            <div className="divider-line">|</div>
            <div className="curators-link">
              {/* <Link to="/curators">Curators</Link> */}
              <Link to="/curators/coming-soon" state={{ page: "Curators" }}>
                Curators
              </Link>
            </div>
            <div className="divider-line">|</div>
            <div className="artists-link">
              {/* <Link to="/artist-dashboard/upload-art">Artists</Link> */}
              <Link to="/artist/coming-soon" state={{ page: "Artists" }}>
                Artists
              </Link>
            </div>
          </div>
        </div>

        {/* Headr Top Middle */}
        <div className="header--top--middle">
          <div className="company-logo flex">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        {/* Header Top Right */}
        <div className="header--top--right">
          {userData && Object.keys(userData).length !== 0 ? (
            // User Name and cart link
            <div className="user-name-and-cart-link-section">
              <div className="user-name-wrapper">{userData?.firstname}</div>

              {/* Cart Icon Wrapper */}
              <div className="cart-icon-link-wrapper">
                <Link to={"/cart"}>
                  <img src={cartIcon} alt="" />
                  <div className="cart-amount">
                    {userData && userData.cart && userData.cart[0] !== null
                      ? userData.cart.length
                      : 0}
                  </div>
                </Link>
              </div>

              {/* Logout btn Wrapper */}
              <div className="logout-button-wrapper">
                <button onClick={() => dispatch(logoutUser())}>
                  <div className="logout-button-text">Log out</div>
                </button>
              </div>
            </div>
          ) : (
            <div className="header--top--right-inner">
              <div className="register-btn" onClick={handleRegisterShow}>
                <p className="cursor">Register</p>
              </div>
              <div className="divider-line">|</div>
              <div className="login-btn" onClick={handleShow}>
                <p className="cursor">Login</p>
              </div>
            </div>
          )}
        </div>

        {/* <=TOP NAVBAR TABLET VIEW SECTION */}
        <div className="header--top--right--tablet">
          <div
            // onClick={handleNavbarBurgerOpen}
            className="open-navbar-burger"
          >
            <img
              onClick={handleTopNavbarOpen}
              src={navBurger}
              alt=""
              className="navburger-icon"
            />
            <img
              onClick={handleTopNavbarClose}
              src={navClose}
              alt=""
              className="navclose-icon none"
            />
          </div>
          {/* Tablet Top navbar */}
          <div className="header--top--right--tablet-modal none">
            {userData && Object.keys(userData).length !== 0 ? (
              <>
                <div className="top--navbar-link">
                  <Link to={"/cart"}>Cart</Link>
                </div>
                <div className="top--navbar-link">
                  <button onClick={() => dispatch(logoutUser())}>
                    <div className="logout-button-text">Log out</div>
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* Register Link */}
                <div
                  onClick={handleRegisterShow}
                  className="top--navbar-link tablet-register-link"
                >
                  Register
                </div>
                {/* Login Link */}
                <div
                  onClick={handleShow}
                  className="top--navbar-link tablet-register-link"
                >
                  Login
                </div>
              </>
            )}
            {/* galleries Link */}
            <div className=" top--navbar-link tablet-galleries-link">
              <Link to="/gallery">Galleries</Link>
            </div>
            {/* Curators Link */}
            <div className="top--navbar-link tablet-curators-link">
              <Link to="/curators">Curators</Link>
            </div>
            {/* Artists Link */}
            <div className="top--navbar-link tablet-artists-link">
              <Link to="/artist-dashboard/upload-art">Artists</Link>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <p className="lead">LOG IN</p>
            <div className="signin-error-wrapper">
              <ErrorHandler
                errorHandlerObj={errorHandlerObj}
                className="signin-error-handler"
              />
            </div>
          </Modal.Header>
          <Modal.Body closeButton>
            <div
              className={`auth-form-container ${
                errorHandlerObj.hasError ? "container-push" : ""
              }`}
            >
              <form>
                <div className="form-group mb-3">
                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Email"
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="submit-button-wrapper">
                  <button
                    type="submit"
                    onClick={onSubmit}
                    className="mylongflatbtn mybtn--blue"
                    style={{ backgroundColor: "#383ba4" }}
                  >
                    LOGIN
                  </button>
                  {isLoading && <LoadingSpinner />}
                </div>
                <p className="lean mt-2">
                  <h6>
                    <Link to="/forgotPassword">Forgot Password?</Link>
                  </h6>
                </p>
              </form>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showRegister}
          onHide={handleRegisterClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <p className="lead">Please fill the form below to register</p>

            <div className="signin-error-wrapper">
              <ErrorHandler
                errorHandlerObj={errorHandlerObj}
                className="signin-error-handler"
              />
            </div>
          </Modal.Header>
          <Modal.Body closeButton>
            <div className="register-modal">
              <div className="register-modal--image">
                <img src={register} />
              </div>
              <form>
                <div
                  className={`auth-form-container ${
                    errorHandlerObj.hasError ? "container-push" : ""
                  }`}
                >
                  <div className="form-group mb-3">
                    <label htmlFor="firstname">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      placeholder="Enter First Name"
                      onChange={(e) =>
                        setNewUser({ ...newUser, firstname: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="lastname">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Enter Last Name"
                      onChange={(e) =>
                        setNewUser({ ...newUser, lastname: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter email"
                      onChange={(e) =>
                        setNewUser({ ...newUser, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      placeholder="Enter your address"
                      onChange={(e) =>
                        setNewUser({ ...newUser, address: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      onChange={(e) =>
                        setNewUser({ ...newUser, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="password">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirm-password"
                      placeholder="Password"
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      I agree to SnowAfrica's{" "}
                      <Link
                        to="/terms"
                        style={{ color: "rgba(20, 20, 255, 0.9)" }}
                      >
                        Terms of Use
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="/privacy"
                        style={{ color: "rgba(20, 20, 255, 0.9)" }}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </label>
                  </div>

                  <div className="submit-button-wrapper">
                    <button
                      type="submit"
                      disabled={error.status || !isRegisterFormFilled}
                      onClick={onRegisterSubmit}
                      className="mylongflatbtn mybtn--blue"
                    >
                      REGISTER
                    </button>
                    {isLoading && <LoadingSpinner />}
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Top;
