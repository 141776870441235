

export function displayPrice(product, currencyContext) {
    const cost = (product.price && currencyContext.currencySelected === "₦")
    ? Math.round(((product.price) + (product.price * 0.35) + (20 * currencyContext.rate))).toLocaleString()
    : (product.price && currencyContext.currencySelected === "$") 
    ? Math.round(((product.price / currencyContext.rate) + ((product.price * 0.35)/currencyContext.rate) + 20)).toLocaleString()
    :  "0";
    // console.log('kkk',cost)
    // console.log('kkk1',product.price)
    // console.log('kkk2',currencyContext.rate)
    return cost


}