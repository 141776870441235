import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import logo from "../.././images/snowafrica-grey.png";
import "./Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <div className="footer-main">
        <Container>
          <Row>
            <Col sm>
              <p className="center">
                <img
                  className="mt-5 mb-2"
                  src={logo}
                  height="40px"
                  alt="logo"
                />
              </p>
            </Col>
            <Col sm>
              <div className="footer-link">
                <h5 className="heading-5 mb-3">FOR ARTISTS</h5>
                <p className="lean">
                  <Link to="/artist-register">Join the Community</Link>
                </p>
                <p className="lean">
                  <Link to="/faq">FAQ</Link>
                </p>
                <p className="lean">
                  <Link
                    to={process.env.PUBLIC_URL + "/doc/Artist-handbook.pdf"}
                    target="_blank"
                  >
                    Artist Handbook
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-link">
                <h5 className="heading-5 mb-3">FOR ART BUYERS</h5>
                <p className="lean">
                  <Link to="/faq">Buyer FAQ</Link>
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-link">
                <h5 className="heading-5 mb-3">OUR COMPANY</h5>
                <p className="lean">
                  <Link to="/about-us">About us</Link>
                </p>
                <p className="lean">
                  <Link to="/contact-us">Contact us</Link>
                </p>
                <p className="lean center">
                  <Link to="http://blog.snowafrica.art">Blog</Link>
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-link">
                <h5 className="heading-5 mb-3">LEGALS</h5>
                <p className="lean">
                  <Link to="/terms">Term of Service</Link>
                </p>
                <p className="lean">
                  <Link to="/privacy">Privacy Policy</Link>
                </p>
                <p className="lean center">
                  <Link to="/copyright">Copyright Policy</Link>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm={2}>
              <div className="footer-socials">
                <p className="lean">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/snowafrica"
                  >
                    <i className="fa fa-facebook text-blue"></i>
                  </a>
                </p>
                <p className="lean">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.twitter.com/snowafrica"
                  >
                    <i className="fa fa-twitter text-blue"></i>
                  </a>
                </p>
                <p className="lean">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram/snowafrica"
                  >
                    <i className="fa fa-instagram text-blue"></i>
                  </a>
                </p>
                <p className="lean">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/snowafrica"
                  >
                    <i className="fa fa-linkedin text-blue"></i>
                  </a>
                </p>
              </div>
            </Col>
            <Col sm={1}></Col>
            <Col sm={8}>
              <p className="lean">SUBSCRIBE TO OUR NEWSLETTER</p>
              <div className="fotter-form">
                <input
                  className="form-input"
                  placeholder="john@email.com"
                  type="text"
                />
                <button className="myflatbtn mybtn--blue">Subscribe</button>
              </div>
            </Col>
          </Row>
          <hr />
          <p className="lean">(c) {year} CopyRight SnowAfrica</p>
        </Container>
      </div>
    </>
  );
};

export default Footer;
