const InsertOtherImages = (setPreviewOtherImgOne, setPreviewOtherImgTwo, setPreviewOtherImgThree, setPreviewOtherImgFour) => {

    //SCRIPT TO GET OTHER NON-COMPULSORY IMAGES FROM ADD OTHER IMAGES BTN
    return () => {
        const insertImgOne = document.querySelector(
            "#insert-other-image-selector-one"
        );
        const getFileImageOne = document.querySelector(
            "#insert-other-image-input-1"
        );
        const insertImgTwo = document.querySelector(
            "#insert-other-image-selector-two"
        );
        const getFileImageTwo = document.querySelector(
            "#insert-other-image-input-2"
        );
        const insertImgThree = document.querySelector(
            "#insert-other-image-selector-three"
        );
        const getFileImageThree = document.querySelector(
            "#insert-other-image-input-3"
        );
        const insertImgFour = document.querySelector(
            "#insert-other-image-selector-four"
        );
        const getFileImageFour = document.querySelector(
            "#insert-other-image-input-4"
        );

        // Get all the insert image elements and insert image inputs into an array to be able to iterate through them
        // All insert image elements
        const allInsertImgElements = [insertImgOne, insertImgTwo, insertImgThree, insertImgFour]
        // All insert image inputs
        const allGetFileImageInputs = [getFileImageOne, getFileImageTwo, getFileImageThree, getFileImageFour]

        let clickedInputIndex;

        // Image 1
        // Map through the insert image elements and listen for a click and activate the input click
        allInsertImgElements.map((elem, i) => {
            // Calls click method on the hidden input on click
            return elem.addEventListener("click", function (e) {
                e.stopImmediatePropagation();
                allGetFileImageInputs[i].click();
                clickedInputIndex = i + 1;
            });

        })
        // Map through the inputs and listen for a change in value and set the previewOtherImg state and also give the preview fxn the relative parameter
        allGetFileImageInputs.map((fileInput) => {
            return fileInput.addEventListener("change", function () {
                previewImgOne(fileInput.files[0]);
                // setting the correct state for the file images selected based on the clickes input
                if (clickedInputIndex === 1) {
                    setPreviewOtherImgOne(fileInput.files[0]);
                } else if (clickedInputIndex === 2) {
                    setPreviewOtherImgTwo(fileInput.files[0]);
                } else if (clickedInputIndex === 3) {
                    setPreviewOtherImgThree(fileInput.files[0]);
                } else {
                    setPreviewOtherImgFour(fileInput.files[0]);
                }
            });
        })

        // Set the variables and parameters to the image selected

        // Change the src of the main img preview section based on the clicked input selected
        function previewImgOne(file) {
            const img = document.querySelector(`#preview-other-img-${clickedInputIndex}`);
            img.classList.add("obj");
            img.file = file;

            const reader = new FileReader();
            reader.onload = (function (image) {
                return function (e) {
                    image.src = e.target.result;
                };
            })(img);
            reader.readAsDataURL(file);
            // Removes the invisibility
            img.classList.add("visible");
            // console.log(previewOtherImgOne);
        }

    }
}

export default InsertOtherImages;