import axios from 'axios';
import { api } from '../../api/config';
import {
  GET_ERRORS,
  SEE_ARTWORK,
  GET_PRODUCTS,
  GET_SAMPLE_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_FEATURED,
  ADD_PRODUCT_TO_COLLECTION,
  CLEAR_LOADING,
  UPLOAD_PRODUCT,
  EDIT_PRODUCT,
  VIEW_ARTWORK,
  GET_PRODUCTS_AS_PROPS,
  VIEW_COLLECTION,
  COLLECTION_PUSHED,
  GET_STATUS,
  GETTING_ARTWORK_FROM_CURATOR_LIST,
  GET_ALL_PRODUCTS_BY_ADMIN,
  IS_LOADING_DATA_ON,
  IS_LOADING_DATA_OFF,
  COMMENT_SUCCESSFUL,
  UPDATED_CURATOR_LIST,
  SEARCH_ME
} from '../Constants';
import { getCollections, getMe } from './profilesAction';
import { useSelector, useDispatch } from 'react-redux'

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["accesstoken"] = token;

// Get Collections
export const getProducts = userData => dispatch => {
  axios
    .post(api + 'snowafrica/product', userData)
    .then(res => {
      dispatch({
        type: GET_PRODUCTS,
        payload: res.data.data,
      });
    })
    .catch(err => {
      if (!err.response) {
        return dispatch({
          type: GET_ERRORS,
          payload: 'Something went wrong',
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getSampleProduct = userData => dispatch => {
  axios
    .post(api + 'snowafrica/sample-product', userData)
    .then(res => {
      dispatch({
        type: GET_SAMPLE_PRODUCTS,
        payload: res.data.data,
      });
    })
    .catch(err => {
      if (!err.response) {
        return dispatch({
          type: GET_ERRORS,
          payload: 'Something went wrong',
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Get a single product
export const getProductById = id => dispatch => {
  // console.log('jjjj', id)
  axios
    .post(api + 'snowafrica/product/user/title', id)
    .then(res => {
      // console.log('response',res);
      dispatch({
        type: GET_PRODUCT_BY_ID,
        payload: res.data.data,
      });
    })
    .catch(err => {
      if (!err.response) {
        return dispatch({
          type: GET_ERRORS,
          payload: 'Something went wrong',
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

//Get Featured
export const getFeatured = userData => dispatch => {
  axios
    .post(api + 'snowafrica/product/getfeatured', userData)
    .then(res => {
      dispatch({
        type: GET_FEATURED,
        payload: res.data.data,
      });
    })
    .catch(err => {
      if (!err.response) {
        return dispatch({
          type: GET_ERRORS,
          payload: 'Something went wrong',
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Upload Product
export const uploadProduct = userData => dispatch => {
  // console.log(userData.image);
  axios
    .post(api + 'snowafrica/upload/', userData.image)
    .then(res => {
      if (res.status === 200 && res.data.Location) {
        userData.product.photo = res.data.Location;
        axios
          .post(api + 'snowafrica/product/create', userData.product)
          .then(res => {
            dispatch({
              type: UPLOAD_PRODUCT,
              payload: false,
            });
          });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const editProduct = product => dispatch => {
  // console.log(userData.image);
  axios
    .put(api + 'snowafrica/product/edit', product)
    .then(res => {
            dispatch({
              type: EDIT_PRODUCT,
              payload: res.data.message,
            });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

export const seeArtwork = product => dispatch => {
  console.log('P', product);
  axios
    .put(api + 'snowafrica/product/view', product)
    .then(res => {
      console.log('hi there',res);
            dispatch({
              type: SEE_ARTWORK,
              payload: res.data.message,
            });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

//Add Product to Collections
export const addProductToCollection = userData => dispatch => {
  axios
    .post(api + 'snowafrica/curatorlist/addItemToList', userData)
    .then(res => {
      if (res.data.message === 'CuratorList updated successfully') {
        dispatch({
          type: ADD_PRODUCT_TO_COLLECTION,
          payload: true,
        });
        dispatch(getCollections());
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

//View Artwork
export const viewArtwork = userData => dispatch => {
  dispatch({
    type: VIEW_ARTWORK,
    payload: userData,
  });
};

//Clear Loading
export const clearLoading = () => dispatch => {
  dispatch({
    type: CLEAR_LOADING,
    payload: null,
  });
};

export const sendProductAsProps = (data, name) => dispatch => {
  const all = {
    data,
    name,
  };
  dispatch({
    type: GET_PRODUCTS_AS_PROPS,
    payload: all,
  });
};

export const sendProductAsPropsPrice = (data, name, value) => dispatch => {
  const all = {
    data,
    name,
    value,
  };
  dispatch({
    type: GET_PRODUCTS_AS_PROPS,
    payload: all,
  });
};

export const collectionView = data => dispatch => {
  dispatch({
    type: VIEW_COLLECTION,
    payload: data,
  });
};

export const collectionsToList = data => dispatch => {
  dispatch({
    type: COLLECTION_PUSHED,
    payload: data,
  });
};

export const deleteProduct = data => dispatch => {
  axios
    .delete(api + 'snowafrica/product/', data)
    .then(res => { 
      dispatch({
        type: VIEW_ARTWORK,
        payload: {},
      });
    })
    .catch(err => {
      // console.log("FAILED DELETE", err)
    });
};

export const updateProduct = data => dispatch => {
  axios
    .put(api + 'snowafrica/product/', data)
    .then(res => {
      // console.log('PRODUCT RESPONSE', res);
      // window.location.reload();
    })
    .catch(err => {
      console.log('ERROR FROM UPDATING', { err });
    });
};

export const populateArtworkDetailFromCuratorList = data => dispatch => {
  dispatch({
    type: GETTING_ARTWORK_FROM_CURATOR_LIST,
    payload: data,
  });
};

export const getAllProductsByAdmin = () => dispatch => {
  // getting all products by the admin
  axios
    .get(api + 'snowafrica/product/forAdmin')
    .then(res => {
      dispatch({
        type: GET_ALL_PRODUCTS_BY_ADMIN,
        payload: res.data.data,
      });
    })
    .catch(err => {
      console.log('ERROR', { err });
    });
};

export const authorizeAnArtwork = data => dispatch => {
  axios
    .put(api + 'snowafrica/product/authorize', data)
    .then(res => {
      dispatch(getAllProductsByAdmin());
    })
    .catch(err => {});
};

export const deauthorizeArtwork = data => dispatch => {
  axios
    .put(api + 'snowafrica/product/deauthorize', data)
    .then(res => {
      dispatch(getAllProductsByAdmin());
    })
    .catch(err => {});
};

export const makeFeatured = data => dispatch => {
  axios
    .put(api + 'snowafrica/product/makefeatured', data)
    .then(res => {
      dispatch(getAllProductsByAdmin());
    })
    .catch(err => {});
};

export const removeFeatured = data => dispatch => {
  axios
    .put(api + 'snowafrica/product/removefeatured', data)
    .then(res => {
      dispatch(getAllProductsByAdmin());
    })
    .catch(err => {});
};

export const toggleLoadingOn = () => dispatch => {
  dispatch({
    type: IS_LOADING_DATA_ON,
  });
};

export const toggleLoadingOff = () => dispatch => {
  dispatch({
    type: IS_LOADING_DATA_OFF,
  });
};

export const addCommentToArtwork = data => dispatch => {
  axios
    .post(api + 'snowafrica/curatorlist/editItem', data)
    .then(res => {
      dispatch({
        type: COMMENT_SUCCESSFUL,
        payload: res.data.data,
      });
      dispatch(getMe());
    })
    .catch(err => {
      console.log('ERROR FROM UPDATING COMMENT');
    });
};

export const updateCuratorList = data => dispatch => {
  console.log('TESTING IF THIS WORKS', data);
  // axios
  //   .put(api + "snowafrica/curatorlist", data)
  //   .then(res => {
  //     console.log("RESPONSE", res.data.data)
  //   })
  //   .catch(err => {
  //     console.log("ERROR", err)
  //   })
  //
};

export const getCuratorListById = data => dispatch => {
  axios
    .post(api + 'snowafrica/curatorlist', data)
    .then(res => {
      dispatch({
        type: UPDATED_CURATOR_LIST,
        payload: res.data.data,
      });
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};

export const getSearchResult = data => dispatch => {
  axios
    .post(api + 'snowafrica/product/search', data)
    .then(res => {
      dispatch({
        type: SEARCH_ME,
        payload: res.data.data,
      });
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};
