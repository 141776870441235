import React from "react";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="container mb-4">
        <h2 className="mt-4 mb-4 text-center">SNOWAFRICA TERMS OF USE </h2>
        <h4 className="mt-4 mb-4">Last Updated: May 20, 2020 </h4>
        <p>
          Welcome to SnowAfrica. These Terms of Use ("Terms") are an agreement
          between you ("you" or "your") and Art.sy, Inc. d/b/a SnowAfrica
          ("SnowAfrica" or "we" or "our" or "us") that governs your access to
          and use of our website, products, and services (collectively,
          "Services"). By accessing or using our Services in any way, you agree
          to these Terms. If you do not agree to these Terms, you are not
          permitted to access or use our Services. Please read these Terms
          carefully, and feel free to
          <a href="mailto:info@SnowAfrica.art"> contact us</a> if you have any
          questions.{" "}
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          1. BASIC TERMS
        </h4>
        <p>
          1.1 You may use our Services only if you can form a binding contract
          with us, and only as permitted by law. You are not allowed to use our
          Services if you are a person under the age of thirteen (13).
          Additional eligibility requirements may apply to some Services, and we
          will notify you of those requirements in these Terms or otherwise in
          connection with those Services.{" "}
        </p>
        <p>
          1.2 If you are using our Services on behalf of a company or other
          entity, you represent and warrant that you are at least eighteen (18)
          years old, and that you are authorized to bind that company or other
          entity to these Terms, in which case the terms "you" and "your" in
          these Terms will refer to that entity.{" "}
        </p>
        <p>
          1.3 We offer a variety of Services, and additional guidelines, terms
          and conditions may apply to some Services ("Service Terms"). By using
          those Services, you agree to their Service Terms. If any of our
          Services have Service Terms that conflict with these Terms, those
          Service Terms will control to the extent of the conflict as relates to
          those Services.{" "}
        </p>
        <p>
          1.4 For information about our privacy practices, please see our
          Privacy Policy, which can be viewed at
          https://snowafrica.art/privacypolicy . Our Privacy Policy is
          incorporated into and considered part of these Terms. By accessing or
          using our Services in any way, you agree that your information may be
          collected, stored, shared, processed, and used in accordance with our
          Privacy Policy and these Terms.{" "}
        </p>
        <p>
          1.5 You will need to register for an SnowAfrica account in order to
          use some of our Services. You agree to provide only true and accurate
          information in connection with your account, and to update all such
          information as necessary to keep it accurate and current.{" "}
        </p>
        <p>
          1.6 You are responsible for maintaining the confidentiality of your
          account password, and for all activities that occur under or through
          your account or password. You agree to notify us immediately if you
          suspect or become aware of any unauthorized access to or use of your
          account or password or other breach of security on our Services.{" "}
        </p>
        <p>
          1.7 The permission we give you to register for an SnowAfrica account
          and use our Services under these Terms is non-exclusive and
          non-transferable. You are not allowed to rent, sell, lease, sublicense
          or otherwise transfer your SnowAfrica account, or any access to or use
          of our Services, to any third party.{" "}
        </p>
        <p>
          1.8 We may reclaim or repurpose any username or URL on our Services,
          at any time at our sole discretion, for any reason, including if we
          believe it is necessary to comply with the rights of a third party.{" "}
        </p>
        <p>
          1.9 Some of our Services may be accessible on mobile devices. You
          agree not to use those Services in a way that distracts you and
          prevents you from complying with any traffic or safety laws.{" "}
        </p>
        <p>
          1.10 You consent to receive communications from us electronically, and
          you agree that we may communicate with you by posting notices on our
          Services and/or by email. You agree that all agreements, notices,
          disclosures and other communications that we provide to you
          electronically satisfy any legal requirement that such communications
          be in writing. You agree that all notices we provide to you by email
          will be considered received by you on the day that we send them. If
          you register for an SnowAfrica account or otherwise provide us with an
          email address, you agree that we are not responsible for any automatic
          filtering that you or your network provider may apply to any email
          that we send to the email address you provided. If at any time you
          would like to withdraw your consent to receive emails, please use the
          "unsubscribe" button on the email, or contact us at
          privacy@SnowAfrica.net to do so, however it is understood and agreed
          by you that if you withdraw consent, you may not be able to
          participate in all Services offered.{" "}
        </p>
        <p>
          1.11 You are responsible for providing any equipment (such as
          computers or mobile devices) and network access necessary for you to
          use our Services or communicate with us at your own cost.{" "}
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          2. DEFINITIONS
        </h4>
        <h4 className="mt-4 mb-4">In these Terms: </h4>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            "User" means any person or entity that accesses or uses our Services
            in any way, whether or not they register for an SnowAfrica account,
            including you.
          </li>
          <li>
            "Content" means any and all images, text, information, data, audio,
            video, graphics, computer code, software, and other material
            provided on or through our Services, including when we send you
            email. Content includes both SnowAfrica Content and User Content.
          </li>
          <li>
            "SnowAfrica Content" means any and all Content that we provide on or
            through our Services, including Content licensed from a third party,
            but excluding User Content.
          </li>
          <li>
            "User Content" means any and all Content that a User submits, posts,
            publishes or otherwise provides on or through our Services.
          </li>
          <li>
            "Your User Content" means any and all User Content that you submit,
            post, publish or otherwise provide on or through our Services.
          </li>
          <li>
            "on our Services" means on the SnowAfrica website and/or (as
            applicable) on any SnowAfrica mobile application.
          </li>
          <li>
            "including" means "including but not limited to" unless we
            specifically indicate otherwise.
          </li>
        </ul>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          3. OWNERSHIP
        </h4>
        <p>
          3.1 All Content is owned by SnowAfrica or by others who have licensed
          their Content to us, and is protected by U.S. and international
          copyright laws, trademark laws, and/or other proprietary rights and
          laws. Our Services are also protected as a compilation and/or
          collective work under U.S. and international copyright laws.{" "}
        </p>
        <p>
          3.2 The trademarks, service marks, logos, and product names displayed
          on or in connection with our Services are the registered and
          unregistered trademarks and service marks of SnowAfrica or third
          parties in the U.S. and/or other countries.{" "}
        </p>
        <p>
          3.3 As between you and SnowAfrica, SnowAfrica owns and retains, solely
          and exclusively, all rights, title, and interest in and to our
          Services, the look and feel, design and organization of our Services,
          all SnowAfrica Content, and the compilation of all Content on our
          Services, including all copyrights, moral rights, trademark rights,
          trade secrets, patent rights, database rights, and other intellectual
          property and proprietary rights therein.{" "}
        </p>
        <p>
          3.4 These Terms do not grant you any ownership over any Content, or
          any intellectual property rights in any Content, although you remain
          the owner of any intellectual property rights that you may have in
          Your User Content.{" "}
        </p>
        <p>
          3.5 Neither these Terms nor your use of our Services grants you any
          license or permission under any copyright, trademark, or other
          intellectual property of SnowAfrica or any third party, whether by
          implication or otherwise.{" "}
        </p>
        <p>
          3.6 These Terms do not grant you the right to use any of our
          trademarks, service marks, logos, product names, domain names, or
          other distinctive brand features in any way.{" "}
        </p>
        <p>
          3.7 We welcome feedback, comments and suggestions about our Services
          ("Feedback"). However, you acknowledge and agree that we will have the
          right to use any and all Feedback at our sole discretion, for any and
          all purposes, commercial or otherwise, without any obligation of any
          kind to you. In any case, we will have no obligation to act on, use or
          respond to any Feedback in any way.{" "}
        </p>
        <p>
          3.8 We reserve all rights not expressly granted to you in these Terms.{" "}
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          4. GENERAL CONTENT TERMS
        </h4>
        <p>
          4.1 Please note that Content covers a wide range of art and subject
          matter, is generally uncensored, and may include nudity or other
          visual or written material that some people may consider offensive or
          inappropriate for children and minors. If you allow your child or
          anyone else to use your computer or other device, it is solely your
          responsibility to prevent them from accessing any Content that you
          think is or may be inappropriate for them.{" "}
        </p>
        <p>
          4.2 Subject to these Terms, you may access and view Content within our
          Services for your own personal, non-commercial use, in the context of
          your SnowAfrica User experience, in accordance with the normal
          functionality and restrictions of our Services.{" "}
        </p>
        <p>
          4.3 We value open source software, and some software used in our
          Services may be offered under an open source license that we will make
          available to you. The open source license may contain provisions that
          override some of these Terms as relates to the use of that software.{" "}
        </p>
        <p>
          4.4 Some Services may enable you to post, embed or transmit some
          Content in a way that will be accessible to others on our Services or
          in other locations online (such as other websites and social media
          services). You may use such Services in accordance with their normal
          functionality and restrictions, as permitted by any applicable Service
          Terms. However, the availability of such Services does not imply or
          give you permission to reproduce, distribute or otherwise use such
          Content in any other way, whether on our Services or in any other
          location.{" "}
        </p>
        <p>
          4.5 If you are a teacher, you may display Content on our Services to
          your students at a non-commercial indoor lecture or seminar conducted
          by you, provided you do not reproduce or distribute Content outside
          our Services in any way that is not specifically otherwise permitted
          by these Terms. Also, if any of your students access or use our
          Services, these Terms will apply to each of those students
          individually. However, please note that as per section 1.1, your
          students are not allowed to use our Services if they are under the age
          of thirteen (13).{" "}
        </p>
        <p>
          4.6 Except as expressly permitted by these Terms, you must not
          reproduce, distribute, adapt, modify, translate, create derivative
          works from, publish or otherwise use any Content in any way without
          express prior written permission from us or (as applicable) the
          appropriate third-party rights holder.{" "}
        </p>
        <p>
          4.7 Any commercial exploitation of any image or other Content without
          express prior written permission from us or (as applicable) the
          appropriate third-party rights holder, including any commercialized
          reproduction, distribution, publishing, or creation of derivative
          works, is strictly prohibited.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          5. USER CONTENT AND COMMUNICATIONS
        </h4>
        <p>
          5.1 Some Services may enable Users to submit, post, publish or
          otherwise provide User Content. Each User is solely responsible for
          all User Content that they provide on or through our Services.{" "}
        </p>
        <p>
          5.2 We do not endorse or make any representations or warranties of any
          kind with respect to any User Content and/or any statements, ideas,
          advice or opinions communicated on, through, or in connection with our
          Services (whether online, offline, orally, in writing or otherwise) by
          any User and/or third party, whether with respect to accuracy,
          completeness, truthfulness, reliability or otherwise. You acknowledge
          that any use of or reliance on any User Content and/or any such
          statements, ideas, advice or opinions is solely at your own risk.{" "}
        </p>
        <p>
          5.3 You acknowledge that we have no obligation to review or screen any
          User Content, and that by using our Services, you may be exposed to
          User Content that is inaccurate, misleading, offensive or otherwise
          objectionable.{" "}
        </p>
        <p>
          5.4 Some User Content may be marked as "featured" (or marked with
          other similar language) when it is provided on or through our
          Services. Such markings are for general informational use only and are
          not an endorsement, representation or warranty of any kind by us.{" "}
        </p>
        <p>
          5.5 We reserve the right, but have no obligation, to monitor and/or
          review any and all User Content and/or communications transmitted on
          or through our Services, to enforce or investigate potential
          violations of these Terms or our other policies or agreements with
          Users, to detect, prevent or otherwise address fraud, security or
          technical issues, or to otherwise administer, improve or operate our
          Services and/or customer support, without notice or liability, at any
          time at our sole discretion. We also reserve the right to access,
          preserve and disclose any information if we believe it is reasonably
          necessary to: (i) respond to claims against us or comply with any law,
          regulation, legal process or governmental request; (ii) enforce or
          administer these Terms or our other policies or agreements with Users;
          (iii) conduct customer support, or detect, prevent or otherwise
          address fraud, security or technical issues; or (iv) protect or
          enforce the rights, property or safety of SnowAfrica, you, or others.{" "}
        </p>
        <p>
          5.6 We reserve the right to remove or refuse to display any User
          Content on our Services, in whole or part, if we believe that such
          User Content may violate these Terms, the law or any third-party
          rights, or for any other reason, without notice or liability, at any
          time at our sole discretion.{" "}
        </p>
        <p>
          5.7 You acknowledge that you are in the best position to know if Your
          User Content may be used in connection with our Services. You are
          solely responsible for ensuring that Your User Content does not
          violate any law or regulation, or any right or intellectual property
          of any third party, including any copyright, moral right, trademark
          right, trade secret, patent right, privacy right, publicity right, or
          contractual right.{" "}
        </p>

        <p>
          5.8 By submitting, posting, publishing or otherwise providing any User
          Content on or through our Services, you grant us a non-exclusive,
          worldwide, perpetual, irrevocable, royalty-free, sublicensable
          (through multiple tiers) right and license to use, host, store,
          process, reproduce, distribute, publish, adapt, modify, translate,
          create derivative works from, publicly perform, and publicly display
          Your User Content, in any and all media now known or later developed,
          for the purposes of operating, distributing, promoting, and improving
          our Services, and developing new Services. You represent and warrant
          that: (i) you own or otherwise control all of the rights to Your User
          Content; and (ii) the use of Your User Content does not violate these
          Terms and will not violate any right of, or cause injury to, any
          person or entity.{" "}
        </p>
        <p>
          5.9 Nothing in these Terms will restrict any other rights that we may
          have or later obtain with respect to Your User Content, such as rights
          under applicable laws or other licenses.{" "}
        </p>
        <p>
          5.10 We will not be liable or responsible for any User Content, or for
          any use of Your User Content by us in accordance with these Terms.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          6. OPEN ACCESS IMAGES
        </h4>
        <p>
          6.1 Certain images of artwork or other objects displayed on our
          Services may be available to download and use independently outside
          our Services ("Open Access Images"). Open Access Images are sourced
          from third parties (such as certain museums or other organizations)
          that have made the images available for unrestricted use, or for use
          under certain conditions (such as attribution requirements or
          additional licenses).{" "}
        </p>
        <p>
          6.2 In order to notify you which images are Open Access Images, we
          will provide a button marked "Download Image" (or an equivalently
          marked image downloading tool) with each Open Access Image on our
          Services ("Image Downloading Tool"). Where available, the Image
          Downloading Tool will be provided on the listing page for the artwork
          or other object depicted in an Open Access Image. Please note that if
          we do not provide an Image Downloading Tool with an image on our
          Services, that image is not considered an Open Access Image under
          these Terms.{" "}
        </p>
        <p>
          6.3 You may download Open Access Images from our Services using the
          Image Downloading Tool that we provide, and you may use Open Access
          Images without restriction, subject to any applicable laws, and any
          applicable third-party rights and conditions. However, please note
          that this permission applies only to any rights that SnowAfrica itself
          may have with respect to the use of Open Access Images.{" "}
        </p>
        <p>
          6.4 We are not granting any permission or otherwise acting on behalf
          of any third party in connection with any Open Access Images. We do
          not represent or warrant that the use of any Open Access Images in any
          way will not violate the copyrights or other rights of third parties
          (such as trademark rights, privacy rights or publicity rights).{" "}
        </p>
        <p>
          6.5 When you download or use Open Access Images in any way, you are
          solely responsible for determining and complying with any applicable
          third-party rights and conditions.{" "}
        </p>
        <p>
          6.6 To help you determine what conditions may apply to the use of Open
          Access Images, certain rights information, source-identifying
          information, and conditions may be posted on our Services (for
          example, as part of the listing for the artwork or other object
          depicted in an Open Access Image). However, you agree that we have no
          obligation to post any such information or conditions on our Services,
          and that whether or not any such information or conditions are posted
          on our Services, additional conditions not posted on our Services may
          still apply, and you are responsible for determining and complying
          with them.{" "}
        </p>
        <p>
          6.7 You acknowledge and agree that: (i) your use of Open Access Images
          is at your own risk; and (ii) we will not be liable to you or any
          third party for any claims, demands, damages or losses of any kind
          resulting directly or indirectly from your use of or inability to use
          any Open Access Images in any way.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          7. MARKETPLACE
        </h4>
        <p>
          7.1 Our Services include an online marketplace where Users may browse,
          buy, bid on, list, market, offer for sale, and sell artwork and other
          property. Our Services also include features that enable Users to
          contact and communicate with us or other Users about the property
          listed on our Services or otherwise.{" "}
        </p>
        <p>
          7.2 Minors are not allowed to use our Services as buyers, bidders or
          sellers. Any person that uses our Services to buy, bid on, list,
          market, offer or sell property represents and warrants that they are
          at least eighteen (18) years old and are otherwise able to enter into
          and form binding contracts under applicable law.{" "}
        </p>
        <p>
          7.3 Users must receive our express written permission and may be
          required (at our sole discretion) to provide additional information
          and/or to meet additional eligibility requirements before they are
          allowed to list, market, offer or sell property on or through our
          Services. For example, sellers may need to complete an additional
          registration process. These Terms apply to sellers, but these Terms
          alone do not give you permission to use our Services as a seller. As
          an additional example, Users who may wish to bid and/or buy in
          auctions may also be required to provide additional documentation
          (including, without limitation, financial information) before being
          permitted to do so.{" "}
        </p>
        <p>
          7.4 Our Conditions of Sale contain important information and
          additional terms and conditions that apply to our marketplace. It is
          your responsibility to read them carefully before buying, bidding on,
          inquiring about, listing, marketing, offering or selling any property
          on or through our Services. Our Conditions of Sale are incorporated
          into and considered part of these Terms.
        </p>
        <p>
          7.5 As a marketplace, unless otherwise agreed to or noted in writing,
          we do not own, take title to, have possession of, or sell the property
          listed, marketed, offered or sold on or in connection with our
          Services (whether online or offline). We do not sell property on
          behalf of the seller. All property is offered for sale and sold
          directly from the seller to the buyer, and we are not a party to any
          sale. We do not transfer, and are not responsible for transferring,
          legal ownership or physical possession of property from the seller to
          the buyer.{" "}
        </p>
        <p>
          7.6 We may facilitate communication or payment between buyers,
          prospective buyers, bidders, and sellers in connection with some
          property. However, such Services are offered for convenience only. We
          have no agency authority and are not the agent of any buyer,
          prospective buyer, bidder or seller for any purpose. Our involvement
          in any sale transaction is limited to providing this marketplace, and,
          in some cases, accepting payment from the buyer on behalf of the
          seller in connection with the sale of some property. We may, but are
          not obligated to, provide intermediary services between buyers,
          prospective buyers, bidders, and sellers in connection with customer
          service or dispute resolution matters. In the event we elect in our
          sole discretion to provide intermediary services, we will attempt to
          help you resolve disputes in good faith and based solely on our
          interpretation of our policies, in our sole discretion; we will not
          make judgments regarding legal issues or claims. Our decision is final
          and binding on all parties and cannot be appealed, challenged or
          reversed.{" "}
        </p>
        <p>
          7.7 We do not endorse or control and are not responsible for the
          conduct (whether online or offline) of any buyer, prospective buyer,
          bidder or seller. We make no representations or warranties as to the
          character, reputation, policies or practices of any buyer, prospective
          buyer, bidder or seller. We make no representations or warranties that
          any buyer, prospective buyer, bidder or seller will complete any
          transaction or otherwise perform as promised (whether or not we
          collect or agree to collect payment from any buyer on behalf of any
          seller).{" "}
        </p>
        <p>
          7.8 We do not endorse or make any representations or warranties of any
          kind, express or implied, with respect to: (i) Users' listings on our
          Services or related User Content, whether as to accuracy,
          completeness, truthfulness, reliability or otherwise; or (ii) any
          property listed, marketed, offered or sold (whether online or offline)
          by any User and/or third party, whether as to quality, size,
          condition, description, provenance, attribution, authenticity,
          legality, merchantability, fitness for a particular purpose, or
          otherwise.{" "}
        </p>
        <p>
          7.9 No statement made by any seller about any property (whether
          orally, in writing, on our Services or otherwise) will be considered a
          representation, warranty, or assumption of liability of any kind by
          us.{" "}
        </p>
        <p>
          7.10 We reserve the right, at any time at our sole discretion, to
          refuse to list, de-list, or delay or suspend listing of any property
          on our Services. We will not be liable to any User for doing so.{" "}
        </p>
        <p>
          7.11 We may receive a commission from some sellers on some sales,
          subject to separate agreements between us and those sellers.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          8. GENERAL RESTRICTIONS
        </h4>
        <p>
          8.1 You agree that you will not (and will not attempt to, or assist or
          encourage any other person or entity to):{" "}
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            "submit, post, publish or otherwise provide any User Content on or
            through our Services, or take any other action in connection with
            our Services (whether online or offline), that: (i) infringes,
            misappropriates or violates the rights or intellectual property of
            any third party, including any copyright, moral right, trademark
            right, trade secret, patent right, privacy right, publicity right,
            or contractual right: (ii) violates (or encourages any conduct that
            would violate) any applicable local, state, national, or
            international law or regulation, including any tax law, consumer
            protection law, or law governing the export or import of data,
            goods, services or software; (iii) is deceptive, misleading,
            fraudulent, defamatory, libelous, abusive, harassing,
            discriminatory, hateful, malicious, inciting of violence,
            threatening, sexually explicit, or obscene; or (iv) impersonates or
            misrepresents your relationship with any person or entity;
          </li>
          <li>
            create a false or misleading SnowAfrica account or User profile with
            inaccurate or untrue information;
          </li>
          <li>
            use our Services to list, market, offer for sale, or sell goods or
            services without our express prior written permission;
          </li>
          <li>
            use our Services or any Content in connection with posting or
            distributing spam or other unauthorized or unsolicited advertising,
            promotional messages, or bulk electronic communications;
          </li>
          <li>
            collect information about Users (including email addresses) or send
            marketing email or other promotional communications to Users without
            their consent;
          </li>
          <li>
            access our Services by any means other than the interface and
            instructions that we provide;
          </li>
          <li>
            access, tamper with, or use non-public areas of our Services, our
            computer systems, or the technical delivery systems of our service
            providers;
          </li>
          <li>
            breach, disable or circumvent any security or authentication
            measures on or in connection with our Services;
          </li>
          <li>
            interfere with the normal operation of our Services or the access of
            any User, including transmitting any viruses or harmful code,
            flooding our Services with excessive requests or traffic, or taking
            any other action that creates (in our sole estimation) an
            unreasonable or disproportionately large load on our servers or
            systems;
          </li>
          <li>
            decipher, decompile, disassemble, reverse engineer, or otherwise
            derive or extract any source code or underlying ideas or algorithms
            of, any of our Services;
          </li>
          <li>
            adapt, modify, create derivative works from, or redistribute any of
            our Services without our express prior written permission;
          </li>
          <li>
            use any robot, scraper, spider, or other automatic or manual process
            to monitor or extract data from our Services without our express
            prior written permission (we may also use robot exclusion headers
            within our Services and you agree to comply with all such headers);
          </li>
          <li>
            forge any TCP/IP packet header or any part of the header information
            in any email or posting, or in any way use our Services or any
            Content to send altered, deceptive, or false source-identifying
            information;
          </li>
          <li>
            mirror, frame or display any part of our Services on any other
            website or elsewhere without our express prior written permission;
          </li>
          <li>
            use any meta-tags or other hidden text or metadata containing any
            SnowAfrica trademark, service mark, product name, or URL without our
            express prior written permission;
          </li>
          <li>
            use any SnowAfrica trademark, service mark, product name, logo or
            URL in any way that incorrectly suggests our affiliation with or
            endorsement of any person, entity, event, product or service;
          </li>
          <li>
            use any trademark, service mark, product name, logo, trade dress, or
            design that is in any way confusingly similar to any SnowAfrica
            trademark, service mark, product name, or logo, or to the look and
            feel of any of our Services; or
          </li>
          <li>
            remove, conceal, modify or tamper with any copyright, trademark, or
            other proprietary marking or notice, or any digital watermark or
            other technical measure used to indicate the source or ownership of
            any image or other Content.
          </li>
        </ul>
        <p>
          8.2 Without limiting other rights or remedies, we reserve the right to
          limit, terminate or suspend any User's account and/or access to or use
          of any or all of our Services, at any time at our sole discretion, if
          we believe that such User is violating the rights of any third party,
          that such User's conduct may be exposing us or others to legal or
          financial liability, or that such User is acting inconsistently with
          the letter or spirit of these Terms, Service Terms, and other
          applicable agreements with us, and we will have no liability to such
          User for doing so. In any case, we reserve the right to investigate
          and prosecute violations of these Terms to the fullest extent
          permitted by applicable law.{" "}
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          9. MOBILE APPLICATIONS
        </h4>
        <p>
          9.1 As part of our Services, we may offer you the use of SnowAfrica
          mobile applications, including applications that may be accessed
          through the iTunes App Store ("iTunes-Sourced Apps") and applications
          that may be accessed by other means.{" "}
        </p>
        <p>
          9.2 Your use of our mobile applications is subject to these Terms and
          any applicable Service Terms that we may post on our Services or
          otherwise make available to you in connection with our mobile
          applications.{" "}
        </p>
        <p>
          9.3 We may update any of our mobile applications without notice, at
          any time at our sole discretion, and these Terms will apply to any
          updated versions.{" "}
        </p>
        <p>
          9.4 The following terms and conditions in this section apply to
          iTunes-Sourced Apps:{" "}
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            "You agree to use iTunes-Sourced Apps only on an iPhone/iPad OS
            product that you own or control, and as permitted by the App Store
            Terms of Service.
          </li>
          <li>
            You acknowledge that these Terms are an agreement between you and
            us, and not with Apple, and SnowAfrica, not Apple, is solely
            responsible for iTunes-Sourced Apps and the content of
            iTunes-Sourced Apps.
          </li>
          <li>
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to any
            iTunes-source App.
          </li>
          <li>
            If any iTunes-Sourced App fails to conform to any applicable
            warranty, you may notify Apple, and Apple will refund the purchase
            price for such iTunes-Sourced App to you. To the fullest extent
            permitted by applicable law, Apple will have no other warranty
            obligation whatsoever with respect to any iTunes-Sourced App, and
            any other claims, losses, liabilities, damages, costs or expenses
            attributable to any failure of any iTunes-Sourced App to conform to
            any warranty will be our sole responsibility. However, please note
            that we expressly disclaim all warranties to the fullest extent
            permitted by applicable law (as provided in section 11 of these
            Terms).
          </li>
          <li>
            You and we acknowledge that Apple is not responsible for addressing
            any claims by you or any third party relating to any iTunes-Sourced
            App or your possession and use of any iTunes-Sourced App, including:
            (i) product liability claims; (ii) any claim that any iTunes-Sourced
            App fails to conform to any applicable legal or regulatory
            requirement; and (iii) claims arising under consumer protection or
            similar legislation.
          </li>
          <li>
            You and we acknowledge that, in the event of any claim by any third
            party that any iTunes-Sourced App or your possession and use of any
            iTunes-Sourced App infringes that party's intellectual property
            rights, as between SnowAfrica and Apple, SnowAfrica, not Apple, will
            be solely responsible for the investigation, defense, settlement and
            discharge of such claim to the extent required by these Terms.
          </li>
          <li>
            By downloading or using any iTunes-Sourced App, you represent and
            warrant that you are not located in a country that is subject to a
            U.S. Government embargo or that has been designated by the U.S.
            Government as a "terrorist supporting" country, and that you are not
            on any U.S. Government list of prohibited or restricted parties.
          </li>
          <li>
            You and we acknowledge that Apple and Apple's subsidiaries are
            third-party beneficiaries of these Terms as relates to
            iTunes-Sourced Apps and that, upon your acceptance of these Terms,
            Apple will have the right (and will be deemed to have accepted the
            right) to enforce these Terms against you as a third-party
            beneficiary of these Terms as relates to iTunes-Sourced Apps.
          </li>
        </ul>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          10. OTHER WEBSITES AND SERVICES
        </h4>
        <p>
          10.1 Our Services may include links and features that enable you to
          access other websites or services, and other websites or services may
          include links to our Services.{" "}
        </p>
        <p>
          10.2 Such links and features are for convenience only and do not
          constitute or imply any endorsement, warranty, or assumption of
          liability of any kind by us with respect to any such websites or
          services, or any information, materials, goods or services on or
          available from any such websites or services.{" "}
        </p>
        <p>
          10.3 You are solely responsible for, and assume sole risk arising out
          of, any use of or reliance on any such websites or services, or any
          information, materials, goods or services on or available from any
          such websites or services.{" "}
        </p>
        <p>
          10.4 You acknowledge and agree that the SnowAfrica Parties (defined
          below) are not responsible and will not be liable for: (i) the
          accuracy or availability of any such websites or services; or (ii) any
          information, materials, goods or services on or available from any
          such websites or services.{" "}
        </p>
        <p>
          10.6 You are solely responsible for reviewing any terms of use and/or
          privacy policies of any other websites or services to which we may
          link, and we assume no liability for the information contained
          therein.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          11. DISCLAIMER OF WARRANTIES
        </h4>
        <p>
          11.1 YOU ACKNOWLEDGE AND AGREE THAT YOUR ACCESS TO AND USE OF OUR
          SERVICES IS AT YOUR OWN RISK, AND THAT OUR SERVICES ARE PROVIDED TO
          YOU ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE MAKE NO REPRESENTATIONS
          OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, WITH RESPECT TO OUR
          SERVICES OR ANY CONTENT, INCLUDING ANY WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND QUIET
          ENJOYMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR
          USAGE OF TRADE, ALL OF WHICH SNOWAFRICA AND OUR OFFICERS, OWNERS,
          DIRECTORS, CONSULTANTS, AGENTS, AND EMPLOYEES (COLLECTIVELY, THE
          "SNOWAFRICA PARTIES") EXPRESSLY DISCLAIM TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW. THE SNOWAFRICA PARTIES MAKE NO
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, THAT
          OUR SERVICES OR ANY CONTENT WILL MEET YOUR REQUIREMENTS OR BE
          AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, ACCURATE, RELIABLE,
          COMPLETE, FREE OF ERRORS, SAFE, SECURE, OR FREE OF VIRUSES OR OTHER
          HARMFUL COMPONENTS, OR THAT THE USE OF OUR SERVICES IN ANY WAY WILL
          GIVE RISE TO ANY SPECIFIC RESULTS. APPLICABLE LAW MAY NOT PERMIT THE
          EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE EXCLUSIONS IN THIS
          SECTION MAY NOT APPLY TO YOU.{" "}
        </p>
        <p>
          11.2 If the use of our Services results in the need for servicing or
          replacing any equipment or data, the SnowAfrica Parties will not be
          responsible for those costs or losses, and we urge you to back up your
          data at all times.{" "}
        </p>
        <p>
          11.3 No advice or information (whether oral or written) obtained from
          the SnowAfrica Parties, or through our Services or any Content, will
          create any warranty by us not expressly stated in these Terms or in a
          separate written agreement between you and us.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          12. GENERAL RELEASE
        </h4>
        <p>
          12.1 We may provide rules for using our Services, but the SnowAfrica
          Parties do not endorse or control and are not responsible for the
          conduct (whether online or offline) of any User and/or third party on
          or in connection with our Services.{" "}
        </p>
        <p>
          12.2 TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU RELEASE
          THE SNOWAFRICA PARTIES FROM ALL CLAIMS, DEMANDS, SUITS, DAMAGES
          (ACTUAL AND CONSEQUENTIAL), LOSSES, LIABILITIES, AND EXPENSES
          (INCLUDING ATTORNEYS' FEES), OF EVERY KIND AND NATURE, KNOWN AND
          UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND UNDISCLOSED, ARISING
          OUT OF OR IN CONNECTION WITH: (i) ANY DISPUTES BETWEEN YOU AND ONE (1)
          OR MORE OTHER USERS OR OTHER THIRD PARTIES RESULTING DIRECTLY OR
          INDIRECTLY FROM YOUR USE OF OUR SERVICES; (ii) ANY USER CONTENT OR
          CONDUCT, PERFORMANCE OR NON-PERFORMANCE (WHETHER ONLINE OR OFFLINE) OF
          ANY OTHER USER OR OTHER THIRD PARTY IN ANY WAY CONNECTED WITH OUR
          SERVICES, INCLUDING ANY DEFAMATORY, MISLEADING, OFFENSIVE OR UNLAWFUL
          CONDUCT OR USER CONTENT, OR ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
          SERVICES, YOUR ACCOUNT OR YOUR USER CONTENT AND/OR INFORMATION; (iii)
          ANY ARTWORK OR OTHER PROPERTY EXHIBITED, LISTED, MARKETED, OFFERED FOR
          SALE, OR SOLD (WHETHER ONLINE OR OFFLINE) BY ANY OTHER USER OR OTHER
          THIRD PARTY ON, THROUGH, OR IN ANY WAY CONNECTED WITH OUR SERVICES; OR
          (iv) ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS (WHETHER IN PERSON,
          ONLINE OR OTHERWISE) BETWEEN YOU AND ONE (1) OR MORE OTHER USERS OR
          OTHER THIRD PARTIES RESULTING DIRECTLY OR INDIRECTLY FROM YOUR USE OF
          OUR SERVICES. YOU EXPRESSLY WAIVE ANY BENEFITS OR PROTECTIONS, WHETHER
          STATUTORY OR OTHERWISE, THAT WOULD OTHERWISE LIMIT THE COVERAGE OF
          THIS RELEASE TO INCLUDE ONLY THOSE CLAIMS WHICH YOU MAY KNOW OR
          SUSPECT TO EXIST IN YOUR FAVOR AT THE TIME OF ENTERING INTO THIS
          RELEASE.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          13. LIMITATION OF LIABILITY
        </h4>
        <p>
          13.1 UNDER NO CIRCUMSTANCES WILL ANY OF THE SNOWAFRICA PARTIES BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
          EXEMPLARY, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES, ANY PERSONAL
          OR BODILY INJURY OR EMOTIONAL DISTRESS, OR ANY LOSS OF PROFITS,
          REVENUE, BUSINESS, DATA, USE, GOODWILL OR OTHER INTANGIBLE LOSSES,
          ARISING OUT OF OR IN CONNECTION WITH: (i) THESE TERMS; (ii) OUR
          SERVICES; (iii) THE USE OF OR INABILITY TO USE OUR SERVICES; (iv) ANY
          CONDUCT, PERFORMANCE OR NON-PERFORMANCE (WHETHER ONLINE OR OFFLINE) OF
          ANY OTHER USER OR OTHER THIRD PARTY ON OR IN CONNECTION WITH OUR
          SERVICES; OR (v) ANY CONTENT, GOODS OR SERVICES MADE AVAILABLE ON,
          THROUGH, OR IN ANY WAY CONNECTED WITH OUR SERVICES (WHETHER ONLINE OR
          OFFLINE) BY US OR ANY OTHER USER OR OTHER THIRD PARTY.{" "}
        </p>
        <p>
          13.2 THE EXCLUSIONS AND LIMITATIONS OF LIABILITY PROVIDED ABOVE IN
          SUBSECTION 13.1 APPLY TO ALL CLAIMS, WHETHER BASED ON WARRANTY,
          CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
          ANY OTHER LEGAL THEORY, WHETHER OR NOT ANY OF THE SNOWAFRICA PARTIES
          HAVE BEEN ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
          DAMAGE OR LOSS, AND EVEN IF A REMEDY SET FORTH IN THESE TERMS IS FOUND
          TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. APPLICABLE LAW MAY NOT PERMIT
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, SO SOME OF THE EXCLUSIONS AND LIMITATIONS PROVIDED
          ABOVE IN SUBSECTION 13.1 MAY NOT APPLY TO YOU, IN WHICH CASE THE
          LIABILITY OF THE SNOWAFRICA PARTIES WILL BE LIMITED TO THE FULLEST
          EXTENT PERMITTED BY APPLICABLE LAW.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          14. INDEMNIFICATION
        </h4>
        <p>
          14.1 You agree to indemnify, defend, and hold harmless SnowAfrica and
          our officers, directors, agents, and employees from and against any
          and all third-party claims, demands, suits, and proceedings, and all
          related damages, losses, judgments, liabilities, and expenses
          (including reasonable attorneys' fees) arising out of or related to:
          (i) your breach of any of these Terms, Service Terms, and other
          applicable agreements with us (including any term or condition
          incorporated into these Terms by reference); (ii) Your User Content;
          (iii) any misrepresentation made by you; or (iv) your violation of any
          law or the rights of any third party, including any intellectual
          property right, privacy right, or publicity right.{" "}
        </p>
        <p>
          14.2 We reserve the right to assume the exclusive defense and control
          of any matter subject to indemnification by you, in which case you
          agree to cooperate as fully as reasonably required by us in conducting
          such defense. In any case, you agree not to settle any such matter
          without our prior written consent. Your indemnification and defense
          obligations provided in these Terms will survive these Terms and your
          use of our Services.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          15. CHOICE OF LAW AND DISPUTE RESOLUTION
        </h4>
        <p>
          15.1 You agree that all claims or disputes you have against us arising
          out of or related to these Terms or our Services must be resolved
          exclusively by a state or federal court located in Nigeria., whether
          or not any third parties are involved. You agree to submit to the
          personal jurisdiction of, and agree that venue is proper in, the
          courts located in Nigeria., for the purpose of litigating all such
          claims or disputes, and you expressly waive any objection as to
          inconvenient forum.{" "}
        </p>
        <p>
          15.2 These Terms, the rights and obligations of you and us under these
          Terms, and all claims or disputes that might arise between you and us,
          will be governed by and construed in accordance with the laws of
          Nigeria, and, to the extent applicable, the laws of the Nigeria,
          excluding any conflict of laws principles, and without regard to your
          jurisdiction of residence.{" "}
        </p>
        <p>
          15.3 You agree that regardless of any law to the contrary, each claim
          you might have against us arising out of or related to these Terms or
          our Services must be filed within the applicable statute of
          limitations, or one (1) year after such claim arose, or else such
          claim will be permanently barred.{" "}
        </p>
        <p>
          15.4 Nothing in these Terms will prevent us from seeking injunctive or
          other equitable relief in any jurisdiction.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          16. INTERNATIONAL USE
        </h4>
        <p>
          16.1 Our Services are based in the Nigeria and we do not represent or
          warrant that our Services will be appropriate or available for use
          outside the Nigeria.{" "}
        </p>
        <p>
          16.2 If you are located outside the Nigeria, you agree to ensure that
          your access to and use of our Services complies with all applicable
          local, state, national, and international laws and regulations,
          including any laws and regulations governing the import or export of
          data, goods, services or software. If you are located in the EU,
          please refer to our Privacy Policy regarding the transfer of data from
          the EU to the Nigeria.{" "}
        </p>
        <p>
          16.3 You must not access or use our Services if any applicable local,
          state, national, or international law or regulation prohibits you from
          receiving products or services originating from the Nigeria.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          17. CHANGES TO OUR SERVICES
        </h4>
        <p>
          17.1 Our Services are updated frequently, and their form and
          functionality may change without notice. We reserve the right to
          change, modify, add, remove or discontinue any and all of our Services
          (and/or the appearance, design, functionality, and all other aspects
          of any and all of our Services), in whole or part, temporarily or
          permanently, with or without notice, for any reason or no reason, at
          any time at our sole discretion.{" "}
        </p>
        <p>
          17.2 We will not be liable to you or any third party for any damages
          or losses of any kind resulting directly or indirectly from any
          changes made by us to our Services, including any loss of business or
          the ability to use any product, service or Content.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          18. CHANGES TO THESE TERMS
        </h4>
        <p>
          18.1 Our business changes with time, and these Terms will change also.
          We reserve the right to change these Terms, including the right to
          change, modify, add or remove any part of these Terms, without prior
          notice, at any time at our sole discretion.{" "}
        </p>
        <p>
          18.2 All changes to these Terms will be effective when posted on our
          Services, or at such later date as may be specified in the updated
          Terms.{" "}
        </p>
        <p>
          18.3 By continuing to use our Services after any changes to these
          Terms become effective, you agree to such changes and the updated
          Terms.{" "}
        </p>
        <p>
          18.4 You agree that we may notify you of any changes to these Terms by
          posting the updated Terms on our Services, and you agree to review
          these Terms regularly and inform yourself of all applicable changes.{" "}
        </p>
        <p>
          18.5 If you do not agree to any changes made by us to these Terms, you
          will no longer be permitted to use our Services.{" "}
        </p>
        <p>
          18.6 Changes to these Terms will not apply to any claim or dispute
          that arose before such changes became effective, all of which will
          remain subject to the version of these Terms in effect at the time
          that such claim or dispute arose.{" "}
        </p>
        <p>
          18.7 We will not be liable to you or any third party for any damages
          or losses of any kind resulting directly or indirectly from any
          changes made by us to these Terms, including any loss of business or
          the ability to use any product, service or Content.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          19. TERMINATION
        </h4>
        <p>
          19.1 You may stop using our Services at any time, subject to any other
          agreements between you and us.{" "}
        </p>
        <p>
          19.2 Regardless of any other statement in these Terms, we reserve the
          right to terminate these Terms, to terminate your account (if you have
          an account), and/or to limit, terminate or suspend your access to or
          use of any or all of our Services, at any time at our sole discretion,
          without notice or liability, for any reason or no reason, including if
          you violate the letter or spirit of these Terms, Service Terms, and
          other applicable agreements with us.
        </p>
        <p>
          19.3 After any termination of these Terms and/or any termination of
          your access to or use of our Services, the following will survive and
          remain in full force and effect: (i) all outstanding obligations you
          may have to us under these Terms or otherwise; (ii) all remedies for
          breach of these Terms; and (iii) the following sections of these
          Terms: 3 (Ownership), 5 (User Content and Communications), 7
          (Marketplace), 10 (Other Websites and Services), 11 (Disclaimer of
          Warranties), 12 (General Release), 13 (Limitation of Liability), 14
          (Indemnification), 15 (Choice of Law and Dispute Resolution), 16
          (International Use), 17 (Changes to our Services), 18 (Changes to
          these Terms), 19 (Termination), and 23 (Miscellaneous).{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          20. COPYRIGHT POLICY
        </h4>
        <p>
          20.1 We respect the intellectual property rights of others and expect
          Users to do the same. In accordance with the Digital Millennium
          Copyright Act ("DMCA"), we will respond to notices of claimed
          copyright infringement that are properly provided to our designated
          copyright agent ("Copyright Agent"), whose contact information is
          listed below.{" "}
        </p>
        <p>
          20.2 We reserve the right to remove or disable access to any Content
          on our Services claimed to be infringing, at any time at our sole
          discretion, without notice or liability. In appropriate circumstances,
          we will also terminate Users and account holders who are repeat
          infringers.{" "}
        </p>
        <p>
          20.3 If you believe that Content on our Services has been used in a
          way that constitutes copyright infringement, please provide our
          Copyright Agent with a written notice containing all of the following
          information ("DMCA Notice"):{" "}
        </p>
        <p>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed;{" "}
        </p>
        <p>
          Identification of the copyrighted work claimed to have been infringed;{" "}
        </p>
        <p>
          Identification of the Content that is claimed to be infringing or to
          be the subject of infringing activity, and information reasonably
          sufficient to permit us to locate such Content on our Services;{" "}
        </p>
        <p>
          Information reasonably sufficient to permit us to contact you, such as
          an address, telephone number, and, if available, an email address;{" "}
        </p>
        <p>
          a statement that you have a good faith belief that use of the Content
          in the manner complained of is not authorized by the copyright owner,
          its agent, or the law; and{" "}
        </p>
        <p>
          a statement that the information in your notice is accurate and, under
          penalty of perjury, that you are authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed.{" "}
        </p>
        <p>
          20.4 If you are the provider of Content that was removed (or to which
          access was disabled) and you believe that such Content is not
          infringing, or that the use of such Content is authorized by the
          copyright owner, the copyright owner's agent, or the law, you may
          provide our Copyright Agent with a written counter-notice containing
          all of the following information ("Counter-Notice"):{" "}
        </p>
        <p>your physical or electronic signature; </p>
        <p>
          identification of the Content that was removed or to which access was
          disabled, and the location at which such Content appeared on our
          Services before it was removed or access to it was disabled;{" "}
        </p>
        <p>
          a statement under penalty of perjury that you have a good faith belief
          that the Content was removed or disabled as a result of mistake or
          misidentification of the Content; and{" "}
        </p>
        <p>
          your name, address, and telephone number, and a statement that you
          consent to the jurisdiction of the Federal District Court for the
          judicial district in which your address is located, or, if your
          address is outside the Nigeria, for any judicial district in which
          SnowAfrica may be found, and that you will accept service of process
          from the person who provided the notice of claimed infringement or an
          agent of such person.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          21. JOB LISTINGS
        </h4>
        <p>
          21.1 We may post information and listings on our Services or on
          third-party social media services describing careers with SnowAfrica,
          internships, or positions for which we are or may be hiring.{" "}
        </p>
        <p>
          21.2 Please note that such information and listings are for general
          informational use only, may be changed by us at any time without
          notice, and are not an offer of employment or any other offer or
          warranty by us.{" "}
        </p>
        <p>
          21.3 Please note that if you would like to apply to an open position
          posted on our site, you will be referred to a third party site in
          order to submit your application.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          22. PROMOTIONS
        </h4>
        <p>
          22.1 We may offer or conduct promotions, sweepstakes or contests
          ("Promotions") on or in connection with our Services from time to
          time, by ourselves or in conjunction with third parties.{" "}
        </p>
        <p>
          22.2 Your participation in Promotions is subject to these Terms and
          any official rules that we may post on our Services or otherwise make
          available to you in connection with Promotions ("Promotion Rules"). If
          any Promotions have Promotion Rules that conflict with these Terms,
          those Promotion Rules will control to the extent of the conflict as
          relates to those Promotions.{" "}
        </p>

        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          23. MISCELLANEOUS
        </h4>
        <p>
          23.1 These Terms, including our Privacy Policy, Conditions of Sale,
          and Service Terms, other applicable agreements with us, and any other
          terms and conditions incorporated into these Terms by reference,
          together with any other written agreements that you may enter into
          with us regarding our Services, constitutes the entire agreement and
          understanding between you and us regarding our Services, and
          supersedes any other communications, representations or
          understandings.{" "}
        </p>
        <p>
          23.2 If any provision of these Terms is found by a court of competent
          jurisdiction to be invalid or unenforceable for any reason, that
          provision will be enforced to the maximum extent permitted, and these
          Terms will otherwise remain in full force and effect.{" "}
        </p>
        <p>
          23.3 No delay or failure by us to exercise or enforce any right or
          provision of these Terms will be considered a waiver. No right or
          remedy under these Terms will be considered waived by us unless the
          waiver is in writing and signed by an SnowAfrica representative who
          intends and is duly authorized to agree to the waiver on our behalf.
          No single or partial exercise by us of any right or remedy under these
          Terms will prevent us from exercising any other right or remedy.{" "}
        </p>
        <p>
          23.4 These Terms will be binding on your successors and assigns, but
          you are not allowed to assign, transfer or sublicense these Terms or
          any right or obligation under these Terms without our prior written
          consent. These Terms, and all rights granted to or reserved by us
          under these Terms, will benefit and be enforceable by our successors
          and assigns.{" "}
        </p>
        <p>
          23.5 There are no third-party beneficiaries to these Terms except as
          expressly provided in these Terms.{" "}
        </p>
        <p>
          23.6 You and we are independent contractors under these Terms. No
          agency, partnership, joint venture, employment, sales representative,
          or franchise relationship is created or implied by these Terms or your
          use of our Services.{" "}
        </p>
        <p>
          23.7 Nothing in these Terms will prevent us from complying with any
          applicable law or regulation.{" "}
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
