import React, { useEffect, useState } from "react";
import "./UploadArt.css";
import InsertPhotoIcon from "../../../images/insert-photo.png";
import InsertOtherImages from "./InsertOtherImages";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";

function Main() {
  // States
  const [previewImg, setPreviewImg] = useState("");
  const [previewOtherImgOne, setPreviewOtherImgOne] = useState("");
  const [previewOtherImgTwo, setPreviewOtherImgTwo] = useState("");
  const [previewOtherImgThree, setPreviewOtherImgThree] = useState("");
  const [previewOtherImgFour, setPreviewOtherImgFour] = useState("");
  const [artworkName, setArtworkName] = useState("");
  const [artworkCategory, setArtworkCategory] = useState("");
  const [artworkStyle, setArtworkStyle] = useState("");
  const [artistName, setArtistName] = useState("");
  const [artSpecification, setArtSpecifications] = useState("");
  const [aboutArt, setAboutArt] = useState("");
  const [matchingColorsList, setMatchingColorsList] = useState([]);

  const allColors = [
    "white",
    "black",
    "yellow",
    "blue",
    "red",
    "green",
    "brown",
    "purple",
    "violet",
    "cyan",
  ];

  // Array containing the data for the other images select section. Used beacuse of DRY.
  const otherImagesForPreview = [
    {
      index: "1",
      sectionId: "insert-other-image-selector-one",
      inputId: "insert-other-image-input-1",
      previewImgId: "preview-other-img-1",
    },
    {
      index: "2",
      sectionId: "insert-other-image-selector-two",
      inputId: "insert-other-image-input-2",
      previewImgId: "preview-other-img-2",
    },
    {
      index: "3",
      sectionId: "insert-other-image-selector-three",
      inputId: "insert-other-image-input-3",
      previewImgId: "preview-other-img-3",
    },
    {
      index: "4",
      sectionId: "insert-other-image-selector-four",
      inputId: "insert-other-image-input-4",
      previewImgId: "preview-other-img-4",
    },
  ];

  //   Functions
  // Handle add more img
  const handleAddMoreImg = function (e) {
    // Other view section
    const otherViews = document.querySelector(".other-views");
    if (otherViews.classList.contains("none")) {
      // Remove the display:none class from other views and add it to the button
      otherViews.classList.remove("none");
      e.target.classList.add("none");
    } else {
      otherViews.classList.add("none");
    }
  };

  //   Handle upload
  const handleUpload = function () {
    // array containing all the other preview images file data
    let otherImagesPreviewArray = [
      previewOtherImgOne,
      previewOtherImgTwo,
      previewOtherImgThree,
      previewOtherImgFour,
    ];

    // Removing the unselected(empty) files and leaving the selected ones to be sstored in the result on submit
    otherImagesPreviewArray = otherImagesPreviewArray.filter((file) => {
      return file !== "";
    });

    let result = {
      previewMainImg: previewImg,
      artworkName,
      artworkCategory,
      artworkStyle,
      artistName,
      artSpecification,
      aboutArt,
      matchingColorsList,
      previewOtherImg: otherImagesPreviewArray,
    };
    console.log("upload-art", result);
  };

  //   Handle Cancel
  const handleCancel = function () {};

  //   Handle Matching colors click
  const handleMatchingColorClick = function (e, color) {
    let matchingColors = [];
    e.stopPropagation();
    // Check if the clicked color has already been selected before selecting
    if (e.target.classList.contains("color-selected")) {
      e.target.classList.remove("color-selected");
      //   filter out the color from the list of selected colors
      matchingColors = matchingColorsList.filter((col) => {
        return color !== col;
      });
      //   Then set the state to the colors left after filtering
      setMatchingColorsList([...matchingColors]);
      //   setMatchingColorsList(matchingColors);
    } else {
      // If it hasn't been selected
      e.target.classList.add("color-selected");
      //   Add the new color to the list of selected colors by setting the state
      setMatchingColorsList([...matchingColorsList, color]);
    }
    // setMatchingColorsList(matchingColors);
  };

  // UseEffect
  useEffect(() => {
    // Script to add preview image
    const insertImg = document.querySelector("#insert-main-image-selector");
    const getFile = document.querySelector("#insert-main-image-input");

    // Calls click method on the hidden input on click
    insertImg.addEventListener("click", function (e) {
      e.stopPropagation();
      getFile.click();
    });
    // Set the variables and parameters to the image selected
    getFile.addEventListener("change", function () {
      preview(getFile.files[0]);
      setPreviewImg(getFile.files[0]);
    });

    // Change the src of the main img preview section
    function preview(file) {
      const img = document.querySelector("#previewimg");
      img.classList.add("obj");
      img.file = file;

      const reader = new FileReader();
      reader.onload = (function (image) {
        return function (e) {
          image.src = e.target.result;
        };
      })(img);
      reader.readAsDataURL(file);
      // Removes the invisibility
      img.classList.add("visible");
    }

    //SCRIPT TO GET OTHER NON-COMPULSORY IMAGES FROM ADD OTHER IMAGES BTN
    // Calling the insert images fxn that was imported and passing all the set image states as parameters.
    InsertOtherImages(
      setPreviewOtherImgOne,
      setPreviewOtherImgTwo,
      setPreviewOtherImgThree,
      setPreviewOtherImgFour
    )();
  }, []);

  return (
    <div className="upload-art-main">
      <div className="main-inner">
        {/* Main picturre */}
        <div className="main-inner-col main-picture">
          <div className="section-title">Main Picture</div>
          <div className="horizontal-line"></div>

          {/* Insert Image Box */}
          <div className="main-picture--insert">
            <div
              className="main-picture--insert-inner"
              id="insert-main-image-selector"
            >
              <img
                src={InsertPhotoIcon}
                className="insert-photo-icon"
                alt="insert-img"
              />
              {/* Select image input which  is hidden */}
              <input
                type="file"
                className="insert-main-image-input"
                name="insert-main-image"
                id="insert-main-image-input"
                accept="image/*"
              />
              {/* Preview img */}
              <img id="previewimg" alt="preview-img" className="hidden" />
            </div>
            <div className="insert-main-image-text">
              <div>
                <img
                  src={InsertPhotoIcon}
                  className="insert-photo-label-icon"
                  alt="insert-img"
                />
              </div>
              <div className="insert-main-image-label">
                <p>Select Main Picture</p>
                <p>(This image will be used as the preview image)</p>
              </div>
            </div>
          </div>

          {/* Add more images button */}
          <div className="add-more-img">
            <button onClick={handleAddMoreImg} className="add-more-img-btn">
              ADD MORE IMAGES
            </button>
          </div>

          {/* Other Image views */}
          <div className="other-views none" id="other-image-views">
            <div className="other-views-inner">
              {/* Mapping through the data array(4) for the images preview */}
              {otherImagesForPreview.map((imageData, i) => (
                <div
                  key={i + 1}
                  className={`other-image other-image-${i + 1}`}
                  id={imageData.sectionId}
                >
                  {/* Insert image centered picture*/}
                  <img
                    src={InsertPhotoIcon}
                    className="insert-photo-icon"
                    alt="insert-img"
                  />
                  {/* Select image input which  is hidden */}
                  <input
                    type="file"
                    className="insert-other-image-input"
                    name={`insert-other-image-${i + 1}`}
                    id={imageData.inputId}
                    accept="image/*"
                  />
                  {/* Preview img which appears when selected*/}
                  <img
                    id={imageData.previewImgId}
                    alt="preview-img"
                    className="hidden preview-other-img"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Basic info */}
        <div className="main-inner-col basic-info">
          <div className="section-title">Basic Info</div>
          <div className="horizontal-line"></div>

          {/* Basic info form */}
          <div className="basic-info--form">
            <form>
              {/* Artwork name */}
              <div className="form-group mb-3">
                <label htmlFor="artwork-name" className="form-label">
                  ARTWORK NAME:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="artwork-name"
                  aria-describedby="artworkName"
                  onChange={(e) => setArtworkName(e.target.value)}
                  value={artworkName}
                />
              </div>

              {/* Artwork category */}
              <div className="form-group mb-3">
                <label htmlFor="artwork-category" className="form-label">
                  ARTWORK CATEGORY:
                </label>
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="artwork-category"
                    id="artwork-category"
                    aria-describedby="artworkCategory"
                    className="form-control form-dropdown"
                    onChange={(e) => setArtworkCategory(e.target.value)}
                    value={artworkCategory}
                  >
                    <option defaultValue={"Select"}>Select</option>
                    <option value="NFT">NFT</option>
                    <option value="WILDLIFE">WILDLIFE</option>
                    <option value="ABSTRACT">ABSTRACT</option>
                  </select>
                </div>
              </div>

              {/* Artwork style */}
              <div className="form-group mb-3">
                <label htmlFor="artwork-style" className="form-label">
                  ARTWORK STYLE:
                </label>
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="artwork-style"
                    id="artwork-style"
                    aria-describedby="artworkStyle"
                    className="form-control form-dropdown"
                    onChange={(e) => setArtworkStyle(e.target.value)}
                    value={artworkStyle}
                  >
                    <option defaultValue={"Select"}>Select</option>
                    <option value="NFT">NFT</option>
                    <option value="WILDLIFE">WILDLIFE</option>
                    <option value="ABSTRACT">ABSTRACT</option>
                  </select>
                </div>
              </div>

              {/* Artist Name */}
              <div className="form-group mb-3">
                <label htmlFor="artist-name" className="form-label">
                  ARTIST NAME:
                </label>
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="artist-name"
                    id="artist-name"
                    aria-describedby="artistName"
                    className="form-control form-dropdown"
                    onChange={(e) => setArtistName(e.target.value)}
                    value={artistName}
                  >
                    <option defaultValue={"Select"}>Select</option>
                    <option value="NFT">MICHELANGELO</option>
                    <option value="WILDLIFE">DONNATELLO</option>
                    <option value="ABSTRACT">PICASSO</option>
                  </select>
                </div>
              </div>
            </form>
          </div>

          {/* Add Artist */}
          <div className="add-artist">
            <button className="add-artist-btn">
              <span>+</span> Add artist
            </button>
          </div>
        </div>

        {/* More details */}
        <div className="main-inner-col more-details">
          <div className="section-title">More Details</div>
          <div className="horizontal-line"></div>

          <div className="more-details--form">
            <form>
              {/* SPECIFICATIONS OF THE ART */}
              <div className="form-group mb-3">
                <label htmlFor="art-specification" className="form-label">
                  SPECIFICATIONS OF THE ART:
                </label>
                <textarea
                  type="text"
                  className="form-control art-specification-input"
                  id="art-specification"
                  aria-describedby="artSpecification"
                  placeholder={`Eg \n size: 43 \n frame: wwooden \n canva: print`}
                  onChange={(e) => setArtSpecifications(e.target.value)}
                  value={artSpecification}
                />
              </div>

              {/* SELECT MATCHING COLORS */}
              <div className="form-group mb-3">
                <label htmlFor="select-maatching-colors" className="form-label">
                  SELECT MATCHING COLOR(S):
                </label>
              </div>
              {/* Matching colors div*/}
              <div className="matching-colors-select mb-3">
                <div className="matching-colors-select-inner">
                  {/* Mapping through the colors*/}
                  {allColors.map((color, i) => (
                    <div
                      key={i}
                      className={`color color-${i}-${color}`}
                      style={{ backgroundColor: color }}
                      onClick={(e) => handleMatchingColorClick(e, color)}
                    ></div>
                  ))}
                </div>
              </div>
              {/* ABOUT THE ART */}
              <div className="form-group mb-3">
                <label htmlFor="about-art" className="form-label">
                  ABOUT THE ART:
                </label>
                <textarea
                  type="text"
                  className="form-control about-art"
                  id="about-art"
                  aria-describedby="aboutArt"
                  onChange={(e) => setAboutArt(e.target.value)}
                  value={aboutArt}
                />
              </div>
            </form>
          </div>

          {/* UPLOAD AND CANCEL */}
          <div className="submit-section">
            <button onClick={handleUpload} className="btn-inner upload-btn">
              UPLOAD
            </button>
            <button onClick={handleCancel} className="btn-inner cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
