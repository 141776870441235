import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import facebookIcon from "../../../../images/facebook.png";
import instagramIcon from "../../../../images/instagram.png";
import twitterIcon from "../../../../images/twitter.png";
import linkedinIcon from "../../../../images/linkedin.png";
import snowAfricaLogo from "../../../../images/Snowafrica.png";
import "./InvoiceCard.css";
import { numberWithCommas } from "../../../../utils/numberWithCommas";

function InvoiceCard({ rowData }) {
  // Functions and Variables
  //   const [productPrice, setProductPrice] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState("");
  const [taxPrice, setTaxPrice] = useState("");
  //   const [discountPrice, setDiscountPrice] = useState("0");
  const [totalPrice, setTotalPrice] = useState(false);
  const [rows, setRows] = useState();

  //   USEEFFECT
  useEffect(() => {
    function createData(
      sku,
      productName,
      artist,
      merchant,
      cost,
      deliveryFee,
      taxFee,
      totalPrice
    ) {
      setDeliveryPrice(deliveryFee);
      setTaxPrice(taxFee);
      setTotalPrice(totalPrice);

      return {
        sku,
        productName,
        artist,
        merchant,
        cost,
        deliveryFee,
        taxFee,
        totalPrice,
      };
    }

    const rows = [
      createData(
        rowData.sku,
        rowData.productName,
        rowData.artist,
        rowData.merchant,
        rowData.cost,
        rowData.deliveryFee,
        rowData.taxFee,
        rowData.totalPrice
      ),
    ];
    setRows(rows);
  }, [rowData]);

  return (
    <div className="invoice-card">
      <div className="invoice-card-inner">
        {/* Row One Invoice Card Header */}
        <div className="invoice-card-row-one invoice-card-header">
          {/* Left Side of Header */}
          <div className="card-header--left issuance-details">
            <div className="issued-to-title">ISSUED TO</div>
            {/* Issued to Name */}
            <div className="issued-to-name">Chisom Ugwummadu</div>
            {/* Issued to Email */}
            <div className="issued-to-mail">chisom@snowafricauser.com</div>
          </div>

          {/* Right Side of Header */}
          <div className="card-header--right">
            <div className="socials-section">
              {/* Facebook */}
              <a href="#" className="facebook social-link">
                <img src={facebookIcon} alt="facebook-icon" />
              </a>
              <a href="#" className="twitter social-link">
                <img src={twitterIcon} alt="twitter-icon" />
              </a>
              <a href="#" className="instagram social-link">
                <img src={instagramIcon} alt="instagram-icon" />
              </a>
              <a href="#" className="linkedin social-link">
                <img src={linkedinIcon} alt="linkedin-icon" />
              </a>
            </div>

            {/* Snowafrica Logo */}
            <div className="snowafrica-logo">
              <img src={snowAfricaLogo} alt="logo" />
            </div>

            {/* Company Invoice Number */}
            <div className="company-invoice-number">
              snowafrica.com | Invoice No: AE12045
            </div>
          </div>
        </div>
        {/* Row Two Invoice Card Body */}
        <div className="invoice-card-row-two invoice-card-body">
          <div className="invoice-card-body--inner">
            <TableContainer component="div">
              <Table
                sx={{
                  minWidth: 650,
                  mb: 2,
                  border: "none",
                  boxShadow: "none",
                  borderBottom: "1px solid #dadada",
                  borderTop: "2px solid rgb(159, 159, 159)",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell align="left">PRODUCT NAME</TableCell>
                    <TableCell align="left">ARTIST</TableCell>
                    <TableCell align="left">MERCHANT</TableCell>
                    <TableCell align="left">COST</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.sku}
                      </TableCell>
                      <TableCell align="left">{row.productName}</TableCell>
                      <TableCell align="left">{row.artist}</TableCell>
                      <TableCell align="left">{row.merchant}</TableCell>
                      <TableCell align="left">
                        N{row.cost && numberWithCommas(row.cost) + ".00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        {/* Row Three Invoice Card Footer */}
        <div className="invoice-card-row-three invoice-card-footer">
          {/* Left Side of Footer / Delivery Info */}
          <div className="card-footer--left delivery-info">
            {/* Delivery date */}
            <div className="delivery-date">
              <div className="delivery-date-title">DELIVERY DATE</div>
              <div className="delivery-date-value">In Progress</div>
            </div>

            {/* Delivery Address */}
            <div className="delivery-address">
              <div className="delivery-address-title">DELIVERY ADDRESS</div>
              <div className="delivery-address-value">
                34 Chisom's Address, Ikoyi, Lagos, Nigeria
              </div>
            </div>
          </div>

          {/* Right Side of Footer / Price Summary */}
          <div className="card-footer--right price-summary">
            <div className="summary-table">
              {/* Delivery Price */}
              <div className="delivery-price-summary">
                {/*Delivery summary title */}
                <div className="delivery-price-summary-title summary-row-title">
                  Delivery
                </div>
                {/*Delivery summary value */}
                <div className="delivery-price-summary-value summary-row-value">
                  N{deliveryPrice && numberWithCommas(deliveryPrice) + ".00"}
                </div>
              </div>

              {/* Tax Price */}
              <div className="tax-price-summary">
                {/*Tax summary title */}
                <div className="tax-price-summary-title summary-row-title">
                  Tax
                </div>
                {/*Tax summary value */}
                <div className="tax-price-summary-value summary-row-value">
                  N{taxPrice && numberWithCommas(taxPrice) + ".00"}
                </div>
              </div>

              {/* Discount Price */}
              <div className="discount-price-summary">
                {/*Discount summary title */}
                <div className="discount-price-summary-title summary-row-title">
                  Discount
                </div>
                {/*Discount summary value */}
                <div className="discount-price-summary-value summary-row-value">
                  N0.00
                </div>
              </div>

              {/* Total Product Summary Price*/}
              <div className="total-summary-price">
                N{totalPrice && numberWithCommas(totalPrice) + ".00"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceCard;
