import {
  CREATE_TRANSACTION,
  FIND_TRANSACTION_BY_ID,
  GET_ALL_TRANSACTIONS,
  SUCCESS_CREATING_TRANSACTION,
  LOADED_TRANSACTION_OBJECT,
} from '../Constants';

const initialState = {
  isSuccessful: false,
  allTransactions: [],
  transactionObject: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CREATING_TRANSACTION:
      return {
        ...state,
        isSuccessful: true,
      };
    case GET_ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payload,
      };
    case LOADED_TRANSACTION_OBJECT:
      return {
        ...state,
        transactionObject: action.payload,
      };
    default:
      return state;
  }
}
