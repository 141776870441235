import React, { useState, useEffect } from "react";
import "./ComingSoon.css";
import { useLocation } from "react-router-dom";
import Footer from "../LandingPage/Footer/Footer";
import Header from "../LandingPage/Header/Header";
import emptyBoard from "../../images/empty-painting-board.jpg";

function ComingSoon() {
  // Functions, States and Variables
  const location = useLocation();
  //   States
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    if (location.state) {
      setCurrentPage(location.state.page);
    }
  }, [location]);
  return (
    <div className="coming-soon-container">
      <Header />
      <div className="coming-soon-container--inner">
        <div className="coming-soon-header-text-wrapper">
          {currentPage} Coming Soon...
        </div>

        <div className="coming-soon-hero-img-wrapper">
          <img src={emptyBoard} alt="" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ComingSoon;
