import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import logo from "../.././images/Snowafrica.png";
import register from "../.././images/register.jpg";
import { loginUser, registerUser } from "../../redux/actions/authActions";
import "./Top.css";

const Top = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showRegister, setRegisterShow] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState({ email: "", password: "" });
  const [newUser, setNewUser] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "user",
    phone: "",
    state: "",
  });
  const allState = [
    { Name: "Adamawa" },
    { Name: "Akwa Ibom" },
    { Name: "Anambra" },
    { Name: "Bauchi" },
    { Name: "Bayelsa" },
    { Name: "Benue" },
    { Name: "Borno" },
    { Name: "Cross River" },
    { Name: "Delta" },
    { Name: "Ebonyi" },
    { Name: "Edo" },
    { Name: "Ekiti" },
    { Name: "Enugu" },
    { Name: "Gombe" },
    { Name: "Imo" },
    { Name: "Jigawa" },
    { Name: "Kaduna" },
    { Name: "Kano" },
    { Name: "Katsina" },
    { Name: "Kebbi" },
    { Name: "Kogi" },
    { Name: "Kwara" },
    { Name: "Lagos" },
    { Name: "Nasarawa" },
    { Name: "Niger" },
    { Name: "Ogun" },
    { Name: "Ondo" },
    { Name: "Osun" },
    { Name: "Oyo" },
    { Name: "Plateau" },
    { Name: "Rivers" },
    { Name: "Sokoto" },
    { Name: "Taraba" },
    { Name: "Yobe" },
    { Name: "Zamfara" },
    { Name: "FCT" },
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRegisterClose = () => setRegisterShow(false);
  const handleRegisterShow = () => setRegisterShow(true);

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email: user.email,
      password: user.password,
    };
    dispatch(loginUser(userData));
    setShow(false)
  };

  const onRegisterSubmit = (e) => {
    e.preventDefault();
    const registerNewUser = {
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      email: newUser.email,
      password: newUser.password,
      usertype: newUser.userType,
      phone: newUser.phone,
      shippingAddress: newUser.state,
    };

    if (comparePasswords(newUser.password, newUser.confirmPassword) === false) {
      return;
    }
    dispatch(registerUser(registerNewUser, navigate));
  };

  const comparePasswords = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      const message = {
        message: "Passwords do not match",
      };
      setError({ errors: message });
      return false;
    }
  };

  return (
    <div className="top-main">
      <Container>
        <div className="top">
          <div className="top-menu">
            <ul>
              <li className="cursor">
                <Link to="/gallery">Categories</Link>
              </li>

              <li className="li-space">|</li>

              <li className="cursor">
                <Link to="/curators">Curators</Link>
              </li>

              <li className="li-space">|</li>

              <li className="cursor">
                <a target="_blank" href="http://blog.snowafrica.art/">
                  Blog
                </a>
              </li>
            </ul>
          </div>
          <div className="top-logo">
            <Link to="/">
              <img className="cursor" src={logo} alt="snowafrica logo" />
            </Link>
          </div>
          <div className="top-authetication">
            <div>
              <p className="p-menu cursor" onClick={handleRegisterShow}>
                Register |
              </p>
            </div>
            <div>
              <p className="p-menu cursor" onClick={handleShow}>
                Login |
              </p>
            </div>
            <div>
              <p className="p-menu cursor">
                <Link to="/artist-register">Become an Artist</Link>
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <p className="lead">LOG IN</p>
        </Modal.Header>
        <Modal.Body closeButton>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter Email"
                onChange={(e) => setUser({ ...user, email: e.target.value })}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Password"
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
            </div>

            <button
              type="submit"
              onClick={onSubmit}
              className="mylongflatbtn mybtn--blue"
            >
              LOGIN
            </button>
            <p className="lean">
            <Link to="/forgotPassword">
            Forgot Password?
                </Link>
            </p>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showRegister}
        onHide={handleRegisterClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <p className="lead">Please fill the form below to register</p>
        </Modal.Header>
        <Modal.Body closeButton>
          <div className="register-modal">
            <div className="register-modal--image">
              <img src={register} />
            </div>
            <form>
              <div className="form-group mb-3">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="Enter First Name"
                  onChange={(e) =>
                    setNewUser({ ...newUser, firstname: e.target.value })
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="Enter Last Name"
                  onChange={(e) =>
                    setNewUser({ ...newUser, lastname: e.target.value })
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                  onChange={(e) =>
                    setNewUser({ ...newUser, email: e.target.value })
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={(e) =>
                    setNewUser({ ...newUser, password: e.target.value })
                  }
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={(e) =>
                    setNewUser({ ...newUser, confirmPassword: e.target.value })
                  }
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  onChange={() => setChecked(!checked)}
                />
                <label className="form-check-label" for="exampleCheck1">
                  I agree to SnowAfrica's Terms of Use and Privacy Policy.
                </label>
              </div>
              <button
                type="submit"
                disabled={error.status || !checked}
                onClick={onRegisterSubmit}
                className="mylongflatbtn mybtn--blue"
              >
                REGISTER
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Top;
