import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Collection.css";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import likeBtn from "../../../images/like-icon.svg";
import cartBtn from "../../../images/cart-icon.svg";
import { Pagination } from "../../Pagination/Pagination";
import Masonry from "react-masonry-css";
import Header from "../../LandingPage/Header/Header";
import isEmpty from "../../../validation/is-empty";
import { numberWithCommas } from "../../../utils/numberWithCommas";

// Dummy Data Creation
function createData(
  id,
  curatorName,
  displayImgSrc,
  collectionName,
  collection
) {
  return {
    id,
    curatorName,
    displayImgSrc,
    collectionName,
    //   Note that collection is an array / list of artworks
    collection,
  };
}
const rows = [
  createData(1, "Eclair", "Pic 2015-01-23 17-42-22.jpg", "clair 1", [
    {
      artworkName: "The flying tornado in the storm",
      artistName: "Michelangelo",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
      price: "500000",
    },
    {
      artworkName: "The flying ship",
      artistName: "Picasso",
      displayImgSrc: "register.jpg",
      price: "400000",
    },
    {
      artworkName: "The flying horse in the dessert that gallops",
      artistName: "Daniel",
      displayImgSrc: "sell.jpg",
      price: "900000",
    },
    {
      artworkName: "The flying car",
      artistName: "Yahiko",
      displayImgSrc: "register.jpg",
      price: "1200000",
    },
    {
      artworkName:
        "The flying plane in the sky that never falls until time ends",
      artistName: "Nagato",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
      price: "300000",
    },
    {
      artworkName: "The flying plane",
      artistName: "Nagato",
      displayImgSrc: "register2.jpg",
      price: "500000",
    },
    {
      artworkName: "The flying cart",
      artistName: "Levi Ackermann",
      displayImgSrc: "register.jpg",
      price: "2100000",
    },
    {
      artworkName: "The flying horse",
      artistName: "Yahiko",
      displayImgSrc: "register.jpg",
      price: "1200000",
    },
    {
      artworkName: "The flying plane",
      artistName: "Nagato",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
      price: "300000",
    },
    {
      artworkName: "The flying plane",
      artistName: "Nagato",
      displayImgSrc: "register2.jpg",
      price: "500000",
    },
    {
      artworkName: "The flying cart",
      artistName: "Levi Ackermann",
      displayImgSrc: "register.jpg",
      price: "2100000",
    },
    {
      artworkName: "The flying horse",
      artistName: "Yahiko",
      displayImgSrc: "register.jpg",
      price: "1200000",
    },
    {
      artworkName: "The flying plane",
      artistName: "Nagato",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
      price: "300000",
    },
    {
      artworkName: "The flying plane",
      artistName: "Nagato",
      displayImgSrc: "register2.jpg",
      price: "500000",
    },
    {
      artworkName: "The flying cart",
      artistName: "Levi Ackermann",
      displayImgSrc: "register.jpg",
      price: "2100000",
    },
  ]),
];

function Collection() {
  const { collection: collectionName } = useParams();
  const [originalRows, setOriginalRows] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const [rowLength, setRowlength] = useState(0);
  const [minPriceRangeValue, setMinPriceRangeValue] = useState(1000);
  const [maxPriceRangeValue, setMaxPriceRangeValue] = useState(5000000);
  const [matchingColorsList, setMatchingColorsList] = useState([]);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [pageLayout, setPageLayout] = useState("grid");

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      const currentRows = originalRows?.collection.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  const allColors = [
    "white",
    "black",
    "yellow",
    "blue",
    "red",
    "green",
    "brown",
    "purple",
    "violet",
    "cyan",
  ];

  //   Column Breakpoint
  const breakpointColumnsObj = {
    default: 4,
    1660: 3,
    1220: 2,
    810: 1,
  };

  //   Handle Matching colors click
  const handleMatchingColorClick = function (e, color) {
    let matchingColors = [];
    e.stopPropagation();
    // Check if the clicked color has already been selected before selecting
    if (e.target.classList.contains("color-selected")) {
      e.target.classList.remove("color-selected");
      //   filter out the color from the list of selected colors
      matchingColors = matchingColorsList.filter((col) => {
        return color !== col;
      });
      //   Then set the state to the colors left after filtering
      setMatchingColorsList([...matchingColors]);
      //   setMatchingColorsList(matchingColors);
    } else {
      // If it hasn't been selected
      e.target.classList.add("color-selected");
      //   Add the new color to the list of selected colors by setting the state
      setMatchingColorsList([...matchingColorsList, color]);
    }
    // setMatchingColorsList(matchingColors);
  };

  // PRICE RANGES SCRIPT
  function getVals() {
    // Get slider values
    var slide1 = parseFloat(document.querySelector(".slider-1").value);
    var slide2 = parseFloat(document.querySelector(".slider-2").value);
    // Neither slider will clip the other, so make sure we determine which is larger
    if (slide1 > slide2) {
      var tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    var displayElement = document.querySelector(".rangeValues");
    displayElement.innerHTML =
      "NGN " +
      numberWithCommas(minPriceRangeValue) +
      " - NGN" +
      numberWithCommas(maxPriceRangeValue);
    setMinPriceRangeValue(slide1);
    setMaxPriceRangeValue(slide2);
  }

  window.onload = function () {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
      var sliders = sliderSections[x].getElementsByTagName("input");
      for (var y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].oninput = getVals;
          // Manually trigger event first time to display values
          sliders[y].oninput();
        }
      }
    }
  };

  //   BUTTON ACTIONS

  //   Handle Like Button Click
  const handleArtworkLike = function (e) {
    e.stopPropagation();
    if (e.target.classList.contains("liked")) {
      e.target.classList.remove("liked");
      e.target.style.opacity = 0.6;
    } else {
      e.target.classList.add("liked");
      e.target.style.opacity = 0.9;
    }
  };
  //   Handle Like Cart Click
  const handleArtworkCart = function (e) {
    e.stopPropagation();
  };

  //   USEEFFECT
  useEffect(() => {
    setOriginalRows(rows[0]);
  }, []);

  return (
    <div className="collection-view">
      <Header />
      <div className="collection-view--inner">
        {/* Left Side / Collection Info and Navigation */}
        <div className="left-column collection-view-info">
          <div className="collection-view-info--inner">
            <img
              src={
                originalRows &&
                require("../../../../src/images/" + originalRows.displayImgSrc)
              }
              alt="img"
            />
            <div className="collection-view-info--main">
              {/* Collection Name */}
              <div className="collection-name-section">
                {/* Collection Name Title */}
                <div className="collection-name-title">COLLECTION</div>
                {/*  Collection Name Value */}
                <div className="collection-name-value">
                  {originalRows && originalRows.collectionName}
                </div>
              </div>

              {/* Curator Name */}
              <div className="curator-name-section">
                {/* Curator Name Title */}
                <div className="curator-name-title">CURATOR</div>
                {/*  Curator Name Value */}
                <div className="curator-name-value-text">
                  {originalRows && originalRows.curatorName}
                </div>
              </div>

              {/* Collection View navigation */}
              <div className="collection-view-navigation">
                {/* Price ranges */}
                <div className="price-ranges-section">
                  <div className="price-range-title-value">
                    <p>Price</p>
                    {/* Price ranges */}
                    <div className="rangeValues"></div>
                  </div>
                  <section className="range-slider">
                    <input
                      value={minPriceRangeValue}
                      min="1000"
                      max="5000000"
                      step="1000"
                      type="range"
                      className="slider-1"
                      onChange={getVals}
                    />
                    <input
                      value={maxPriceRangeValue}
                      min="1000"
                      max="5000000"
                      step="1000"
                      type="range"
                      className="slider-2"
                      onChange={getVals}
                    />
                  </section>
                </div>

                {/* SELECT MATCHING COLORS */}
                <div className="matching-colors">
                  <div className="mb-3 matching-colors-title">Color</div>
                  {/* Matching colors div*/}
                  <div className="matching-colors-select mb-3">
                    <div className="matching-colors-select-inner">
                      {/* Mapping through the colors*/}
                      {allColors.map((color, i) => (
                        <div
                          key={i}
                          className={`color color-${i}-${color}`}
                          style={{ backgroundColor: color }}
                          onClick={(e) => handleMatchingColorClick(e, color)}
                        ></div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Results per page checkbox */}
                <div className="collection-results-per-page products--header-column-three">
                  {/* RESULTS PER PAGE */}
                  <label
                    htmlFor="results-per-page"
                    className="form-label products-form-label"
                  >
                    RESULTS PER PAGE:
                  </label>
                  <div className="form-group form-group-dropdown mb-3">
                    <div className="left-arrow-section">
                      <img src={arrowUp} alt="arrow-up" />
                      <img src={arrowDown} alt="arrow-down" />
                    </div>
                    <select
                      name="results-per-page"
                      id="results-per-page"
                      aria-describedby="resultsPerPage"
                      className="form-control form-dropdown"
                      onChange={(e) => {
                        setResultsPerPage(Number(e.target.value));
                        setCurrentPage(1);
                      }}
                      style={{ cursor: "pointer" }}
                      value={resultsPerPage}
                    >
                      <option defaultValue="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                  </div>
                </div>

                {/* Layout Selected Section */}
                <div className="layout-select-section">
                  <div className="layout-select-title">VIEW</div>
                  <div className="layout-select-options">
                    {/* Layout Grid */}
                    <div className="layout-btn layout-grid">
                      <button onClick={(e) => setPageLayout("grid")}>
                        GRID
                      </button>
                    </div>

                    {/* Layout Full width */}
                    <div className="layout-btn layout-full-width">
                      <button onClick={(e) => setPageLayout("full")}>
                        Full Width
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side / Collection List of Artworks */}
        <div className="right-column collection-view-content collection-grid-layout">
          <div className="collection-view-content--inner">
            {/*  Boxes Section*/}
            <div className="artwork-box-section">
              {/* Using the current items from the pagination pluugin, loop through given the collection as the center as iteration*/}
              {pageLayout === "grid" ? (
                <div className="grid-box-layout">
                  {/* GRID LAYOUT */}
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {/* array of JSX items */}
                    {currentItems?.map((collection, i) => (
                      <div
                        key={i + 1}
                        className={`grid-artwork-box`}
                        id={`artwork-box-${i + 1}`}
                      >
                        <a
                          href={`/artworks/${collection.artistName}/${collection.artworkName}`}
                        >
                          <img
                            src={require("../../../../src/images/" +
                              collection.displayImgSrc)}
                            alt="display-img"
                            className="display-img"
                          />
                        </a>
                        {/* Box Footer Section */}
                        <div className="grid-artwork-box-footer">
                          {/* Artwork Name */}
                          <div className="artwork-name">
                            {collection.artworkName}
                          </div>

                          {/* Artist Name */}
                          <div className="artist-name">
                            Artist: {collection.artistName}
                          </div>

                          {/* Lower Side of footer */}
                          <div className="lower-box-footer">
                            {/* Artwork Price */}
                            <div className="artwork-price">
                              NGN
                              {numberWithCommas(collection.price)}
                            </div>

                            {/* Action Buttons */}
                            <div className="action-buttons">
                              {/* Like Button */}
                              <div className="like-button">
                                <img
                                  onClick={handleArtworkLike}
                                  src={likeBtn}
                                  alt="like-btn"
                                />
                              </div>

                              {/* Cart Button */}
                              <div className="cart-button">
                                <img
                                  onClick={handleArtworkCart}
                                  src={cartBtn}
                                  alt="cart-btn"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
              ) : (
                <div className="full-box-layout">
                  {/* FULL WIDTH BOX LAYOUT */}
                  <div className="full-box-layout--inner">
                    {currentItems?.map((collection, i) => (
                      <div
                        key={i + 1}
                        className={`full-artwork-box`}
                        id={`artwork-box-${i + 1}`}
                      >
                        <a
                          href={`/artworks/${collection.artistName}/${collection.artworkName}`}
                        >
                          <img
                            src={require("../../../../src/images/" +
                              collection.displayImgSrc)}
                            alt="display-img"
                            className="full-display-img"
                          />
                        </a>
                        {/* Box Footer Section */}
                        <div className="full-artwork-box-footer">
                          {/* Artwork Name */}
                          <div className="artwork-name">
                            {collection.artworkName}
                          </div>

                          {/* Artist Name */}
                          <div className="artist-name">
                            Artist: {collection.artistName}
                          </div>

                          {/* Lower Side of footer */}
                          <div className="lower-box-footer">
                            {/* Artwork Price */}
                            <div className="artwork-price">
                              NGN
                              {numberWithCommas(collection.price)}
                            </div>

                            {/* Action Buttons */}
                            <div className="action-buttons">
                              {/* Like Button */}
                              <div className="like-button">
                                <img
                                  onClick={handleArtworkLike}
                                  src={likeBtn}
                                  alt="like-btn"
                                  className="full-like-btn"
                                />
                              </div>

                              {/* Cart Button */}
                              <div className="full-cart-button">
                                <img
                                  onClick={handleArtworkCart}
                                  src={cartBtn}
                                  alt="cart-btn"
                                  className="full-cart-btn"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Footer / Pagination */}
          <div className="collection-view-footer">
            <div className="collection-view-footer--inner">
              <div className="collection-footer">
                <div className="pagination-section">
                  {originalRows?.collection &&
                    !isEmpty(originalRows.collection) && (
                      <Pagination
                        paginate={paginate}
                        rowsPerPage={resultsPerPage}
                        totalPosts={originalRows?.collection.length}
                        currentPage={currentPage}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Collection;
