import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./Collections.css";
import { Pagination } from "../../Pagination/Pagination";
import Image from "../../../images/register2.jpg";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import Header from "../../LandingPage/Header/Header";
import isEmpty from "../../../validation/is-empty";
// Dummy Data creation
function createData(id, curatorName, displayImgSrc, collections) {
  return {
    id,
    curatorName,
    displayImgSrc,
    collections,
  };
}

const rows = [
  createData(1, "Eclair", "Pic 2015-01-23 17-42-22.jpg", [
    {
      collectionName: "clair 1",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 2",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 3",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 4",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 5",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 6",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 7",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 8",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    {
      collectionName: "clair 9",
      displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    },
    // {
    //   collectionName: "clair 10",
    //   displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    // },
  ]),
  createData(2, "Cupcake", "register2.jpg", [
    { collectionName: "collection-cupacake-1" },
    { collectionName: "collection-cupacake-2" },
    { collectionName: "collection-cupacake-3" },
    { collectionName: "collection-cupacake-4" },
    { collectionName: "collection-cupacake-5" },
    { collectionName: "collection-cupacake-6" },
    { collectionName: "collection-cupacake-7" },
  ]),
  createData(3, "Donut", "register.jpg", [
    { collectionName: "collection-donut-1" },
    { collectionName: "collection-donut-2" },
    { collectionName: "collection-donut-3" },
    { collectionName: "collection-donut-4" },
    { collectionName: "collection-donut-5" },
    { collectionName: "collection-donut-6" },
    { collectionName: "collection-donut-7" },
  ]),
  createData(4, "Frozen yoghurt", "register2.jpg", [
    "collection-yoghurt-1",
    "collection-yoghurt-2",
    "collection-yoghurt-3",
    "collection-yoghurt-4",
    "collection-yoghurt-5",
    "collection-yoghurt-6",
  ]),
  createData(5, "Gingerbread", "register.jpg", [
    "collection-gingerbread-1",
    "collection-gingerbread-2",
    "collection-gingerbread-3",
    "collection-gingerbread-4",
    "collection-gingerbread-5",
    "collection-gingerbread-6",
  ]),
  createData(6, "Honeycomb", "register.jpg", [
    "collection-honeycomb-1",
    "collection-honeycomb-2",
    "collection-honeycomb-3",
    "collection-honeycomb-4",
    "collection-honeycomb-5",
    "collection-honeycomb-6",
  ]),
  createData(7, "Ice cream sandwich", "Pic 2015-01-23 17-42-22.jpg", [
    "collection ice-cream 1",
    "collection ice-cream 2",
    "collection ice-cream 3",
    "collection ice-cream 4",
    "collection ice-cream 5",
    "collection ice-cream 6",
  ]),
  createData(8, "Jelly Bean", "register.jpg", [
    "collection jelly bean 1",
    "collection jelly bean 2",
    "collection jelly bean 3",
    "collection jelly bean 4",
    "collection jelly bean 5",
    "collection jelly bean 6",
  ]),
  createData(9, "KitKat", "Pic 2015-01-23 17-42-22.jpg", [
    "collection KitKat 1",
    "collection KitKat 2",
    "collection KitKat 3",
    "collection KitKat 4",
    "collection KitKat 5",
    "collection KitKat 6",
  ]),
  createData(10, "Lollipop", "register2.jpg"),
  createData(11, "Donak", "Pic 2015-01-23 17-42-22.jpg"),
  createData(12, "Marshmallo", "Pic 2015-01-23 17-42-22.jpg"),
  createData(13, "Nougat", "register.jpg"),
  createData(14, "Oreo", "register2.jpg"),
];
function Collections() {
  // Functions and variables

  const { name: curatorName } = useParams();
  const [originalRows, setOriginalRows] = useState(null);
  // console.log(originalRows[0].collectionName);
  const [currentItems, setCurrentItems] = useState(null);
  const [rowLength, setRowlength] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(5);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      const currentRows = originalRows?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  //   USEEFFECT
  useEffect(() => {
    let selectedCollection = rows.filter((row) => {
      return row.curatorName === curatorName;
    });
    // setOriginalRows(selectedCollection[0].collections);
    setOriginalRows(rows[0].collections);
    // setRowlength(originalRows.length - 1);
  }, []);

  return (
    <div className="collections">
      <Header />
      <div className="collections--inner">
        {/* Left Fixed Side Section */}
        <section className="side-section section--left">
          <img src={Image} alt="img" />

          <div className="collection-info">
            <div className="info--curator-name">{curatorName}</div>
            <div className="info--about">
              <div className="about-title">About</div>
              <div className="about-content">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Minima accusamus aliquam exercitationem consectetur nulla quod
                  maiores. Harum dolorem incidunt ratione
                </p>
                <p>Lorem ipsum dolor sit amet Harum dolorem incidunt ratione</p>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Minima accusamus aliquam exercitationem consectetur nulla quod
                  maiores
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Right Fixed Main Section */}
        <main className="main-section section--right">
          {/* Results per page checkbox */}
          <div className="collections-results-per-page">
            {/* RESULTS PER PAGE */}
            <label
              htmlFor="results-per-page"
              className="form-label collections-form-label"
            >
              RESULTS PER PAGE:
            </label>
            <div className="form-group form-group-dropdown mb-3">
              <div className="left-arrow-section">
                <img src={arrowUp} alt="arrow-up" />
                <img src={arrowDown} alt="arrow-down" />
              </div>
              <select
                name="results-per-page"
                id="results-per-page"
                aria-describedby="resultsPerPage"
                className="form-control form-dropdown"
                onChange={(e) => {
                  setResultsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
                style={{ cursor: "pointer" }}
                value={resultsPerPage}
              >
                <option defaultValue="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </div>
          </div>
          {/* Collection Boxes Section*/}
          <div className="collections-box-section">
            {/* Using the current items from the pagination pluugin, loop through given the collection as the center as iteration*/}
            {currentItems?.map((collection, i) => (
              <div
                key={i + 1}
                className={`collection-box`}
                id={`collection-box-${i + 1}`}
              >
                {
                  // [{ id: "1", collectionName: "me" }][0].collectionName
                  // typeof collection
                }
                <img
                  src={require("../../../../src/images/" +
                    collection.displayImgSrc)}
                  alt="display-img"
                />
                {/* Box Footer Section */}
                <div className="collection-box-footer">
                  <div className="collection-name">
                    Collection {collection.collectionName}
                  </div>
                  {/* Lower Side of footer */}
                  <div className="lower-box-footer">
                    {/* View Collection Link */}
                    <div className="view-collection-link">
                      <a
                        href={`/curators/${curatorName}/${collection.collectionName}`}
                      >
                        view collection
                      </a>
                    </div>

                    {/* No of Artworks in Collection */}
                    <div className="no-of-artworks">10 artworks</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="collection-footer">
            <div className="pagination-section">
              {originalRows && !isEmpty(originalRows) && (
                <Pagination
                  paginate={paginate}
                  rowsPerPage={resultsPerPage}
                  totalPosts={originalRows.length}
                  currentPage={currentPage}
                />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Collections;
