import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <div className="navbar-main">
      <Container>
        <div className="navbar">
          <div className="navbar-menu">
            <ul>
              <li>
                <Link to="/artist-dashboard/upload-art">ARTISTS</Link>
              </li>
              <li>
                <Link to="/gallery/sort/artworks">ARTWORKS</Link>
              </li>
              <li>
                <Link to="/gallery/sort/paintings">PAINTING</Link>
              </li>
              <li>
                <Link to="/gallery/sort/drawings">DRAWINGS</Link>
              </li>
              <li>
                <Link to="/gallery/sort/prints">PRINTS</Link>
              </li>
              <li>
                <Link to="/gallery/sort/photography">PHOTOGRAPHY</Link>
              </li>
              <li>
                <Link to="/gallery/sort/landscape">LANDSPACE</Link>
              </li>
            </ul>
          </div>

          <div className="navbar-search">
            <div className="input-icons">
              <i className="fa fa-search icon"></i>
              <input
                placeholder="Search..."
                className="input-field"
                type="text"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
