import React, { useState, useRef, useEffect } from "react";
import "./Galleries.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import arrowRight from "../../images/arrow-right.png";
import searchIcon from "../../images/search.png";
import Footer from "../LandingPage/Footer/Footer";
import { Pagination } from "../Pagination/Pagination";
import {
  getProducts,
  viewArtwork,
  seeArtwork,
} from "../../redux/actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Header from "../LandingPage/Header/Header";
import isEmpty from "../../validation/is-empty";

function createData(id, galleryName, displayImgSrc) {
  return {
    id,
    galleryName,
    displayImgSrc,
  };
}

const rows = [
  createData(1, "Paintings", "Pic 2015-01-23 17-42-22.jpg"),
  createData(2, "Drawings", "register2.jpg"),
  createData(3, "Prints", "register.jpg"),
  createData(4, "Photography", "register2.jpg"),
  createData(5, "Landscape", "register.jpg"),
];

function Galleries() {
  // Functions and Variables

  const [originalRows, setOriginalRows] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const [sortByLocation, setSortByLocation] = useState("all");
  const [searched, setSearched] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [filteredRows, setFilteredRows] = useState(null);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      //  Handle Search functionality on the boxes
      const filteredGalleries = originalRows.filter((row) => {
        return row.galleryName.toLowerCase().includes(searched.toLowerCase());
      });

      setFilteredRows(filteredGalleries);

      const currentRows = filteredGalleries?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage, searched]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  // Refs
  const searchResultTextRef = useRef();

  // Functions

  // Handles the hover effect on the curators box
  const hoverEffectHandler = function () {
    if (!isEmpty(originalRows)) {
      originalRows?.map((row, i) => {
        document
          .getElementById(`gallery-box-${i + 1}`)
          ?.addEventListener("mouseover", (e) => {
            e.stopPropagation();
            if (e.target.tagName === "IMG" || e.target.tagName === "DIV") {
              //   Remove the hidden class from the view box section
              e.target.parentElement.lastChild.classList.remove("hidden");
            }
          });
        document
          .getElementById(`gallery-box-${i + 1}`)
          ?.addEventListener("mouseleave", (e) => {
            e.stopPropagation();
            //   Add the hidden class to the view box section
            e.target.lastChild.classList.add("hidden");
          });
      });
    }
  };

  // USEEFFECT

  useEffect(() => {
    hoverEffectHandler();
  }, [filteredRows]);

  useEffect(() => {
    setOriginalRows(rows);
  }, []);

  return (
    <div className="galleries">
      <Header />
      <div className="galleries--inner">
        {/* Gallery Header */}
        <div className="galleries--header">
          <div className="galleries--inner-title">Browse Categories</div>
          <div className="galleries--header-inner">
            {/* Sort By Check box */}
            <div className="sort-by galleries--header-column-one">
              {/* LOCATIONS */}
              <label
                htmlFor="sort-by"
                className="form-label galleries-form-label"
              >
                FILTER BY LOCATION:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="sort-by"
                  id="sort-by"
                  aria-describedby="sortByLocation"
                  className="form-control form-dropdown"
                  onChange={(e) => setSortByLocation(e.target.value)}
                  value={sortByLocation}
                >
                  <option value="all">All Locations</option>
                  <option value="nigeria">Nigeria</option>
                  <option value="ghana">Ghana</option>
                  <option value="south-africa">South Africa</option>
                </select>
              </div>
            </div>

            {/* Search bar */}
            <div className="search-bar galleries--header-column-two">
              {/* SEARCH BAR */}
              <div className="search-bar-section">
                <label
                  htmlFor="search-bar"
                  className="form-label galleries-form-label"
                >
                  SEARCH FOR GALLERY:
                </label>
                <div className="form-group form-group-dropdown mb-3">
                  <div className="left-search-icon-section">
                    <img src={searchIcon} alt="search" />
                  </div>
                  <input
                    name="search-bar"
                    id="search-bar"
                    type="search"
                    aria-describedby="searchBar"
                    className="form-control form-search"
                    placeholder="Search..."
                    value={searched}
                    onChange={(e) => {
                      setSearched(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Results per page checkbox */}
            <div className="results-per-page galleries--header-column-three">
              {/* RESULTS PER PAGE */}
              <label
                htmlFor="results-per-page"
                className="form-label galleries-form-label"
              >
                RESULTS PER PAGE:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="results-per-page"
                  id="results-per-page"
                  aria-describedby="resultsPerPage"
                  className="form-control form-dropdown"
                  onChange={(e) => {
                    setResultsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  value={resultsPerPage}
                >
                  <option defaultValue="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* SEARCH RESULT TEXT THAT SHOWS WHEN SEARCH VALUE IS NOT EMPTY */}
        <div ref={searchResultTextRef} className="search-result-text none">
          {/* <div className="result-text-row-one">
                        <p>Search Result for "{searched}"</p>
                    </div>
                    <div className="result-text-row-two">
                        <p>{originalRows.length} Results</p>
                    </div> */}
        </div>

        {/* CURATOR BODY */}
        <div className="galleries--body">
          <div className="galleries--body-inner">
            {/* Array of gallery boxes */}
            {currentItems?.map((gallery, i) => (
              <div
                key={i + 1}
                className={`gallery-box`}
                id={`gallery-box-${i + 1}`}
              >
                <img
                  src={require("../../../src/images/" + gallery.displayImgSrc)}
                  alt="display-img"
                />
                <a
                  href={`/gallery/sort/${gallery.galleryName.toLowerCase()}`}
                  className="gallery-box-footer"
                >
                  <div className="gallery-name">{gallery.galleryName}</div>
                  <div className="arrow-image">
                    <img
                      src={arrowRight}
                      alt="right-arrow"
                      className="right-arrow-img"
                    />
                  </div>
                </a>
                <div className="hover-view-section hidden">
                  <a
                    href={`/gallery/sort/${gallery.galleryName.toLowerCase()}`}
                  >
                    VIEW
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*  */}
        {/* Footer / Pagination */}
        <div className="collection-view-footer">
          <div className="collection-view-footer--inner">
            <div className="collection-footer">
              <div className="pagination-section">
                {originalRows && !isEmpty(originalRows) && (
                  <Pagination
                    paginate={paginate}
                    rowsPerPage={resultsPerPage}
                    totalPosts={filteredRows?.length}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Galleries;
