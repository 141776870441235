import React, { useState } from "react";
import Footer from "../../components/LandingPage/Footer/Footer";
import Header from "../../components/LandingPage/Header/Header";

import "./ForgotPassword.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handleEmailSubmit = function (e) {
    const successMessageContainer = document.querySelector(
      ".email-success-message-container"
    );
    const ForgotPasswordForm = document.querySelector(
      ".forgot-password-container--inner form"
    );
    if (!email) {
      return false;
    } else {
      e.preventDefault();
      const result = { email };

      ForgotPasswordForm.classList.add("none");
      successMessageContainer.classList.remove("none");
    }
  };
  return (
    <div className="forgot-password-container">
      <Header />
      {/* Content Container */}
      <div className="forgot-password-container--inner">
        <form action="#" className="">
          {/* Email Address */}
          <div className="forgot-password-title">Forgot Password?</div>
          <div className="form-group mb-3">
            <label htmlFor="user-email" className="form-label">
              EMAIL ADDRESS:
            </label>
            <input
              type="email"
              className="form-control"
              id="user-email"
              aria-describedby="userEmail"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="you@example.com"
              required
            />
          </div>

          {/* Submit button */}
          <button onClick={handleEmailSubmit}>Request Reset</button>
        </form>

        <div className="email-success-message-container none">
          <p>An email has been sent to you with next steps</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
