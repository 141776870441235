import React from 'react';
import Top from './../components/Top/Top';
import NavbarCom from './../components/Navbar/Navbar';
import Footer from './../components/Footer/Footer';

const About = () => {
    return ( <>
        <Top/>
        <NavbarCom/>
        <div className="container mb-4">
            <h2 className="mt-4 mb-4 text-center">ABOUT US </h2>
            
        </div>
        <Footer/>
    </> );
}
 
export default About;