import React, { useState } from "react";
import "./ResetPassword.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Top from "../../components/Top/Top";

function ResetPassword() {
  // Functions and State Variables
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //   Functions
  const handleResetPasswordSubmit = function (e) {
    const successMessageContainer = document.querySelector(
      ".password-reset-success-message-container"
    );
    const ResetPasswordForm = document.querySelector(
      ".reset-password-container--inner form"
    );
    if (!password || !confirmPassword) {
      return false;
    } else if (password !== confirmPassword) {
      alert("Passwords do not match!");
    } else {
      e.preventDefault();
      const result = { password };
      ResetPasswordForm.classList.add("none");
      successMessageContainer.classList.remove("none");
    }
  };
  return (
    <div className="reset-password-container">
      <Top />
      <Navbar />
      {/* Content Container */}
      <div className="reset-password-container--inner">
        <form action="#" className="">
          {/* Email Address */}
          <div className="reset-password-title">Reset Password</div>

          {/* Password Input */}
          <div className="form-group mb-3">
            <label htmlFor="user-password" className="form-label">
              PASSWORD:
            </label>
            <input
              type="password"
              className="form-control"
              id="user-password"
              aria-describedby="userPassword"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </div>

          {/* Confirm Password Input */}
          <div className="form-group mb-3">
            <label htmlFor="user-confirm-password" className="form-label">
              CONFIRM PASSWORD:
            </label>
            <input
              type="password"
              className="form-control"
              id="user-confirm-password"
              aria-describedby="userConfirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              required
            />
          </div>

          {/* Submit button */}
          <button onClick={handleResetPasswordSubmit}>Reset Password</button>
        </form>

        <div className="password-reset-success-message-container none">
          <p>Your Password has been successfully reset!</p>

          {/* Go to Sign In */}
          <button>
            <a href="/artist-login"> Go To Signin</a>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetPassword;
