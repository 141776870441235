import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";

const Privacy = () => {
  return (
    <>
      <Header />
      <div className="container mb-4">
        <h2 className="mt-4 mb-4 text-center">SNOWAFRICA PRIVACY POLICY</h2>
        <p>
          This Privacy Policy ("Policy") describes how SnowAfrica, Inc. d/b/a
          SnowAfrica (“SnowAfrica” or “we” or “our” or “us”) collects, stores,
          shares, and uses information, and your choices about privacy when you
          use our website, products, and services (collectively, “Services”).
          Your privacy is important to us, and we ask that you read this Policy
          carefully to be informed about our practices. This Policy is part of
          our Terms of Use, which can be viewed at:{" "}
          <Link to="/terms">https://snowAfrica.art/terms</Link>. Any capitalized
          terms that are used but not defined in this Policy have the same
          meaning given to them in our Terms of Use. By accessing or using our
          Services in any way, you agree that your information may be collected,
          stored, shared, and used as described in this Policy and our Terms of
          Use. By using our Services, you consent to the collection, use and
          sharing of your Personal Information as outlined in this Policy. You
          may withdraw consent at any time in writing, however such withdrawal
          does not negate our right to process your data prior to your
          withdrawal.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          WHEN THIS PRIVACY POLICY APPLIES
        </h4>
        <p>
          This Policy applies to all our Services, regardless of how you use
          them, and whether or not you register for a SnowAfrica account. This
          Policy applies to how we collect, store, share, and use information.
          This Policy does not apply to any entity that we do not own or
          control, or to any person that we do not employ or manage.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          TYPES OF INFORMATION WE PROCESS
        </h4>
        <p>
          <strong>Information You Provide:</strong> We may collect the
          information that you provide (such as name, email address, home
          address, phone number) or otherwise make available to us when you
          access or use our Services, including when you:
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            fill in any forms on our Services, such as when you register for a
            SnowAfrica account or update your account or User profile
            information;
          </li>
          <li>
            access or use our Services, such as when you search for or view
            artwork or other items, follow artists, galleries, institutions or
            other Users, place bids in an auction or otherwise perform
            transactions, or communicate with other Users (this can include the
            information you provide on or through our Services, and information
            about how you access or use our Services, such as which features you
            use and when you use them);
          </li>
          <li>
            link your SnowAfrica account to your account on another social media
            service (including when you sign up for our Services or sign into
            our Services through another social media service), in which case we
            may obtain your username on that service and other information you
            make publicly available through that service, which may vary
            depending on your settings on that service; or
          </li>
          <li>communicate with us.</li>
        </ul>
        <p>
          <strong>Information Collected Automatically:</strong> We may
          automatically collect certain information about how you access or use
          our Services, and by using our Services you consent to the collection
          of that information. Examples include but are not limited to: internet
          browser, operating system, device and application identifiers, IP
          address and geo-location information, access times and dates,
          referring and exit pages and URLs, clickstream data, search terms,
          pages viewed, time spent on pages, bounce rate and login frequency.
        </p>
        <p>
          <strong>
            Information from Cookies, Web Beacons and Similar Technologies:
          </strong>{" "}
          We may collect information using cookies, web beacons and similar
          technologies. These technologies help us to operate, improve and
          customize our Services, Content, and marketing, for example, by
          helping us to better understand how Users access and engage with our
          Services, Content, emails and ads. We may also use third-party service
          providers (such as analytics or ad providers) that may use cookies,
          web beacons and similar technologies to help operate their services.
          Your browser may have settings that allow you to decline cookies, but
          please note that some of our Services may not be available if you do
          so, as some of our Services require cookies to operate. For more
          information about cookies, web beacons, similar technologies, and how
          they are used, please see our Cookie Policy below.
        </p>
        <p>
          <strong>Information from Other Users: </strong> If you use our
          Services to communicate with another User or perform a transaction
          with another User, we may obtain information or feedback from them
          about their interaction with you (for example, whether you were
          responsive to their communications or completed a transaction with
          them). Also, when Users share User Content through our Services, if it
          contains information about you, we will receive that information as
          well. More information about User Content is provided below.
        </p>
        <p>
          <strong>Information from Other Sources: </strong> We may obtain
          information about you from third-party partners (such as other
          companies with which we may jointly offer services or conduct
          promotional events), third-party service providers, credit bureaus or
          other sources, including but not limited to demographic information,
          contact information, open data, publicly available data and credit
          check information. We may use this information for legitimate business
          purposes, including the performance of a contract, customer support,
          fraud prevention, and credit-related decisions in connection with our
          Services, along with other purposes described in this Policy.
        </p>
        <p>
          <strong>Information from Mobile Use: </strong> When you access our
          Services on a mobile device, for example, when you download or use an
          SnowAfrica mobile application or visit our website from a mobile
          browser, we may obtain information about your location and mobile
          device, including a unique identifier for your device which
          information may be used for legitimate business purposes. Along with
          the other purposes described in this Policy, we may use this
          information to customize our Services, Content and marketing for your
          location. Most mobile devices let you disable location services, and
          your choices for location services may vary depending on your mobile
          device.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          HOW WE USE THE INFORMATION WE COLLECT AND THE REASONS FOR DOING SO
        </h4>
        <p>
          The information we collect helps us to operate, improve, protect, and
          customize our Services, as well as develop new Services. The
          information we collect also helps us provide customer support and make
          our Services more efficient for you and other Users.
        </p>
        <p>
          For the sake of transparency, it is our responsibility to outline for
          you the legitimate business purposes for the collection of information
          and the reasons why we do so, in order for you to make an informed
          decision regarding the use of our Services.
        </p>
        <p>
          In addition to the other uses of information described in this Policy,
          we may use all the information that we obtain from or about you for
          the following purposes and reasons:
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            to provide our Services to you, including new Services we develop
            later (reason: legitimate business purpose and performance of a
            contract);
          </li>
          <li>
            to provide you with customer support (reason: legitimate business
            purpose).
          </li>
          <li>
            to facilitate your communications or transactions with us or other
            Users (reason: to receive consent, and for legitimate business
            purpose);
          </li>
          <li>
            to operate, improve, and customize our Services, Content, and
            marketing, to develop new Services, and to understand how you use
            and interact with our Services and Content, and the products,
            services, and Content of others (reason: legitimate business
            purpose);
          </li>
          <li>
            to develop and display more customized Content and ads to you on or
            off our Services (reason: to receive consent, and for legitimate
            business purpose);
          </li>
          <li>
            to promote and maintain a trusted, safer, and more reliable
            environment on our Services, for example, to help detect, prevent or
            otherwise address fraud, security or technical issues, conduct
            investigations or risk assessments, or verify actions taken by you
            or associated with your account (reason: to protect the public
            interest and for legitimate business purpose);
          </li>
          <li>
            to contact you (by email, postal mail, telephone, mobile devices,
            SMS text message, or as otherwise authorized by you) in order to
            address issues with your account or use of our Services, collect
            fees, send you updates about our Services or policies, or for other
            purposes permitted by law (reason: performance of a contract and for
            legitimate business purpose);
          </li>
          <li>
            to contact you (by email, postal mail, telephone, mobile devices,
            SMS text message, or as otherwise authorized by you) in order to
            send you marketing communications, promotional messages, and offers
            about our Services and Content, and the products, services, and
            Content of others (see below for information on how to opt out of
            receiving certain communications from us) (reason: to receive
            consent, and for legitimate business purpose);
          </li>
          <li>
            to facilitate your participation in contests, sweepstakes or other
            promotional events sponsored or conducted by us or by others in
            conjunction with us (reason: performance of a contract);
          </li>
          <li>
            to comply with our legal obligations, resolve disputes, or enforce
            our Terms of Use or any other SnowAfrica policies or agreements with
            Users (reason: compliance with a legal obligation); and
          </li>
          <li>
            to perform other functions as described when the information is
            collected or requested, or for other purposes with your prior
            consent (reason: legitimate business purpose).
          </li>
        </ul>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          COMMUNICATION PREFERENCES
        </h4>
        <p>
          We provide registered Users with settings on our Services to control
          whether they receive certain communications from us. If you do not
          want to receive marketing email from us, you can unsubscribe by
          following the unsubscribe link in the email you received, changing
          your preferences in your profile and account settings (if you have
          registered for an SnowAfrica account), or emailing us at{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
          You may not be able to opt out of receiving some communications from
          us, such as email about your account, your relationship with us, or
          your transactions with us or other Users. For further information,
          please don’t hesitate to contact us at{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
          Please note that unsubscribing from receiving emails from us may
          result in a less robust experience of our Services.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          ACCOUNTS AND USER PROFILES
        </h4>
        <p>
          <strong>Registration, Access and Records:</strong> To register for a
          SnowAfrica account, you will need to do so by providing your full name
          and a valid email address. If you have received our permission to use
          our Services as a business, you may need to complete an additional
          registration process and provide additional information. If you have a
          SnowAfrica account, you may access, add, remove or update certain
          information about you in your profile and account settings, as
          indicated on our Services. It is your responsibility to update all
          such information as necessary to keep it accurate and current. When
          you update your account or profile information, we may keep a record
          of the unrevised information for purposes consistent with this Policy.
          If you wish to cancel your account, you may do so by using the tools
          provided in your profile and account settings, subject to any other
          agreements between you and us and any transactions which have not yet
          been completed. If you terminate your account, we may keep a record of
          any or all information associated with you or your account, as
          required or permitted by law, for purposes consistent with this
          Policy, and any such data shall be attached to a unique identifier
          which cannot be used to identify you; specific account data that
          includes Personal Information including your name, e- mail, contact
          information, and certain other records such as settings and collector
          profiles will be removed at such termination. For further information,
          please contact us at{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
        </p>
        <p>
          <strong>Profile and Account Information:</strong> To make our Services
          more interactive, certain information about Users’ profiles or
          accounts may be made accessible to other Users on or through our
          Services, as indicated in Users’ profile and account settings on our
          Services, or as otherwise indicated by us when you use certain
          Services. Our Services may include settings to control how certain
          profile or account information is shared with other Users on or
          through our Services, and you should check your profile and account
          settings regularly to be informed of your current choices. For further
          information, please contact us{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
        </p>
        <p>
          <strong>Data Retention:</strong> We will keep and use your data as
          described in this Policy for (i) as long as your account is in use, is
          open, or is active; (ii) as long as is necessary to comply with any
          tax, legal, and/or other regulatory requirements; and (iii) to protect
          and defend against potential legal claims. If you would like to
          request the deletion of your personally identifiable information or
          request that it be returned to you, please contact us at{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a> By
          deleting your data from our Site, you understand that you will no
          longer be able to use our Services. We will retain your anonymized,
          aggregated, depersonalized data after it has been deleted from our
          Site.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          FAVORITES AND FOLLOWS
        </h4>
        <p>
          Our Services may include features that enable you to designate your
          “favorite” artists, curators or other creators, artwork or other
          items, or categories of artwork or other items. Our Services may also
          include features that enable you to “follow” galleries, institutions
          or other Users, artists, curators or other creators, or categories of
          artwork or other items.
        </p>
        <p>
          If you enable such designations, we may include you in email
          communications regarding those designations. If you would like to opt
          out of receiving those emails, please follow the unsubscribe link at
          the bottom of the relevant email or visit your account profile to
          update your email settings. For further information, please contact us
          at <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          USER CONTENT
        </h4>
        <p>
          As described in our Terms of Use, some Services may enable you to
          provide User Content on or through our Services. When you post or
          publish User Content on our Services, any information contained in the
          User Content (including information in images or videos) becomes
          publicly accessible on our Services (and may also be indexed by and
          accessible through third-party search engines), and we and other Users
          may access and use the User Content as described in our Terms of Use.
          This applies even if you or someone else also provides such
          information to us by other means, and our use of such information
          might have been more limited under this Policy if such information was
          only provided to us by such other means. It is your responsibility to
          ensure that you have the right to share any information about others
          that you may share with us or with others through our Services.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          USER COMMUNICATIONS
        </h4>
        <p>
          <strong>Connecting with Other Users:</strong> We aim to provide a
          platform and marketplace where Users can discover and engage with a
          wide range of artwork, design and other items from around the world,
          including items from the various galleries, institutions and others
          that use our Services as exhibitors or sellers. To help make this
          happen, our Services may include features, such as messaging tools and
          email addresses hosted on our Services, that enable you to contact and
          communicate with other Users. For example, when an auction house,
          gallery, or other seller lists an artwork or other item on our
          Services, our Services may enable you to contact them about that item
          or about other items they may have available.
        </p>
        <p>
          <strong>Sharing Information with Other Users:</strong> When you use
          our Services to contact another User or perform a transaction with
          another User, such as an auction house, gallery, or institution, they
          may obtain your name, email address and (as applicable) other contact
          information from us to facilitate communications, transactions, or for
          their marketing purposes. Sometimes such Users may be able to obtain
          additional information about you when you use our Services to contact
          them or perform a transaction with them. For example, they may be able
          to obtain information about your location, interests and other
          information, as indicated by us in connection with these Services, or
          as otherwise indicated in your profile and account settings. Our
          Services may include settings that enable you to choose how certain
          information is shared with other Users when you contact them or
          perform a transaction with them, as indicated when you use these
          Services or in your profile and account settings.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          WITH WHOM DO WE SHARE PERSONAL INFORMATION?
        </h4>
        <p>
          <strong>Personal Information.</strong> Under this Policy, "Personal
          Information" means information that by itself can be used to identify
          or contact a specific person (for example, a person’s name, email
          address, postal address or phone number), and Personal Information
          does not include information that has been aggregated or made
          anonymous so that by itself it no longer identifies and can no longer
          reasonably be used to contact a specific person. In certain
          circumstances, Personal Information may also include information you
          voluntarily provide to us such as artists and galleries you choose to
          follow. We will only share your Personal Information with others in
          the ways described in this Policy, or otherwise with your prior
          consent. In addition to the other sharing of Personal Information
          described in this Policy, we may share your Personal Information with
          others in the following contexts.
        </p>
        <ul style={{ listStyleType: "circle" }}>
          <li>
            Service Providers: We may contract with third-party service
            providers that help us provide our Services, or that perform
            services for us related to our Services, and they may process your
            Personal Information as necessary to perform those functions for us.
            Examples may include but are not limited to: identity verification,
            document authentication, public database review, order fulfillment,
            package delivery, payment processing, bill collection, fraud
            investigation, email and postal mail administration, customer
            service assistance, web and mobile data analytics, display ads, and
            server and database hosting services. For the avoidance of doubt, we
            do not sell your Personal Information to our Service Providers.
          </li>
          <li>
            Business Transfers: If we are involved in a merger, acquisition, or
            sale of assets, your Personal Information may be one of the assets
            transferred. In this event, if you have registered for a SnowAfrica
            account, we will notify you of the change of ownership by sending an
            email to the most recent email address you provided us under your
            SnowAfrica account.
          </li>
          <li>
            Legal Purposes: We may access, preserve, and disclose your Personal
            Information and/or other information if we believe that such access,
            preservation or disclosure is reasonably necessary to: (i) comply
            with any law, regulation, legal process or governmental request
            (such as search warrants, subpoenas or court orders), which may
            include responding to legal requests from jurisdictions outside the
            United States; (ii) enforce our Terms of Use or our other policies
            or agreements with Users, or investigate potential violations; (iii)
            detect, prevent or otherwise address fraud, security or technical
            issues; (iv) protect or enforce the rights or property of
            SnowAfrica, you, or any other person or entity; or (v) prevent
            physical harm to any person, including situations involving possible
            violence or self-harm.
          </li>
        </ul>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          AGGREGATED AND NON-PERSONAL INFORMATION
        </h4>
        <p>
          We may aggregate and/or make anonymous any Personal Information that
          we have obtained in any way from or about you or others so that such
          information no longer contains any Personal Information. We may use
          and share any aggregated, anonymous, or other non-personally
          identifying information for any legitimate business purposes,
          including research and development, marketing, and to inform others
          about how our Services or Content are engaged with or used.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          PAYMENT INFORMATION
        </h4>
        <p>
          If you use our marketplace or register for certain Services, you may
          need to provide payment information, such as a credit card number,
          type and expiration date. We do not intentionally store credit card
          information entered on our Services. This information is stored by a
          third-party payment processor, and the use and storage of this
          information will be subject to the payment processor’s own terms of
          service and privacy policy. We do receive certain information from the
          payment processor, including a unique token that we associate with you
          to authorize your purchases, and in certain cases, the last four
          digits of the credit card number associated with that token, in order
          to help prevent fraud and identity theft. If you do not wish to
          provide payment information, you will not be able to use certain
          Services on our Site.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          OTHER WEBSITES AND SERVICES
        </h4>
        <p>
          Our Services may include links to other websites or services that we
          do not own or control, and other websites or services that we do not
          own, or control may include links to our Services. Our Services may
          also include features that enable you to link your SnowAfrica account
          to your account on other social media services. For example, you may
          be able to sign up for (or sign in to) our Services through another
          social media service. Our Services may also include features that
          enable you to share Content on other websites and social media
          services. However, these links and features are not an endorsement by
          us of any other websites or services, or any of their privacy policies
          or practices. When you visit or use other websites or services, they
          may collect information from you, including using cookies, web beacons
          or similar technologies. We encourage you to read the privacy policies
          of other websites and services that you visit or use to be informed of
          their practices.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          INTERNATIONAL PROCESSING
        </h4>
        <p>
          SnowAfrica is headquartered in the Nigeria. Our Users (including the
          galleries and other organizations that use our Services as exhibitors
          or sellers), service providers, and other third parties you may
          interact with in connection with our Services, may be located around
          the world, including countries that may not offer the same level of
          protection for Personal Information as that offered in the Nigeria. By
          accessing or using our Services in the Nigeria or any other country or
          jurisdiction, you agree that your information may be transferred and
          processed in the Nigeria and any other country or jurisdiction.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          CHILDREN’S INFORMATION
        </h4>
        <p>
          Our Services are not directed to children under the age of 16, and we
          do not knowingly collect Personal Information from people in this age
          group. If you believe we may have collected Personal Information from
          anyone under the age of 16, please{" "}
          <Link to="/contact">contact us.</Link>
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          SECURITY
        </h4>
        <p>
          Every SnowAfrica account is protected by a password to help maintain
          privacy and security on our Services. If you register for an account,
          we urge you to use a strong password containing unique numbers,
          letters, and special characters, and to protect the confidentiality of
          your password at all times. If you suspect or become aware of any
          unauthorized access to or use of your account or password, you agree
          to immediately <Link to="/contact">notify us</Link> and change your
          password. Please note that while we seek to protect your information
          and maintain the security of our Services, due to the possibility of
          hardware or software failure, unauthorized entry or use and other
          factors, we cannot guarantee the security of any information, whether
          online or offline. Any transmission of information is at your own
          risk. Please also note that any information you provide to us via
          email is unencrypted.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          STATUTORY REQUESTS
        </h4>
        <p>
          Some jurisdictions have laws that give people the right to access or
          correct their Personal Information which a company has about them. We
          will honor any statutory right you may have to access or correct your
          Personal Information that we have in our records, and you can email
          such requests to{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>{" "}
          Once we receive your request, we will let you know if an
          administrative fee will apply to fulfill your request, as permitted by
          applicable law. However, please note that even if you have a legal
          right to request access to information or to correct information, as
          permitted by applicable law, we may reject requests that are
          unreasonably repetitive, would require disproportionate technical
          effort (for example, developing a new system or materially changing an
          existing practice), would jeopardize the privacy of others, or would
          be extremely impractical to fulfill (for example, requests to access
          information located on backup systems).
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          DISPUTE RESOLUTION
        </h4>
        <p>
          As this Policy is part of our Terms of Use, any disputes involving you
          and us that relate to privacy or the use of your information, and that
          arise out of or are related to this Policy or our Services, will be
          subject to our Terms of Use, including any provisions regarding the
          limitation of damages and liability, choice of law, and dispute
          resolution.
        </p>
        <p>
          If you do not receive timely acknowledgement of your complaint, or if
          your complaint is not satisfactorily addressed, you can direct our
          attention to it via Social Media.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          CHANGES TO THIS PRIVACY POLICY
        </h4>
        <p>
          Our business changes with time, and our Privacy Policy will change
          also. We reserve the right to change this Policy, including our Cookie
          Policy, without prior notice, at any time at our sole discretion. For
          example, and without limitation, we may change this Policy to reflect
          changes to the law or changes to our Services. All changes to this
          Policy will be effective when posted on our Services, or at such later
          date as may be specified in the updated Policy. We will notify you of
          any changes to this Policy by posting the updated Policy on our
          Services, and you agree to review this Policy regularly and inform
          yourself of all applicable changes. By continuing to use our Services
          after any changes to this Policy become effective, you agree to such
          changes and the updated Policy. Unless we notify you otherwise, the
          current version of this Policy will apply to all information that we
          have about you or your account.
        </p>
        <h4 className="mt-4 mb-4 text-center" style={{ color: "#FCB040" }}>
          COOKIE POLICY
        </h4>
        <p>
          <strong>Cookies, Web Beacons, and Similar Technologies:</strong> We
          may use cookies, web beacons and similar technologies, such as
          tracking URLs, to collect information about how you or others access
          or engage with our Services, Content, emails, and ads displayed on or
          off our Services, and to operate, develop, improve, and customize our
          Services, Content, and marketing, including ads displayed on or off
          our Services. Most browsers automatically accept cookies, but your
          browser may have settings that enable you to decline or delete cookies
          on your device. Please note that if you decline cookies, you may not
          be able to sign in, customize, or use some of the interactive features
          of our Services, as some of our Services require cookies to operate.
        </p>
        <p>
          <strong>What these Technologies Are:</strong> “Cookies” are small data
          files placed on your computer or other device which record
          information. For example, a cookie could enable our Services to
          recognize your browser, while another cookie could store your account
          preferences and other information to help make our Services more
          customized. We may use two kinds of cookies, “session cookies” and
          “persistent cookies.” Session cookies normally expire when you close
          your browser, while persistent cookies remain on your device until
          they expire or are deleted. “Web beacons” (also known as “pixel tags”
          or “clear GIFS”) are pieces of code or pixels embedded in a web page
          or email to track engagement with and responses to emails, web pages
          or ads, or to access or communicate with cookies. “Tracking URLs” are
          special URLs that can be used to track engagement with and responses
          to emails or web pages.
        </p>
        <p>
          <strong>Third-Party Analytics, Service, and Ad Providers:</strong> We
          may use third-party analytics and services providers who help us to
          better understand how our Services and Content are accessed and used,
          including for web analytics, audience measurement, personalization and
          segmentation, and relationship management purposes. In turn, this
          information helps us to develop and improve our Services and Content.
          Our third-party analytics and service providers include but are not
          limited to Google Analytics. These providers may use cookies, web
          beacons and similar technologies to collect information about how
          Users access or engage with our Services, Content, emails, and ads on
          or off our Services. For more information about how Google may collect
          and process information in connection with Google Analytics,{" "}
          <Link to="https://policies.google.com/technologies/partner-sites">
            click here.
          </Link>
          We may also use third-party advertising providers, including ad
          artworks or exchanges, to help display ads on our Services or on other
          websites, including customized ads based on past online activity.
          These third parties may use cookies, web beacons and similar
          technologies to help provide their services to us, and to collect
          information about your and others’ online activity across different
          websites. These providers may also collect your IP address, device
          identifier, or identifier for advertising (IDFA). The third-party
          advertising providers we use include but are not limited to Google
          Analytics, and we use its Remarketing and Demographics and Interest
          Reporting features, which enables Google Analytics to collect data
          about traffic on our Services through{" "}
          <Link to="https://policies.google.com/technologies/types">
            Google advertising cookies{" "}
          </Link>
          and anonymous identifiers. We and third-party providers, including
          Google Analytics, may use first-party cookies (i.e. cookies set by the
          website you are visiting) and third-party cookies (i.e. cookies set by
          a third- party website other than the website you are visiting) to
          inform, optimize, and serve ads based on past visits to our Services.
          We do not control the practices of any third-party providers, and this
          Cookie Policy (and our Privacy Policy in general) does not cover the
          use of cookies, web beacons or similar technologies by any third
          parties.
        </p>
        <p>
          <strong>Opting-Out:</strong> Third-party providers may offer you a way
          to opt-out of receiving cookies from them by visiting their own
          websites or the websites of industry groups. For example, you may
          opt-out of receiving ad-related cookies from Google through its{" "}
          <Link to="https://adssettings.google.com/authenticated?hl=en">
            Ad Settings.
          </Link>{" "}
          If you opt-out of receiving cookies from one of these providers, it
          may place a cookie on your device to remember your preference.
          Accordingly, if you delete cookies, install a new browser, or start
          using a new device, you may need to repeat the opt-out process. To opt
          out of receiving any marketing materials, please email us at{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art.</a>
        </p>
        <p>
          <strong>Changes to this Cookie Policy:</strong> As this Cookie Policy
          is part of our Privacy Policy, we may update it at any time as
          described in our Privacy Policy. Please review this Cookie Policy
          regularly to be informed of any updates.
        </p>
        <h4 className="mt-4 mb-4 text-center " style={{ color: "#FCB040" }}>
          CONTACTING SNOWAFRICA
        </h4>
        <p>
          If you have questions or comments about our Privacy Policy or our
          privacy practices, would like to know what Personal Information we
          hold about you, or would like to update, delete, or request access to
          such Personal Information, you may contact us by email sent to{" "}
          <a href="mailto:privacy@SnowAfrica.art">privacy@SnowAfrica.art</a>, or
          by postal mail sent to{" "}
        </p>
        <h4></h4>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
