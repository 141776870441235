import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import ErrorHandler from "../components/error/ErrorHandler";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";
import LoadingSpinner from "../components/loading-spinner/LoadingSpinner";
import SuccessHandler from "../components/success/SuccessHandler";
import { sendMessageToAdmin } from "../redux/actions/contactActions";

const Contact = () => {
  // Functions, States and variables
  const dispatch = useDispatch();
  // States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  //   Functions
  const handleSubmitMessage = function (e) {
    if (
      firstName !== "" &&
      lastName !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      message !== ""
    ) {
      e.preventDefault();
      const data = {
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber,
        message,
      };
      console.log(data);

      //   TODO Call the send message API
      dispatch(
        sendMessageToAdmin(
          data,
          setIsLoading,
          setErrorHandlerObj,
          setSuccessHandlerObj
        )
      );
    }
  };

  //   Useeffect
  useEffect(() => {
    if (errorHandlerObj.hasError || successHandlerObj.isSuccess) {
      window.scrollTo({ top: 0 });
    }
  }, [errorHandlerObj, successHandlerObj]);

  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
      setMessage("");
    }
  }, [successHandlerObj]);

  return (
    <div className="contact-us-container">
      <Header />

      <div className="contact-error-success-message-wrapper">
        <ErrorHandler errorHandlerObj={errorHandlerObj} />
        <SuccessHandler successHandlerObj={successHandlerObj} />
      </div>

      <div className="container contact-us-container--inner">
        <h2 className="mt-4 mb-4 text-center">Send Us a Message </h2>
        <div>
          <form>
            <div className="form-group mb-3">
              <label htmlFor="firstName">First Name</label>
              <input
                type="firstName"
                className="form-control"
                id="first-name-input"
                aria-describedby="first-name-input"
                placeholder="Enter first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="lastName"
                className="form-control"
                id="last-name-input"
                aria-describedby="last-name-input"
                placeholder="Enter last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email-input"
                aria-describedby="email-input"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phone-number-input"
                aria-describedby="phone-number-input"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="message">Your Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              ></textarea>
            </div>

            <div className="submit-button-wrapper">
              <button
                className="mylongflatbtn mybtn--blue"
                onClick={(e) => handleSubmitMessage(e)}
              >
                Submit
              </button>
              {isLoading && <LoadingSpinner />}
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
