import React from "react";
import "./VerifyEmail.css";

function VerifyEmail() {
  return (
    <div className="verify-email-container--inner">
      <div className="verify-email-title">Welcome Email Sent!</div>
      <div className="verify-email-message-sub">
        Please check and verify your email as we welcome you to SnowAfrica
      </div>

      <div className="welcome-agreement-text">
        By signing up, you agree to our Terms of use and Privacy Policy. You'll
        receive weekly updates on recently added artworks, new curated
        collections, featured artists, and more.
      </div>
    </div>
  );
}

export default VerifyEmail;
