import React, { useState, useEffect, useLayoutEffect } from "react";
import "./Podcast.css";
import Header from "../LandingPage/Header/Header";
import Footer from "../LandingPage/Footer/Footer";
import { Pagination } from "../Pagination/Pagination";
import { api } from "../../api/config";
import axios from "axios";
import Moment from "react-moment";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import isEmpty from "../../validation/is-empty";

function Podcast() {
  // Functions, States and Variables

  // States
  const [allBlogs, setAllBlogs] = useState(null);
  const [currentItems, setCurrentItems] = useState(null);
  const fetchPosts = (pageNum) => {
    axios({
      method: "GET",
      url: pageNum
        ? `${api}snowafrica/posts?page=${pageNum}`
        : `${api}snowafrica/posts`,
    }).then((response) => {
      const validPosts = [];
      response.data.data[0].posts.map((post) => {
        console.log(post);
        if (post.categories.includes("podcast")) validPosts.push(post);
        return validPosts;
      });
      setAllBlogs(validPosts);
    });
  };

  const [resultsPerPage, setResultsPerPage] = useState(5);
  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (allBlogs) {
      const currentRows = allBlogs?.slice(indexOfFirstPost, indexOfLastPost);

      setCurrentItems(currentRows);
    }
  }, [allBlogs, currentPage, resultsPerPage]);

  // USEEFFECT
  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentItems, currentPage]);
  return (
    <div className="blog-container">
      {/* <Top /> */}
      <Header />

      {/* Blog Container Inner Section */}
      {allBlogs ? (
        <div className="blog-container--inner">
          <section className="blog-list-main-section">
            {currentItems?.map((blog, i) => (
              <div key={i + 1} className="blog-box-container">
                <a href={`/podcast/${blog.ID}`}>
                  <div className="blog-image-preview-wrapper">
                    <img src={blog.image} alt="" />
                  </div>

                  <div className="blog-preview-title">{blog.post_title}</div>

                  <div className="tag-name-and-date-wrapper">
                    {/* Tag Name Preview */}
                    {/* <div className="tag-name-wrapper">
                          {allBlogs[blog].tag}
                        </div> */}

                    {/* <div className="seperator-dot"></div> */}

                    {/* Date published */}
                    <div className="date-published-wrapper">
                      <Moment format="D MMM YYYY" withTitle>
                        {blog.post_date}
                      </Moment>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </section>

          {allBlogs && !isEmpty(allBlogs) && (
            <div className="pagination-section blog-pagination-section">
              <Pagination
                paginate={paginate}
                rowsPerPage={resultsPerPage}
                totalPosts={allBlogs.length}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="loader-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {/* Footer */}
      {allBlogs && <Footer />}
    </div>
  );
}

export default Podcast;
