import React, { useState, useEffect } from "react";
import "./Podcast.css";
import { useParams, Link } from "react-router-dom";
import Header from "../LandingPage/Header/Header";
import Footer from "../LandingPage/Footer/Footer";
import leftArrow from "../../images/arrow-left.png";
import { api } from '../../api/config';
import axios from 'axios';
import Moment from 'react-moment';

function SinglePodcast() {
  // Functions, States and Variables
  const { id } = useParams();

  // States
  const [currentBlog, setCurrentBlog] = useState(null);

  // useEffect
  useEffect(() => {
    axios({
      method: "GET",
      url: `${api}snowafrica/posts/${id}`,
    }).then((response) => {
      setCurrentBlog(response.data.data[0].post);
    });
  }, [id]);
  return (
    <div className="single-blog-container">
      {currentBlog && (
        <>
          {/*  Header*/}
          <Header />

          <div className="single-blog-container--inner">
            {/* Blog Header Row */}
            <section className="single-blog-main-row-one single-blog-header-row">
              {/* Header Left */}
              <div className="single-blog-header-row--left-wrapper">
                {/* Back to blog button wrapper */}
                <div className="back-to-blog-button-wrapper">
                  <Link to={"/podcasts"}>
                    <img src={leftArrow} alt="" /> Back to blog
                  </Link>
                </div>

                <div className="single-blog-tag-name-and-date-container">
                  {/* Tag  Name */}
                  <div className="single-blog-tag-name-wrapper">
                    {/* Title */}
                    {/* <div className="tag-name-title">TAG</div> */}

                    {/* Tag Value */}
                    {/* <div className="tag-name-value">{currentBlog.tag}</div> */}
                  </div>

                  {/* Date Published */}
                  <div className="single-blog-date-wrapper">
                    {/* Title */}
                    <div className="date-title">DATE</div>

                    {/* Tag Value */}
                    <div className="date-value">
                        <Moment format="D MMM YYYY" withTitle>
                            {currentBlog.post_date}
                          </Moment>
                      </div>
                  </div>
                </div>
              </div>

              {/* Header Right */}
              <div className="single-blog-header-row--right-wrapper">
                <div className="single-blog-image-wrapper">
                  <img
                    src={
                      // currentBlog &&
                      // require("../../../src/images/landing-page/" +
                        currentBlog.image
                    }
                    alt=""
                  />
                </div>
              </div>
            </section>

            {/* Blog Body Row */}
            <section className="single-blog-main-row-two single-blog-body-row">
              {/* Body Left */}
              {/* <div className="single-blog-body-row--left-wrapper">
                <div className="related-posts-container">
                  <div className="related-posts-title">RELATED POSTS</div>

                  {currentBlog?.relatedBlogs?.map((blog, i) => (
                    <div key={i + 1} className="related-posts-link-box">
                      <Link to={`/blog/${blog._id}`}>{blog.title}</Link>
                    </div>
                  ))}
                </div>
              </div> */}

              {/* Body Right */}
              <div className="single-blog-body-row--right-wrapper">
                <div className="single-blog--blog-name">
                  {currentBlog.post_title}
                </div>

                {/* <div className="single-blog--sub-header">
                  {currentBlog.subheader}
                </div> */}

                <div className="single-blog--blog-body"
                        dangerouslySetInnerHTML={{ __html: `${currentBlog.post_content}` }}
                />
              </div>
            </section>
          </div>

          {/* Footter */}
          <Footer />
        </>
      )}
    </div>
  );
}

export default SinglePodcast;
