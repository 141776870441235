import React from "react";
import "./OrderSuccessful.css";
import CartFooter from "../Cart/CartFooter";
import Header from "../LandingPage/Header/Header";

function OrderSuccessful() {
  return (
    <div className="order-successful-container">
      <Header />
      <div className="order-successful-container--inner">
        <div className="order-successful-content-box">
          <div className="order-successful-message">
            Your order was <span>successful</span>.
          </div>

          <div className="continue-shopping-button">
            <a href="#">CONTINUE SHOPPING</a>
          </div>
        </div>
      </div>

      {/* Footer */}
      <CartFooter />
    </div>
  );
}

export default OrderSuccessful;
