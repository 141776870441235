import React from "react";
import "./Cart.css";

function CartFooter() {
  const date = new Date();

  const presentYear = date.getFullYear();

  return (
    <div className="cart-footer-wrapper">
      <div className="cart-footer-wrapper--inner">
        {/* Copyright */}
        <div className="copyright-section">
          &copy; {presentYear} Copyright Snowafrica
        </div>

        <div className="terms-of-usage-section">
          <div className="terms-of-usage-section--inner">
            {/* terms of service */}
            <div className="terms-of-service-link terms-of-usage-link">
              <a href="#">Terms of Service</a>
            </div>

            {/* Privacy policy */}
            <div className="privacy-policy-link terms-of-usage-link">
              <a href="#">Privacy Policy</a>
            </div>

            {/* Copyright policy */}
            <div className="copyright-policy-link terms-of-usage-link">
              <a href="#">Copyright Policy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartFooter;
