export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_ARTIST = "SET_CURRENT_ARTIST";
export const SET_ADMIN_LAYOUT = "SET_ADMIN_LAYOUT";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_ARTISTS = "GET_ALL_ARTISTS";
export const GET_AN_ARTIST = "GET_AN_ARTIST";
export const REGISTER_CURATOR = "REGISTER_CURATORS";
export const GET_PRIVILEGE = "GET_PRIVILEGE";
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const GET_CURATOR = "GET_CURATOR";
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const EDIT_COLLECTION = "EDIT_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";

export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const CLEAR_CURRENT_COLLECTION = "CLEAR_CURRENT_COLLECTION";
export const GET_PROFILES = "GET_PROFILES";

export const UPLOAD_PRODUCT = "UPLOAD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const VIEW_ARTWORK = "VIEW_ARTWORK";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_SAMPLE_PRODUCTS = "GET_SAMPLE_PRODUCTS";
export const GET_PRODUCT_BY_ID = "GET PRODUCT BY ID";
export const GET_FEATURED = "GET_FEATURED";
export const GET_RISING = "GET_RISING";
export const ADD_PRODUCT_TO_COLLECTION = "ADD_PRODUCT_TO_COLLECTION";
export const CLEAR_CURRENT_PRODUCTS = "CLEAR_CURRENT_PRODUCTS";
export const CLEAR_LOADING = "CLEAR_LOADING";


export const GET_PRODUCTS_AS_PROPS = "GET_PRODUCTS_AS_PROPS"

export const GET_USER_PHOTO = "GET_USER_PHOTO"

export const VIEW_COLLECTION = "VIEW_COLLECTION"

export const GET_ALL_CURATORS = "GET_ALL_CURATORS"

export const CURATOR_PUSHED = "CURATOR_PUSHED"

export const COLLECTION_PUSHED = "COLLECTION_PUSHED"
export const GET_STATUS = "GET_STATUS"


export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS"
export const ADDED_ORDER_ITEM_TO_ORDER_CART = "ADDED_ORDER_ITEM_TO_ORDER_CART"
export const FIND_TRANSACTION_BY_ID = "FIND_TRANSACTION_BY_ID"
export const CREATE_TRANSACTION = "CREATE_TRANSACTION"
export const SUCCESS_CREATING_TRANSACTION = "SUCCESS_CREATING_TRANSACTION"

export const ADDED_TO_CART = "ADDED_TO_CART"
export const DELETED_FROM_CART = "DELETED_FROM_CART"

export const CLICKED_ORDER = "CLICKED_ORDER"
export const GET_ME = "GET_ME"
export const STILL_ME = "STILL_ME"

export const GET_ALL_ORDER = "GET_ALL_ORDER"

export const GETTING_ARTWORK_FROM_CURATOR_LIST = "GETTING_ARTWORK_FROM_CURATOR_LIST"

export const ORDER_CREATED = "ORDER_CREATED"
export const DELETED_ORDER = "DELETED_ORDER"

export const CREATING_ORDER = "CREATING_ORDER"

export const GET_ALL_PRODUCTS_BY_ADMIN = "GET_ALL_PRODUCTS_BY_ADMIN"

export const IS_LOADING_DATA_ON = "IS_LOADING_DATA_ON"

export const IS_LOADING_DATA_OFF = "IS_LOADING_DATA_OFF"

export const LOADED_TRANSACTION_OBJECT = "LOADED_TRANSACTION_OBJECT"

export const COMMENT_SUCCESSFUL = "COMMENT_SUCCESSFUL"

export const GETTING_CURATOR_ID = "GETTING_CURATOR_ID"

export const UPDATED_CURATOR_LIST = "UPDATED_CURATOR_LIST"

export const DELETE_USER = "DELETE USER"

export const GET_CURRENCY_RATE = "GET_CURRENCY_RATE"

export const SET_CURRENCY_RATE = "SET_CURRENCY_RATE"

export const SEARCH_ME = "SEARCH_ME"

export const SEE_ARTWORK = "SEE_ARTWORK"
