import React from "react";
import Accordion from "react-bootstrap/Accordion/";
import Card from "react-bootstrap/Card";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";

export default function FAQ() {
  return (
    <>
      <Header />
      <div className="container mb-4">
        <h2 className="mt-4 mb-4 text-center">Frequently Asked Questions</h2>
        <Accordion defaultactivekey="0">
          <Accordion.Item eventKey="0">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">What is SnowAfrica?</h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    SnowAfrica is a free online platform which manages
                    portfolios of art and connects artists, art lovers and
                    collectors, all in one place.
                  </p>
                  <p>
                    Our offerings are unparalleled selection of African art,
                    advisory, promotion and trade of art while delivering
                    optimum satisfaction for artist and art enthusiasts. At
                    SnowAfrica, we have a clear mission to increase the number
                    and propensity of purchase for African contemporary art. In
                    achieving this, we will provide artworks of various sorts,
                    for different purposes, and we will provide an intensive
                    guide to our customers on their purchasing decision.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  How Do I Find A Specific Art/Artist On SnowAfrica?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    Step 1- Enter the artist/art name in the search box at top
                    right corner of the page.
                  </p>
                  <p>Step 2- Click on the category from the drop down.</p>
                  <p>Step 3- Press Enter.</p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <h3 className="m-4" style={{ paddingTop: "50px" }}>
            Purchase from SnowAfrica
          </h3>

          <Accordion.Item eventKey="2">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">How is my art fulfilled?</h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    SnowAfrica has a partnership with a reputable shipping
                    company.
                  </p>
                  <p>
                    We work with our artists every step of the way to ensure
                    artworks are shipped according to our very high standards.
                  </p>
                  <p>
                    Expect your artwork to arrive with plenty of support
                    packaged in to make its journey as safe as possible.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  I’m having trouble paying by credit/debit?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    If your credit/debit card is being declined during purchase,
                    please ensure you input correct card details.
                  </p>
                  <p>
                    If you still experience problems, please contact your bank
                    for advice.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  How Do I Remove Items from My Shopping Cart?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    Step 1- Click on the shopping cart icon located at the top
                    right corner
                  </p>
                  <p>Step 2- Click on the X icon</p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  Do I Need To Create An Account To Purchase?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    We require all users to create an account before a purchase
                    can be made.
                  </p>
                  <p>
                    Step 1- Click on the Sign-up icon on homepage or click
                    Register at the top right corner
                  </p>
                  <p>Step 2- Fill in your details and click Register</p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  How Do I purchase an artwork?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>Step 1: Locate the art you are interested in</p>
                  <p>Step 2: You can either click on Buy or Add to Cart</p>
                  <p>Step 3: Fill in your Shipping Address</p>
                  <p>Step 4: Review Details</p>
                  <p>Step 5: Click on Proceed to Payment</p>
                  <p>
                    Step 6: Enter your Payment information then Click on Pay.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  What Payment Methods Do You Accept?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>SnowAfrica accepts the following payment methods:</p>
                  <p>Verve</p>
                  <p>Visa</p>
                  <p>Mastercard</p>
                  <p>
                    Bank Transfer (Please send an email to{" "}
                    <a href="mailto:orders@snowafrica.art">
                      orders@snowafrica.art
                    </a>
                    )
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  Will I Be Charged Taxes Or Customs Fees?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>No, we cover the cost of this including shipping.</p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  How Do I Track my shipment?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    After purchase, a tracking number with courier name will be
                    sent to your email address. This can be used to track your
                    art on the DHL page.
                  </p>
                  <p>
                    A progress email on milestones of the shipment will be sent
                    until its delivered. For any concern regarding
                    purchase/deliver, please email{" "}
                    <a href="mailto:orders@snowafrica.art">
                      orders@snowafrica.art
                    </a>{" "}
                    Monday to Saturday.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="10">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  How Long Will It Take To Receive My Order?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    Orders are usually delivered between 5-10 business days. An
                    order details with the delivery details will be sent to your
                    email after purchase.
                  </p>
                  <p>
                    If delay occurs, it could be due to severe weather
                    conditions, natural disasters or cumbersome clearing
                    process. Please add an additional 2-3 business days to the
                    estimated delivery date of your order.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="11">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">
                  Can I Cancel An Order Prior To Shipment?
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    Once an item has been delivered to our service center,
                    cancellation can’t be made.
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>

          <Accordion.Item eventKey="12">
            <Card>
              <Accordion.Header>
                <h6 className="accordion-header">SnowAfrica Return policy</h6>
              </Accordion.Header>
              <Accordion.Body>
                <Card.Body>
                  <p>
                    Due to many reasons and how delicate and difficult returns
                    are. We care about your patronage and thrilled with your
                    purchase, we will do our very best to help in return or
                    change of artwork bought as quick and easy as possible.
                  </p>
                  <p>
                    We offer 10 day return policy for purchases. If you feel you
                    need to return your artwork for whatever reason, you can
                    notify us within 10 (ten) days of your purchase, return the
                    item to us and receive a full refund.
                  </p>
                  <p>
                    Please pack the artwork in the original tube or box, and
                    include the certificate of authenticity. We will not accept
                    the return of a piece without proper paperwork. Moreover,
                    please note that artworks should be returned in perfect
                    condition to qualify for return or exchange.
                  </p>
                  <p>
                    However, shipping, customs and duties insurance charges and
                    related fees incurred during the transaction (Bank, Payment
                    Gateways etc.,) will be deducted.
                  </p>
                  <p>contact</p>
                  <p>
                    Call us for any advice or assistance on{" "}
                    <a href="mailto:contact@snowafrica.art">
                      contact@snowafrica.art
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Body>
            </Card>
          </Accordion.Item>
        </Accordion>
      </div>
      <Footer />
    </>
  );
}

// export default FAQ;
