import React from "react";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";

const ArtistLogin = () => {
  return (
    <>
      <Header />
      <div className="artist-login pb-5">
        <form>
          <h3 className="center">Log into your Merchant Dashboard</h3>
          <div className="form-group mb-3 mt-4">
            <label for="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="email"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group mb-3">
            <label for="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
            />
          </div>

          <button type="submit" className="mylongflatbtn mybtn--blue">
            LOGIN
          </button>
          <p className="lean mt-3">
            <h5>
              <a href="/forgotPassword">Forgot Password?</a>
            </h5>
          </p>
          <p className="lean mt-2 mb-7">
            <h6 style={{ color: "rgba(0, 0, 0, 0.7)" }}>
              Not a merchant?{" "}
              <a href="/artist-register" style={{ color: "rgba(0, 0, 0, 1)" }}>
                Sign up
              </a>
            </h6>
          </p>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ArtistLogin;
