import React, { useState } from "react";
import "./EditBillingInfo.css";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";

function EditBillingInfo() {
  // Functions, States and Variables

  //   STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondPhoneNumber, setSecondPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  // State, the one with a capital not react state
  const [state, setState] = useState(false);
  const [landmarkName, setLandmarkName] = useState("");
  const [password, setPassword] = useState("");

  // Functions
  // Handles Add Second Phone Input Button Click
  const handleAddSecondPhoneInput = function (e) {
    e.preventDefault();
    let secondPhoneInputSection = document.querySelector(
      ".second-phone-input-section"
    );
    // Remove the add second number button
    e.target.parentElement.classList.add("none");
    // Add the second number Input
    secondPhoneInputSection.classList.remove("none");
  };
  // Handles Add Landmark Button
  const handleAddLandmarkInput = function (e) {
    e.preventDefault();
    let landmarkInputSection = document.querySelector(
      ".landmark-input-section"
    );
    // Remove the add landmark button
    e.target.classList.add("none");
    // Add the landmark input section
    landmarkInputSection.classList.remove("none");
  };

  // Handle Edit Billing Info Form Save
  const handleEditBillingInfoSave = function (e) {
    e.preventDefault();
    const changeSuccessMessageWrapper = document.querySelector(
      ".change-success-message"
    );
    const changeFailureMessageWrapper = document.querySelector(
      ".change-failure-message"
    );
    const resultData = {
      firstName,
      lastName,
      phoneNumber,
      secondPhoneNumber,
      address,
      city,
      country,
      state,
      landmarkName,
      password,
    };
    console.log(resultData);
    // IF password does not match the one on the database
    if (password === "") {
      // Hide the change success message if it is opened and ddisplay the change failure message
      changeFailureMessageWrapper.classList.remove("none");
      changeSuccessMessageWrapper.classList.add("none");
    } else {
      // Hide the change failure message if it is opened and ddisplay the change success message
      changeFailureMessageWrapper.classList.add("none");
      changeSuccessMessageWrapper.classList.remove("none");
    }
  };

  return (
    <div className="edit-billing-info-container">
      <div className="edit-billing-info-container--inner">
        {/*Profile Section */}
        <div className="edit-billing-info--col profile-section">
          <div className="section-title">Profile</div>
          <div className="horizontal-line"></div>

          {/* Shipping Profile form */}
          <div className="profile-section--form">
            <form>
              {/* First Name */}
              <div className="form-group mb-3">
                <label htmlFor="first-name" className="form-label">
                  FIRST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="first-name"
                  aria-describedby="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              {/* Last Name */}
              <div className="form-group mb-3">
                <label htmlFor="last-name" className="form-label">
                  LAST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="last-name"
                  aria-describedby="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              {/* Phone Number */}
              <div className="form-group mb-3">
                <label htmlFor="phone-number" className="form-label">
                  PHONE NUMBER:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="phone-number"
                  aria-describedby="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              {/* Second Phone Number */}
              <div className="form-group mb-3 second-phone-input-section none">
                <label htmlFor="second-phone-number" className="form-label">
                  SECOND PHONE NUMBER:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="second-phone-number"
                  aria-describedby="secondPhoneNumber"
                  value={secondPhoneNumber}
                  onChange={(e) => setSecondPhoneNumber(e.target.value)}
                />
              </div>

              {/* Add Second Number */}
              <div className="add-second-phone-no">
                <button
                  onClick={handleAddSecondPhoneInput}
                  className="add-second-phone-no-btn"
                >
                  <span>+</span> Add Second Number
                </button>
              </div>
            </form>
          </div>
        </div>

        {/*Billing Address Section*/}
        <div className="edit-billing-info--col billing-address-section">
          <div className="section-title">Billing Address</div>
          <div className="horizontal-line"></div>

          {/*Shipping  Billing Address form */}
          <div className="billing-addresss-section--form">
            <form>
              {/* Address Input */}
              <div className="form-group mb-3">
                <label htmlFor="address-input" className="form-label">
                  ADDRESS:
                </label>
                <textarea
                  type="text"
                  className="form-control cart-form-control address-input"
                  id="address-input"
                  aria-describedby="addressInput"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>

              {/* Landmark Input */}
              <div className="form-group mb-3 landmark-input-section none">
                <label htmlFor="landmark-input" className="form-label">
                  LANDMARK:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="landmark-input"
                  aria-describedby="landmarkInput"
                  value={landmarkName}
                  onChange={(e) => setLandmarkName(e.target.value)}
                />
              </div>

              {/* Add Landmark */}
              <div className="add-landmark">
                <button
                  onClick={handleAddLandmarkInput}
                  className="add-landmark-btn"
                >
                  <span>+</span> Add Landmark
                </button>
              </div>

              {/* City Input */}
              <div className="form-group mb-3">
                <label htmlFor="city-input" className="form-label">
                  CITY:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city-input"
                  aria-describedby="cityInput"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>

              {/* Country Input */}
              <div className="form-group mb-3">
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="country-input"
                    id="country-input"
                    aria-describedby="countryInput"
                    className="form-control form-dropdown"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option value="" disabled>
                      Choose your country
                    </option>
                    <option value="NG">NIGERIA</option>
                    <option value="SA">SOUTH AFRICA</option>
                    <option value="GH">GHANA</option>
                    <option value="KY">KENYA</option>
                  </select>
                </div>
              </div>

              {/* State Input */}
              <div className="form-group mb-3">
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="state-input"
                    id="state-input"
                    aria-describedby="stateInput"
                    className="form-control form-dropdown"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  >
                    <option value="false" disabled>
                      Choose your state
                    </option>
                    <option value="OY">Oyo State</option>
                    <option value="OS">Osun State</option>
                    <option value="AB">Abia State</option>
                    <option value="BE">Benue State</option>
                  </select>
                </div>
              </div>

              {/* Password Input */}
              <div className="form-group mb-3">
                <label htmlFor="password-input" className="form-label">
                  ENTER PASSWORD:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password-input"
                  aria-describedby="passwordInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="change-failure-message none">
                <p>Oops! The password was incorrect.</p>
              </div>

              <div className="save-button">
                <button onClick={handleEditBillingInfoSave}>SAVE</button>
              </div>

              <div className="change-success-message none">
                <p>You have successfully changed your billing info!</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBillingInfo;
