import React, { useState, useEffect } from "react";
import "./PurchaseHistory.css";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import Table from "react-bootstrap/Table";
import { Pagination } from "../../Pagination/Pagination";
import isEmpty from "../../../validation/is-empty";
import { numberWithCommas } from "../../../utils/numberWithCommas";

function createArtworkData(
  id,
  artworkName,
  artistName,
  artworkPrice,
  artworkCategory,
  purchaseStatus,
  displayImgSrc,
  artworkDeliveryDate = ""
) {
  return {
    id,
    artworkName,
    artistName,
    artworkPrice,
    artworkCategory,
    purchaseStatus,
    displayImgSrc,
    artworkDeliveryDate,
  };
}

const originalRows = [
  createArtworkData(
    1,
    "Guernica",
    "Pablo Picasso",
    "650000",
    "Painting",
    "in progress",
    "Pic 2015-01-23 17-42-22.jpg"
  ),
  createArtworkData(
    2,
    "The Water Lily Pond",
    "Claude Monet",
    "1500000",
    "Sculpture",
    "delivered",
    "register.jpg",
    "21st August, 2021"
  ),
  createArtworkData(
    3,
    "Woman with a Parasol",
    "Leonardo Da Vinci",
    "800000",
    "Landscape",
    "delivered",
    "register2.jpg",
    "1st July, 2021"
  ),
];

function PurchaseHistory() {
  // Functions, States and Variables
  // States
  // const [purchaseHistoryRow, setPurchaseHistoryRow] = useState([]);
  const [purchaseHistoryRow, setPurchaseHistoryRow] = useState(null);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState(null);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (purchaseHistoryRow) {
      const currentRows = purchaseHistoryRow?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [purchaseHistoryRow, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  // Functions

  useEffect(() => {
    // setPurchaseHistoryRow([]);
    setPurchaseHistoryRow(originalRows);
  }, []);

  return (
    <div className="purchase-history-container">
      <div className="purchase-history-container--inner">
        {/* Title */}
        <div className="purchase-history-container-title">Purchase History</div>

        {/*Purchase History RESULTS PER PAGE */}
        <div className="purchase-history-results-per-page-wrapper">
          <label
            htmlFor="purchase-history-results-per-page-input"
            className="form-label purchase-history-results-per-page-form-label"
          >
            RESULTS PER PAGE:
          </label>
          <div className="form-group form-group-dropdown">
            <div className="left-arrow-section">
              <img src={arrowUp} alt="arrow-up" />
              <img src={arrowDown} alt="arrow-down" />
            </div>
            <select
              name="results-per-page"
              id="purchase-history-results-per-page-input"
              aria-describedby="resultsPerPage"
              className="form-control form-dropdown"
              onChange={(e) => {
                setResultsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
              style={{ cursor: "pointer" }}
              value={resultsPerPage}
            >
              <option defaultValue="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div>
        </div>

        {/* Purchase History Table Container */}
        <main className="purchase-history-table-container">
          <div className="purchase-history-table--inner">
            <Table responsive="lg" className="cart-table">
              <thead>
                <tr>
                  {["ITEM(S)", "STATUS", "PRICE"].map((COLUMN, index) => (
                    <th key={index + 1}>{COLUMN}</th>
                  ))}
                </tr>
              </thead>
              <tbody
                style={{
                  borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                {purchaseHistoryRow?.length < 1 ||
                isEmpty(purchaseHistoryRow) ? (
                  // IF purchas history is empty
                  <div className="empty-purchase-history-container">
                    <div className="empty-purchase-history-message">
                      You haven't made any purchases yet
                    </div>

                    <div className="get-inspired-button">
                      <a href="/landing-page">GET INSPIRED</a>
                    </div>
                  </div>
                ) : (
                  <>
                    {currentItems?.map((item, i) => (
                      <tr key={i + 1} className="tr-cart">
                        {/* Items */}
                        <td className="td-items">
                          <div className="td-items--wrapper">
                            {/* Artwork Image */}
                            <div className="items--artwork-image">
                              <img
                                src={require("../../../../src/images/" +
                                  item.displayImgSrc)}
                                alt="artwork-img"
                              />
                            </div>

                            {/* Artwork Info Details */}
                            <div className="items--artwork-info-details">
                              {/* Artwork name */}
                              <div className="items--artwork-name">
                                {item.artworkName}
                              </div>

                              {/* Artist Name */}
                              <div className="items--artist-name">
                                Artist: {item.artistName}
                              </div>

                              {/* Artwork Category */}
                              <div className="items--artwork-category">
                                {item.artworkCategory}
                              </div>
                            </div>
                          </div>
                        </td>

                        {/* Status */}
                        <td className="td-status">
                          <div className="td-status--wrapper">
                            {item.purchaseStatus === "in progress" ? (
                              <div className="items--purchase-status status-progress">
                                In progress
                              </div>
                            ) : (
                              <div className="items--purchase-status status-delivered">
                                <div className="purchase-status--inner">
                                  Delivered
                                </div>
                                <div className="purchase-delivery-date">
                                  ({item.artworkDeliveryDate})
                                </div>
                              </div>
                            )}
                          </div>
                        </td>

                        {/* Price */}
                        <td>
                          <div className="td-price--wrapper">
                            NGN
                            {numberWithCommas(item.artworkPrice)}
                            .00
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
          <div className="collection-view-footer">
            <div className="collection-view-footer--inner">
              <div className="pagination-section">
                {purchaseHistoryRow && !isEmpty(purchaseHistoryRow) && (
                  <Pagination
                    paginate={paginate}
                    rowsPerPage={resultsPerPage}
                    totalPosts={purchaseHistoryRow.length}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PurchaseHistory;
