import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./LandingPage.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import logo from "../.././images/Snowafrica.png";
import snowLogoGray from "../.././images/landing-page/snow-logo-gray.jpg";
import arrival from "../.././images/arrival.jpg";
import sell from "../.././images/sell.jpg";
import landingOne from "../.././images/landing.jpg";
import arrowRight from "../.././images/arrow-right-long.svg";
import angleRight from "../.././images/arrow-right.png";
import imageOne from "../.././images/register.jpg";
import imageTwo from "../.././images/register2.jpg";
import imageThree from "../.././images/Pic 2015-01-23 17-42-22.jpg";
import drawingsImg from "../.././images/landing-page/drawings.png";
import printsImg from "../.././images/landing-page/prints.png";
import paintingsImg from "../.././images/landing-page/paintings.png";
import photographyImg from "../.././images/landing-page/photography.png";
import sculptureImg from "../.././images/landing-page/sculptures.png";
import imageFour from "../.././images/landing-page/slice41.png";
import starIcon from "../.././images/star.svg";
import yellowStarIcon from "../.././images/landing-page/star.png";
// import truckIcon from "../.././images/landing-page/truck.png";
import clockIcon from "../.././images/landing-page/on-time.png";
import truckIcon from "../.././images/landing-page/truck.svg";
import padlockIcon from "../.././images/landing-page/padlock.png";
import {
  getFeatured,
  getSampleProduct,
} from "../../redux/actions/productAction";
import { getRising } from "../../redux/actions/authActions";
import heroImgOne from "../.././images/landing-page/hero-img-1.jpg";
import heroImgTwo from "../.././images/landing-page/hero-img-4.jpg";
import heroImgThree from "../.././images/landing-page/hero-img-3.jpg";
import axios from "axios";
import { api } from "../../api/config";
import NewsletterSubscribe from "./newsletter-form/NewsletterSubscribe";

function LandingPage() {
  // Functions, States and Variables

  // Function
  // Handles Email for mailing list submit
  const handleEmailSubmit = function (e) {
    e.preventDefault();
    const userEmail = { email };
  };
  const dispatch = useDispatch();

  const featured = useSelector((state) => state.products.featured);
  const sampleProducts = useSelector((state) => state.products.sampleProducts);
  const rising = useSelector((state) => state.auth.rising);

  const [featuredArtists, setFeaturedArtists] = useState([]);
  // const [trendingArtists, setTrendingArtists] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [varyingProducts, setVaryingProducts] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    dispatch(getFeatured());
    dispatch(getSampleProduct());
    dispatch(getRising());
  }, []);

  useEffect(() => {
    setFeaturedProducts(featured);
    // console.log("featured", featured);
  }, [featured]);

  useEffect(() => {
    setVaryingProducts(sampleProducts);
    // console.log("sample", sampleProducts);
  }, [sampleProducts]);

  useEffect(() => {
    setFeaturedArtists(rising);
    // console.log("rising", rising);
  }, [rising]);

  // useEffect(() => {
  //   setFeaturedProducts(featured);
  // }, [featured]);

  // useEffect(() => {
  //   setVaryingProducts(sampleProducts);
  // }, [sampleProducts]);

  // useEffect(() => {
  //   setFeaturedArtists(rising);
  // }, [rising]);

  useEffect(() => {
    // SliddeShow Function
    function showSlides(n) {
      let i;
      let slides = document.querySelectorAll(".mySlides");
      let dots = document.getElementsByClassName("dot");
      // console.log(slides);
      if (n > slides.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        // dots[i].className = dots[i].className.replace(" active", "");
        dots[i].classList.remove("active");
      }
      slides[slideIndex - 1].style.display = "block";
      dots[slideIndex - 1].classList.add("active");
    }

    let slideIndex = 1;
    showSlides(slideIndex);

    // Next/previous controls
    function plusSlides(n) {
      showSlides((slideIndex += n));
    }

    // Thumbnail image controls
    function currentSlide(n) {
      showSlides((slideIndex = n));
    }

    let dotOne = document.querySelector(".dot-one");
    let dotTwo = document.querySelector(".dot-two");
    let dotThree = document.querySelector(".dot-three");

    dotOne.addEventListener("click", (e) => {
      e.stopPropagation();
      currentSlide(1);
    });
    dotTwo.addEventListener("click", (e) => {
      e.stopPropagation();
      currentSlide(2);
    });
    dotThree.addEventListener("click", (e) => {
      e.stopPropagation();
      currentSlide(3);
    });

    const changeImageInterval = setInterval(() => {
      plusSlides(1);
    }, 8000);

    return () => clearInterval(changeImageInterval);
  }, []);

  const handleScrollToRight = function (e, containerClass) {
    e.stopPropagation();
    const container = document.querySelector(`.${containerClass}`);

    const container_scroll_width = container.scrollWidth;
    let container_scoll_left = container.scrollLeft;
    container_scoll_left += 400;
    if (container_scoll_left >= container_scroll_width - 900) {
      container_scoll_left = 400;
    }
    container.scrollLeft = container_scoll_left;
  };

  const [allNews, setAllNews] = useState(null);

  const fetchAllNews = () => {
    axios({
      method: "GET",
      url: `${api}snowafrica/posts`,
    }).then((response) => {
      const validNews = [];
      response.data.data[0].posts.map((post) => {
        console.log(post);
        if (post.categories.includes("news")) validNews.push(post);
        return validNews;
      });
      // console.log(convertToPlain(validNews[0].post_content), "validNews");
      setAllNews(validNews);
    });
  };

  function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("div");
    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;
    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  useEffect(() => {
    fetchAllNews();
  }, []);

  return (
    <div className="homepage-container">
      <Header />
      <div className="homepage-container--inner">
        {/* ROW 1 / SECTION 1 */}
        <div className="homepage-main--row-one top--row-one">
          {/* Large frame */}
          <div className="row-one--left large-frame">
            <div className="mySlides">
              <img src={landingOne} alt="" />
            </div>

            <div className="mySlides">
              <img src={heroImgOne} alt="" />
            </div>

            <div className="mySlides">
              <img src={heroImgThree} alt="" />
            </div>
            {/* Content on frame */}
            <div className="content-on-large-frame">
              {/* Picture navigator dots */}
              <div className="picture-navigator-btn">
                <div className="dot-one dot"></div>
                <div className="dot-two dot"></div>
                <div className="dot-three dot"></div>
              </div>
              {/* CONTENT TEXT */}
              <div className="row-one--left-content-text">
                <div className="row">BROWSE OUR COLLECTION</div>
                <div className="row">
                  Discover & Purchase Art From Top Emerging Artist
                </div>
              </div>
              {/* content btn */}
              <div className="content-explore-btn">
                <button>
                  <Link to="/artworks">
                    EXPLORE
                    <span>
                      <img
                        src={arrowRight}
                        alt="arrow"
                        className="right-arrow"
                      />
                    </span>
                  </Link>
                </button>
              </div>

              {/* Mobile Picture Navigator dots */}
              {/* <div className="picture-navigator-btn Mobile__picture-navigator-btn">
                <div className="dot-one dot"></div>
                <div className="dot-two dot"></div>
                <div className="dot-three dot"></div>
              </div> */}
            </div>
          </div>

          {/* Small frame*/}
          <div className="row-one--right small-frame">
            {/* top */}
            <div className="small-frame-top">
              <div className="small-frame-top-image-wrapper">
                <img src={sell} alt="img-two" />
                {/* content */}
                <div className="content-on-frame ">
                  <div className="content-text ">
                    <div className="rowr">New Arrivals</div>
                    <div className="content-browse-btn">
                      <button>
                        <Link to="/artworks">BROWSE</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* bottom */}
            <div className="small-frame-top">
              <div className="small-frame-top-image-wrapper lower-image-wrapper">
                <img src={heroImgTwo} alt="img-two" />
                {/* Content on frame */}
                <div className="content-on-frame">
                  <div className="content-text">
                    <div className="row">Want to Sell Art?</div>
                    <div className="content-browse-btn">
                      <button>
                        <Link to="/artist-register">BECOME A MERCHANT</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ROW 2 / Featured Artist Section */}
        <div className="homepage-main--row-two featured-artist-section">
          <div className="featured-artist-section-title">Featured Artist</div>

          <div className="featured-artist-main-container">
            {/* Featured artist box */}
            {featuredArtists &&
              featuredArtists.map((artist, i) => (
                <div key={i} className="featured-artist-box">
                  <Link
                    to={
                      artist && artist.username
                        ? `/artwork/${artist.username}/${artist.products[0].slug}`
                        : `/artworks``/artworks`
                    }
                  >
                    <div className="artist-display-img-one artist-display-img-wrapper">
                      {artist.products[0] && (
                        <img src={artist.products[0]?.photo} alt="" />
                      )}
                    </div>
                  </Link>
                  <div className="grid-artist-display-wrapper lower-img-display-wrapper">
                    {artist.products[1] && (
                      <div className="artist-display-img-two artist-display-img-wrapper">
                        <Link
                          to={
                            artist.username
                              ? `/artwork/${artist.username}/${artist.products[1]?.slug}`
                              : `/artworks`
                          }
                        >
                          {artist.products[1] && (
                            <img src={artist.products[1]?.photo} alt="" />
                          )}
                        </Link>
                      </div>
                    )}
                    {artist.products[2] && (
                      <div className="artist-display-img-three artist-display-img-wrapper">
                        <Link
                          to={
                            artist.username
                              ? `/artwork/${artist.username}/${artist.products[2]?.slug}`
                              : `/artworks`
                          }
                        >
                          {artist.products[2] && (
                            <img src={artist.products[2].photo} alt="" />
                          )}
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="featured-artist-box-footer">
                    {/* Artist info */}
                    <div className="artist-info">
                      <div className="artist-pofile-img">
                        <img src={artist.photoUrl} alt="profile-img" />
                      </div>
                      <div className="featured-artist-bio">
                        <div className="featured-artist-name">
                          {artist.firstname} {artist.lastname}
                        </div>
                        <div className="featured-artist-city">
                          {artist.location}
                        </div>
                      </div>
                    </div>
                    {/* Artist Rating */}
                    {/* <div className="artist-rating">
                    4 <img src={starIcon} alt="" />
                  </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* ROW 6 / Sale on snowafrica Section */}
        <div className="homepage-main--row-six sale-on-snow-section">
          <div className="sale-on-snow-section--inner">
            <div className="sale-on-snow-section-title">Artworks</div>

            <div
              className="angle-right-icon sale-on-snow-angle-right"
              onClick={(e) =>
                handleScrollToRight(e, "sale-on-snow--artworks-main-wrapper")
              }
            >
              <img src={angleRight} alt="" />
            </div>
            {/* Main Section Wrapper */}
            <div className="sale-on-snow-main-wrapper sale-on-snow--artworks-main-wrapper">
              {/* Artwork for sale Box | artwork one */}
              {varyingProducts.map((product, i) => (
                <div
                  key={i + 1}
                  className="sale-on-snow-box-container  artwork-for-sale-one"
                >
                  <Link
                    to={
                      product.artist.user_id && product.artist.user_id.username
                        ? `/artwork/${product.artist.user_id.username}/${product.slug}`
                        : `/artworks`
                    }
                    // href={`/artworks/${originalRows[item].artist.user_id.firstname}/${originalRows[item].title}`}
                  >
                    <div className="sale-on-snow-artwork-image">
                      <img src={product.photo} alt="" />
                    </div>
                    <div className="sale-on-snow-artwork-info">
                      <div className="sale-on-snow-artwork-name">
                        {product.title}
                      </div>
                      <div className="sale-on-snow-artwork-artist-name">
                        {product &&
                          product.artist &&
                          product.artist.user_id &&
                          product.artist.user_id.firstname}{" "}
                        {product &&
                          product.artist &&
                          product.artist.user_id &&
                          product.artist.user_id.lastname}
                      </div>
                      {/* <div className="sale-on-snow-artwork-location">Lagos</div> */}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ROW 3 / Explore by category Section */}
        <div className="homepage-main--row-three explore-by-category-section">
          <div className="explore-by-category-section--inner">
            <div className="explore-by-category-section-title">
              Explore by category
            </div>

            <div className="explore-by-category-main-wrapper">
              <div
                className="angle-right-icon explore-by-category-angle-right"
                onClick={(e) =>
                  handleScrollToRight(e, "explore-by-category-main-wrapper")
                }
              >
                <img src={angleRight} alt="" />
              </div>
              {/* column one */}
              <div className="category-section-column-one category-section-column">
                <div className="category-box-container painting-category">
                  <Link to="/artworks/Painting">
                    <img src={paintingsImg} alt="" />
                  </Link>
                </div>
                <div className="category-box-container prints-category">
                  <Link to="/artworks/Prints">
                    <img src={printsImg} alt="" />
                  </Link>
                </div>
              </div>
              {/* column two */}
              <div className="category-section-column-two category-section-column">
                <div className="category-box-container drawings-category">
                  <Link to="/artworks/Drawing">
                    <img src={drawingsImg} alt="" />
                  </Link>
                </div>
              </div>
              {/* column three */}
              <div className="category-section-column-three category-section-column">
                <div className="category-box-container photography-category">
                  <Link to="/artworks/Photography">
                    <img src={photographyImg} alt="" />
                  </Link>
                </div>
                <div className="category-box-container sculptures-category">
                  <Link to="/artworks/Sculpture">
                    <img src={sculptureImg} alt="" />
                  </Link>
                </div>
              </div>
              {/* column four */}
              {/* <div className="category-section-column-four category-section-column">
                <div className="category-box-container landscape-category">
                  <Link to="/artworks/Prints">
                    <img src={printsImg} alt="" />
                  </Link>
                </div>
              </div> */}
              {/* Column Five / Only visible on mobile */}
              <div className="category-section-column-five category-section-column">
                <div className="category-box-container drawings-category">
                  <Link to="/artworks/Drawing">
                    <img src={drawingsImg} alt="" />
                  </Link>
                </div>
                <div className="category-box-container landscape-category">
                  <Link to="/artworks/Prints">
                    <img src={printsImg} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ROW 4 / Curators Joining the platform Section */}
        {/* <div className="homepage-main--row-four curators-joining-section">
          <div className="curators-joining-section--inner">
            <div className="curators-joining-section-title">
              <p> The curators joining our platform</p>
              <div className="rhombus-like-shape"></div>
            </div>

            <div className="angle-right-icon curators-joining-angle-right" onClick={(e) => handleScrollToRight(e, "curators-joining-main-wrapper")}>
              <img src={angleRight} alt=""    />
            </div> */}
        {/* Main Wrapper Section */}
        {/* <div className="curators-joining-main-wrapper"> */}
        {/* curator joining box | curator one */}
        {/* <div className="curators-joining-box-container curator-joining-one">
                <div className="curators-joining-image">
                  <img src={curatorOne} alt="" />
                </div>
                <div className="curator-joining-bio">
                  <div className="curator-name">Curator Name</div>
                  <div className="curator-location-and-time-spent">
                    <div className="curator-location">Lagos</div>
                    <div className="curator-time-spent">5yrs</div>
                  </div>
                </div>
              </div> */}
        {/* curator joining box | curator two */}
        {/* <div className="curators-joining-box-container curator-joining-two">
                <div className="curators-joining-image">
                  <img src={curatorTwo} alt="" />
                </div>
                <div className="curator-joining-bio">
                  <div className="curator-name">Curator Name</div>
                  <div className="curator-location-and-time-spent">
                    <div className="curator-location">Lagos</div>
                    <div className="curator-time-spent">5yrs</div>
                  </div>
                </div>
              </div> */}
        {/* curator joining box | curator three */}
        {/* <div className="curators-joining-box-container curator-joining-three">
                <div className="curators-joining-image">
                  <img src={curatorThree} alt="" />
                </div>
                <div className="curator-joining-bio">
                  <div className="curator-name">Curator Name</div>
                  <div className="curator-location-and-time-spent">
                    <div className="curator-location">Lagos</div>
                    <div className="curator-time-spent">5yrs</div>
                  </div>
                </div>
              </div> */}

        {/* EXTRAS FOR TEST */}
        {/* curator joining box | curator two */}
        {/* <div className="curators-joining-box-container curator-joining-two">
                <div className="curators-joining-image">
                  <img src={curatorTwo} alt="" />
                </div>
                <div className="curator-joining-bio">
                  <div className="curator-name">Curator Name</div>
                  <div className="curator-location-and-time-spent">
                    <div className="curator-location">Lagos</div>
                    <div className="curator-time-spent">5yrs</div>
                  </div>
                </div>
              </div> */}
        {/* curator joining box | curator three */}
        {/* <div className="curators-joining-box-container curator-joining-three">
                <div className="curators-joining-image">
                  <img src={curatorThree} alt="" />
                </div>
                <div className="curator-joining-bio">
                  <div className="curator-name">Curator Name</div>
                  <div className="curator-location-and-time-spent">
                    <div className="curator-location">Lagos</div>
                    <div className="curator-time-spent">5yrs</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* ROW 6 / Sale on snowafrica Section */}
        <div className="homepage-main--row-six sale-on-snow-section">
          <div className="sale-on-snow-section--inner">
            <div className="sale-on-snow-section-title">Sale on SNOWAFRICA</div>

            <div
              className="angle-right-icon sale-on-snow-angle-right"
              onClick={(e) =>
                handleScrollToRight(e, "sale-on-snow--sales-main-wrapper")
              }
            >
              <img src={angleRight} alt="" />
            </div>
            {/* Main Section Wrapper */}
            <div className="sale-on-snow-main-wrapper sale-on-snow--sales-main-wrapper">
              {/* Artwork for sale Box | artwork one */}
              {featuredProducts.map((product, i) => (
                <div
                  key={i + 1}
                  className="sale-on-snow-box-container  artwork-for-sale-one"
                >
                  <Link
                    to={
                      product.artist.user_id && product.artist.user_id.username
                        ? `/artwork/${product.artist.user_id.username}/${product.slug}`
                        : `/artworks`
                    }
                    // href={`/artworks/${originalRows[item].artist.user_id.firstname}/${originalRows[item].title}`}
                  >
                    <div className="sale-on-snow-artwork-image">
                      <img src={product.photo} alt="" />
                    </div>
                    <div className="sale-on-snow-artwork-info">
                      <div className="sale-on-snow-artwork-name">
                        {product.title}
                      </div>
                      <div className="sale-on-snow-artwork-artist-name">
                        {product.artist.user_id.firstname}{" "}
                        {product.artist.user_id.lastname}
                      </div>
                      {/* <div className="sale-on-snow-artwork-location">Lagos</div> */}
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* ROW 7 / APPLICATION REVIEWS SECTION */}
        <div className="homepage-main--row-seven application-reviews-section">
          <div className="application-reviews-section--inner">
            <div className="application-reviews-section-title">
              Don't take our word for it
            </div>

            <div
              className="angle-right-icon application-reviews-angle-right"
              onClick={(e) =>
                handleScrollToRight(
                  e,
                  "application-reviews-wrapper-right-section"
                )
              }
            >
              <img src={angleRight} alt="" />
            </div>

            <div className="application-reviews-main-wrapper">
              {/* Left Section */}
              <div className="application-reviews-wrapper-left-section">
                <div className="re-company-logo">
                  <img src={snowLogoGray} alt="" />
                </div>

                <div className="total-review-summary-setion">
                  <div className="average-review-title">Excellent</div>
                  <div className="five-star-wrapper">
                    <div className="star-box">
                      <img src={starIcon} alt="" />
                    </div>
                    <div className="star-box">
                      <img src={starIcon} alt="" />
                    </div>
                    <div className="star-box">
                      <img src={starIcon} alt="" />
                    </div>
                    <div className="star-box">
                      <img src={starIcon} alt="" />
                    </div>
                    <div className="star-box">
                      <img src={starIcon} alt="" />
                    </div>
                  </div>
                  {/* <div className="review-basis">
                    Based on <span>3, 260 reviews</span>
                  </div> */}
                </div>
              </div>
              {/* Right Section */}
              <div className="application-reviews-wrapper-right-section">
                {/* Client review box */}
                <div className="client-review-box client-one-review-box">
                  <div className="client-name">Oluwaseun</div>
                  <div className="client-star-rating">
                    <div className="five-star-wrapper">
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="client-text-review-major">
                    {/* I'm Impressed. */}
                  </div>
                  <div className="client-text-review-sub">
                    Snow Africa is a platform that allows artist to be heard and
                    talk about their process of creating, philosophy and
                    inspiration. Snow Africa is the platform you want to tune in
                    as an artist, art collector, or art connoisseur, to hear
                    artists discuss their art and the influence of the
                    environment on their art.
                  </div>
                  {/* <div className="client-location">Amsterdam</div>
                  <div className="review-date">15th October</div> */}
                </div>
                <div className="client-review-box client-one-review-box">
                  <div className="client-name">Grace</div>
                  <div className="client-star-rating">
                    <div className="five-star-wrapper">
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                      <div className="star-box">
                        <img src={starIcon} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="client-text-review-major">
                    {/* I'm Impressed. */}
                  </div>
                  <div className="client-text-review-sub">
                    I love the initiative behind SnowAfrica. For me, it is a
                    one-stop shop to learn about art (their blogs), buy art and
                    relate with other upcoming artists like myself
                  </div>
                  {/* <div className="client-location">Amsterdam</div>
                  <div className="review-date">15th October</div> */}
                </div>
                {/* Client review box */}
                {[0].map((num, i) => (
                  <div
                    key={i + 1}
                    className="client-review-box client-one-review-box"
                  >
                    <div className="client-name">Victoria</div>
                    <div className="client-star-rating">
                      <div className="five-star-wrapper">
                        <div className="star-box">
                          <img src={starIcon} alt="" />
                        </div>
                        <div className="star-box">
                          <img src={starIcon} alt="" />
                        </div>
                        <div className="star-box">
                          <img src={starIcon} alt="" />
                        </div>
                        <div className="star-box">
                          <img src={starIcon} alt="" />
                        </div>
                        <div className="star-box">
                          <img src={starIcon} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="client-text-review-major">
                      {/* On time and efficient. */}
                    </div>
                    <div className="client-text-review-sub">
                      It’s amazing what SNOW AFRICA is doing for upcoming
                      artists- giving us a space to talk about ourselves and our
                      art. It was such a great honor to be part of the podcast.
                      You guys are doing well👍
                    </div>
                    {/* <div className="client-location">Amsterdam</div>
                    <div className="review-date">15th October</div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* ROW 8 / SNOWAFRICA MAAGAZINE SECTION */}
        {/* <div className="homepage-main--row-seven snowafrica-magazine-section">
          <div className="snowafrica-magazine-section-title">
            SNOWAFRICA Magazine <span>Explore African Art...</span>
          </div>
          Main Section Wrapper ---Commented FIX
          <div className="snowafrica-magazine-main-wrapper">
            <div className="snowafrica-magazine-main-wrapper--inner">
              Left Section ---Commented FIX
              <div className="left-wrapper-section magazine-cover-highlight-section">
                <img src={imageThree} alt="" />

                <div className="lower-magazine-info">
                  <div className="lower-magazine-info--row-one magazine-hightlight">
                    HIGHLIGHT <span>we just launched a new magazine</span>
                  </div>
                  <div className="lower-magazine-info--row-two magazine-showcase">
                    SHOWCASE: A magazine for the artists to service the
                    collectors
                  </div>
                  <div className="lower-magazine-info--row-three release-date">
                    January 7, 2022
                  </div>
                </div>
              </div>
              Right Section ---Commented FIX
              <div className="right-wrapper-section magazine-content-highlight-section">
                Row One ---Commented FIX
                <div className="magazine-content-highlight-section--row-one magazine-content-highlight-section--row">
                  <div className="content-highlight-image">
                    <img src={imageTwo} alt="" />
                  </div>

                  <div className="content-highlight-info">
                    <div className="content-highlight-title">
                      INTERIOR ART DECO
                    </div>
                    <div className="content-highlight-summary">
                      Tips and advice on how to choose and buy art for your
                      home. get best advice from our art consultant and style
                      your property without breaking the bank.
                    </div>
                    <div className="content-highlight-release-date">
                      January 7, 2022
                    </div>
                  </div>
                </div>
                Row Two ---Commented FIX
                <div className="magazine-content-highlight-section--row-two magazine-content-highlight-section--row">
                  <div className="content-highlight-image">
                    <img src={imageThree} alt="" />
                  </div>

                  <div className="content-highlight-info">
                    <div className="content-highlight-title">GALLERY FOCUS</div>
                    <div className="content-highlight-summary">
                      Here are some of the trending galleries pushing the
                      boundaries of exhibitonsin Africa.
                    </div>
                    <div className="content-highlight-release-date">
                      January 7, 2022
                    </div>
                  </div>
                </div>
                Row Three ---Commented FIX
                <div className="magazine-content-highlight-section--row-three magazine-content-highlight-section--row">
                  <div className="content-highlight-image">
                    <img src={imageFour} alt="" />
                  </div>

                  <div className="content-highlight-info">
                    <div className="content-highlight-title">
                      NFTs ON OUR RADAR
                    </div>
                    <div className="content-highlight-summary">
                      Why NFT is a game changer for artists and how Snow Africa
                      is preparing young artists to embrace this opportunity to
                      create wealth for young creatives
                    </div>
                    <div className="content-highlight-release-date">
                      January 7, 2022
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* ROW 8.5 / SNOWAFRICA NEWS SECTION */}
        <div className="homepage-main--row-eight-point-five snowafrica-news-section">
          <div className="snowafrica-news-section-title">NEWS</div>
          {/* Main Section Wrapper */}
          <div
            className="angle-right-icon snow-news-angle-right"
            onClick={(e) =>
              handleScrollToRight(e, "snowafrica-news-main-wrapper")
            }
          >
            <img src={angleRight} alt="" />
          </div>
          <div className="snowafrica-news-main-wrapper">
            {/* News Box Container */}
            {allNews?.map((news, index) => (
              <div key={index + 1} className="news-link-and-info-section--row">
                <a
                  href={convertToPlain(news.post_content)}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <div className="news-highlight-image">
                    {news.image && <img src={news.image || ""} alt="" />}
                  </div>

                  <div className="news-highlight-info">
                    <div className="news-highlight-title">
                      {news.post_title}
                    </div>

                    <div className="read-news-button-wrapper">
                      <button>Open News</button>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* ROW 9 / MAILING LIST SECTION */}
        <div className="homepage-main--row-seven mailing-list-section">
          <div className="mailing-list-section--inner">
            <div className="mailing-list-section-title">
              Join our mailing list
            </div>
            <div className="mailing-list-subsidiary-text">
              <p>
                Sign up below to join SnowAfrica's email newsletter, we will
                keep you updated with relevant artwork and news, no cluttering
                of your inbox
              </p>
            </div>

            {/* Newsletter subscribe form */}
            <NewsletterSubscribe />

            <div className="services-offered-section">
              {/* Sttunning artworks */}
              <div className="service-offered-box stunning-artworks">
                <div className="service-offered-icon">
                  <img src={yellowStarIcon} alt="" />
                </div>

                <div className="service-offered-title">Stunning Artworks</div>
                <div className="service-offered-sub-title">
                  from young african talent
                </div>
              </div>
              {/* We deliver globally */}
              <div className="service-offered-box deliver-globally">
                <div className="service-offered-icon">
                  <img src={truckIcon} alt="" />
                </div>

                <div className="service-offered-title">We deliver globally</div>
                <div className="service-offered-sub-title">
                  using trusted logistics partners
                </div>
              </div>
              {/* On time */}
              <div className="service-offered-box on-time">
                <div className="service-offered-icon">
                  <img src={clockIcon} alt="" />
                </div>

                <div className="service-offered-title">On time</div>
                <div className="service-offered-sub-title">
                  7 working days fufillment
                </div>
              </div>
              {/* Secure payments */}
              <div className="service-offered-box on-time">
                <div className="service-offered-icon">
                  <img src={padlockIcon} alt="" />
                </div>

                <div className="service-offered-title">Secure payments</div>
                <div className="service-offered-sub-title">
                  by credit or debit cards and transfers
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
