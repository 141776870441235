import React from "react";
import "./OrderFailed.css";
import CartFooter from "../Cart/CartFooter";
import Header from "../LandingPage/Header/Header";

function OrderFailed() {
  return (
    <div className="order-failed-container">
      <Header />
      <div className="order-failed-container--inner">
        <div className="order-failed-content-box">
          <div className="order-failed-message">
            Your order <span>failed</span>.
            <p>Please check your payment information.</p>
          </div>

          <div className="back-to-payment-info-button">
            <a href="#">BACK TO PAYMENT INFO</a>
          </div>
        </div>
      </div>

      {/* Footer */}
      <CartFooter />
    </div>
  );
}

export default OrderFailed;
