import React, { useState, useEffect } from "react";
import Home from './pages/Home';
import ArtistRegister from './pages/ArtistRegister';
import ArtistLogin from './pages/ArtistLogin';
import Terms from './pages/Terms';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Copyright from './pages/Copyright';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import ArtistDashboard from './pages/ArtistDashbaord';
import { Provider } from "react-redux";
import store from "./store";
import './App.css';
import CurrencyContext from "./context/currencyContext";
import Curators from './components/Curators/Curators';
import Collections from './components/Curators/Collections/Collections';
import Collection from './components/Curators/Collection/Collection';
import Artist from './components/Artists/Artist';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Galleries from "./components/Galleries/Galleries";
import ArtHouse from "./components/ArtHouse/ArtHouse";
import ArtworksSort from "./components/GallerySort/Artworks/ArtworksSort";
import Artwork from "./components/Artwork/Artwork";
import Cart from "./components/Cart/Cart"
import OrderFailed from "./components/OrderFailed/OrderFailed"
import OrderSuccessful from "./components/OrderSuccessful/OrderSuccessful"
import Shipping from "./components/Shipping/Shipping";
import Payment from "./components/Payment/Payment";
import Review from "./components/Review/Review";
import LandingPage from "./components/LandingPage/LandingPage";
import UserDashboardLayout from "./components/UserDashboard/Layout";
import Blogs from "./components/Blog/Blogs";
import SingleBlog from "./components/Blog/SingleBlog";
import Podcast from "./components/Podcast/Podcast";
import SinglePodcast from "./components/Podcast/SinglePodcast";
import Favourites from "./components/UserDashboard/Favourites/Favourites";
import ArtistFAQ from "./pages/ArtistFAQ";
import ComingSoon from "./components/ComingSoon/ComingSoon";

function App() {

  const location = window.location.pathname
  const [currencySelected, setCurrencySelected] = useState('₦');
  const [rate, setRate] = useState(600);

  const handleCurrencyChange = (currency, rate) => {
    setCurrencySelected(currency);
    setRate(rate);
  }

  // Used to determine whether to display Naira or Dollar equivalent to the customer
  const getUserLocation = () => {
    fetch("https://geolocation-db.com/json/697de680-a737-11ea-9820-af05f4014d91")
      .then(response => response.json())
      .then(data => {
        // fetch("https://snowafrica-backend.herokuapp.com/snowafrica/currencyrate")
        fetch("https://backend.snowafrica.art/snowafrica/currencyrate")
          // fetch("http://localhost:6001/snowafrica/currencyrate")
          .then(response => response.json())
          .then(res => {
            let rate = res.data.rate;
            if (data.country_name === 'Nigeria') {
              // handleCurrencyChange('₦', rate);
              handleCurrencyChange('₦', 600);
            }
            else {
              // handleCurrencyChange('$', rate);
              handleCurrencyChange('$', 600);
            }
          }
          )
          .catch(ex => console.log(ex));
      })
      .catch(ex => console.log(ex));
  }

  useEffect(() => {
    getUserLocation()
  })



  return (
    <>
      <Provider store={store}>
        <Router>
          <CurrencyContext.Provider value={{ currencySelected, rate, onCurrencyChange: handleCurrencyChange }}>
            <Routes>
              <Route path='/forgotPassword' element={<ForgotPassword />} />
              <Route path='/resetPassword:token' element={<ResetPassword />} />
              <Route path='/artist-dashboard/upload-art' element={<ArtistDashboard />} />
              <Route path='/artist/coming-soon' element={<ComingSoon />} />
              <Route path='/artist-dashboard/biodata' element={<ArtistDashboard />} />
              <Route path='/artist-dashboard/payment-information' element={<ArtistDashboard />} />
              <Route path='/artist-dashboard/products' element={<ArtistDashboard />} />
              <Route path='/artist-dashboard/transactions' element={<ArtistDashboard />} />
              <Route path="/artist-register" element={<ArtistRegister />} />
              <Route path='/curators' element={<Curators />} />
              <Route path='/curators/coming-soon' element={<ComingSoon />} />
              <Route path='/curators/:name' element={<Collections />} />
              <Route path='/curators/:name/:collection' element={<Collection />} />
              <Route path="/artist-login" element={<ArtistLogin />} />
              <Route path="/artist/:artistName" element={<Artist />} />
              <Route path="/gallery" element={<Galleries />} />
              <Route path='/gallery/coming-soon' element={<ComingSoon />} />
              <Route path="/gallery/:galleryName" element={<ArtHouse />} />
              <Route path="/artwork/:user/:slug" element={<Artwork />} />
              <Route path="/artworks/" element={<ArtworksSort />} />
              <Route path="/artworks/:category" element={<ArtworksSort />} />
              {/* <Route path="/artworks/:drawings" element={<ArtworksSort />} />
              <Route path="/artworks/:prints" element={<ArtworksSort />} />
              <Route path="/artworks/:drawings" element={<ArtworksSort />} />
              <Route path="/artworks/:photography" element={<ArtworksSort />} />
              <Route path="/artworks/:landscape" element={<ArtworksSort />} /> */}
              {/* <Route path="/artworks/sort/paintings" element={<PaintingSort />} />
              <Route path="/artworks/sort/drawings" element={<DrawingsSort />} />
              <Route path="/artworks/sort/prints" element={<PrintsSort />} />
              <Route path="/artworks/sort/photography" element={<PhotographySort />} />
              <Route path="/artworks/sort/landscape" element={<LandscapeSort />} /> */}
              {/* <Route path="/artworks/:categoryName" element={<ArtHouse />} /> */}
              {/* <Route path="/artworks" element={<ArtHouse />} /> */}
              <Route path="/cart" element={<Cart />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/order-review" element={<Review />} />
              <Route path="/order-failed" element={<OrderFailed />} />
              <Route path="/order-successful" element={<OrderSuccessful />} />
              <Route path="/customer/account-details" element={<UserDashboardLayout />} />
              <Route path="/customer/favourites" element={<UserDashboardLayout />} />
              <Route path="/customer/edit-billing-info" element={<UserDashboardLayout />} />
              <Route path="/customer/edit-card-details" element={<UserDashboardLayout />} />
              <Route path="/customer/purchase-history" element={<UserDashboardLayout />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/blog/:id" element={<SingleBlog />} />
              <Route path="/podcasts" element={<Podcast />} />
              <Route path="/podcast/:id" element={<SinglePodcast />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/copyright" element={<Copyright />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/buyer-faq" element={<FAQ />} />
              <Route path="/artist-faq" element={<ArtistFAQ />} />
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="/" element={<Home />} /> */}
            </Routes>
          </CurrencyContext.Provider>
        </Router>
      </Provider>
    </>
  );
}

export default App;
