import React, { useState,useEffect, useContext } from "react";
import "./Shipping.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import leftArrow from "../../images/arrow-left.png";
import CartFooter from "../Cart/CartFooter";
import Header from "../LandingPage/Header/Header";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { useSelector, useDispatch } from "react-redux";
import CurrencyContext from "../../context/currencyContext";
import { displayPrice } from "../../utils/displayPrice";
import { updateAddressPhone } from "../../redux/actions/profilesAction";
import { Link, useNavigate } from "react-router-dom";
import isEmpty from "../../validation/is-empty";
import _ from "lodash"
import { getPrice } from "../../utils/getPrice";

function Shipping() {
  const dispatch = useDispatch();
  // Functions, States and Variables
  // const mainUser = useSelector((state) => console.log('llll',state.profile.profile));
  const mainUser = useSelector((state) => state.profile.profile);
  const currencyContext = useContext(CurrencyContext);
  const [user,setUser] = useState(mainUser);
  //   STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondPhoneNumber, setSecondPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(false);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  // State, the one with a capital not react state
  const [state, setState] = useState(false);

  const handleAddSecondPhoneInput = function (e) {
    e.preventDefault();
    let secondPhoneInputSection = document.querySelector(
      ".second-phone-input-section"
    );
    // Remove the add second number button
    e.target.parentElement.classList.add("none");
    // Add the second number Input
    secondPhoneInputSection.classList.remove("none");
  };

  const updateShippingDetails = () => {
    if(user.address.split('')[0] !== address){
      const fullAddress = address+', '+city+' '+state+' '+country;
      const data = {
        address: fullAddress,
        phone: user && user.phone
      }
      dispatch(updateAddressPhone(data))
    }
  }

  const calculateTotalShippingFee = (data) => {
    // console.log('kkk',data);
    if(data && data.length){
      let result =  0
      for(let i = 0; i < data.length; i++){
        if(!isEmpty(data[i].weight)){
          result = result + calculateShippingFee(data[i].weight) 
        }
      }
      setShippingFee(result);
    }
  }
  const calculateShippingFee = (data) => {
    let figure;
    // console.log('ooo',currencyContext.rate)
    if (data > 0 && data <= 2){
       figure = 3640;
      // this.setState({
      //   shippingFee: 3640,
      //   sizeCategory: "small",
      //   total: 3640+this.props.products.artwork.price,
      // })
      // return (3640)
    }
    if(data > 2 && data <= 10){
      figure = 8613;
      // this.setState({
      //   shippingFee: 8613,
      //   sizeCategory:"medium",
      //   total: 8613+this.props.products.artwork.price,
      // })
    }
    if (data > 10){
      figure = 15907
      // this.setState({
      //   shippingFee: 15907,
      //   sizeCategory: "large",
      //   total: 15907+this.props.products.artwork.price,
      // })
      // return 15907
    }
    if(data === 0){
      figure = 0
      // this.setState({
      //   shippingFee: 0,
      //   sizeCategory:"unspecified",
      //   total: this.props.products.artwork.price,
      // })
    }
    return (currencyContext.currencySelected === "₦")? figure : figure/currencyContext.rate;
  }

  useEffect(() => {
    if(!_.isEmpty(user)){
      console.log('jj', user)
      let fullAddress = user.shippingAddress.split(',');
      console.log('full', fullAddress)
      setFirstName(user.firstname)
      setLastName(user.lastname)
      setPhoneNumber(user.phone)
      setAddress(fullAddress[0])
      setCity(fullAddress[1])
      setCountry(fullAddress[4])
      setState(fullAddress[3])
    }
  }, [user]);

  useEffect(() => {
    setUser(mainUser);
  }, [mainUser])

  useEffect(() => {
    // console.log('jj', user.cart)
    // (originalCartRows && originalCartRows.length > 0 && originalCartRows[0] !== null)
    if(user && user.cart && user.cart.length > 0 && user.cart[0] !== null){
      calculateTotalShippingFee(user.cart)
      // const totalCartPriceNum = user && user.cart
      //   .map((cartRow) => {
      //     return parseInt(displayPrice(cartRow,currencyContext).replace(/,/g,'',10));
      //   })
      //   .reduce((acc, num) => acc + num);

      let totalCartPriceNum = 0
      user && user.cart
        .map((cartRow) => {
          totalCartPriceNum = totalCartPriceNum + (getPrice(cartRow,currencyContext))
        })
  
      setTotalCartPrice(totalCartPriceNum);
      let orderTotal = totalCartPriceNum + shippingFee;
      // console.log('ppp', totalPrice)
      console.log('ppp', totalCartPriceNum)
      console.log('bbb', shippingFee)
      // console.log('ppp', orderTotal)
      setTotalPrice(orderTotal)
    }
    
  }, [user]);

  useEffect(() => {
      let orderTotal = totalCartPrice + shippingFee;
      // console.log('ppp', totalPrice)
      console.log('ppp', totalCartPrice)
      console.log('bbb', shippingFee)
      // console.log('ppp', orderTotal)
      setTotalPrice(orderTotal)
    
  }, [totalCartPrice,shippingFee]);

  return (
    <div className="shipping-container">
      <Header />
      {/* Cart Header */}
      <div className="shipping--header">
        <div className="shipping--header--inner">
          {/* Title */}
          <div className="shipping-title">
            <p>Shipping</p>
            {/* Shipping top Links */}
            <div className="shipping-top-links">
              <div className="shipping-top-links--inner">
                {/* Artist Name */}
                <div className="cart-link shipping-top-link">
                  <a href={`/cart`}>Cart</a>
                </div>
                {/* Divider */}
                <span className="link-divider">/</span>
                {/* Shipping */}
                <div className="shipping-link shipping-top-link">Shipping</div>
              </div>
            </div>
          </div>

          {/*Order progression */}
          <div className="order-progression">
            <div className="order-progression--inner shipping-order-progression">
              {/* Cart */}
              <div className="cart-progression ">
                <div className="progression-title">Cart</div>
              </div>

              {/* Shipping */}
              <div className="shipping-progression">
                <div className="progression-title">Shipping</div>
              </div>

              {/* Payment */}
              <div className="payment-progression">
                <div className="progression-title">Payment</div>
              </div>

              {/* Review */}
              <div className="review-progression">
                <div className="progression-title">Review</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Shipping Main Container */}
      <div className="shipping-container--inner ">
        {/*Profile Section */}
        <div className="shipping-container--col profile-section">
          <div className="section-title">Profile</div>
          <div className="horizontal-line"></div>

          {/* Shipping Profile form */}
          <div className="profile-section--form">
            <form>
              {/* First Name */}
              <div className="form-group mb-3">
                <label htmlFor="first-name" className="form-label">
                  FIRST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="first-name"
                  aria-describedby="firstName"
                  value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                  disabled
                />
              </div>

              {/* Last Name */}
              <div className="form-group mb-3">
                <label htmlFor="last-name" className="form-label">
                  LAST NAME:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="last-name"
                  aria-describedby="lastName"
                  value={lastName}
                  // onChange={(e) => setLastName(e.target.value)}
                  disabled
                />
              </div>

              {/* Phone Number */}
              <div className="form-group mb-3">
                <label htmlFor="phone-number" className="form-label">
                  PHONE NUMBER:
                </label>
                <input
                  type="text"
                  className="form-control cart-form-control"
                  id="phone-number"
                  aria-describedby="phoneNumber"
                  value={phoneNumber}
                  // onChange={(e) => setPhoneNumber(e.target.value)}
                  disabled
                />
              </div>

              {/* Second Phone Number */}
              {/* <div className="form-group mb-3 second-phone-input-section none">
                <label htmlFor="second-phone-number" className="form-label">
                  SECOND PHONE NUMBER:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="second-phone-number"
                  aria-describedby="secondPhoneNumber"
                  value={secondPhoneNumber}
                  onChange={(e) => setSecondPhoneNumber(e.target.value)}
                  required
                />
              </div> */}

              {/* Add Second Number */}
              {/* <div className="add-second-phone-no">
                <button
                  onClick={handleAddSecondPhoneInput}
                  className="add-second-phone-no-btn"
                >
                  <span>+</span> Add Second Number
                </button>
              </div> */}
            </form>
          </div>
        </div>

        {/*Billing Address Section*/}
        <div className="shipping-container--col billing-address-section">
          <div className="section-title">Billing Address</div>
          <div className="horizontal-line"></div>

          {/*Shipping  Billing Address form */}
          <div className="billing-addresss-section--form">
            <form>
              {/* Address Input */}
              <div className="form-group mb-3">
                <label htmlFor="address-input" className="form-label">
                  ADDRESS:
                </label>
                <textarea
                  type="text"
                  className="form-control cart-form-control address-input"
                  id="address-input"
                  aria-describedby="addressInput"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>

              {/* City Input */}
              <div className="form-group mb-3">
                <label htmlFor="city-input" className="form-label">
                  CITY:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="city-input"
                  aria-describedby="cityInput"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
              </div>

              {/* State Input */}
              <div className="form-group mb-3">
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="state-input"
                    id="state-input"
                    aria-describedby="stateInput"
                    className="form-control form-dropdown"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  >
                    <option value="false" disabled>
                      Choose your state
                    </option>
                    <option value="LA">Lagos State</option>
                    <option value="OY">Oyo State</option>
                    <option value="OS">Osun State</option>
                    <option value="AB">Abia State</option>
                    <option value="BE">Benue State</option>
                  </select>
                </div>
              </div>

              {/* Country Input */}
              <div className="form-group mb-3">
                <div className="form-group-dropdown">
                  <div className="left-arrow-section">
                    <img src={arrowUp} alt="arrow-up" />
                    <img src={arrowDown} alt="arrow-down" />
                  </div>
                  <select
                    name="country-input"
                    id="country-input"
                    aria-describedby="countryInput"
                    className="form-control form-dropdown"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  >
                    <option value="false" disabled>
                      Choose your country
                    </option>
                    <option value="NG">NIGERIA</option>
                    <option value="SA">SOUTH AFRICA</option>
                    <option value="GH">GHANA</option>
                    <option value="KY">KENYA</option>
                  </select>
                </div>
              </div>

              <div className="delivery-time-info">
                <p>Please, allow up to two weeks for international delivery.</p>
              </div>
            </form>
          </div>
        </div>

        {/*ORDER PRICE SUMMARY */}
        <div className="shipping-container--col shipping-details-summary">
          <div className="order-price-summary--inner shipping-details-summary--inner">
            {/* Artwork subtotal */}
            <div className="artwork-subtotal order-price-parameter-row">
              <div className="artwork-subtotal-title order-price-parameter-title">
                ARTWORKS SUBTOTAL:
              </div>
              <div className="artwork-subtotal-value order-price-parameter-value">
                {/* NGN{numberWithCommas("1300000")}.00 */}
                {currencyContext.currencySelected} { totalCartPrice.toLocaleString()}.00
              </div>
            </div>

            {/* Discount */}
            <div className="order-discount order-price-parameter-row">
              <div className="order-discount-title order-price-parameter-title">
                DISCOUNT:
              </div>
              <div className="order-discount-value order-price-parameter-value">
              {currencyContext.currencySelected} {numberWithCommas("0")}.00
              </div>
            </div>

            {/* Shipping */}
            <div className="shipping-price order-price-parameter-row">
              <div className="shipping-price-title order-price-parameter-title">
                SHIPPING:
              </div>
              <div className="shipping-price-value order-price-parameter-value">
              {currencyContext.currencySelected} {shippingFee.toLocaleString()}
              </div>
            </div>

            {/* Tax */}
            <div className="tax-inclusivity order-price-parameter-row">
              <div className="tax-inclusivity-title order-price-parameter-title">
                TAX:
              </div>
              <div className="tax-inclusivity-value order-price-parameter-value">
                {"Inclusive"}
              </div>
            </div>

            {/* Order Total */}
            <div className="total-order-price order-price-parameter-row">
              <div className="total-order-price-title order-price-parameter-title">
                ORDER TOTAL:
              </div>
              <div className="total-order-price-value order-price-parameter-value">
              {currencyContext.currencySelected} { totalPrice.toLocaleString()}.00
              </div>
            </div>

            {/* Proceed to checkout link */}
            <div className="shipping--proceed-to-checkout-wrapper">
              <Link
                onClick={updateShippingDetails}
                to="/payment"
                className="shipping--proceed-to-checkout-wrapper--inner"
              >
                <p>PROCEED TO CHECKOUT</p>
              </Link>
            </div>
          </div>

          {/* International Shipments Note */}
          <div className="international-shipments-note">
            <p>
              Note: International shipments may be subject to additional import
              taxes, custom duties or fees imposed by the destination country,
              payable by the buyer upon receipt.
            </p>
          </div>
        </div>

        {/* Go back to Cart Btn*/}
        <div className="go-back-to-cart-btn-section">
          <div className="go-back-to-cart-btn-section--inner">
            {/* Back to cart */}
            <a href="/cart" className="action-back-to-cart-wrapper">
              <img src={leftArrow} alt="left-arrow" />
              <p>Back to Cart</p>
            </a>
          </div>
        </div>
      </div>

      <CartFooter />
    </div>
  );
}

export default Shipping;
