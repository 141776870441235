import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import visaLogo from "../../../images/visa.png";
import mastercardLogo from "../../../images/mastercard-logo.png";
import paypalLogo from "../../../images/paypal.png";
import logo from "../../../images/Snowafrica.png";
import facebookIcon from "../../../images/landing-page/facebook.svg";
import twitterIcon from "../../../images/landing-page/twitter.svg";
import pinterestIcon from "../../../images/landing-page/pinterest.svg";
import instagramIcon from "../../../images/landing-page/instagram.svg";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-container--inner">
        {/* Footer Upper */}
        <div className="footer--inner-upper">
          {/* Upper Column 1 */}
          <div className="footer-upper footer--upper-row-one">
            {/* logo */}
            <div className="footer--company-logo">
              <img src={logo} alt="" />
            </div>
          </div>
          {/* Upper Column 2 | For artists */}
          <div className="footer-upper footer-upper--row-two">
            {/* Row Two footer Colum One */}
            <div className="row-two--column-one row-two--footer-columns">
              <div className="column--title">For Artists</div>

              <div className="column-link-wrapper">
                {/* Join the community */}
                <div className="column-link join-the-community-link">
                  <Link to="#">Join the Community</Link>
                </div>
                {/* Download Artist Guide */}
                <div className="column-link download-artist-guide-link">
                  <a
                    href='https://mcusercontent.com/10447c81db3f92bbc4a520047/files/9c308c2c-e42d-90d6-e86d-d23566c8a83c/Artist_handbook.pdf'
                    target="_blank"
                  >
                    Download Artist Guide
                  </a>
                </div>
                {/* FAQ*/}
                {/* TODO Create Artist Faq Page and Get Info From Snow */}
                <div className="column-link faq-link">
                  <Link to="/artist-faq">FAQ</Link>
                </div>
              </div>
            </div>
            {/* Row Two footer Colum Two | For Buyer */}
            <div className="row-two--column-two row-two--footer-columns">
              <div className="column--title">For Buyer</div>

              <div className="column-link-wrapper">
                {/* Join the community */}
                {/* <div className="column-link join-the-community-link">
                  <Link to="#">Join the Community</Link>
                </div> */}
                {/* Download Artist Guide */}
                <div className="column-link download-artist-guide-link">
                  <a
                    href='https://mcusercontent.com/10447c81db3f92bbc4a520047/files/9c308c2c-e42d-90d6-e86d-d23566c8a83c/Artist_handbook.pdf'
                    target="_blank"
                  >
                    Download Artist Guide
                  </a>
                </div>
                {/* FAQ*/}
                <div className="column-link faq-link">
                  <Link to="/buyer-faq">FAQ</Link>
                </div>
              </div>
            </div>

            {/* Row Two footer Colum Three | Our Company */}
            {/* <div className="row-two--column-three row-two--footer-columns">
              <div className="column--title">Our Company</div>

              <div className="column-link-wrapper">
                About Us--comment
                <div className="column-link about-us-link">
                  <Link to="#">About Us</Link>
                </div>
                Our Artists--comment
                <div className="column-link our-artists-link">
                  <Link to="#">Our Artists</Link>
                </div>
                Art Magazine--comment
                <div className="column-link art-magazine-link">
                  <Link to="#">Art Magazine</Link>
                </div>
                Our Team--comment
                <div className="column-link our-team-link">
                  <Link to="#">Our Team</Link>
                </div>
                FAQ--comment
                <div className="column-link faq-link">
                  <Link to="#">FAQ</Link>
                </div>
              </div>
            </div> */}

            {/* Row Two footer Colum Four | Customer Service */}
            <div className="row-two--column-four row-two--footer-columns">
              <div className="column--title">Customer Service</div>

              <div className="column-link-wrapper">
                {/* Terms of Services */}
                <div className="column-link terms-of-services-link">
                  <Link to="/terms">Terms of Services</Link>
                </div>
                {/* Privacy Policy */}
                <div className="column-link privacy-policy-link">
                  <Link to="/privacy">Privacy Policy</Link>
                </div>
                {/* Contact Us */}
                <div className="column-link contact-us-link">
                  <Link to="/contact-us">Contact us</Link>
                </div>
                {/* Copyright Policy */}
                {/* <div className="column-link copyright-policy-link">
                  <Link to="/privacy">Copyright Policy</Link>
                </div> */}
                {/* My Profile */}
                {/* <div className="column-link my-profile-link">
                  <Link to="#">My Profile</Link>
                </div> */}
                {/* Art for Corporates */}
                {/* <div className="column-link art-for-corporates-link">
                  <Link to="#">Art for Corporates</Link>
                </div> */}
                {/* Art for Interior Designers */}
                {/* <div className="column-link art-for-interior-designers-link">
                  <Link to="#">Art for Interior Designers</Link>
                  <p>+234 803 816 4719</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Footer lower */}
        <div className="footer--inner-lower">
          <div className="copyright"> &copy; 2022 Copyright Snowafrica </div>

          <div className="footer--inner-lower--right">
            {/* Social Media Links Container */}
            <div className="social-media-links-container">
              {/* Facebook */}
              <div className="social-media-link-wrapper facebook-link-wrapper">
                <a
                  href={
                    "https://web.facebook.com/search/top?q=snowafrica%20art"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebookIcon} alt="" />
                </a>
              </div>
              {/* Twitter */}
              <div className="social-media-link-wrapper twitter-link-wrapper">
                <a
                  href={"https://twitter.com/SnowAfricaart-"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitterIcon} alt="" />
                </a>
              </div>
              {/* instagram */}
              <div className="social-media-link-wrapper instagram-link-wrapper">
                <a
                  href={"https://www.instagram.com/snowafricaart/?hl=ens"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="" />
                </a>
              </div>
              {/* pinterest */}
              <div className="social-media-link-wrapper pinterest-link-wrapper">
                <a
                  href={"https://pin.it/4adnhbk"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={pinterestIcon} alt="" />
                </a>
              </div>
            </div>

            {/* Financial Supported */}
            <div className="financial-supportted-logos">
              {/* Visa Logo */}
              <div className="visa-logo logo">
                <img src={visaLogo} alt="visa-logo" />
              </div>
              {/* Mastercard Logo */}
              <div className="mastercard-logo logo">
                <img src={mastercardLogo} alt="mastercard-logo" />
              </div>
              {/* Paypal Logo */}
              <div className="paypal-logo logo">
                <img src={paypalLogo} alt="paypal-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
