

export function getPrice(product, currencyContext) {
    
    const cost = (product.price && currencyContext.currencySelected === "₦")
    ? Math.round(((product.price) + (product.price * 0.35) + (20 * currencyContext.rate)))
    : (product.price && currencyContext.currencySelected === "$") 
    ? Math.round(((product.price / currencyContext.rate) + ((product.price * 0.35)/currencyContext.rate) + 20))
    :  "0";
    // console.log('cost',cost)
    // console.log('productPrice',product.price)
    // console.log('rate',currencyContext.rate)
    // console.log('currency',currencyContext.currencySelected)
    // console.log('context',currencyContext)
    return cost


}