import React, { useState, useRef, useEffect } from "react";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import searchIcon from "../../../images/search.png";
import TransactionInvoiceTable from "./TransactionInvoice/TransactionInvoiceTable";
import CashFlows from "./Cashflows/Cashflows";
import Orders from "./Orders/Orders";
import "./Transactions.css";
import InvoiceCard from "./TransactionInvoice/InvoiceCard";
import { numberWithCommas } from "../../../utils/numberWithCommas";

function Transactions() {
  // Variables and states
  // Create the row data function for the transaction invoices table
  function createTransactionInvoicesData(
    sku,
    productName,
    artist,
    category,
    merchant,
    cost,
    deliveryFee = "3000",
    taxFee = "60000"
  ) {
    return {
      sku,
      productName,
      artist,
      category,
      merchant,
      cost,
      deliveryFee,
      taxFee,
      tableName: "transactions-invoice",
      totalPrice:
        parseInt(cost, 10) + parseInt(deliveryFee, 10) + parseInt(taxFee, 10),
    };
  }

  // Create the row data function for the cashflows table
  function createCashflowsData(amount, date, category, sku) {
    return {
      amount,
      date,
      category,
      sku: category,
      tableName: "cashflows",
    };
  }

  // Create data for Transaction Invvoices Table
  const transactionInvoicesRows = [
    createTransactionInvoicesData(
      "AFOO045",
      "Wooden Board on Pain Canvas with Gloss",
      "Hezekiah Oluwole",
      "Painting",
      "Alpha Set Collections",
      "1200000"
    ),
    createTransactionInvoicesData(
      "BC21345",
      "The Monalisa",
      "Bob Chimezie",
      "Painting",
      "Alpha Set Collections",
      "3350000"
    ),
    createTransactionInvoicesData(
      "BD01045",
      "Saint Luis Boats",
      "Pablo Picasso",
      "Sculpture",
      "Alpha Set Collections",
      "700000"
    ),
    createTransactionInvoicesData(
      "AE12045",
      "Best of all Possible Worlds",
      "Vincent Van Gogh",
      "Painting",
      "Alpha Set Collections",
      "900000"
    ),
    createTransactionInvoicesData(
      "AB04045",
      "Peripheral Vision",
      "Jacqueline Roque",
      "Category A",
      "Alpha Set Collections",
      "1770000"
    ),
    createTransactionInvoicesData(
      "AB04045",
      "Migration, Circular",
      "Henri Matisse",
      "Category B",
      "Alpha Set Collections",
      "2130000"
    ),
    createTransactionInvoicesData(
      "EF94204",
      "Modern Three Pears",
      "Bob Chimezie",
      "Sculpture",
      "Alpha Set Collections",
      "530000"
    ),
    createTransactionInvoicesData(
      "CA09705",
      "Guernica",
      "Pablo Picasso",
      "Category B",
      "Alpha Set Collections",
      "90000"
    ),
    createTransactionInvoicesData(
      "AF64048",
      "The Old Guitarist",
      "Hezekiah Oluwole",
      "Category A",
      "Alpha Set Collections",
      "1000000"
    ),
    createTransactionInvoicesData(
      "DE19037",
      "La Reve",
      "Daniel Adeneye",
      "Painting",
      "Alpha Set Collections",
      "6350000"
    ),
  ];

  // Create data for Cashflows Table
  const cashflowsRows = [
    createCashflowsData("2,300,000.00", "17th August, 2016", "Sculpture"),
    createCashflowsData("1,200,000.00", "12th Sept, 2016", "Painting"),
    createCashflowsData("700,000.00", "23th Aug, 2016", "Category A"),
    createCashflowsData("2,300,000.00", "17th August, 2016", "Sculpture"),
    createCashflowsData("700,000.00", "23th Aug, 2016", "Category A"),
    createCashflowsData("900,000.00", "23th Aug, 2016", "Category B"),
    createCashflowsData("1,200,000.00", "12th Sept, 2016", "Painting"),
    createCashflowsData("2,100,000.00", "17th August, 2016", "Sculpture"),
    createCashflowsData("300,000.00", "23th Aug, 2016", "Category A"),
    createCashflowsData("900,000.00", "23th Aug, 2016", "Category B"),
    createCashflowsData("1,200,000.00", "12th Sept, 2016", "Painting"),
    createCashflowsData("2,300,000.00", "17th August, 2016", "Sculpture"),
    createCashflowsData("700,000.00", "23th Aug, 2016", "Category A"),
    createCashflowsData("800,000.00", "23th Aug, 2016", "Category B"),
    createCashflowsData("1,200,000.00", "12th Sept, 2016", "Painting"),
    createCashflowsData("900,000.00", "23th Aug, 2016", "Category B"),
  ];

  // Set the original rows to the data based on the page i.e if the page is cashflows, row data should be cashflowsRows
  const [sortBy, setSortby] = useState("SKU");
  const [searched, setSearched] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState("10");
  const [pageContent, setPageContent] = useState("cashflows");
  const [originalRows, setOriginalRows] = useState(cashflowsRows);
  const [totalCashflowNumber, setTotalCashflowNumber] = useState("");
  const [rowData, setRowData] = useState({});
  const [searchParameter, setSearchParameter] = useState("category");

  //Ref
  const cashflowLinkRef = useRef();
  const ordersLinkRef = useRef();
  const transactionsInvoiceLinkRef = useRef();
  const transactionInvoiceCardRef = useRef();

  // Functions
  // Reequest search function that queries the table based on the search parameter and search value and returns the deesired rows
  const requestSearch = (searchedValue, searchParam) => {
    const filteredRows = originalRows.filter((row) => {
      // alert(searchParam);
      return row[searchParam]
        .toLowerCase()
        .includes(searchedValue.toLowerCase());
    });
    setOriginalRows(filteredRows);
  };

  // Handle on change of the search bar
  const handleSearchOnChange = function (e) {
    // Set the search data state to the search value
    setSearched(e.target.value);
    let searchResultText = document.querySelector(".search-result-text");
    // Handle Search Result Text View when search is not !empty
    if (e.target.value !== "") {
      // TODO Create state for seach parameter and change state based on page change
      requestSearch(searched, searchParameter);
      searchResultText.classList.remove("none");
    }

    // Remove searched text view when when search is === empty
    if (e.target.value === "") {
      searchResultText.classList.add("none");
    }
  };

  // Handle on oninput of the search bar to determine if it was backspace that was pressed
  // Handles backspace press or delete press on search
  const handleKeyDown = function (e) {
    // If pageContent is transactions invoice, and key === backspace || delete, rehandle search setting rows to transactions rows
    if (pageContent === "transactions-invoice") {
      let key = e.keyCode || e.charCode;
      // IF keycode is backspace or delete
      if (key === 8 || key === 46) {
        setSearched(e.target.value);
        requestSearch(e.target.value, "productName");
        setOriginalRows(transactionInvoicesRows);
      }
    }

    // If pageContent is cashflows, and key === backspace || delete, rehandle search setting rows to cashflows rows
    if (pageContent === "cashflows") {
      let key = e.keyCode || e.charCode;
      // IF keycode is backspace or delete
      if (key === 8 || key === 46) {
        setSearched(e.target.value);
        requestSearch(e.target.value, "category");
        setOriginalRows(cashflowsRows);
      }
    }
  };

  // HANDLE SIDEBAR NAV LINKS
  // Handle Cashflows Link Click
  const handleCashflowLinkClick = function () {
    // Set Page to Cashflows page
    setPageContent("cashflows");
    // Set Table Rows to filter through to casshflow rows
    setOriginalRows(cashflowsRows);
    // Set Table Field value to set as filtering parameter on search
    setSearchParameter("category");
    // Set searched value to empty when the page changes and remove the search result section
    setSearched("");
    document.querySelector(".search-result-text").classList.add("none");
    // Handle color change on active class active
    cashflowLinkRef.current.classList.add("active-link");
    ordersLinkRef.current.classList.remove("active-link");
    transactionsInvoiceLinkRef.current.classList.remove("active-link");
  };

  // Handle Orders Link Click
  const handleOrdersLinkClick = function () {
    // Set Page to Orders page
    setPageContent("orders");
    // Set searched value to empty when the page changesand remove the search result section
    setSearched("");
    // Handle color change on active class active
    ordersLinkRef.current.classList.add("active-link");
    cashflowLinkRef.current.classList.remove("active-link");
    transactionsInvoiceLinkRef.current.classList.remove("active-link");
  };

  // Handle Transactions Invoice Link Click
  const handleTransactionsInvoiceLinkClick = function () {
    // Set Page to Transactions Invoice page
    setPageContent("transactions-invoice");
    // Set Table Rows to filter through to transaction invoice rows
    setOriginalRows(transactionInvoicesRows);
    // Set Table Field value to set as filtering parameter on search
    setSearchParameter("productName");
    // Set searched value to empty when the page changes
    setSearched("");
    document.querySelector(".search-result-text").classList.add("none");
    // Handle color change on active class active
    transactionsInvoiceLinkRef.current.classList.add("active-link");
    ordersLinkRef.current.classList.remove("active-link");
    cashflowLinkRef.current.classList.remove("active-link");
  };

  // Handle Table View Click
  const handleTableRowViewClick = function (e, rowData) {
    if (pageContent === "transactions-invoice") {
      let transactionInvoiceCard = document.querySelector(
        ".transaction-invoice-card"
      );
      setRowData(rowData);
      transactionInvoiceCard.classList.remove("none");
    }
  };

  // Handle Collapse Button Press
  const handleCollapseButtonClick = function () {
    if (pageContent === "transactions-invoice") {
      let transactionInvoiceCard = document.querySelector(
        ".transaction-invoice-card"
      );
      transactionInvoiceCard.classList.add("none");
    }
  };

  // USEEFFECT
  useEffect(() => {
    // Get the total cashflows and return the comma-format
    const getTotalStructuredNumber = function (array) {
      let totalCashflowAmontArr = [];
      array.forEach((rows) => {
        // Push all the row amounts into the total cash array
        totalCashflowAmontArr.push(rows.amount);
      });

      // Map the arr and remove all commas, then add to get the total cashflow amount
      let totalCashflowAmont = totalCashflowAmontArr
        .map((num) => {
          // Map through the array and remove the 2 trailing zeros and remove all special characters (i.e . and ,)
          num = num.toLocaleString();
          return num;
        })
        .reduce((acc, num) => {
          // Use a reduce method to add the returned number values andd return thee accumulator
          return parseInt(acc, 10) + parseInt(num, 10);
        });

      totalCashflowAmont = numberWithCommas(totalCashflowAmont);
      return totalCashflowAmont;
    };
    let totalCashflowStructured = getTotalStructuredNumber(cashflowsRows);
    // Set the total cashflow class to the cashflow number state
    setTotalCashflowNumber(totalCashflowStructured);
  }, []);

  return (
    <div className="transactions-main">
      <div className="transactions-main-inner">
        {/* Products Header */}
        <div className="transactions--header">
          <div className="transactions--header-inner">
            {/* Sort By Check box */}
            <div className="sort-by transactions--header-column-one">
              {/* SORT BY */}
              <label
                htmlFor="sort-by"
                className="form-label transactions-form-label"
              >
                SORT BY:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="sort-by"
                  id="sort-by"
                  aria-describedby="sortBy"
                  className="form-control form-dropdown"
                  onChange={(e) => setSortby(e.target.value)}
                  value={sortBy}
                >
                  <option value="sku">SKU</option>
                  <option value="product-name">PRODUCT NAME</option>
                  <option value="artist">ARTIST</option>
                  <option value="category">CATEGORY</option>
                </select>
              </div>
            </div>

            {/* Search bar */}
            <div className="search-bar transactions--header-column-two">
              <div className="transactions-search-bar-section ">
                {/* SEARCH BAR*/}
                <label
                  htmlFor="search-bar"
                  className="form-label transactions-form-label"
                >
                  SEARCH{" "}
                  {pageContent === "cashflows"
                    ? "THROUGH CASHFLOW"
                    : pageContent === "orders"
                    ? "FOR ORDER"
                    : "FOR INVOICE"}
                  :
                </label>
                <div className="form-group form-group-dropdown mb-3">
                  <div className="left-search-icon-section">
                    <img src={searchIcon} alt="search" />
                  </div>
                  <input
                    name="search-bar"
                    id="search-bar"
                    type="search"
                    aria-describedby="searchBar"
                    className="form-control form-search"
                    placeholder="Search..."
                    value={searched}
                    onChange={(e) => {
                      handleSearchOnChange(e);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>

            {/* Results per page checkbox */}
            <div className="results-per-page transactions--header-column-three">
              {/* RESULTS PER PAGE */}
              <label
                htmlFor="results-per-page"
                className="form-label transactions-form-label"
              >
                RESULTS PER PAGE:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="results-per-page"
                  id="results-per-page"
                  aria-describedby="resultsPerPage"
                  className="form-control form-dropdown"
                  onChange={(e) => setResultsPerPage(e.target.value)}
                  value={resultsPerPage}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* TRANSACTION INVOICE CARD */}
        {/* Only show if page is transaction-invoice */}
        {pageContent === "transactions-invoice" ? (
          <div
            ref={transactionInvoiceCardRef}
            className="transaction-invoice-card none"
          >
            <InvoiceCard rowData={rowData} />
            <div
              onClick={handleCollapseButtonClick}
              className="collapse-button"
            >
              <span>
                <img src={arrowUp} alt="arrow-up" className="arrow-up-large" />
                COLLAPSE
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* SEARCH RESULT TEXT THAT SHOWS WHEN SEARCH VALUE IS NOT EMPTY */}
        <div className="search-result-text none">
          <div className="result-text-row-one">
            <p>Search Result for "{searched}"</p>
          </div>
          <div className="result-text-row-two">
            <p>{originalRows.length} Results</p>
          </div>
        </div>

        {/* Products BODY / TABLE */}
        <div className="transactions--body">
          {/* Side Nav Section */}
          <div className="transactions--nav page-column column-one">
            <div className="header-inner">
              {/* Title row 1 */}
              <div className="title-row-1 actions-text">ACTIONS</div>
              {/* Title Row 2 */}
              <div className="main-page-title title-row-2">
                <div
                  ref={cashflowLinkRef}
                  onClick={handleCashflowLinkClick}
                  className="actions-link cashflow-link active-link"
                >
                  Cashflow
                </div>
                <div
                  ref={ordersLinkRef}
                  onClick={handleOrdersLinkClick}
                  className="actions-link orders-link"
                >
                  Orders
                </div>
                <div
                  ref={transactionsInvoiceLinkRef}
                  onClick={handleTransactionsInvoiceLinkClick}
                  className="actions-link transactions-invoice-link"
                >
                  Transactions Invoice
                </div>
              </div>
            </div>
            {/* Add the cashflows total section when page is cashflows */}
            {pageContent === "cashflows" ? (
              <div className="total-cashflow-section">
                <div className="total-cashflow-section-title">
                  Total Cashflow
                </div>
                <div className="total-cashflow-section-body">
                  NGN{totalCashflowNumber}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* Main Page Section */}
          <div className="transactions--body-inner page-column column-two ">
            {/*  If page is cashflows, display cashflows page*/}
            {pageContent === "cashflows" ? (
              <div className="cashflows-section">
                <CashFlows originalRows={originalRows} />
              </div>
            ) : // If page is orders, display orders page*
            pageContent === "orders" ? (
              <div className="orders-section">
                <Orders />
              </div>
            ) : (
              //  Else If page is transaction invoices, display transaction invoices page
              <div className="transactions-invoice-section">
                <TransactionInvoiceTable
                  searched={searched}
                  setSearched={setSearched}
                  originalRows={originalRows}
                  resultsPerPage={resultsPerPage}
                  handleTableRowViewClick={handleTableRowViewClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
