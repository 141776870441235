import React, { useState } from "react";
import "./EditCardDetails.css";

const userCardDetails = {
  cardType: "mastercard",
  cardNumber: "1234456767897653",
  cardExpirationMonth: "01",
  cardExpirationYear: "19",
};
function EditCardDetails() {
  // Functions, States and Variables
  // States
  const [currentCardDetails, setCurrentCardDetails] = useState(userCardDetails);
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [cardExpirationMonth, setCardExpirationMonth] = useState("MM");
  const [cardExpirationYear, setCardExpirationYear] = useState("YY");

  const cardMonthArray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const cardYearArray = ["22", "23", "24", "25", "26", "27", "28", "29", "30"];

  // Credit Card Number Format Function
  const cc_format = function (value) {
    // handles Check on input to allow only digits
    setCardNumber(value);
    // let v = value
    //   .replace(/\s+/g, "")
    //   .replace(/[^0-9]/gi, "")
    //   .replace(/{[^}]*}/g, "");

    // let matches = v.match(/\d{4,16}/g);
    // let match = matches && (matches[0] || "");
    // let parts = [];
    // let len = match && match.length;
    // for (let i = 0; i < len; i += 4) {
    //   parts.push(match.substring(i, i + 4));
    // }

    // if (parts.length) {
    //   setCardNumber(parts.join(" "));
    // } else {
    //   setCardNumber(value);
    // }
  };

  // Checks if a value is a didit and digit only
  const checkDigit = function (value, setValue) {
    // Replaces all non digits with an emptyy string
    // let formattedValue =
    //   value && value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    // Sets the value to its respective dictated state value
    // setValue(formattedValue);
    setValue(value);
  };

  // Handle CVV change to check if value is a digit only
  const handleCardCVVChange = function (value) {
    checkDigit(value, setCardCVV);
  };

  // Handle Edit Card Details Data Save
  const handleEditCardDetailsDataSave = function (e) {
    const successMessageContainer = document.querySelector(
      ".edit-card-details-change-success-message"
    );

    if (
      nameOnCard !== "" &&
      cardNumber !== "" &&
      cardCVV !== "" &&
      cardExpirationMonth !== "" &&
      cardExpirationYear !== ""
    ) {
      e.preventDefault();
      const resultData = {
        nameOnCard,
        cardNumber,
        cardCVV,
        cardExpirationMonth,
        cardExpirationYear,
      };

      successMessageContainer.classList.remove("none");
      setTimeout(() => {
        successMessageContainer.classList.add("none");
      }, 10000);
    }
  };
  return (
    <div className="edit-card-details-container">
      <div className="edit-card-details-container--inner">
        {/*Profile Section */}
        <div className="edit-card-details--row">
          <div className="section-title">Billing Card</div>
          <div className="horizontal-line"></div>

          <div className="current-card-details-container">
            {/* Card Number */}
            <div className="current-card-number-wrapper">
              <div className="current-card-number-title card-details-title">
                CURRENT CARD NUMBER:
              </div>

              <div className="current-card-number-value card-details-value">
                {currentCardDetails.cardType === "mastercard"
                  ? "MasterCard"
                  : "Visa"}{" "}
                - XXXX-XXXX-XXXX-{currentCardDetails.cardNumber.slice(-4)}
              </div>
            </div>
            <div className="current-card-expiration-date-wrapper">
              <div className="current-card-expiration-date-title card-details-title">
                EXPIRATION DATE:
              </div>

              <div className="current-card-expiration-date-value card-details-value">
                {currentCardDetails.cardExpirationMonth}/20
                {currentCardDetails.cardExpirationYear}
              </div>
            </div>
          </div>
        </div>

        {/* Edit Card Details */}
        <div className="edit-card-details--row edit-card-details-section">
          <div className="section-title">Edit Card Details</div>
          <div className="horizontal-line"></div>

          {/* Edit Card Details Form */}
          <div className="edit-card-details-section---form profile-section--form">
            <form>
              <div className="edit-card-details-form--row">
                {/* Card Number */}
                <div className="form-group mb-3">
                  <label htmlFor="card-number-input" className="form-label">
                    CARD NUMBER:
                  </label>
                  <input
                    type="text"
                    className="cart-form-control form-control"
                    id="card-number-input"
                    aria-describedby="cardNumberInput"
                    value={cardNumber}
                    onChange={(e) => cc_format(e.target.value)}
                    required
                  />
                </div>

                {/* Card Expiration Date */}
                <div className="sub-form-group mb-3">
                  <label htmlFor="card-expiration-month" className="form-label">
                    EXPIRATION DATE:
                  </label>

                  {/* Card Expiration Select SYear */}
                  <div className="card-expiration-select-section">
                    {/* Month Expiration Select Section */}
                    <select
                      className="cart-form-control form-control expiration-date-dropdown"
                      id="card-expiration-month"
                      aria-describedby="cardExpirationMonth"
                      value={cardExpirationMonth}
                      onChange={(e) => setCardExpirationMonth(e.target.value)}
                      required
                    >
                      <option disabled value="MM">
                        MM
                      </option>
                      {cardMonthArray.map((monthValue) => (
                        <option key={monthValue} value={monthValue}>
                          {monthValue}
                        </option>
                      ))}
                    </select>

                    {/*Year Expiration Select Section */}
                    <select
                      className="cart-form-control form-control expiration-date-dropdown"
                      id="card-expiration-year"
                      aria-describedby="cardExpirationYear"
                      value={cardExpirationYear}
                      onChange={(e) => setCardExpirationYear(e.target.value)}
                      required
                    >
                      <option disabled value="YY">
                        YY
                      </option>
                      {cardYearArray.map((yearValue) => (
                        <option key={yearValue} value={yearValue}>
                          {yearValue}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Name on card */}
                <div className="form-group mb-3">
                  <label htmlFor="name-on-card-input" className="form-label">
                    NAME ON CARD:
                  </label>
                  <input
                    type="text"
                    className="cart-form-control form-control"
                    id="name-on-card-input"
                    aria-describedby="nameOnCardInput"
                    value={nameOnCard}
                    onChange={(e) => setNameOnCard(e.target.value)}
                    required
                  />
                </div>

                {/* Sub group Form Group with CVV and Expiration Date */}
                <div className="sub-form-group mb-3">
                  {/* Card CVV input */}
                  <div className="sub-form-group mb-3 card-cvv-input-section">
                    <label htmlFor="card-cvv-input" className="form-label">
                      CVV:
                    </label>
                    <input
                      type="text"
                      className="cart-form-control form-control"
                      id="card-cvv-input"
                      aria-describedby="cardCvvInput"
                      maxLength={3}
                      value={cardCVV}
                      onChange={(e) => handleCardCVVChange(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* Save Button */}
              <div className="save-button">
                <button onClick={handleEditCardDetailsDataSave}>SAVE</button>
              </div>

              {/* Success Message Container */}
              <div className="edit-card-details-change-success-message change-success-message none">
                <p>You changes have been successfully made.</p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCardDetails;
