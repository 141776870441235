import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "../LandingPage/Footer/Footer";
import "./Artwork.css";
import cartIcon from "../../images/cart-icon.svg";
import likeIcon from "../../images/like-icon.svg";
import eyeIcon from "../../images/eye-icon.svg";
import zoomIcon from "../../images/zoom-icon.svg";
import facebookIcon from "../../images/facebook.svg";
import twitterIcon from "../../images/twitter.svg";
import arrowLeft from "../../images/left-chevron.svg";
import arrowRight from "../../images/right-chevron.svg";
import Modal from "react-bootstrap/Modal";
import Header from "../LandingPage/Header/Header";
import { getProductById } from "../../redux/actions/productAction";
import {
  addProductToCart,
  deleteProductFromCart,
} from "../../redux/actions/orderActions";
import { useSelector, useDispatch } from "react-redux";
import CurrencyContext from "../../context/currencyContext";
import { displayPrice } from "../../utils/displayPrice";
import { loginUser } from "../../redux/actions/authActions";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import ErrorHandler from "../error/ErrorHandler";

function createLikableArtworksData(id, artworkName, artistName, displayImgSrc) {
  return {
    id,
    artworkName,
    artistName,
    displayImgSrc,
  };
}

// function createArtworkData(
//   id,
//   artworkName,
//   artworkPrice,
//   artworkCategory,
//   artworkSpecs,
//   artworkLikes,
//   artworkViews,
//   aboutArt,
//   aboutArtHouse,
//   mainDisplayImgSrc,
//   otherArtistArtwork
// ) {
//   return {
//     id,
//     artworkName,
//     artworkPrice,
//     artworkCategory,
//     artworkSpecs,
//     artworkLikes,
//     artworkViews,
//     aboutArt, // A short text about the artwork
//     aboutArtHouse, // A short OPTIONAL text about the arthouse
//     mainDisplayImgSrc, //array of artwork images for display
//     otherArtistArtwork, //array of other artworks by artist
//   };
// }

// const originalRows = [
//   createArtworkData(
//     1,
//     "Guernica",
//     "650000",
//     "Painting",
//     {
//       width: "20",
//       height: "18",
//       inches: "0.4",
//       frame: "wooden",
//       others: "Canvas print",
//     },
//     "246",
//     "3127",
//     // About Art String
//     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus id fugit ratione nulla! Debitis, ea. Providentaccusamus architecto magni molestias, cumque doloremque distinctio eligendi officia aperiam iste, ex at labore. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis error aspernatur delectus cumque ullam reprehenderit expedita, officia ipsam dolores id maiores. Quaerat natus aliquid eius provident, quas illo. In, alias.",

//     // About ArtHouse String
//     "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus id fugit ratione nulla! Debitis, ea. Providentaccusamus architecto magni molestias, cumque doloremque distinctio eligendi officia aperiam iste, ex at labore.",

//     ["Pic 2015-01-23 17-42-22.jpg", "register2.jpg", "register.jpg"],
//     [
//       {
//         artworkName: "La vie",
//         curatorName: "Dan Adeneye",
//         artworkPrice: "800000",
//         displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
//       },
//       {
//         artworkName: "Salvador mundi",
//         curatorName: "John Doe",
//         artworkPrice: "950000",
//         displayImgSrc: "register.jpg",
//       },
//       {
//         artworkName: "Woman with a Parasol",
//         curatorName: "Bill Smith",
//         artworkPrice: "390000",
//         displayImgSrc: "register2.jpg",
//       },
//     ]
//   ),
// ];

const likeableArtworksRows = [
  createLikableArtworksData(
    1,
    "Guernica",
    "Picasso",
    "Pic 2015-01-23 17-42-22.jpg"
  ),
  createLikableArtworksData(
    2,
    "The Water Lily Pond",
    "Claude Monet",
    "register.jpg"
  ),
  createLikableArtworksData(3, "The weeping woman", "Picasso", "register2.jpg"),
  createLikableArtworksData(
    4,
    "The last supper",
    "Da Vinci",
    "Pic 2015-01-23 17-42-22.jpg"
  ),
  createLikableArtworksData(5, "la Reve", "Picasso", "register.jpg"),
];

function Artwork() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  let { user, slug } = useParams();
  const artwork = useSelector((state) => state.products.artwork);

  const [product, setProduct] = useState({});
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const handleClose = () => {
    setShow(false);
    setErrorHandlerObj({ hasError: false, message: "" });
  };
  const handleShow = () => setShow(true);
  const [userDetails, setUser] = useState({ email: "", password: "" });
  // Functions, States and Variables
  // const { artistName, artworkName } = useParams();

  // const [originalArtworkRows, setOriginalArtworkRows] = useState(originalRows);
  // const [user, setUser] = useState([]);
  // const [slug, setSlug] = useState([]);

  // const [otherArtworkByArtist, setOtherArtworkByArtist] = useState(
  //   originalArtworkRows[0].otherArtistArtwork
  // );

  //   console.log(originalArtworkRows[0].displayImgSrcc);

  const [otherLikableArtworks, setOtherLikableArtworks] =
    useState(likeableArtworksRows);
  const [artworkAddedToCart, setArtworkAddedToCart] = useState(false);
  const [artworkAddedToFavorites, setArtworkAddedToFavorites] = useState(false);
  const [presentImageIndex, setPresentImageIndex] = useState(0);
  const [showImageDetailsModal, setShowImageDetailsModal] = useState(false);

  const handleArtworkdetailsModalClose = () => setShowImageDetailsModal(false);
  const handleArtworkdetailsModalShow = () => setShowImageDetailsModal(true);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  //   Functions
  //   Handle Add to cart
  const handleAddToCart = () => {
    // console.log("ggg", product);
    if (!artworkAddedToCart) {
      dispatch(addProductToCart({ id: product._id }));
    } else {
      dispatch(deleteProductFromCart({ id: product._id }));
    }
    setArtworkAddedToCart(!artworkAddedToCart);
  };

  // const login = () => {
  //   console.log('clicked')
  // }

  const onSubmit = (e) => {
    setErrorHandlerObj({ hasError: false, message: "" });
    if (user.email !== "" && user.password !== "") {
      e.preventDefault();
      const userData = {
        email: userDetails.email,
        password: userDetails.password,
      };
      // dispatch(loginUser(userData));
      dispatch(loginUser(userData, navigate, setErrorHandlerObj, setIsLoading));
    }
  };
  //   Handle Add to favorites
  const handleAddToFavorites = function () {
    const favoritesLikeIcon = document.querySelector(
      ".add-to-favorites-container button img"
    );
    if (!artworkAddedToFavorites) {
      console.log("Artwork added to favorites");
      favoritesLikeIcon.classList.add("added-to-favorites");
    } else {
      console.log("Artwork removed to favorites");
      favoritesLikeIcon.classList.remove("added-to-favorites");
    }
    setArtworkAddedToFavorites(!artworkAddedToFavorites);
  };

  // USEEFFECT
  useEffect(() => {
    //   Handles the hover effect on the curators box
    // otherLikableArtworks.map((row, i) => {
    //   document
    //     .getElementById(`likeable-artwork-box-${i + 1}`)
    //     .addEventListener("mouseover", (e) => {
    //       e.stopPropagation();
    //       if (e.target.tagName === "IMG" || e.target.tagName === "DIV") {
    //         //   Remove the hidden class from the view box section
    //         e.target.parentElement.lastChild.classList.remove("hidden");
    //       }
    //     });
    //   document
    //     .getElementById(`likeable-artwork-box-${i + 1}`)
    //     .addEventListener("mouseleave", (e) => {
    //       e.stopPropagation();
    //       //   Add the hidden class to the view box section
    //       e.target.lastChild.classList.add("hidden");
    //     });
    // });
  }, []);

  useEffect(() => {
    dispatch(getProductById({ user, slug }));
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setUserLoggedIn(true);
    }
    setProduct(artwork);
  }, []);

  useEffect(() => {
    setProduct(artwork);
  }, [artwork]);

  const handleZoomInOut = function (e) {
    e.stopPropagation();
    if (e.target.classList.contains("zoom")) {
      e.target.previousSibling.classList.toggle("zoomed");
    } else if (e.target.classList.contains("zoom-icon")) {
      e.target.parentElement.previousSibling.classList.toggle("zoomed");
    }
  };
  // Handle Previous DisplayImage Click
  // const handlePreviousImageView = function () {
  //   let arrDisplayImg = [...originalArtworkRows[0].mainDisplayImgSrc];
  //   if (
  //     presentImageIndex > 0 &&
  //     presentImageIndex <= arrDisplayImg.length - 1
  //   ) {
  //     handleImageToDisplay("previous");
  //   }
  //   return false;
  // };

  // Handle Next Display Image Click
  // const handleNextImageView = function () {
  //   let arrDisplayImg = [...originalArtworkRows[0].mainDisplayImgSrc];
  //   if (presentImageIndex < arrDisplayImg.length - 1) {
  //     handleImageToDisplay("next");
  //   }
  //   return false;
  // };

  // const handleImageToDisplay = function (action) {
  //   let arrDisplayImg = [...originalArtworkRows[0].mainDisplayImgSrc];
  //   let imageUrl = arrDisplayImg[presentImageIndex];

  //   if (action && action === "next") {
  //     //   console.log(presentImageIndex, imageUrl, arrDisplayImg);
  //     imageUrl = arrDisplayImg[presentImageIndex + 1];
  //     setPresentImageIndex(presentImageIndex + 1);
  //   } else if (action && action === "previous") {
  //     //   console.log(presentImageIndex, imageUrl, arrDisplayImg);
  //     imageUrl = arrDisplayImg[presentImageIndex - 1];
  //     setPresentImageIndex(presentImageIndex - 1);
  //   }

  //   return require("../../../src/images/" + imageUrl);
  // };

  return (
    <div className="artwork-container">
      {/* <ErrorHandler
        errorHandlerObj={errorHandlerObj}
        className="signin-error-handler"
      /> */}
      <Header />
      {/* Links aat the top of page */}
      <div className="artworks-top-links">
        <div className="artworks-top-links--inner">
          {/* Artworks link */}
          <div className="artworks-link artwork-top-link">Artworks</div>
          {/* Divider */}
          <span className="link-divider">/</span>
          <div className="top-artwork-name">{product.category}</div>

          <span className="link-divider">/</span>
          {/* Name of Artwork */}
          <div className="top-artwork-name">{product.title}</div>
        </div>

        <div className="mobile-view-artwork-sidebar-section">
          <button onClick={handleArtworkdetailsModalShow}>Image Details</button>
          <Modal
            show={showImageDetailsModal}
            onHide={handleArtworkdetailsModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <p className="lead">Image Details</p>
            </Modal.Header>
            <Modal.Body closeButton>
              <div className="artwork-sidebar-section">
                <div className="artwork-siderbar-section--inner">
                  <div className="artwork-details-wrapper">
                    {/* Top Details */}
                    <div className="top-details">
                      {/* Artwork Name */}
                      <div className="details--artwork-name">
                        {product.title}
                      </div>
                      {/* Artist Name */}
                      <div className="details--artist-name">
                        <span className="artist-name-key">Artist:</span>
                        <span className="artist-name-value">
                          {/* {(product.artist.user_id && product.artist.user_id.firstname)
                                ? product.artist.user_id.firstname+ " "+ product.artist.user_id.lastname : "Unavailable"} */}
                        </span>
                      </div>
                    </div>

                    {/* Middle Details */}
                    <div className="middle-details">
                      {/* Category Name */}
                      <div className="details--category-name">
                        <span className="category-name-value">
                          {product.category}
                        </span>
                      </div>
                      {/* Specs */}
                      <div className="details--specs">
                        {/* Title */}
                        <div className="details--specs-title">SPECS</div>
                        <div className="specs-wrapper">
                          <div className="specs--size">
                            <span className="specs--size-title">Size:</span>
                            <span className="specs--size-value">
                              {product.dimension} W x H
                              {/* {originalArtworkRows[0].artworkSpecs.height} H x{" "}
                              {originalArtworkRows[0].artworkSpecs.width} W x{" "}
                              {originalArtworkRows[0].artworkSpecs.inches} in */}
                            </span>
                          </div>
                          <div className="specs--frame">
                            <span className="specs--frame-title">Frame:</span>
                            <span className="specs--frame-value">
                              {/* {originalArtworkRows[0].artworkSpecs.frame} */}
                            </span>
                          </div>
                          <div className="specs--canvas">
                            <span className="specs--canvas-value">
                              {/* {originalArtworkRows[0].artworkSpecs.others} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Bottom Details */}
                    <div className="bottom-details">
                      <CurrencyContext.Consumer>
                        {(currencyContext) => (
                          <div className="details-price">
                            {currencyContext.currencySelected}{" "}
                            {displayPrice(product, currencyContext)}
                          </div>
                        )}
                      </CurrencyContext.Consumer>
                      {/* <div className="details--price">
                        NGN
                        {numberWithCommas(originalArtworkRows[0].artworkPrice)}
                      </div> */}
                    </div>
                  </div>

                  {/* Add to cart */}
                  <div className="add-to-cart-btn-container">
                    {!userLoggedIn ? (
                      <button onClick={handleShow}>LOGIN TO ADD TO CART</button>
                    ) : (
                      <button onClick={handleAddToCart}>
                        {artworkAddedToCart
                          ? "REMOVE FROM CART"
                          : "ADD TO CART"}
                        <img src={cartIcon} alt="cart-btn" />
                      </button>
                    )}
                  </div>

                  {/* Artwork Interactions */}
                  <div className="artwork-interactions">
                    {/* Artwork Views */}
                    <div className="artwork-views artwork-interaction">
                      <img src={eyeIcon} alt="eye-icon" />
                      <span>{product.likeCount}</span>
                    </div>
                    {/* Artwork Likes */}
                    <div className="artwork-likes artwork-interaction">
                      <img src={likeIcon} alt="like-icon" />
                      <span>
                        {/* {numberWithCommas(product.favoriteCount)} */}
                      </span>
                    </div>
                  </div>

                  {/* Add to favourites */}
                  <div className="add-to-favorites-container">
                    <button onClick={handleAddToFavorites}>
                      <img src={likeIcon} alt="cart-btn" />{" "}
                      {/* {product.favorites.length > 0
                        ? "Remove from Favourites"
                        : "Add to Favourites"} */}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>

      {/* Artwork Main Content Container */}
      <div className="artwork-container--inner">
        {/* Main Artwork Section On the left */}
        <main className="artwork-left-container artwork-main-section">
          {/* Artwork Image View Container */}
          <div className="artwork-image-view-container">
            <div className="artwork-image-view-container--inner">
              {/* <img
                src={handleImageToDisplay()}
                alt="artwork-img"
                className="current-img"
              /> */}
              {/* Zoom Icon */}
              <div
                // onClick={handleZoomInOut}
                className="artwork-image-view-container--inner"
              >
                {product.photo && (
                  <img
                    alt="display-img"
                    className="display-img zoom-icon"
                    src={`https://ik.imagekit.io/pg3ftznydl/${product.photo.substring(
                      47
                    )}`}
                  />
                )}
                {/* <img src={zoomIcon} alt="zoom-icon" className="zoom-icon" /> */}
              </div>
              {/* Left Arrow */}
              {/* <div
                onClick={handlePreviousImageView}
                className="arrows arrow-left-container"
              >
                <img src={arrowLeft} alt="left-arrow" />
              </div> */}
              {/* Right Arrow */}
              {/* <div
                onClick={handleNextImageView}
                className="arrows arrow-right-container"
              >
                <img src={arrowRight} alt="right-arrow" />
              </div> */}
            </div>

            {/* Display image overview with next and previos btn */}
            <div className="display-image-overview-container">
              <div className="display-image-overview-container--inner">
                {/* Overview left arrow */}
                {/* <div
                  onClick={handlePreviousImageView}
                  className="overview-left-arrow overview-arrow"
                >
                  <img src={arrowLeft} alt="left-arrow" />
                </div> */}

                <div className="overview-image-boxes">
                  {product.photo && (
                    <img
                      alt="display-img"
                      className="display-img"
                      src={`https://ik.imagekit.io/pg3ftznydl/${product.photo.substring(
                        47
                      )}`}
                    />
                  )}
                </div>

                {/* Overview right arrow */}
                {/* <div
                  onClick={handleNextImageView}
                  className="overview-right-arrow overview-arrow"
                >
                  <img src={arrowRight} alt="right-arrow" />
                </div> */}
              </div>
            </div>
          </div>

          {/* Feel free to share container */}
          <div className="share-image-container">
            <div className="share-title">Feel free to share:</div>
            {/* Facebook Share */}
            <div className="facebook-share ">
              <a href="#">
                <img src={facebookIcon} alt="facebook-icon" />
                Share
              </a>
            </div>
            {/* Twitter Share */}
            <div className="twitter-share ">
              <a href="#">
                <img src={twitterIcon} alt="twitter-icon" />
                Tweet
              </a>
            </div>
          </div>

          {/* Artwork Information */}
          <div className="artwork-information">
            {/* About Art */}
            <div className="artwork-about-art">
              {/* Title */}
              <div className="artwork-about-art--title">ABOUT THE ART</div>
              {/* Content */}
              <div className="artwork-about-art--value">
                <p>{product.description}</p>
              </div>
            </div>

            {/* ArtHouse / gallery Value (optional) */}
            {/* {originalArtworkRows[0].aboutArtHouse && (
              <div className="artwork-about-arthouse"> */}
            {/* Title */}
            {/* <div className="artwork-about-arthouse--title">
                  {"ArtHouse Name"}
                </div> */}
            {/* Content */}
            {/* <div className="artwork-about-arthouse--value">
                  <p>{originalArtworkRows[0].aboutArtHouse}</p>
                </div>
              </div>
            )} */}
          </div>

          {/* Other works by the artist */}
          {/* <div className="other-artist-works">
            <div className="other-artist-works-title">
              Other works by {artistName}
            </div>
            <div className="artwork-box-section"> */}
          {/* Other Works by artist Boxes */}
          {/* <div className="grid-box-layout other-artist-works-grid-box-layout"> */}
          {/* array of JSX items */}
          {/* {otherArtworkByArtist &&
                  otherArtworkByArtist.map((artwork, i) => (
                    <div
                      key={i}
                      className={`grid-artwork-box other-artist-works-grid-artwork-box`}
                      id={`artwork-box-${i + 1}`}
                    >
                      <a
                        href={`/artworks/${artistName}/${artwork.artworkName}`}
                      >
                        <img
                          src={require("../../../src/images/" +
                            artwork.displayImgSrc)}
                          alt="display-img"
                          className="display-img"
                        />
                      </a> */}
          {/* Box Footer Section */}
          {/* <div className="grid-artwork-box-footer"> */}
          {/* Artwork Name */}
          {/* <div className="artwork-name">
                          {artwork.artworkName}
                        </div> */}

          {/* Artist Name */}
          {/* <div className="artist-name">
                          Curator: {artwork.curatorName}
                        </div> */}

          {/* Lower Side of footer */}
          {/* <div className="lower-box-footer"> */}
          {/* Artwork Price */}
          {/* <div className="artwork-price">
                            NGN
                            {numberWithCommas(artwork.artworkPrice)}
                          </div>
                        </div> 
                      </div> 
                    </div>
                  ))} 
              </div> 
            </div> 
          </div> */}

          {/* Other Artworks you might like*/}
          {/* <div className="other-artworks-you-might-like">
            <div className="other-artworks-you-might-like-title">
              Other Artworks you might like
            </div>
            <div className="artwork-box-section"> */}
          {/* Using the current items from the pagination pluugin, loop through given the item as the center as iteration*/}
          {/* <div className="grid-box-layout other-artworks-you-might-like-grid-box-layout"> */}
          {/* GRID LAYOUT */}
          {/* array of JSX items */}
          {/* {otherLikableArtworks &&
                  otherLikableArtworks.map((artwork, i) => (
                    <div
                      key={i}
                      className={`grid-artwork-box other-artworks-you-might-like-grid-artwork-box`}
                      id={`likeable-artwork-box-${i + 1}`}
                    >
                      <img
                        src={require("../../../src/images/" +
                          artwork.displayImgSrc)}
                        alt="display-img"
                        className="display-img"
                      />
                      <div className="likeable-hover-view-section hidden">
                        <a
                          href={`/artworks/${artwork.artistName}/${artwork.artworkName}`}
                        >
                          VIEW
                        </a>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div> */}
        </main>

        {/* Sidebar Artwork Section On the Right */}
        <div className="artwork-right-container artwork-sidebar-section">
          <div className="artwork-siderbar-section--inner">
            <div className="artwork-details-wrapper">
              {/* Top Details */}
              <div className="top-details">
                {/* Artwork Name */}
                <div className="details--artwork-name">{product.title}</div>
                {/* Artist Name */}
                <div className="details--artist-name">
                  <span className="artist-name-key">Artist:</span>
                  <span className="artist-name-value">
                    {/* {(product.artist.user_id && product.artist.user_id.firstname)
                                ? product.artist.user_id.firstname+ " "+ product.artist.user_id.lastname : "Unavailable"} */}
                  </span>
                </div>
              </div>

              {/* Middle Details */}
              <div className="middle-details">
                {/* Category Name */}
                <div className="details--category-name">
                  <span className="category-name-value">
                    {product.category}
                  </span>
                </div>
                {/* Specs */}
                <div className="details--specs">
                  {/* Title */}
                  <div className="details--specs-title">SPECS</div>
                  <div className="specs-wrapper">
                    <div className="specs--size">
                      <span className="specs--size-title">Size:</span>
                      <span className="specs--size-value">
                        {/* {originalArtworkRows[0].artworkSpecs.height} H x{" "}
                        {originalArtworkRows[0].artworkSpecs.width} W x{" "}
                        {originalArtworkRows[0].artworkSpecs.inches} in */}
                        {product.dimension} W x H
                      </span>
                    </div>
                    <div className="specs--frame">
                      {/* <span className="specs--frame-title">Frame:</span>
                      <span className="specs--frame-value">
                        {originalArtworkRows[0].artworkSpecs.frame}
                      </span> */}
                    </div>
                    <div className="specs--canvas">
                      {/* <span className="specs--canvas-value">
                        {originalArtworkRows[0].artworkSpecs.others}
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Bottom Details */}
              <div className="bottom-details">
                <div className="details--price">
                  {/* NGN{numberWithCommas(originalArtworkRows[0].artworkPrice)} */}
                  <CurrencyContext.Consumer>
                    {(currencyContext) => (
                      <div className="details-price">
                        {currencyContext.currencySelected}{" "}
                        {displayPrice(product, currencyContext)}
                      </div>
                    )}
                  </CurrencyContext.Consumer>
                </div>
              </div>
            </div>

            {/* Add to cart */}
            <div className="add-to-cart-btn-container">
              {!userLoggedIn ? (
                <button onClick={handleShow}>LOGIN TO ADD TO CART</button>
              ) : (
                <button onClick={handleAddToCart}>
                  {artworkAddedToCart ? "REMOVE FROM CART" : "ADD TO CART"}
                  <img src={cartIcon} alt="cart-btn" />
                </button>
              )}
            </div>

            {/* Artwork Interactions */}
            <div className="artwork-interactions">
              {/* Artwork Views */}
              <div className="artwork-views artwork-interaction">
                <img src={eyeIcon} alt="eye-icon" />
                <span>{product.likeCount}</span>
              </div>
              {/* Artwork Likes */}
              <div className="artwork-likes artwork-interaction">
                <img src={likeIcon} alt="like-icon" />
                <span>{/* {numberWithCommas(product.favoriteCount)} */}</span>
              </div>
            </div>

            {/* Add to favourites */}
            <div className="add-to-favorites-container">
              <button onClick={handleAddToFavorites}>
                <img src={likeIcon} alt="cart-btn" />{" "}
                {artworkAddedToFavorites
                  ? "Remove from Favourites"
                  : "Add to Favourites"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <p className="lead">LOG IN</p>
          <div className="signin-error-wrapper">
            <ErrorHandler
              errorHandlerObj={errorHandlerObj}
              className="signin-error-handler"
            />
          </div>
        </Modal.Header>
        <Modal.Body closeButton>
          <div
            className={`auth-form-container ${
              errorHandlerObj.hasError ? "container-push" : ""
            }`}
          >
            <form>
              <div className="form-group mb-3">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter Email"
                  onChange={(e) =>
                    setUser({ ...userDetails, email: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={(e) =>
                    setUser({ ...userDetails, password: e.target.value })
                  }
                  required
                />
              </div>

              <div className="submit-button-wrapper">
                <button
                  type="submit"
                  onClick={onSubmit}
                  className="mylongflatbtn mybtn--blue"
                >
                  LOGIN
                </button>
                {isLoading && <LoadingSpinner />}
              </div>

              <p className="lean mt-2">
                <h6>
                  <Link to="/forgotPassword">Forgot Password?</Link>
                </h6>
              </p>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Artwork;
