import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import register from "./../images/register.jpg";
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import Header from "../components/LandingPage/Header/Header";
import Footer from "../components/LandingPage/Footer/Footer";

const ArtistRegister = () => {
  // States and variables
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [artworkLocation, setArtworkLocation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //   Functions
  const handleArtistRegisterSubmit = function (e) {
    let artistRegisterContainer = document.querySelector(".artist-register");
    let emailVerifyContainer = document.querySelector(
      ".verify-email-container"
    );
    // e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
    } else if (
      !Boolean(firstName) ||
      !Boolean(lastName) ||
      !Boolean(email) ||
      //   !Boolean(artworkLocation) ||
      !Boolean(phoneNumber) ||
      !Boolean(password)
    ) {
      // Do not prevent default hence, allow default form check like required take place
      return false;
    } else {
      e.preventDefault();
      const registerData = {
        firstName,
        lastName,
        email,
        phoneNumber,
        artworkLocation,
        password,
      };
      //   Remove the form and bring in the verify email container
      artistRegisterContainer.classList.add("none");
      emailVerifyContainer.classList.remove("none");
      setTimeout(() => {
        navigate("/artist-login");
      }, 10000);
    }
  };

  return (
    <>
      <Header />
      <div className="artist-register mb-5">
        <div className="artist-register--image">
          <img src={register} />
        </div>
        <form>
          <p className="mt-5 mb-3">BASIC INFORMATION</p>
          <div className="form-group mb-3">
            <label for="firstName">First Name</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              aria-describedby="firstName"
              placeholder="Enter First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              aria-describedby="lastName"
              placeholder="Enter Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label for="email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              aria-describedby="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label for="phone">Phone Number</label>
            <input
              type="phone"
              className="form-control"
              id="email"
              aria-describedby="email"
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label for="location">Artwork Location</label>
            <select
              className="form-control"
              id="location"
              value={artworkLocation}
              onChange={(e) => setArtworkLocation(e.target.value)}
              required
            >
              <option value="FCT">FCT</option>
              <option value="Lagos">Lagos</option>
              <option value="Uyo">Uyo</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <label for="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label for="password">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
              required
            />
            <label className="form-check-label" for="exampleCheck1">
              I agree to SnowAfrica's{" "}
              <Link to="/terms" style={{ color: "rgba(20, 20, 255, 0.9)" }}>
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link to="/privacy" style={{ color: "rgba(20, 20, 255, 0.9)" }}>
                Privacy Policy
              </Link>
              .
            </label>
          </div>
          <button
            type="submit"
            onClick={handleArtistRegisterSubmit}
            className="mylongflatbtn mybtn--blue"
          >
            BECOME A MERCHANT
          </button>
          {/* <p className="lean">Forgot Password?</p>
            <p className="lean">Not a member yet? Sign up</p> */}
        </form>
      </div>
      <div className="verify-email-container none">
        <VerifyEmail />
      </div>
      <Footer />
    </>
  );
};

export default ArtistRegister;
