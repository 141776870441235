import React, { useState, useRef } from "react";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import searchIcon from "../../../images/search.png";
import ProductsTable from "./ProductsTable";
import "./Products.css";

function Products() {
  // Variables and states
  function createData(sku, productName, artist, category, viewBtn, deleteBtn) {
    return {
      sku,
      productName,
      artist,
      category,
      viewBtn,
      deleteBtn,
    };
  }

  const rows = [
    createData("Cupcake", 305, 3.7, 67, "VIEW", "Delete"),
    createData("Donut", 452, 25.0, 51, "VIEW", "Delete"),
    createData("Eclair", 262, 16.0, 24, "VIEW", "Delete"),
    createData("Frozen yoghurt", 159, 6.0, 24, "VIEW", "Delete"),
    createData("Gingerbread", 356, 16.0, 49, "VIEW", "Delete"),
    createData("Honeycomb", 408, 3.2, 87, "VIEW", "Delete"),
    createData("Ice cream sandwich", 237, 9.0, 37, "VIEW", "Delete"),
    createData("Jelly Bean", 375, 0.0, 94, "VIEW", "Delete"),
    createData("KitKat", 518, 26.0, 65, "VIEW", "Delete"),
    createData("Lollipop", 392, 0.2, 98, "VIEW", "Delete"),
    createData("Donak", 452, 25.0, 51, "VIEW", "Delete"),
    createData("Marshmallow", 318, 0, 81, "VIEW", "Delete"),
    createData("Nougat", 360, 19.0, 9, "VIEW", "Delete"),
    createData("Oreo", 437, 18.0, 63, "VIEW", "Delete"),
  ];

  const [originalRows, setOriginalRows] = useState(rows);
  const [sortBy, setSortby] = useState("SKU");
  const [searched, setSearched] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState("10");
  // Refs
  const searchResultTextRef = useRef();

  // Functions
  // REQUEST SEARCH ON ROWS
  const requestSearch = (searchedValue) => {
    const filteredRows = originalRows.filter((row) => {
      return row.sku.toLowerCase().includes(searchedValue.toLowerCase());
    });
    setOriginalRows(filteredRows);
  };

  // Handles backspace press or delete press on search
  const handleKeyDown = function (e) {
    let key = e.keyCode || e.charCode;
    // IF keycode is backspace or delete
    if (key === 8 || key === 46) {
      setSearched(e.target.value);
      requestSearch(e.target.value);
      setOriginalRows(rows);
    }
  };

  // Handle on change or oninput of the search bar
  const handleSearchOnChange = function (e) {
    // Set the search data state to the search value
    setSearched(e.target.value);
    let searchResultText = document.querySelector(".search-result-text");
    // Handle Search Result Text View when search is not !empty
    if (e.target.value !== "") {
      requestSearch(searched);
      searchResultText.classList.remove("none");
    }

    // Remove searched text view when when search is === empty
    if (e.target.value === "") {
      searchResultText.classList.add("none");
    }
  };

  return (
    <div className="products-main">
      <div className="products-main-inner">
        {/* Products Header */}
        <div className="products--header">
          <div className="products--header-inner">
            {/* Sort By Check box */}
            <div className="sort-by products--header-column-one">
              {/* SORT BY */}
              <label
                htmlFor="sort-by"
                className="form-label products-form-label"
              >
                SORT BY:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="sort-by"
                  id="sort-by"
                  aria-describedby="sortBy"
                  className="form-control form-dropdown"
                  onChange={(e) => setSortby(e.target.value)}
                  value={sortBy}
                >
                  <option value="sku">SKU</option>
                  <option value="product-name">PRODUCT NAME</option>
                  <option value="artist">ARTIST</option>
                  <option value="category">CATEGORY</option>
                </select>
              </div>
            </div>

            {/* Search bar */}
            <div className="search-bar products--header-column-two">
              {/* SEARCH BAR */}
              <div className="products-search-bar-section">
                <label
                  htmlFor="search-bar"
                  className="form-label products-form-label"
                >
                  SEARCH FOR PRODUCT:
                </label>
                <div className="form-group form-group-dropdown mb-3">
                  <div className="left-search-icon-section">
                    <img src={searchIcon} alt="search" />
                  </div>
                  <input
                    name="search-bar"
                    id="search-bar"
                    type="search"
                    aria-describedby="searchBar"
                    className="form-control form-search"
                    placeholder="Search..."
                    value={searched}
                    onChange={(e) => {
                      handleSearchOnChange(e);
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>

            {/* Results per page checkbox */}
            <div className="results-per-page products--header-column-three">
              {/* RESULTS PER PAGE */}
              <label
                htmlFor="results-per-page"
                className="form-label products-form-label"
              >
                RESULTS PER PAGE:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="results-per-page"
                  id="results-per-page"
                  aria-describedby="resultsPerPage"
                  className="form-control form-dropdown"
                  onChange={(e) => setResultsPerPage(e.target.value)}
                  value={resultsPerPage}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* SEARCH RESULT TEXT THAT SHOWS WHEN SEARCH VALUE IS NOT EMPTY */}
        <div ref={searchResultTextRef} className="search-result-text none">
          <div className="result-text-row-one">
            <p>Search Result for "{searched}"</p>
          </div>
          <div className="result-text-row-two">
            <p>{originalRows.length} Results</p>
          </div>
        </div>

        {/* Products BODY / TABLE */}
        <div className="products--body">
          <div className="products--body-inner">
            {/* THE PRODUCTS TABLE PASSING IN THE SEARCHED (VALUE AND SETSEARCHED STATE AS PROPS TO BE USED IN THE TABLE COMPONENT) */}
            <ProductsTable
              searched={searched}
              setSearched={setSearched}
              originalRows={originalRows}
              resultsPerPage={resultsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
