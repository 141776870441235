import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../LandingPage/Header/Header";
import "./Layout.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import AccountDetails from "./AccountDetails/AccountDetails";
import Favourites from "./Favourites/Favourites";
import EditBillingInfo from "./EditBillingInfo/EditBillingInfo";
import EditCardDetails from "./EditCardDetails/EditCardDetails";
import PurchaseHistory from "./PurchaseHistory/PurchaseHistory";

function Layout() {
  const navigate = useNavigate();
  // Functions, States and Variables
  // States
  const [presentPage, setPresentPage] = useState("");
  const [category, setCategory] = useState("all");

  //Ref
  const accountDetailsLinkRef = useRef();
  const favouritesLinkRef = useRef();
  const editBillingInfoLinkRef = useRef();
  const editCardDetailsLinkRef = useRef();
  const purchaseHistoryLinkRef = useRef();

  // Functions

  // HANDLE SIDEBAR NAV LINKS
  // Handle Account Details Link Click
  const handleAccountDetailsClick = function () {
    // Set Page Content to Account Details content
    setPresentPage("account-details");
    navigate("/customer/account-details");

    accountDetailsLinkRef.current.classList.add("active-link");
    // Handle color change on active class active
    favouritesLinkRef.current.classList.remove("active-link");
    editBillingInfoLinkRef.current.classList.remove("active-link");
    editCardDetailsLinkRef.current.classList.remove("active-link");
    purchaseHistoryLinkRef.current.classList.remove("active-link");
  };

  // Handle Favourites Link Click
  const handleFavouritesLinkClick = function () {
    // Set Page Content to Favourites content
    setPresentPage("favourites");
    navigate("/customer/favourites");

    favouritesLinkRef.current.classList.add("active-link");
    accountDetailsLinkRef.current.classList.remove("active-link");
    editBillingInfoLinkRef.current.classList.remove("active-link");
    editCardDetailsLinkRef.current.classList.remove("active-link");
    purchaseHistoryLinkRef.current.classList.remove("active-link");
  };

  // Handle Edit Billing Info Link Click
  const handleEditBillingInfoLinkClick = function () {
    // Set Page Content to Edit Billing Info content
    setPresentPage("edit-billing-info");
    navigate("/customer/edit-billing-info");

    editBillingInfoLinkRef.current.classList.add("active-link");
    accountDetailsLinkRef.current.classList.remove("active-link");
    favouritesLinkRef.current.classList.remove("active-link");
    editCardDetailsLinkRef.current.classList.remove("active-link");
    purchaseHistoryLinkRef.current.classList.remove("active-link");
  };

  // Handle Edit Card Details Link Click
  const handleEditCardDetailsLinkClick = function () {
    // Set Page Content to Edit Card Details content
    setPresentPage("edit-card-details");
    navigate("/customer/edit-card-details");

    // Handle color change on active class active
    editCardDetailsLinkRef.current.classList.add("active-link");
    accountDetailsLinkRef.current.classList.remove("active-link");
    favouritesLinkRef.current.classList.remove("active-link");
    editBillingInfoLinkRef.current.classList.remove("active-link");
    purchaseHistoryLinkRef.current.classList.remove("active-link");
  };

  // Handle Purchase History Link Click
  const purchaseHistoryLinkClick = function () {
    // Set Page Content to Purchase History content
    setPresentPage("purchase-history");
    navigate("/customer/purchase-history");
    // Handle color change on active class active
    purchaseHistoryLinkRef.current.classList.add("active-link");
    accountDetailsLinkRef.current.classList.remove("active-link");
    favouritesLinkRef.current.classList.remove("active-link");
    editBillingInfoLinkRef.current.classList.remove("active-link");
    editCardDetailsLinkRef.current.classList.remove("active-link");
  };

  //   UseEffect
  useEffect(() => {
    // Check Route too determine page content state on reload and  Handle Active Class Placement on first load or reload
    const location = window.location.href;
    // If route is ACCOUNT DETAILS
    if (location.includes("/account-details")) {
      setPresentPage("account-details");
      accountDetailsLinkRef.current.classList.add("active-link");
    } else if (location.includes("/favourites")) {
      // If route is FAVOURITES
      setPresentPage("favourites");
      favouritesLinkRef.current.classList.add("active-link");
    } else if (location.includes("/edit-billing-info")) {
      // If route is EDIT BILLING INFO
      setPresentPage("edit-billing-info");
      editBillingInfoLinkRef.current.classList.add("active-link");
    } else if (location.includes("/edit-card-details")) {
      // If route is EDIT CARD DETAILS
      setPresentPage("edit-card-details");
      editCardDetailsLinkRef.current.classList.add("active-link");
    } else if (location.includes("/purchase-history")) {
      // If route is PURCHASE HISTORY
      setPresentPage("purchase-history");
      purchaseHistoryLinkRef.current.classList.add("active-link");
    }
  }, []);

  return (
    <div className="user-dashboard-layout-container">
      <Header />
      <div className="user-dashboard-layout-container--inner">
        {/* Sidebar / Left Section */}
        <div className="dashboard-sidebar dashboard-left-section">
          {/* Title Sort */}
          <div className="title-sort user-dashboard-title-sort">
            USER DASHBOARD
          </div>
          {/*  User Dashboard Box Section*/}
          <div className="user-dashboard-sidebar-box">
            {/* USer Dashboard Sidebar Nav Section */}
            <div className="user-dashboard-sidenav-bar">
              <div className="user-dashboard-sidenav-bar--inner">
                {/* Nav Section */}
                <div className="user-dashboard-nav-wrapper">
                  {/* Account Details */}
                  <div
                    ref={accountDetailsLinkRef}
                    onClick={handleAccountDetailsClick}
                    className="actions-link account-details-link"
                  >
                    Account Details
                  </div>

                  {/* Favourites */}
                  <div
                    ref={favouritesLinkRef}
                    onClick={handleFavouritesLinkClick}
                    className="actions-link favourites-link"
                  >
                    Favourites
                  </div>

                  {/* Edit billing Info */}
                  <div
                    ref={editBillingInfoLinkRef}
                    onClick={handleEditBillingInfoLinkClick}
                    className="actions-link edit-billing-info-link"
                  >
                    Edit billing Info
                  </div>

                  {/* Edit Card Details */}
                  <div
                    ref={editCardDetailsLinkRef}
                    onClick={handleEditCardDetailsLinkClick}
                    className="actions-link edit-card-details-link"
                  >
                    Edit Card Details
                  </div>

                  {/* Purchase History */}
                  <div
                    ref={purchaseHistoryLinkRef}
                    onClick={purchaseHistoryLinkClick}
                    className="actions-link puchase-history-link"
                  >
                    Purchase History
                  </div>
                </div>

                {/* Artist Box View navigation  */}
                <div className="user-dashboard-category-navigation">
                  {/* Style checkbox */}
                  {/* {presentPage !== "edit-card-details" && (
                    <div className="user-dashboard-category-dropdown">
                      <label
                        htmlFor="user-dashboard-category-input"
                        className="form-label user-dashboard-category-label"
                      >
                        CATEGORY:
                      </label>
                      <div className="form-group form-group-dropdown mb-3">
                        <div className="left-arrow-section">
                          <img src={arrowUp} alt="arrow-up" />
                          <img src={arrowDown} alt="arrow-down" />
                        </div>
                        <select
                          name="user-dashboard-category"
                          id="user-dashboard-category-input"
                          aria-describedby="userDashboardCategory"
                          className="form-control form-dropdown"
                          onChange={(e) => setCategory(e.target.value)}
                          style={{ cursor: "pointer" }}
                          value={category}
                        >
                          <option defaultValue="all">ALL</option>
                        </select>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar / Left Section */}
        <div className="dashboard-main-container dashboard-right-section">
          {presentPage === "account-details" ? (
            <AccountDetails />
          ) : presentPage === "favourites" ? (
            <Favourites />
          ) : presentPage === "edit-billing-info" ? (
            <EditBillingInfo />
          ) : presentPage === "edit-card-details" ? (
            <EditCardDetails />
          ) : (
            <PurchaseHistory />
          )}
        </div>
      </div>
    </div>
  );
}

export default Layout;
