import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Top from "../components/LandingPage/Header/Top";
// import Top from "../components/Top/Top.jsx";
import Navbar from "../components/ArtistDashboard/Navbar/Navbar.jsx";
import UploadArt from "../components/ArtistDashboard/UploadArt/UploadArt.jsx";
import Biodata from "../components/ArtistDashboard/Biodata/Biodata.jsx";
import PaymentInfo from "../components/ArtistDashboard/PaymentInfo/PaymentInfo.jsx";
import Products from "../components/ArtistDashboard/Products/Products.jsx";
import Transactions from "../components/ArtistDashboard/Transactions/Transactions.jsx";

function ArtistDashboard() {
  const location = useLocation();
  // Variable for the dynamic pages
  const [activePage, setActivePage] = useState("upload-art");

  useEffect(() => {
    // Set state to determine the variables based on route of the dynamic pages
    if (location.pathname === "/artist-dashboard/upload-art") {
      setActivePage("upload-art");
    } else if (location.pathname === "/artist-dashboard/biodata") {
      setActivePage("biodata");
    } else if (location.pathname === "/artist-dashboard/payment-information") {
      setActivePage("payment-information");
    } else if (location.pathname === "/artist-dashboard/products") {
      setActivePage("products");
    } else if (location.pathname === "/artist-dashboard/transactions") {
      setActivePage("transactions");
    }
  }, [location.pathname]);

  return (
    <div className="artist-dashboard">
      <Top />
      <Navbar />
      {/* Dynamic content based on the route(active navbar) */}
      <div>
        {activePage === "upload-art" ? (
          <UploadArt />
        ) : activePage === "biodata" ? (
          <Biodata />
        ) : activePage === "payment-information" ? (
          <PaymentInfo />
        ) : activePage === "products" ? (
          <Products />
        ) : (
          <Transactions />
        )}
      </div>
    </div>
  );
}

export default ArtistDashboard;
