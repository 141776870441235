import { api } from '../../api/config';
import axios from 'axios';
import { getMe } from './profilesAction';
import {
  CLICKED_ORDER,
  ADDED_ORDER_ITEM_TO_ORDER_CART,
  GET_ALL_ORDER,
  ORDER_CREATED,
  DELETED_ORDER,
  CREATING_ORDER,
} from '../Constants';
import { toggleLoadingOff, toggleLoadingOn } from './productAction';

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["accesstoken"] = token;

export const addProductToCart = data => dispatch => {
  axios
    .post(api + 'snowafrica/user/addToCart/', data)
    .then(res => {
      dispatch(getMe());
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};

export const deleteProductFromCart = data => dispatch => {
  axios
    .post(api + 'snowafrica/user/removeFromCart', data)
    .then(res => {
      dispatch(getMe());
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};

export const findOrderByQuery = data => dispatch => {
  axios
    .post(api + '', data)
    .then(res => {
      console.log('RESPONSE', res.data);
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};

export const getAllProductInOrder = data => dispatch => {
  axios
    // .get( api + "snowafrica/order/all")
    .post(api + 'snowafrica/order', data)
    .then(res => {
      // console.log("RESPONSE FROM GETTING ALL PRODUCTS", res.data)
      dispatch({
        type: GET_ALL_ORDER,
        payload: res.data.data,
      });
      dispatch(toggleLoadingOff());
    })
    .catch(err => {
      console.log('ERROR FROM GETTING ALL PRODUCTS', err);
    });
};

export const orderNavbarClicked = () => dispatch => {
  dispatch({
    type: CLICKED_ORDER,
  });
};

export const removeItemsInOrder = data => dispatch => {
  data.items.map(one => {
    let item = {
      id: one.product,
    };
    axios
      .post(api + 'snowafrica/user/removeFromCart', item)
      .then(res => {
        dispatch(getMe());
        dispatch(toggleLoadingOff());
      })
      .catch(err => {
        console.log('ERROR', err);
        dispatch(deleteProductFromCart(item));
      });
  });
};

/** export const createOrder = data => dispatch => {
    dispatch(toggleLoadingOn())
    dispatch({
        type: CREATING_ORDER
    })
    axios   
        .post( api + "snowafrica/order/create", data)
        .then(res => {
            dispatch({
                type: ORDER_CREATED,
                payload: res.data
            });
            //console.log(res.data);
            //return res.data;
            dispatch(getAllProductInOrder())
            //dispatch(removeItemsInOrder(data))
            //dispatch(toggleLoadingOff())
        })
        .catch( err => {
            console.log("ERROR", err)
        })
} */

export const createOrder = 
(
  data,
  navigate,
  setErrorHandlerObj,
  setIsLoading
) => dispatch =>
  new Promise((resolve, reject) => {
    setIsLoading(true);
    dispatch(toggleLoadingOn());
    dispatch({
      type: CREATING_ORDER,
    });
    axios
      .post(api + 'snowafrica/order/create', data)
      .then(res => {
        dispatch({
          type: ORDER_CREATED,
          payload: res.data,
        });
        //console.log(res.data);
        //return res.data;
        dispatch(getAllProductInOrder());
        setIsLoading(false);
        navigate('/order-failed');
        //dispatch(removeItemsInOrder(data))
        //dispatch(toggleLoadingOff())
      })
      .catch(err => {
        console.log('ERROR', err);
        setIsLoading(false);
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
      });
  });

/**synccreateOrder = (data) => {
    toggleLoadingOn()
    axios   
        .post( api + "snowafrica/order/create", data)
        .then(res => {
            return res.data;
            //console.log(res.data);
            //return res.data;
            getAllProductInOrder()
            //dispatch(removeItemsInOrder(data))
            //dispatch(toggleLoadingOff())
        })
        .catch( err => {
            console.log("ERROR", err)
        })
}*/

export const deleteOrder = data => dispatch => {
  dispatch(toggleLoadingOn());
  axios
    .post(api + 'snowafrica/order/delete', data)
    .then(res => {
      dispatch(getAllProductInOrder());
    })
    .catch(err => {
      console.log(err);
    });
};

export const updateOrderCart = (data) => dispatch => {

  console.log('kk123k', data)
  dispatch({
      type: ADDED_ORDER_ITEM_TO_ORDER_CART,
      payload: data
  })
}
