import React from "react";
import CashflowsTable from "./CashflowsTable";

function Cashflows({ originalRows }) {
  // Variables and states
  return (
    <div className="cashflows--inner">
      <CashflowsTable originalRows={originalRows} />
    </div>
  );
}

export default Cashflows;
