import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import NewsletterForm from "./NewsletterForm";
import useDisplayMessage from "../../../utils/useDisplayMessage";

const NewsletterSubscribe = () => {
  // Functions, States and Variables
  const URL =
    "https://africa.us10.list-manage.com/subscribe/post?u=2cf695f4d7b11be81e9042a36&amp;id=d66e24acc8";

  // Hooks
  const {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  } = useDisplayMessage();

  return (
    <MailchimpSubscribe
      url={URL}
      render={({ subscribe, status, message }) => {
        return (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
            successHandlerObj={successHandlerObj}
            setSuccessHandlerObj={setSuccessHandlerObj}
            errorHandlerObj={errorHandlerObj}
            setErrorHandlerObj={setErrorHandlerObj}
          />
        );
      }}
    />
  );
};

export default NewsletterSubscribe;
