import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import searchImg from "../../../images/search.png";
import navBurger from "../../../images/landing-page/nav-burger.svg";
import navClose from "../../../images/landing-page/nav-close.svg";
import Top from "./Top";

function Header() {
  // Function, States and variables
  // Handle Open Top Navbar In TABLET AND MOBILE View
  const handleBottomNavbarOpen = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon--bottom");
    const openNavbarIcon = document.querySelector(".navburger-icon--bottom");
    const bottomNavbarTabletModal = document.querySelector(
      ".header--bottom--left--tablet-modal"
    );
    // Toggle Navbar Icons
    closeNavbarIcon.classList.remove("none");
    openNavbarIcon.classList.add("none");

    // handle top navbar modal open
    bottomNavbarTabletModal.classList.remove("none");
    //
    //
    // Close the navbar dropdown in top section
    const topNavbarModal = document.querySelector(
      ".header--top--right--tablet-modal"
    );
    const closeTopNavbarIcon = document.querySelector(".navclose-icon");
    const openTopNavbarIcon = document.querySelector(".navburger-icon");
    // handle top navbar modal open
    topNavbarModal.classList.add("none");
    closeTopNavbarIcon.classList.add("none");
    openTopNavbarIcon.classList.remove("none");
  };

  // Handle Close Top Navbar In TABLET AND MOBILE View
  const handleBottomNavbarClose = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon--bottom");
    const openNavbarIcon = document.querySelector(".navburger-icon--bottom");
    const topNavbarTabletModal = document.querySelector(
      ".header--bottom--left--tablet-modal"
    );

    // Toggle Navbar Icons
    closeNavbarIcon.classList.add("none");
    openNavbarIcon.classList.remove("none");
    // handle top navbar modal open
    topNavbarTabletModal.classList.add("none");
  };
  return (
    <div className="header-container">
      <Top />

      {/* Header Navbar */}
      <div className="header--navbar">
        <div className="header--navbar--inner">
          {/* Header Navbar Left */}
          <div className="header--navbar--left">
            {/* Paintings */}
            <div className="paintings-link categories-link">
              <Link to="/artworks/Painting">PAINTING</Link>
            </div>
            {/* Prints */}
            <div className="prints-link categories-link">
              <Link to="/artworks/Prints">PRINTS</Link>
            </div>
            {/* Drawings */}
            <div className="drawings-link categories-link">
              <Link to="/artworks/Drawing">DRAWINGS</Link>
            </div>
            {/* Photograhy */}
            <div className="photography-link categories-link">
              <Link to="/artworks/Photography">PHOTOGRAPHY</Link>
            </div>
            {/* Landscape */}
            {/* <div className="landscape-link categories-link">
              <Link to="/artworks/Sculpture">SCULPTURE</Link>
            </div> */}
          </div>

          {/* <= BOTTOM NAVBAR TABLET VIEW SECTION */}
          <div className="header--navbar--left--tablet">
            <div
              // onClick={handleNavbarBurgerOpen}
              className="bottom-navbar-open-navbar-burger"
            >
              <img
                onClick={handleBottomNavbarOpen}
                src={navBurger}
                alt=""
                className="navburger-icon--bottom"
                style={{
                  transform: "rotate(180deg)",
                }}
              />
              <img
                onClick={handleBottomNavbarClose}
                src={navClose}
                alt=""
                className="navclose-icon--bottom none"
              />
            </div>
            {/* Tablet Top navbar */}
            <div className="header--bottom--left--tablet-modal none">
              {/* Register Link */}
              <div className="bottom--navbar-link tablet-paintings-link ">
                <Link to="/gallery/sort/paintings">PAINTING</Link>
              </div>
              {/* Prints */}
              <div className="bottom--navbar-link tablet-prints-link">
                <Link to="/gallery/sort/prints">PRINTS</Link>
              </div>
              {/* Drawings */}
              <div className="bottom--navbar-link tablet-drawings-link">
                <Link to="/gallery/sort/drawings">DRAWINGS</Link>
              </div>
              {/* Photograhy */}
              <div className="bottom--navbar-link tablet-photography-link">
                <Link to="/gallery/sort/photography">PHOTOGRAPHY</Link>
              </div>
              {/* Landscape */}
              {/* <div className="bottom--navbar-link tablet-landscape-link">
                <Link to="/gallery/sort/landscape">LANDSPACE</Link>
              </div> */}
              {/* MOBILE ADDITIONAL LINKS */}
              <div className="header--bottom--left--mobile-modal-links">
                {/* Blog link */}
                <div className="bottom--navbar-link mobile-blog-link">
                  <Link target="_blank" to="/blogs">
                    BLOG
                  </Link>
                </div>
                {/* Podcasts Link */}
                <div className="bottom--navbar-link mobile-podcasts-link">
                  <Link target="_blank" to="/podcasts">
                    PODCASTS
                  </Link>
                </div>
                <div className="bottom--navbar-link mobile-podcasts-link">
                  <Link to="/contact-us">CONTACT US</Link>
                </div>
                {/* Showcase Link */}
                {/* <div className="bottom--navbar-link mobile-showcase-link">
                  <Link target="_blank" to="#">
                    SHOWCASE
                  </Link>
                </div> */}
              </div>
              {/* XS MOBILE ADDITIONAL LINKS */}
              {/* <div className="header--bottom--left--xs-mobile-modal-links">
                <div className="bottom--navbar-link mobile-news-link">
                  <Link target="_blank" to="#">
                    NEWS
                  </Link>
                </div>
              </div> */}
            </div>
          </div>

          {/* Header Navbar Middle */}
          <div className="header--navbar--middle">
            {/* Blog Link */}
            {/* <div className="blog-link other-platforms-link">
              <Link to="/blogs">
                BlOG
              </Link>
            </div>
            <div className="divider-line">|</div> */}
            {/* Podcasts Link */}
            {/* <div className="podccasts-link other-platforms-link">
              <Link to="/podcasts">
                PODCASTS
              </Link>
            </div>
            <div className="divider-line">|</div> */}
            {/* Showcase Link */}
            {/* <div className="showcase-link other-platforms-link"> */}
            {/* <Link to="#">
                SHOWCASE
              </Link> */}
            {/* </div> */}
          </div>

          {/* Header Navbar Right */}
          <div className="header--navbar--right">
            <div className="blog-link other-platforms-link">
              <Link to="/blogs">BLOG</Link>
            </div>
            <div className="divider-line">|</div>
            {/* Podcasts Link */}
            <div className="podccasts-link other-platforms-link">
              <Link to="/podcasts">PODCASTS</Link>
            </div>
            <div className="divider-line">|</div>
            {/* Contact us Link */}
            <div className="podccasts-link other-platforms-link">
              <Link to="/contact-us">CONTACT US</Link>
            </div>
            {/* others */}
            {/* <div className="others-dropdown"> */}
            {/* <select
                name="others"
                id="others"
                className="others-dropdown-input"
              >
                <option value="news">News</option>
              </select> */}
          </div>
          {/* Search bar */}
          {/* <div className="search-bar-section"> */}
          {/* <div className="search-bar-inner"> */}
          {/* <input
                  type="search"
                  placeholder="Search..."
                  name="search"
                  id="search"
                  className="search-bar-input"
                /> */}
          {/* </div> */}
          {/* <div className="search-dropdown"> */}
          {/* <select name="others" id="others" className="others-dropdown">
                  <option value="artists">Artists</option>
                  <option value="paintings">Paintings</option>
                  <option value="prints">Prints</option>
                  <option value="drawings">Drawings</option>
                </select> */}
          {/* </div>
              <div className="search-btn">
                <img src={searchImg} alt="search" className="search-icon" /> */}
          {/* </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
