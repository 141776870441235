import {
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_FEATURED,
  GET_SAMPLE_PRODUCTS,
  SEE_ARTWORK,
  CLEAR_CURRENT_PRODUCTS,
  ADD_PRODUCT_TO_COLLECTION,
  CLEAR_LOADING,
  UPLOAD_PRODUCT,
  EDIT_PRODUCT,
  VIEW_ARTWORK,
  GET_PRODUCTS_AS_PROPS,
  VIEW_COLLECTION,
  COLLECTION_PUSHED,
  GET_STATUS,
  GETTING_ARTWORK_FROM_CURATOR_LIST,
  GET_ALL_PRODUCTS_BY_ADMIN,
  IS_LOADING_DATA_ON,
  IS_LOADING_DATA_OFF,
  COMMENT_SUCCESSFUL,
  UPDATED_CURATOR_LIST,
  SEARCH_ME
} from "../Constants";

const initialState = {
  products: [],
  product:{},
  featured: [],
  sampleProducts: [],
  edit:'',
  search:{},
  properties:{},
  productAsPropsStatus: false,
  mediumSentAsProps:"",
  productAsProps: [],
  artwork: {},
  isLoading: null,
  collection: {},
  status:{},
  productId: "",
  allProducts: [],
  isLoadingData: false,
  commentUpdated: {},
  updatedCuratorList: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case GET_SAMPLE_PRODUCTS:
      return {
        ...state,
        sampleProducts: action.payload
      };
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        artwork: action.payload
      };
    case GET_FEATURED:
      return {
        ...state,
        featured: action.payload
      };
    case ADD_PRODUCT_TO_COLLECTION:
      return {
        ...state,
        isLoading: !action.payload
      };
    case CLEAR_LOADING:
      return {
        ...state,
        isLoading: action.payload
      };
    case CLEAR_CURRENT_PRODUCTS:
      return {
        ...state,
        products: {}
      };
    case UPLOAD_PRODUCT:
      return {
        ...state,
        isLoading: action.payload
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        edit: action.payload
      };
    case SEE_ARTWORK:
      return {
        ...state,
        // artwork: action.payload
      };
    case VIEW_ARTWORK:
      return {
        ...state,
        artwork: action.payload
      };
    case GET_PRODUCTS_AS_PROPS: 
      return {
        ...state,
        properties: action.payload,
        // productAsProps: action.payload,
        productAsPropsStatus: true
      }
    case VIEW_COLLECTION:
      return {
        ...state,
        collection: action.payload
      }
    case COLLECTION_PUSHED:
      return {
        ...state,
        collection: action.payload
      }
    case GET_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case GETTING_ARTWORK_FROM_CURATOR_LIST:
      return {
        ...state,
        artwork: action.payload
      }
    case GET_ALL_PRODUCTS_BY_ADMIN:
      return {
        ...state,
        allProducts: action.payload
      }
    case IS_LOADING_DATA_ON:
      return{
        ...state,
        isLoadingData: true
      }
    case IS_LOADING_DATA_OFF:
      return{
        ...state,
        isLoadingData: false
      }
    case COMMENT_SUCCESSFUL:
      return{
        ...state,
        commentUpdated: action.payload
      }
    case UPDATED_CURATOR_LIST:
      return {
        ...state,
        updatedCuratorList: action.payload
      }
    case SEARCH_ME:
      return {
        ...state,
        search: action.payload
      }
    default:
      return state;
  }
}
