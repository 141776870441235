import React from "react";
import { useNavigate } from "react-router-dom";
import galleryImage from "../../../../images/register.jpg";
import arrowLeft from "../../../../images/arrow-left.png";
import "./Orders.css";

function Orders() {
  const navigate = useNavigate();
  return (
    <div className="orders-inner">
      <div className="orders-inner-column">
        {/* LEFT COLUMN / IMAGE COLUMN */}
        <div className="left-column orders-column-one image-column">
          <div className="image-column-inner">
            <img
              src={galleryImage}
              alt="display-img"
              className="display-image"
            />
          </div>
        </div>
        {/* MIDDLE COLUMN / ORDER DETAILS */}
        <div className="middle-column orders-column-two orders-details-column">
          {/* Product details */}
          <div className="order-details--row-one product-details">
            <div className="product-details-title">PRODUCT DETAILS</div>
            {/* Product details body */}
            <div className="product-details-body">
              {/* Row 1 SKU */}
              <div className="product-details-body--row sku-details">
                <span className="row-title">SKU: </span>
                <span className="row-value">AF00045</span>
              </div>

              {/* Row 2 Product Name */}
              <div className="product-details-body--row product-name-details">
                <span className="row-title">Product Name: </span>
                <span className="row-value">
                  Wooden Board on Plain Canvas with Gloss
                </span>
              </div>

              {/* Row 3 Artist */}
              <div className="product-details-body--row artist-details">
                <span className="row-title">Artist: </span>
                <span className="row-value">Bob Chimezie</span>
              </div>

              {/* Row 4 Category */}
              <div className="product-details-body--row category-details">
                <span className="row-title">Category: </span>
                <span className="row-value">Sculpture</span>
              </div>

              {/* Row 5 Style */}
              <div className="product-details-body--row style-details">
                <span className="row-title">Style: </span>
                <span className="row-value">Hand Mesh</span>
              </div>
            </div>
          </div>

          {/* Merchant Information */}
          <div className="order-details--row-two merchant-information">
            <div className="merchant-info-title">MERCHANT INFORMATION</div>
            {/* Merchant Information body */}
            <div className="merchant-info-body">
              {/* Row 1 Merchant Name */}
              <div className="merchant-information-body--row merchant-name">
                <span className="row-title">Merchant: </span>
                <span className="row-value">Hezekiah Oluwole</span>
              </div>

              {/* Row 2 Acccount Type */}
              <div className="merchant-information-body--row account-type">
                <span className="row-title">Account Type: </span>
                <span className="row-value">Shop</span>
              </div>
            </div>
          </div>

          {/* Buyer Information */}
          <div className="order-details--row-two buyer-information">
            <div className="buyer-info-title">BUYER INFORMATION</div>
            {/* Merchant Information body */}
            <div className="buyer-info-body">
              {/* Row 1 Bought by */}
              <div className="buyer-information-body--row bought-by">
                <span className="row-title">Bought By: </span>
                <span className="row-value">name@snowafricauser.com</span>
              </div>

              {/* Row 2 Date Bought */}
              <div className="buyer-information-body--row date-bought">
                <span className="row-title">Date Bought: </span>
                <span className="row-value">12th August, 2016</span>
              </div>

              {/* Row 3 Paid By */}
              <div className="buyer-information-body--row paid-by">
                <span className="row-title">Paid By: </span>
                <span className="row-value">Card</span>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT COLUMN ORDER STATUS / SUMMARY */}
        <div className="right-column orders-column-three order-status-column">
          <div className="order-status-summary">
            <div className="order-status-title">STATUS</div>
            <div className="order-status-value">In Progress</div>
            <div className="order-product-price">
              N<span className="order-product-price-value">1,203,000.00</span>
            </div>
          </div>
        </div>
      </div>
      {/* Back button */}
      <div className="back-btn-section">
        <button onClick={() => navigate(-1)}>
          <img src={arrowLeft} className="left-back-arrow" alt="left-arrow" />
          Back
        </button>
      </div>
    </div>
  );
}

export default Orders;
