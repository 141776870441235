// export const api = "https://snowafricabackend.herokuapp.com/";
// export const api = 'http://localhost:6001/';
// export const api = 'https://snow-back.herokuapp.com/';
// export const api = 'https://snowafricabackend.simptomini.com/';
export const api = 'https://backend.snowafrica.art/';
// export const api = 'https://backend.staging.snowafrica.art/';
// export const api = 'https://snowafrica-backend.herokuapp.com/'; 

// export const SENDGRID_API_KEY =
//     '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';

