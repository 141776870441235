import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import productReducer from "./productReducer";
import transactionReducer from "./transactionReducer";
import orderReducer from "./orderReducer"

export default combineReducers({
  auth: authReducer, 
  errors: errorReducer,
  profile: profileReducer,
  products: productReducer,
  transactions: transactionReducer,
  order: orderReducer
});
