import React, { useState, useEffect,useContext } from "react";
import { useNavigate,Link } from "react-router-dom";
import {PaystackButton} from 'react-paystack';
import "./Payment.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import leftArrow from "../../images/arrow-left.png";
import mastercardLogo from "../../images/mastercard-logo.png";
import americanExpressLogo from "../../images/american-express.png";
import paypalLogo from "../../images/paypal.png";
import visaLogo from "../../images/visa.png";
import informationLogo from "../../images/information.png";
import CartFooter from "../Cart/CartFooter";
import { Navigate } from "react-router-dom";
import { Divider } from "@mui/material";
import Header from "../LandingPage/Header/Header";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { useSelector, useDispatch } from "react-redux";
import CurrencyContext from "../../context/currencyContext";
import { displayPrice } from "../../utils/displayPrice";
import isEmpty from "../../validation/is-empty";
import _ from "lodash";
import { usePaystackPayment } from "react-paystack";
import { api } from "../../api/config";
import axios from "axios";
import { createTransaction } from "../../redux/actions/transactionActions";
import { createOrder } from "../../redux/actions/orderActions";
import ErrorHandler from "../error/ErrorHandler";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";

function Payment() {

  const dispatch = useDispatch();
  // Functions, States and Variables
  const mainUser = useSelector((state) => state.profile.profile);
  const currencyContext = useContext(CurrencyContext);
  const [user,setUser] = useState(mainUser);
  // Functions, States and Variables
  const navigate = useNavigate();
  const paystackKey = "pk_live_dd91070a7cfc9adf1aa041a939fe70e7a7f46efa";
  //   STATES
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondPhoneNumber, setSecondPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState(false);
  // State, the one with a capital not react state
  const [state, setState] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [cardExpirationMonth, setCardExpirationMonth] = useState("MM");
  const [cardExpirationYear, setCardExpirationYear] = useState("YY");

  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [billingAddressConfirmationValue, setBillingAddressConfirmationValue] =
    useState(false);
  const cardMonthArray = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const cardYearArray = [
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];

  const handleAddSecondPhoneInput = function (e) {
    e.preventDefault();
    let secondPhoneInputSection = document.querySelector(
      ".second-phone-input-section"
    );
    // Remove the add second number button
    e.target.parentElement.classList.add("none");
    // Add the second number Input
    secondPhoneInputSection.classList.remove("none");
  };

  useEffect(() => {
    setUser(mainUser);
  }, [mainUser])

  const calculateTotalShippingFee = (data) => {
    if(data && data.length){
      let result =  0
      for(let i = 0; i < data.length; i++){
        if(!isEmpty(data[i].weight)){
          result = result + calculateShippingFee(data[i].weight) 
        }
      }
      setShippingFee(result);
    }
  }

  // const callback = (response) => {
    // if(!isEmpty(this.props.transaction.transactionObject.items)){
    //   this.props.transaction.transactionObject.items.map( one => {

    //     const data = {
    //       user: user._id,
    //       product: one._id,
    //       order: this.props.transaction.transactionObject.orderID,
    //       payment_id: response.transaction
    //     }
    //     // console.log(data);
    //     dispatch(createTransaction(data))
    //   })
    // }
  // }

  const onSuccess = (reference) => {
    console.log('ooop',reference)
    let products = user.cart 
    if(user && user.cart && user.cart.length > 0 && user.cart[0] !== null){
      let artworks =[]
      user.cart.map(item=>{
          let artwork = {
            product: item._id,
            quantity:  1
          }
          artworks.push(artwork)
        })
        const data = {
          paid:true,
          user: user._id,
          address: user.shippingAddress,
          items: artworks,
          amount: totalPrice * 100,
          payment_id: reference
        }
        console.log(data);
        // dispatch(createTransaction(data))
        // navigate('/order-review')
        dispatch(createTransaction(data, products, navigate, setErrorHandlerObj, setIsLoading));
      
    }
  
  }

  const onClose = (reference) => {
    if(user && user.cart){
      let artworks =[]
      user.cart.map(item=>{
          let artwork = {
            product: item._id,
            quantity:  1
          }
          artworks.push(artwork)
        })
        const data = {
          paid:false,
          cancelled:true,
          user: user._id,
          address: user.shippingAddress,
          amount: totalPrice * 100,
          items: artworks
        }
        console.log(data);
        // dispatch(createOrder(data))
        // navigate('/order-failed')
        dispatch(createOrder(data, navigate, setErrorHandlerObj, setIsLoading));
      
    }
    // const payment = {
    //   amount: 20000,
    //   isPaid: reference && reference.status === "success" ? true : false,
    //   reference: reference,
    // };
    // // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log("closed");
    // console.log("Payment Failed!");
    // console.log(payment);
  };

  const handlePaymentWithPaystack = (e) => {
    e.preventDefault()
    initializePayment(onSuccess, onClose);
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: user && user.email,
    amount: totalPrice * 100,
    // publicKey: "pk_test_60cabd931b5c1281652b2bf9c7f8cd35d571f23d",
    publicKey: "pk_live_dd91070a7cfc9adf1aa041a939fe70e7a7f46efa",
  };

  const initializePayment = usePaystackPayment(config);


  const calculateShippingFee = (data) => {
    let figure;
    if (data > 0 && data <= 2){
       figure = 3640;
    }
    if(data > 2 && data <= 10){
      figure = 8613;
    }
    if (data > 10){
      figure = 15907
    }
    if(data === 0){
      figure = 0
    }
    return (currencyContext.currencySelected === "₦")? figure : figure/currencyContext.rate;
  }

  useEffect(() => {
    if(user && user.cart && user.cart.length > 0 && user.cart[0] !== null){
      calculateTotalShippingFee(user.cart)
    const totalCartPriceNum = user && user.cart
      .map((cartRow) => {
        return parseInt(displayPrice(cartRow,currencyContext).replace(/,/g,'',10));
      })
      .reduce((acc, num) => acc + num);

    setTotalCartPrice(totalCartPriceNum);
    let orderTotal = totalCartPriceNum + shippingFee;
    // console.log('ppp', totalPrice)
    // console.log('ppp', totalCartPriceNum)
    // console.log('ppp', shippingFee)
    // console.log('ppp', orderTotal)
    setTotalPrice(orderTotal)
    }
    
  }, [user]);

  useEffect(() => {
    let orderTotal = totalCartPrice + shippingFee;
    // console.log('ppp', totalPrice)
    console.log('ppp', totalCartPrice)
    console.log('bbb', shippingFee)
    // console.log('ppp', orderTotal)
    setTotalPrice(orderTotal)
  
}, [totalCartPrice,shippingFee]);

  // Credit Card Number Format Function
  const cc_format = function (value) {
    // handles Check on input to allow only digits
    setCardNumber(value);
    // let v = value
    // .replace(/\s+/g, "")
    //   .replace(/[^0-9]/gi, "")
    //   .replace(/{[^}]*}/g, "");

    // let matches = v.match(/\d{4,16}/g);
    // let match = matches && (matches[0] || "");
    // let parts = [];
    // let len = match && match.length;
    // for (let i = 0; i < len; i += 4) {
    //   parts.push(match.substring(i, i + 4));
    // }

    // if (parts.length) {
    //   setCardNumber(parts.join(" "));
    // } else {
    //   setCardNumber(value);
    // }
  };

  // Checks if a value is a didit and digit only
  const checkDigit = function (value, setValue) {
    // Replaces all non digits with an emptyy string
    // let formattedValue =
    //   value && value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    // Sets the value to its respective dictated state value
    // setValue(formattedValue);
    setValue(value);
  };

  // Handle CVV change to check if value is a digit only
  const handleCardCVVChange = function (value) {
    checkDigit(value, setCardCVV);
  };

  const handlePaymentandBillingFormsSubmit = function (e) {
    // If the Billing form has to be filled, i.e, change of billing address

    if (!billingAddressConfirmationValue) {
      // If the billing address needs to be filled
      // and payment option is paypal
      if (
        paymentMethod === "paypal" &&
        Boolean(
          firstName &&
            lastName &&
            phoneNumber &&
            address &&
            city &&
            country &&
            state
        )
      ) {
        const resultData = {
          paymentMethod: paymentMethod,
          firstName,
          lastName,
          phoneNumber,
          address,
          city,
          country,
        };
        navigate("/order-review");
        // and payment option is card
      } else if (
        paymentMethod === "card" &&
        Boolean(
          nameOnCard &&
            cardNumber &&
            cardCVV &&
            firstName &&
            lastName &&
            phoneNumber &&
            address &&
            city &&
            country &&
            state
        )
      ) {
        const resultData = {
          paymentMethod: paymentMethod,
          nameOnCard,
          cardNumber,
          cardCVV,
          firstName,
          lastName,
          phoneNumber,
          address,
          city,
          country,
        };
        navigate("/order-review");
      }
    } else {
      // If the Billing form IS NOT be filled, i.e address is shipping address
      // Only Payment details form has to be filled
      // If Payment option is paypal
      if (paymentMethod === "paypal") {
        // Returns only payment option
        const resultData = {
          paymentMethod,
        };
        navigate("/order-review");
        // If payment option is card
      } else if (paymentMethod === "card") {
        if (Boolean(nameOnCard && cardNumber && cardCVV)) {
          e.preventDefault();
          // returns only card details and payment option
          const result = {
            paymentMethod,
            nameOnCard,
            cardNumber,
            cardCVV,
            cardExpirationMonth,
            cardExpirationYear,
          };
          navigate("/order-review");
        }
      }
    }
  };

  // TODO STATE CHANGE with dynamic component, cvv info.
  const handlePaypalPaymentModeClick = function (e) {
    e.preventDefault();
    setPaymentMethod("paypal");
  };

  const handleCardPaymentModeClick = function (e) {
    e.preventDefault();
    setPaymentMethod("card");
  };

  return (
    <div className="payment-container">
      <Header />
      <ErrorHandler
          errorHandlerObj={errorHandlerObj}
          className="signin-error-handler"
        />

      {/* Header with Title and Order Progression */}
      <div className="payment--header">
        <div className="payment--header--inner">
          {/* Title */}
          <div className="payment-title">
            <p>Payment</p>
            {/* Shipping top Links */}
            <div className="payment-top-links">
              <div className="payment-top-links--inner">
                {/* Artist Name */}
                <div className="cart-link payment-top-link">
                  <a href={`/cart`}>Cart</a>
                </div>
                {/* Divider */}
                <span className="link-divider">/</span>
                {/* Payment */}
                <div className="payment-link payment-top-link">Payment</div>
              </div>
            </div>
          </div>

          {/*Order progression */}
          <div className="order-progression">
            <div className="order-progression--inner payment-order-progression">
              {/* Cart */}
              <div className="cart-progression ">
                <div className="progression-title">Cart</div>
              </div>

              {/* Shipping */}
              <div className="shipping-progression">
                <div className="progression-title">Shipping</div>
              </div>

              {/* Payment */}
              <div className="payment-progression">
                <div className="progression-title">Payment</div>
              </div>

              {/* Review */}
              <div className="review-progression">
                <div className="progression-title">Review</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Container Main Inner */}

      <form className="payment-container--inner">
        <div className="payment-container--col payment-card-info">
          <div className="payment-card-info--inner">
            {/* Select payment Option Section */}
            <div className="payment-option-select">
              <div className="payment-option-select--inner">
                {/* Payment Via Card */}
                {/* <div className="payment-via-card payment-mode-btn">
                  <button
                    className={
                      paymentMethod === "card" ? "payment-mode-active" : ""
                    }
                    onClick={handleCardPaymentModeClick}
                  >
                    Pay via Card
                  </button>
                </div> */}

                {/* Payment Via Paypal */}
                {/* <div className="payment-via-paypal payment-mode-btn">
                  <button
                    className={
                      paymentMethod === "paypal" ? "payment-mode-active" : ""
                    }
                    onClick={handlePaypalPaymentModeClick}
                  >
                    Pay via PayPal
                  </button>
                </div> */}
              </div>
            </div>
            {/* Dynamic View for payment Option */}
            {paymentMethod === "card" ? (
              <div className="payment-card-info--form">
                {/* Payment via card  */}
                {/* Card Payment Info Form */}
                {/* Name on card */}
                {/* <div className="form-group mb-3">
                  <label htmlFor="name-on-card-input" className="form-label">
                    NAME ON CARD:
                  </label>
                  <input
                    type="text"
                    className="cart-form-control form-control"
                    id="name-on-card-input"
                    aria-describedby="nameOnCardInput"
                    value={nameOnCard}
                    onChange={(e) => setNameOnCard(e.target.value)}
                    required
                  />
                </div> */}

                {/* Dummy Form group for Grid Placement */}
                <div className="dummy-form-group form-group mb-3"></div>

                {/* Card Number */}
                {/* <div className="form-group mb-3">
                  <label htmlFor="card-number-input" className="form-label">
                    CARD NUMBER:
                  </label>
                  <input
                    type="text"
                    className="cart-form-control form-control"
                    id="card-number-input"
                    aria-describedby="cardNumberInput"
                    value={cardNumber}
                    onChange={(e) => cc_format(e.target.value)}
                    required
                  />
                </div> */}

                {/* Sub group Form Group with CVV and Expiration Date */}
                <div className="form-group sub-grid-form-group mb-3">
                  {/* Card CVV input */}
                  {/* <div className="sub-form-group mb-3 card-cvv-input-section">
                    <label htmlFor="card-cvv-input" className="form-label"> */}
                      {/* CVV: */}
                      {/* CVV Information */}
                      {/* <div className="tooltip-me cvv-information-tooltip">
                        <img src={informationLogo} alt="info-logo" />
                        <div className="tooltiptext-me cvv-information-tooltiptext">
                          The CVV number on your credit card or debit card is a
                          3 digit on VISA&#174;, MasterCard&#174; and
                          Discover&#174; branded credit and debit cards.
                          <p>
                            On your American Express&#174; branded credit or
                            debit card, it is a 4 digit numeric code.
                          </p>
                        </div>
                      </div>
                    </label>
                    <input
                      type="text"
                      className="cart-form-control form-control"
                      id="card-cvv-input"
                      aria-describedby="cardCvvInput"
                      maxLength={3}
                      value={cardCVV}
                      onChange={(e) => handleCardCVVChange(e.target.value)}
                      required
                    />
                  </div> */}

                  {/* Card Expiration Date */}
                  {/* <div className="sub-form-group mb-3">
                    <label
                      htmlFor="card-expiration-month"
                      className="form-label"
                    >
                      EXPIRATION DATE:
                    </label> */}

                    {/* Card Expiration Select SYear */}
                    {/* <div className="card-expiration-select-section"> */}
                      {/* Month Expiration Select Section */}
                      {/* <select
                        className="cart-form-control form-control expiration-date-dropdown"
                        id="card-expiration-month"
                        aria-describedby="cardExpirationMonth"
                        value={cardExpirationMonth}
                        onChange={(e) => setCardExpirationMonth(e.target.value)}
                        required
                      >
                        <option disabled value="MM">
                          MM
                        </option>
                        {cardMonthArray.map((monthValue) => (
                          <option key={monthValue} value={monthValue}>
                            {monthValue}
                          </option>
                        ))}
                      </select> */}

                      {/*Year Expiration Select Section */}
                      {/* <select
                        className="cart-form-control form-control expiration-date-dropdown"
                        id="card-expiration-year"
                        aria-describedby="cardExpirationYear"
                        value={cardExpirationYear}
                        onChange={(e) => setCardExpirationYear(e.target.value)}
                        required
                      >
                        <option disabled value="YY">
                          YY
                        </option>
                        {cardYearArray.map((yearValue) => (
                          <option key={yearValue} value={yearValue}>
                            {yearValue}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                </div> 

                {/* Suppoorted Card comppany Images */}
                <div className="supported-card-company-images">
                  {/* Visa */}
                  <div className="supported--visa-logo supported-card-logo">
                    <img src={visaLogo} alt="visa-logo" />
                  </div>

                  {/* Mastercard */}
                  <div className="supported--mastercard-logo supported-card-logo">
                    <img src={mastercardLogo} alt="mastercard-logo" />
                  </div>

                  {/* American Express */}
                  <div className="supported--american-express-logo supported-card-logo">
                    <img
                      src={americanExpressLogo}
                      alt="american-express-logo"
                    />
                  </div>
                </div>
              </div>
            ) : (
              //  PAYMENY VIA PAYPAL
              <div className="payment-via-paypal-section">
                <div className="payment-via-paypal-section--inner">
                  {/* Paypal Logo */}
                  <div className="paypal-logo">
                    <img src={paypalLogo} alt="paypal-logo" />
                  </div>

                  {/* Redirection Information */}
                  <div className="redirection-information">
                    <p>
                      You will be redirected to PayPal after you have completed
                      your order.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/*Billing address confirmation Checkbox */}
          <div className="billing-address-confirmation-checkbox-section">
            {/* <input
              type="checkbox"
              aria-describedby="billingAddressConfirmationCheckbox"
              id="billing-address-confirmation-checkbox"
              value={billingAddressConfirmationValue}
              onChange={() =>
                setBillingAddressConfirmationValue(
                  !billingAddressConfirmationValue
                )
              }
            /> */}
            {/* <label htmlFor="billing-address-confirmation-checkbox">
              My billing address is the same as my shipping address
            </label> */}
          </div>

          {/* Billing Address Form Section */}
          <div
            id="billing-address-form"
            // Checks is the confirmation value is true and removes the form from display
            className={`billing-address-form-section ${
              billingAddressConfirmationValue && "none"
            }`}
          >
            <div className="billing-address-form-section--inner">
              {/*Profile Section */}
              <div className="billing-address__shipping-container--col profile-section">
                {/* <div className="section-title">Profile</div> */}
                {/* <div className="horizontal-line"></div> */}

                {/* Shipping Profile form */}
                <div className="profile-section--form billing-data-confirmation-form">
                  <div>
                    {/* First Name */}
                    {/* <div className="form-group mb-3">
                      <label htmlFor="first-name" className="form-label">
                        FIRST NAME:
                      </label>
                      <input
                        type="text"
                        className="form-control cart-form-control"
                        id="first-name"
                        aria-describedby="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* Last Name */}
                    {/* <div className="form-group mb-3">
                      <label htmlFor="last-name" className="form-label">
                        LAST NAME:
                      </label>
                      <input
                        type="text"
                        className="form-control cart-form-control"
                        id="last-name"
                        aria-describedby="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* Phone Number */}
                    {/* <div className="form-group mb-3">
                      <label htmlFor="phone-number" className="form-label">
                        PHONE NUMBER:
                      </label>
                      <input
                        type="text"
                        className="form-control cart-form-control"
                        id="phone-number"
                        aria-describedby="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* Second Phone Number */}
                    {/* <div className="form-group mb-3 second-phone-input-section none">
                      <label
                        htmlFor="second-phone-number"
                        className="form-label"
                      >
                        SECOND PHONE NUMBER:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="second-phone-number"
                        aria-describedby="secondPhoneNumber"
                        value={secondPhoneNumber}
                        onChange={(e) => setSecondPhoneNumber(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* Add Second Number */}
                    {/* <div className="add-second-phone-no">
                      <button
                        onClick={handleAddSecondPhoneInput}
                        className="add-second-phone-no-btn"
                      >
                        <span>+</span> Add Second Number
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>

              {/*Billing Address Section*/}
              <div className="billing-address__shipping-container--col billing-address-section">
                {/* <div className="section-title">Billing Address</div> */}
                {/* <div className="horizontal-line"></div> */}

                {/*Shipping  Billing Address form */}
                <div className="billing-addresss-section--form billing-data-confirmation-form">
                  <div>
                    {/* Address Input */}
                    {/* <div className="form-group mb-3">
                      <label htmlFor="address-input" className="form-label">
                        ADDRESS:
                      </label>
                      <textarea
                        type="text"
                        className="form-control cart-form-control address-input"
                        id="address-input"
                        aria-describedby="addressInput"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* City Input */}
                    {/* <div className="form-group mb-3">
                      <label htmlFor="city-input" className="form-label">
                        CITY:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city-input"
                        aria-describedby="cityInput"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div> */}

                    {/* Country Input */}
                    {/* <div className="form-group mb-3">
                      <div className="form-group-dropdown">
                        <div className="left-arrow-section">
                          <img src={arrowUp} alt="arrow-up" />
                          <img src={arrowDown} alt="arrow-down" />
                        </div>
                        <select
                          name="country-input"
                          id="country-input"
                          aria-describedby="countryInput"
                          className="form-control form-dropdown"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          required
                        >
                          <option value="false" disabled>
                            Choose your country
                          </option>
                          <option value="NG">NIGERIA</option>
                          <option value="SA">SOUTH AFRICA</option>
                          <option value="GH">GHANA</option>
                          <option value="KY">KENYA</option>
                        </select>
                      </div>
                    </div> */}

                    {/* State Input */}
                    {/* <div className="form-group mb-3">
                      <div className="form-group-dropdown">
                        <div className="left-arrow-section">
                          <img src={arrowUp} alt="arrow-up" />
                          <img src={arrowDown} alt="arrow-down" />
                        </div>
                        <select
                          name="state-input"
                          id="state-input"
                          aria-describedby="stateInput"
                          className="form-control form-dropdown"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          required
                        >
                          <option value="false" disabled>
                            Choose your state
                          </option>
                          <option value="OY">Oyo State</option>
                          <option value="OS">Osun State</option>
                          <option value="AB">Abia State</option>
                          <option value="BE">Benue State</option>
                        </select>
                      </div>
                    </div> */}

                    {/* <div className="delivery-time-info">
                      <p>
                        Please, allow up to two weeks for international
                        delivery.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*ORDER PRICE SUMMARY */}
        <div className="payment-container--col order-price-summary">
          <div className="order-price-summary--inner">
            {/* Artwork subtotal */}
            <div className="artwork-subtotal order-price-parameter-row">
            <div className="artwork-subtotal-title order-price-parameter-title">
                ARTWORKS SUBTOTAL:
              </div>
              <div className="artwork-subtotal-value order-price-parameter-value">
                {/* NGN{numberWithCommas("1300000")}.00 */}
                {currencyContext.currencySelected} { totalCartPrice.toLocaleString()}.00
              </div>
            </div>

            {/* Discount */}
            <div className="order-discount order-price-parameter-row">
              <div className="order-discount-title order-price-parameter-title">
                DISCOUNT:
              </div>
              <div className="order-discount-value order-price-parameter-value">
              {currencyContext.currencySelected} {numberWithCommas("0")}.00
              </div>
            </div>

            {/* Shipping */}
            <div className="shipping-price order-price-parameter-row">
              <div className="shipping-price-title order-price-parameter-title">
                SHIPPING:
              </div>
              <div className="shipping-price-value order-price-parameter-value">
              {currencyContext.currencySelected} {shippingFee.toLocaleString()}
              </div>
            </div>

            {/* Tax */}
            <div className="tax-inclusivity order-price-parameter-row">
              <div className="tax-inclusivity-title order-price-parameter-title">
                TAX:
              </div>
              <div className="tax-inclusivity-value order-price-parameter-value">
                {"Inclusive"}
              </div>
            </div>

            {/* Order Total */}
            <div className="total-order-price order-price-parameter-row">
              <div className="total-order-price-title order-price-parameter-title">
                ORDER TOTAL:
              </div>
              <div className="total-order-price-value order-price-parameter-value">
              {currencyContext.currencySelected} { totalPrice.toLocaleString()}.00
              </div>
            </div>

            {/* Proceed to checkout link */}
            <div className="payment--proceed-to-checkout-wrapper">
              <button
                type="submit"
                // onClick={handlePaymentandBillingFormsSubmit}
                onClick={handlePaymentWithPaystack}
                className="payment--proceed-to-checkout-wrapper--inner"
              >
                <p>PAY WITH PAYSTACK</p>
              </button>
              {isLoading && <LoadingSpinner />}
              {/* <PaystackButton
                  text="Make Payment"
                  className="payment--proceed-to-checkout-wrapper--inner"//"payButton"
                  callback={this.callback}
                  close={this.close}
                  disabled={false} 
                  embed={false}
                  reference={getReference()}
                  email={user.email}
                  amount={totalPrice * 100}
                  paystackkey={paystackKey}
                  tag="button"
                /> */}
            </div>
          </div>

          {/* International Shipments Note */}
          <div className="international-shipments-note">
            <p>
              Note: International shipments may be subject to additional import
              taxes, custom duties or fees imposed by the destination country,
              payable by the buyer upon receipt.
            </p>
          </div>
        </div>
        {/* Go back to Cart Btn*/}
        <div className="go-back-to-cart-btn-section">
          <div className="go-back-to-cart-btn-section--inner">
            {/* Back to cart */}
            <button
              onClick={() => window.history.back()}
              className="action-back-to-cart-wrapper"
            >
              <img src={leftArrow} alt="left-arrow" />
              <p>Back</p>
            </button>
          </div>
        </div>
      </form>

      <CartFooter />
    </div>
  );
}

export default Payment;
