import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import navBurger from "../../../images/landing-page/nav-burger.svg";
import navClose from "../../../images/landing-page/nav-close.svg";
import Container from "react-bootstrap/Container";
import "./Navbar.css";

const Navbar = () => {
  const location = useLocation();
  const [activeElementIndex, setActiveElementIndex] = useState(0);

  // Handle Open Top Navbar In TABLET AND MOBILE View
  const handleBottomNavbarOpen = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon--bottom");
    const openNavbarIcon = document.querySelector(".navburger-icon--bottom");
    const bottomNavbarTabletModal = document.querySelector(
      ".header--bottom--left--tablet-modal"
    );
    // Toggle Navbar Icons
    closeNavbarIcon.classList.remove("none");
    openNavbarIcon.classList.add("none");

    // handle top navbar modal open
    bottomNavbarTabletModal.classList.remove("none");
    //
    //
    // Close the navbar dropdown in top section
    const topNavbarModal = document.querySelector(
      ".header--top--right--tablet-modal"
    );
    const closeTopNavbarIcon = document.querySelector(".navclose-icon");
    const openTopNavbarIcon = document.querySelector(".navburger-icon");
    // handle top navbar modal open
    topNavbarModal.classList.add("none");
    closeTopNavbarIcon.classList.add("none");
    openTopNavbarIcon.classList.remove("none");
  };

  // Handle Close Top Navbar In TABLET AND MOBILE View
  const handleBottomNavbarClose = function () {
    const closeNavbarIcon = document.querySelector(".navclose-icon--bottom");
    const openNavbarIcon = document.querySelector(".navburger-icon--bottom");
    const topNavbarTabletModal = document.querySelector(
      ".header--bottom--left--tablet-modal"
    );

    // Toggle Navbar Icons
    closeNavbarIcon.classList.add("none");
    openNavbarIcon.classList.remove("none");
    // handle top navbar modal open
    topNavbarTabletModal.classList.add("none");
  };
  useEffect(() => {
    const navbarMenu = document.querySelectorAll(".navbar-menu ul li");

    // On load add active className based on route pathname
    if (location.pathname === "/artist-dashboard/upload-art") {
      // Remove active className from the former active
      navbarMenu[activeElementIndex].classList.remove("active");
      // Set a new state for the active className
      setActiveElementIndex(0);
      // Add active className from the new active
      navbarMenu[activeElementIndex].classList.add("active");
    } else if (location.pathname === "/artist-dashboard/biodata") {
      // Remove active className from the former active
      navbarMenu[activeElementIndex].classList.remove("active");
      // Set a new state for the active className
      setActiveElementIndex(1);
      // Add active className from the new active
      navbarMenu[activeElementIndex].classList.add("active");
    } else if (location.pathname === "/artist-dashboard/payment-information") {
      // Remove active className from the former active
      navbarMenu[activeElementIndex].classList.remove("active");
      // Set a new state for the active className
      setActiveElementIndex(2);
      // Add active className from the new active
      navbarMenu[activeElementIndex].classList.add("active");
    } else if (location.pathname === "/artist-dashboard/products") {
      // Remove active className from the former active
      navbarMenu[activeElementIndex].classList.remove("active");
      // Set a new state for the active className
      setActiveElementIndex(3);
      // Add active className from the new active
      navbarMenu[activeElementIndex].classList.add("active");
    } else if (location.pathname === "/artist-dashboard/transactions") {
      // Remove active className from the former active
      navbarMenu[activeElementIndex].classList.remove("active");
      // Set a new state for the active className
      setActiveElementIndex(4);
      // Add active className from the new active
      navbarMenu[activeElementIndex].classList.add("active");
    }

    // Iterate through the lists of links and watch for a click event
    navbarMenu.forEach((menu, i) => {
      // Remove active classes
      if (menu.classList.contains("active")) {
        menu.classList.remove("active");
      }
      //   Listen to a click event on each list of links
      menu.addEventListener("click", function (e) {
        e.stopImmediatePropagation();
        // Add active className to the clicked link and set the state
        menu.classList.add("active");
        setActiveElementIndex(i);
      });
      navbarMenu[activeElementIndex].classList.add("active");
    });
    // useEffect is depending on a change in pathname and active index
  }, [location.pathname, activeElementIndex]);

  return (
    <div className="navbar-main">
      <div className="navbar">
        {/* <= BOTTOM NAVBAR TABLET VIEW SECTION */}
        <div className="header--navbar--left--tablet">
          <div
            // onClick={handleNavbarBurgerOpen}
            className="bottom-navbar-open-navbar-burger"
          >
            <img
              onClick={handleBottomNavbarOpen}
              src={navBurger}
              alt=""
              className="navburger-icon--bottom"
              style={{
                transform: "rotate(180deg)",
              }}
            />
            <img
              onClick={handleBottomNavbarClose}
              src={navClose}
              alt=""
              className="navclose-icon--bottom none"
            />
          </div>
          {/* Tablet Top navbar */}
          <div className="header--bottom--left--tablet-modal none">
            {/* Register Link */}
            <div className="bottom--navbar-link tablet-paintings-link ">
              <a href="/artist-dashboard/upload-art">UPLOAD ART</a>
            </div>
            {/* Prints */}
            <div className="bottom--navbar-link tablet-prints-link">
              <a href="/artist-dashboard/biodata">BIODATA</a>
            </div>
            {/* Drawings */}
            <div className="bottom--navbar-link tablet-drawings-link">
              <a href="/artist-dashboard/payment-information">
                PAYMENT INFORMATION
              </a>
            </div>
            {/* Photograhy */}
            <div className="bottom--navbar-link tablet-photography-link">
              <a href="/artist-dashboard/products">PRODUCTS</a>
            </div>
            {/* Landscape */}
            <div className="bottom--navbar-link tablet-landscape-link">
              <a href="/artist-dashboard/transactions">TRANSACTIONS</a>
            </div>
          </div>
        </div>

        <div className="navbar-menu artist-dash-nav-menu">
          <ul>
            <li className="active">
              <a href="/artist-dashboard/upload-art">UPLOAD ART</a>
            </li>
            <li>
              <a href="/artist-dashboard/biodata">BIODATA</a>
            </li>
            <li>
              <a href="/artist-dashboard/payment-information">
                PAYMENT INFORMATION
              </a>
            </li>
            <li>
              <a href="/artist-dashboard/products">PRODUCTS</a>
            </li>
            <li>
              <a href="/artist-dashboard/transactions">TRANSACTIONS</a>
            </li>
          </ul>
        </div>

        <div className="navbar-search">
          <div className="input-icons">
            <i className="fa fa-search icon"></i>
            <input
              placeholder="Search..."
              className="input-field"
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
