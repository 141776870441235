import axios from 'axios';
import { api } from '../../api/config';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_ADMIN_LAYOUT,
  CLEAR_CURRENT_PROFILE,
  CLEAR_CURRENT_COLLECTION,
  GET_CURRENCY_RATE,
  SET_CURRENCY_RATE,
  GET_RISING,
} from '../Constants';
import isEmpty from '../../validation/is-empty';

// Register user
const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["accesstoken"] = token;

export const registerUser =
  (
    userData,
    history,
    setErrorHandlerObj,
    setIsLoading
  ) => dispatch => {
    setIsLoading(true);
    axios
      .post(api + 'snowafrica/registerUser', userData)
      .then(res => {
        const { token } = res.data;
        // Set token to local storage
        localStorage.setItem('jwtToken', token);
        setErrorHandlerObj({ hasError: false, message: "" });
        // setResStatusCode(res.status);
        setIsLoading(false);
        history(0);

        // history.push('/verifyemail');
      })
      .catch(err => {
        if (!isEmpty(err) && !isEmpty(err.response)) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload:
              "Something Went Wrong. Please Check your Connection and try again",
          });
        }
        setIsLoading(false);
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data?.message || err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });

      });
  };

//Get Rising
export const getRising = () => dispatch => {
  axios
    .get(api + 'snowafrica/artist/rising',)
    .then(res => {
      dispatch({
        type: GET_RISING,
        payload: res.data.data,
      });
    })
    .catch(err => {
      if (!err.response) {
        return dispatch({
          type: GET_ERRORS,
          payload: 'Something went wrong',
        });
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });

    });
};


// Login - Get User Token
export const loginUser =
  // userData 
  (
    userData,
    navigate,
    setErrorHandlerObj,
    setIsLoading
  ) => dispatch => {
    setIsLoading(true);
    console.log('kk', userData);
    axios
      .post(api + 'snowafrica/loginUser', userData)
      .then(res => {
        const { token } = res.data;
        // Set token to local storage
        localStorage.setItem('jwtToken', token);
        setIsLoading(false);
        navigate(0);
        // window.location.reload();

        // Set token to Auth Header
        // dispatch(setAuthToken(token));
        // // Decode token to get user data
        // const decoded = jwt_decode(token);
        // // Set current User
        // console.log('DECODED', decoded);
        // if (decoded.user_type === '10000.001') {

        //   localStorage.setItem('__sa__', 'allow');
        //   window.location.reload();
        // }
        // if(decoded.user_type === '11000.000'){
        //   window.location.reload();
        //   // window.location.href = "/dashboard";
        // }
        // dispatch(setCurrentUser(decoded));
        // window.location.href = "/";
        // // console.log('here')
        // window.location.reload();
      })
      .catch(err => {
        if (!isEmpty(err) && !isEmpty(err.response)) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response,
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload:
              "Something Went Wrong. Please Check your Connection and try again",
          });
        }
        setIsLoading(false);
        console.log(err.response)
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data?.message || err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
      });
  };

//request password reset link
export const requestPassword = body => dispatch => {
  axios
    .post(api + 'snowafrica/requestPassword', body)
    .then(res => {
      // Save to local storage
      //const { token } = res.data;
      //dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      let payload = {};
      if (!err.response) {
        payload.message =
          'Something Went Wrong. Please Check your Connection and try again';
      } else {
        payload = err.response.data;
      }
      // console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: payload,
      });
    });
};

export const resetPassword = (token, password) => dispatch => {
  axios
    .post(api + 'snowafrica/resetPassword', token, password)
    .then(res => {
      // Save to local storage
      //const { token } = res.data;
      //dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      let payload = {};
      if (!err.response) {
        payload.message =
          'Something Went Wrong. Please Check your Connection and try again';
      } else {
        payload = err.response.data;
      }
      // console.log(err.response);
      dispatch({
        type: GET_ERRORS,
        payload: payload,
      });
    });
};

// Sign In With Google
// export const googleLogin = () => {
//   axios.get(api + "snowafrica/auth/google").then(res => {});
// };

// Set Logged in user
export const clearCurrentProfile = decoded => {
  return {
    type: CLEAR_CURRENT_PROFILE,
    payload: decoded,
  };
};
export const clearCurrentCollection = decoded => {
  return {
    type: CLEAR_CURRENT_COLLECTION,
    payload: decoded,
  };
};
// Set Logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};


// Log Out User
export const logoutUser = () => dispatch => {
  // Remove token from localstorage
  localStorage.removeItem('jwtToken');
  // Remove auth header for futere requests
  setAuthToken(false);
  // Set current user to {} which will set is authenticated to false
  dispatch(setCurrentUser({}));
  dispatch(clearCurrentProfile({}));
  window.location.reload();
};

// Set Admin
export const setAdminLayout = () => dispatch => {
  dispatch({
    type: SET_ADMIN_LAYOUT,
    payload: true,
  });
};

// Set Admin false
export const setAdminLayoutOff = () => dispatch => {
  dispatch({
    type: SET_ADMIN_LAYOUT,
    payload: false,
  });
};

export const getCurrencyRate = () => dispatch => {
  axios
    .get(api + 'snowafrica/currencyrate')
    .then(res => {
      if (res.data != null) {
        dispatch({
          type: GET_CURRENCY_RATE,
          payload: res.data.data,
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err, //response.data
      });
    });
};

export const setCurrencyRate = (rate) => dispatch => {
  axios
    .post(api + 'snowafrica/currencyrate', rate)
    .then(res => {
      window.location.reload();
      // if (res.data != null) {
      //   dispatch({
      //     type: SET_CURRENCY_RATE,
      //     payload: res.data,
      //   });
      // }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err, //response.data
      });
    });
};
