import axios from "axios";

const setAuthToken = token => {
  if (token) {
    // console.log('token', token)
    // Apply to every request
    var accesstoken = "JWT " + token;
    axios.defaults.headers.common["accesstoken"] = accesstoken;
    // window.location.reload();
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["accesstoken"];
  }
};
export default setAuthToken;
