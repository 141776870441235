import React from "react";
import Accordion from "react-bootstrap/Accordion/";
import Card from "react-bootstrap/Card";
import Footer from "../components/LandingPage/Footer/Footer";
import Header from "../components/LandingPage/Header/Header";
function ArtistFAQ() {
  const artistFaqs = [
    {
      question: "What is Snow Africa about?",
      answer: `Snow Africa was established to create a community of budding African artists, by providing useful and timely information and opportunities, to help them actualise their dreams, while promoting and growing Africa's art industry at large.<br/> SnowAfrica is a free online platform which manages portfolios of art and connects artists, art lovers and collectors, all in one place.`,
    },
    {
      question: "Is Snow Africa an art gallery?",
      answer: `Snow Africa surely deals in artworks and art curation. While we feature artworks attend art workshops, and do galleru tours, we are not an art gallery.`,
    },
    {
      question: "Do you sell artworks?",
      answer: `Yes. Snow Africa's services includes the sales and curation of art. More information is provided on the <a href="/">website</a>.`,
    },
    {
      question: "Do you hold artists' workshop at SnowAfrica?",
      answer: `Currently, no. But we have been at a couple of workshops that artists may be intetested in and can draw inspiration from.
    `,
    },
    {
      question: "Do I need to pay to be a Snow Africa artist?",
      answer: `No. It is absolutely free to showcase your artwork on Snow Africa. All you need to do is login and follow the prompt.`,
    },
    {
      question: `How do i join the "Let's talk about Art" podcast?`,
      answer: `One way to do this is to subscribe to our mailing list to get newsletters, send us a mail or send us a DM on our social media page <a href="https://www.instagram.com/snowafricaart/?hl=ens" target={"_blank"}>here</a>.`,
    },
    {
      question: "Is it the podcast show free?",
      answer: `Yes. It's free. We would love you have many african artists share from their well of knowledge.<br/> Our offerings are unparalleled selection of African art, advisory, promotion and trade of art while delivering optimum satisfaction for artist and art enthusiasts. For more information, contact us via email- <a href={mailto:info@snowafrica.art} target="_blank">info@snowafrica.art</a>  or visit our social media pages.`,
    },
  ];
  return (
    <>
      <Header />
      <div className="faq-container mb-4">
        <h2 className="mt-4 mb-4 text-center">Frequently Asked Questions</h2>
        <Accordion defaultactivekey="0">
          {artistFaqs?.map((faq, index) => (
            <Accordion.Item eventKey={index} key={index + 1}>
              <Card>
                <Accordion.Header>
                  <h6 className="accordion-header">{faq.question}</h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                  </Card.Body>
                </Accordion.Body>
              </Card>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <Footer />
    </>
  );
}

export default ArtistFAQ;
