import React, { useState, useEffect, useContext } from "react";
import "./Cart.css";
import CartFooter from "./CartFooter";
import Table from "react-bootstrap/Table";
import leftArrow from "../../images/arrow-left.png";
import cancelIcon from "../../images/cancel-icon.svg";
import Header from "../LandingPage/Header/Header";
import { useSelector, useDispatch } from "react-redux";
import CurrencyContext from "../../context/currencyContext";
import { displayPrice } from "../../utils/displayPrice";
import { deleteProductFromCart } from "../../redux/actions/orderActions";
import { numberWithCommas } from "../../utils/numberWithCommas";
import { Link, useNavigate } from "react-router-dom";
import { getPrice } from "../../utils/getPrice";


// function createArtworkData(
//   id,
//   artworkName,
//   artistName,
//   artworkPrice,
//   artworkSubtotalprice,
//   artworkCategory,
//   artworkSpecs,
//   displayImgSrc
// ) {
//   return {
//     id,
//     artworkName,
//     artistName,
//     artworkPrice,
//     artworkSubtotalprice,
//     artworkCategory,
//     artworkSpecs,
//     displayImgSrc,
//   };
// }

// const originalRows = [
//   createArtworkData(
//     1,
//     "Guernica",
//     "Pablo Picasso",
//     "650000",
//     "900000",
//     "Painting",
//     {
//       width: "20",
//       height: "18",
//       inches: "0.4",
//       frame: "wooden",
//       others: "Canvas print",
//     },
//     "Pic 2015-01-23 17-42-22.jpg"
//   ),
//   createArtworkData(
//     2,
//     "The Water Lily Pond",
//     "Claude Monet",
//     "1230000",
//     "1500000",
//     "Sculpture",
//     {
//       width: "32",
//       height: "28",
//       inches: "0.3",
//       frame: "wooden",
//       others: "Canvas print",
//     },
//     "register.jpg"
//   ),
//   createArtworkData(
//     3,
//     "Woman with a Parasol",
//     "Leonardo Da Vinci",
//     "800000",
//     "800000",
//     "Landscape",
//     {
//       width: "25",
//       height: "28",
//       inches: "0.5",
//       frame: "wooden",
//       others: "Canvas print",
//     },
//     "register2.jpg"
//   ),
// ];

function Cart() {
  const dispatch = useDispatch();
  const currencyContext = useContext(CurrencyContext);
  // STATES
  // const cart = useSelector((state) => console.log(state));
  const cart = useSelector((state) => state.profile.profile.cart);
  // Functions State and Variiables
  // const [originalCartRows, setOriginalCartRows] = useState(originalRows);
  const [originalCartRows, setOriginalCartRows] = useState((cart && cart.length) > 0 ? cart :[]);
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  //   Functions

  // Handle Coupon Code Submit
  const handleCouponCodeSubmit = function () {
    let couponCodeDisplaySection = document.querySelector(
      ".coupon-discount-display-wrapper"
    );
    if (Boolean(couponCode)) {
      console.log("Coupon Code submitted");
      // Later On check for validy of coupon code
      couponCodeDisplaySection.classList.remove("none");
    }
    return false;
  };

  //   Handle cancel of individual cart row
  const handleCartCancel = function (cartRow) {
    let currentCartRows = originalCartRows.filter((rows) => {
      return cartRow._id !== rows._id;
    });
    setOriginalCartRows(currentCartRows);
    dispatch(deleteProductFromCart({ id:cartRow._id }));
    const totalCartPriceNum = originalCartRows && originalCartRows
      .map((cartRow) => {
        return parseInt(displayPrice(cartRow,currencyContext).replace(/,/g,'',10));
      })
      .reduce((acc, num) => acc + num);

    setTotalCartPrice(totalCartPriceNum);
  };

  useEffect(() => {
    setOriginalCartRows((cart && cart.length) > 0 ? cart :[]);
    console.log('yy', originalCartRows)
  }, [cart])

  //   useEffect
  useEffect(() => {
    console.log('ll', originalCartRows)
    // userData && userData.cart && userData.cart[0] !== null
    if((originalCartRows && originalCartRows.length > 0 && originalCartRows[0] !== null)){
      // const totalCartPriceNum = originalCartRows
      //   .map((cartRow) => {
      //     return parseInt(displayPrice(cartRow,currencyContext).replace(/,/g,'',10));
      //   })
      //   .reduce((acc, num) => acc + num);


      let totalCartPriceNum = 0
      originalCartRows
        .map((cartRow) => {
          totalCartPriceNum = totalCartPriceNum + (getPrice(cartRow,currencyContext))
        })
  
    console.log('kkl', totalCartPriceNum)
  
      setTotalCartPrice(totalCartPriceNum);
    }
  }, [originalCartRows]);

  return (
    <div className="cart-container">
      <Header />
      <div className="cart-container--inner">
        {/* Cart Header */}
        <div className="cart--header">
          <div className="cart--header--inner">
            {/* Title */}
            <div className="header-title">Shopping Cart</div>

            {/*Order progression */}
            <div className="order-progression">
              <div className="order-progression--inner cart-order-progression">
                {/* Cart */}
                <div className="cart-progression ">
                  <div className="progression-title">Cart</div>
                </div>

                {/* Shipping */}
                <div className="shipping-progression">
                  <div className="progression-title">Shipping</div>
                </div>

                {/* Payment */}
                <div className="payment-progression">
                  <div className="progression-title">Payment</div>
                </div>

                {/* Review */}
                <div className="review-progression">
                  <div className="progression-title">Review</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Cart Main Body Container */}
        <main className="cart-main-container">
          {((originalCartRows && originalCartRows[0] === null)  || originalCartRows.length === 0) ? (
            //   IF Cart is Empty
            <div className="empty-cart-main-container">
              <div className="empty-cart-main-container--inner">
                <div className="empty-cart-message">
                  There are no items in your cart
                </div>

                <div className="continue-shopping-button">
                  <Link to="/artworks">CONTINUE SHOPPING</Link>
                </div>
              </div>
            </div>
          ) : (
            //   IF Cart is not Empty
            <div className="filled-cart-main-container">
              <div className="filled-cart-main-container--inner">
                <div className="shopping-cart-table">
                  <Table responsive="lg" className="cart-table">
                    <thead>
                      <tr>
                        {["ITEM(S)", "SPECS", "PRICE", "SUBTOTAL", ""].map(
                          (COLUMN, index) => (
                            <th key={index + 1}>{COLUMN}</th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderTop: "2px solid rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {originalCartRows && originalCartRows.map((cartRow, i) => (
                        <tr key={i + 1} className="tr-cart">
                          {/* Items */}
                          <td className="td-items">
                            <div className="td-items--wrapper">
                              {/* Artwork Image */}
                              <div className="items--artwork-image">
                                <img
                                  src={cartRow && cartRow.photo}
                                  alt="artwork-img"
                                />
                              </div>

                              {/* Artwork Info Details */}
                              <div className="items--artwork-info-details">
                                {/* Artwork name */}
                                <div className="items--artwork-name">
                                  {cartRow && cartRow.title}
                                </div>

                                {/* Artist Name */}
                                <div className="items--artist-name">
                                  {/* Artist: {cartRow.artistName} */}
                                </div>

                                {/* Artwork Category */}
                                <div className="items--artwork-category">
                                  {cartRow && cartRow.category}
                                </div>
                              </div>
                            </div>
                          </td>

                          {/* Specs */}
                          <td className="td-specs">
                            <div className="td-specs--wrapper">
                              <div className="specs--size">
                                Size: {cartRow && cartRow.dimension}
                                {/* Size: {cartRow.artworkSpecs.height} H x{" "}
                                {cartRow.artworkSpecs.width} W x{" "}
                                {cartRow.artworkSpecs.inches} in */}
                              </div>
                              <div className="specs--frame">
                                {/* Frame: {cartRow.dimension} */}
                              </div>
                              <div className="specs--canvas">
                                {/* {cartRow.artworkSpecs.others} */}
                              </div>
                            </div>
                          </td>

                          {/* Price */}
                          <td>
                          <CurrencyContext.Consumer>
                                {(currencyContext) => (
                                  <div className="td-price--wrapper">
                                    {cartRow && currencyContext.currencySelected}{" "}
                                    {cartRow && displayPrice(cartRow, currencyContext)}
                                  </div>
                                )}
                              </CurrencyContext.Consumer>
                            {/* <div className="td-price--wrapper">
                              NGN
                              {numberWithCommas(cartRow.price)}
                              .00
                            </div> */}
                          </td>

                          {/* Subtotal */}
                          <td>
                            {/* <div className="td-subtotal--wrapper">
                              NGN
                              {numberWithCommas(cartRow.price)}
                              .00
                            </div> */}
                            <CurrencyContext.Consumer>
                                {(currencyContext) => (
                                  <div className="td-subtotal--wrapper">
                                    {cartRow && currencyContext.currencySelected}{" "}
                                    {cartRow && displayPrice(cartRow, currencyContext)}
                                  </div>
                                )}
                              </CurrencyContext.Consumer>
                          </td>

                          {/* Cancel Cart Button */}
                          <td>
                            <div className="cancel-cart-btn--wrapper">
                              <button onClick={() => handleCartCancel(cartRow)}>
                                <img src={cancelIcon} alt="cancel-icon" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/* Coupon Discount Display Section */}
                <div className="coupon-discount-display-wrapper none">
                  <div className="coupon-discount-display-wrapper--inner">
                    {/* Coupon discount title  */}
                    <div className="coupon-discount-price--title">
                      {/* Discount Applied (Code: F46BA7C): */}
                    </div>
                    {/* Coupon discount value   */}
                    <div className="coupon-discount-price--value">
                      {/* -NGN{numberWithCommas(131000)}.00 */}
                    </div>
                  </div>
                </div>

                {/* Total Cart Container */}
                <div className="total-cart-price-wrapper">
                  <div className="total-cart-price-wrapper--inner">
                    {/* Total Cart price title  */}
                    <div className="total-cart-price--title">TOTAL:</div>
                    {/* Total Cart price value  */}
                    <div className="total-cart-price--value">
                      NGN{totalCartPrice ? numberWithCommas(totalCartPrice): 0}.00
                    </div>
                  </div>
                </div>

                {/* Coupon Code Container */}
                {/* <div className="coupon-code-wrapper">
                  <div className="coupon-code-wrapper--inner">
                    <p>Do you have a coupon code? Enter it here</p>

                    <div className="coupon-input-form">
                      <input
                        type="text"
                        name="coupon-code-input"
                        id="coupon-code-input"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                      />

                      <button onClick={handleCouponCodeSubmit}>APPLY</button>
                    </div>
                  </div>
                </div> */}

                {/* Lower Actions Link and buttons */}
                <div className="lower-actions-buttons-section">
                  <div className="lower-actions-buttons-section--inner">
                    {/* Continue Shopping Link */}
                    <a href="/artworks" className="action-continue-shopping-wrapper">
                      <img src={leftArrow} alt="left-arrow" />
                      <p>Continue Shopping</p>
                    </a>

                    {/* Proceed to checkout link */}
                    <a href="/shipping" className="proceed-to-checkout-wrapper">
                      <p>PROCEED TO CHECKOUT</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>

      {/* Footer */}
      <CartFooter />
    </div>
  );
}

export default Cart;
