import React, { useState, useEffect } from "react";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import "./Biodata.css";

function Biodata() {
  // Variables and states
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [landmark, setLandmark] = useState("");
  const [country, setCountry] = useState("nigeria");
  const [state, setState] = useState("");

  // Functions
  // Handle Save data
  const handleSaveData = function () {
    let result = {
      address,
      city,
      landmark,
      country,
      state,
    };
    console.log("biodata", result);
  };

  // handle Cancel changes
  const handleCancelChanges = function () {
    setAddress("");
    setCity("");
    setLandmark("");
    setCountry("nigeria");
    setState("");
  };

  return (
    <div className="biodata-main">
      <div className="main-inner">
        <div className="biodata-header page-column column-1">
          <div className="header-inner">
            {/* Title row 1 */}
            <div className="title-row-1 agent-dashboard">AGENT DASHBOARD</div>
            {/* Title Row 2 */}
            <div className="main-page-title title-row-2">
              <p>Address</p>
              <p>Settings</p>
            </div>
          </div>
        </div>
        <div className="biodata-body page-column column-2">
          {/* ADDRESS */}
          <div className="main-inner-col biodata-address">
            <div className="section-title">Address</div>
            <div className="horizontal-line"></div>

            {/* Biodata form */}
            <div className="biodata--form">
              <form>
                {/* ADDRESS */}
                <div className="form-group mb-3">
                  <label htmlFor="art-specification" className="form-label">
                    ADDRESS:
                  </label>
                  <textarea
                    type="text"
                    className="form-control address-input"
                    id="agent-address"
                    aria-describedby="agentAddress"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                </div>

                {/* City */}
                <div className="form-group mb-3">
                  <label htmlFor="agent-city" className="form-label">
                    CITY:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="agent-city"
                    aria-describedby="agentCity"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                  />
                </div>

                {/* Add landmark */}
                <div className="add-landmark">
                  <button className="add-landmark-btn">
                    <span>+</span> Add landmark
                  </button>
                </div>

                {/* Country */}
                <div className="form-group  mb-3">
                  <label htmlFor="country-input" className="form-label">
                    COUNTRY:
                  </label>
                  <div className="form-group-dropdown">
                    <div className="left-arrow-section">
                      <img src={arrowUp} alt="arrow-up" />
                      <img src={arrowDown} alt="arrow-down" />
                    </div>
                    <select
                      name="agent-country"
                      id="country-input"
                      aria-describedby="agentCountry"
                      className="form-control form-dropdown"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <option value="nigeria">Nigeria</option>
                      <option value="ghana">Ghana</option>
                      <option value="rwanda">Rwanda</option>
                      <option value="south-africa">South Africa</option>
                      <option value="algeria">Algeria</option>
                      <option value="egypt">Egypt</option>
                    </select>
                  </div>
                </div>

                {/* State */}
                <div className="form-group mb-3">
                  <label htmlFor="state-input" className="form-label">
                    STATE:
                  </label>
                  <div className="form-group-dropdown">
                    <div className="left-arrow-section">
                      <img src={arrowUp} alt="arrow-up" />
                      <img src={arrowDown} alt="arrow-down" />
                    </div>
                    <select
                      name="agent-state"
                      id="country-input"
                      aria-describedby="agentState"
                      className="form-control form-dropdown"
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    >
                      <option defaultValue={"select"}>
                        -- Select State --
                      </option>
                      <option value="lagos">Lagos</option>
                      <option value="oyo">Oyo</option>
                      <option value="kano">Kano</option>
                      <option value="delta">Delta</option>
                      <option value="jos">Jos</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="page-column column-3 save-cancel-section">
          {/* SAVE AND CANCEL */}
          <div className="save-cancel-section-inner">
            <button
              className="btn-inner cancel-changes-btn"
              onClick={handleCancelChanges}
            >
              Cancel changes
            </button>
            <button
              className="btn-inner save-changes-btn"
              onClick={handleSaveData}
            >
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biodata;
