import React, { useRef, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import likeBtn from "../../../images/like-icon.svg";
import cartBtn from "../../../images/cart-icon.svg";
import { Pagination } from "../../Pagination/Pagination";
import "./ArtworksSort.css";
import Masonry from "react-masonry-css";
import Header from "../../LandingPage/Header/Header";
import { getProducts } from "../../../redux/actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import CurrencyContext from "./../../../context/currencyContext";
import { displayPrice } from "../../../utils/displayPrice";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import { isEmpty } from "lodash";
import { numberWithCommas } from "../../../utils/numberWithCommas";
import { addProductToCart,deleteProductFromCart } from "../../../redux/actions/orderActions";

function ArtworksSort() {
  const dispatch = useDispatch();
  const currencyContext = useContext(CurrencyContext);
  // STATES
  const products = useSelector((state) => state.products.products);
  // const products = useSelector(state => state.products.products.filter(product => product.artist.user_id !== null));
  const [originalRows, setOriginalRows] = useState(null);
  // console.log('ll',originalRows);
  const [majorPageSortBasis, setMajorPageSortBasis] = useState("painting");
  const [subsidiaryPageSortBasis, setSubsidiaryPageSortBasis] =
    useState("recent");
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [artworkStyle, setArtworkStyle] = useState("all");
  const [pageLayout, setPageLayout] = useState("grid");
  const [minPriceRangeValue, setMinPriceRangeValue] = useState(1);
  const [maxPriceRangeValue, setMaxPriceRangeValue] = useState(1000);
  const [stepPriceRangeValue, setStepPriceRangeValue] = useState(1);
  const [allColors, setAllColors] = useState();
  const [matchingColorsList, setMatchingColorsList] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [artworkAddedToCart, setArtworkAddedToCart] = useState(false);
  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const {category} = useParams();

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddToCart = (product) => {
    // console.log("ggg", product);
    if (!artworkAddedToCart) {
      dispatch(addProductToCart({ id: product._id }));
    } else {
      dispatch(deleteProductFromCart({ id:product._id }));
    }
    setArtworkAddedToCart(!artworkAddedToCart);
  };

  useEffect(() => {
    if (originalRows) {
      const currentRows = originalRows?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);
  //Ref
  const paintingLinkRef = useRef();
  const categoryBLinkRef = useRef();
  const categoryCLinkRef = useRef();
  const sculptureLinkRef = useRef();
  const designLinkRef = useRef();
  const mostRecentLinkRef = useRef();
  const mostPopularLinkRef = useRef();

  //   Column Breakpoint
  const artworksSortBreakpointColumnsObj = {
    default: 6,
    2060: 5,
    1520: 4,
    1190: 3,
    860: 2,
    650: 1,
  };

  // FUNCTIONS

  // HANDLE SIDEBAR NAV LINKS
  // Handle Cashflows Link Click
  const handlePaintingLinkClick = function () {
    // Set Major Page Content Sort to Painting art content
    setMajorPageSortBasis("painting");
    filter('Painting')
    // Handle color change on active class active
    paintingLinkRef.current.classList.add("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Category B Link Click
  const handleCategoryBLinkClick = function () {
    // Set Major Page Content Sort to Category B art content
    setMajorPageSortBasis("category-b");
    filter('Prints')

    categoryBLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Category C Link Click
  const handleCategoryCLinkClick = function () {
    // Set Major Page Content Sort to Category C art content
    setMajorPageSortBasis("category-c");
    filter('Drawing')

    categoryCLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Sculpture Link Click
  const sculptureLinkClick = function () {
    // Set Major Page Content Sort to Sculpture art content
    setMajorPageSortBasis("sculpture");
    filter('Sculpture')
    // Handle color change on active class active
    sculptureLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Design Link Click
  const designLinkClick = function () {
    // Set Major Page Content Sort to Design art content
    setMajorPageSortBasis("design");
    filter('Photography')
    // Handle color change on active class active
    designLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
  };

  // Handle Most Recent Link Click
  const mostRecentLinkClick = function () {
    // Set Subsidiary Page Content Sort to Most Recent page content
    setSubsidiaryPageSortBasis("recent");
    // Handle color change on active class active
    mostRecentLinkRef.current.classList.add("active-link");
    mostPopularLinkRef.current.classList.remove("active-link");
  };

  // Handle Most Popular Link Click
  const mostPopularLinkClick = function () {
    // Set Subsidiary Page Content Sort to Most Popular page content
    setSubsidiaryPageSortBasis("popular");
    // Handle color change on active class active
    mostPopularLinkRef.current.classList.add("active-link");
    mostRecentLinkRef.current.classList.remove("active-link");
  };

  //   Handle Matching colors click
  const handleMatchingColorClick = function (e, color) {
    let matchingColors = [];
    e.stopPropagation();
    // Check if the clicked color has already been selected before selecting
    if (e.target.classList.contains("color-selected")) {
      e.target.classList.remove("color-selected");
      //   filter out the color from the list of selected colors
      matchingColors = matchingColorsList.filter((col) => {
        return color !== col;
      });
      //   Then set the state to the colors left after filtering
      setMatchingColorsList([...matchingColors]);
      //   setMatchingColorsList(matchingColors);
    } else {
      // If it hasn't been selected
      e.target.classList.add("color-selected");
      //   Add the new color to the list of selected colors by setting the state
      setMatchingColorsList([...matchingColorsList, color]);
    }
    // setMatchingColorsList(matchingColors);
  };

  // PRICE RANGES SCRIPT
  function getVals() {

    // Get slider values
    var slide1 = parseFloat(document.querySelector(".slider-1").value);
    var slide2 = parseFloat(document.querySelector(".slider-2").value);
    // Neither slider will clip the other, so make sure we determine which is larger
    if (slide1 > slide2) {
      var tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    var displayElement = document.querySelector(".rangeValues");
    displayElement.innerHTML =
      (currencyContext.currencySelected === '₦')? 
      "NGN " + numberWithCommas(minPriceRangeValue) +
      " - NGN"+ numberWithCommas(maxPriceRangeValue):
      "USD " + numberWithCommas(minPriceRangeValue) +
      " - USD"+ numberWithCommas(maxPriceRangeValue);
      // (currencyContext.currencySelected === '₦')? " - NGN" : " - USD" +
      // numberWithCommas(maxPriceRangeValue);
    setMinPriceRangeValue(slide1);
    setMaxPriceRangeValue(slide2);
    filterPrice();
    console.log('min', slide1)
    console.log('max', slide2)
  }

  window.onload = function () {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
      var sliders = sliderSections[x].getElementsByTagName("input");
      for (var y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].oninput = getVals;
          // Manually trigger event first time to display values
          sliders[y].oninput();
        }
      }
    }
  };

  //   BUTTON ACTIONS

  //   Handle Like Button Click
  const handleArtworkLike = function (e) {
    e.stopPropagation();
    if (e.target.classList.contains("liked")) {
      e.target.classList.remove("liked");
      e.target.style.opacity = 0.6;
    } else {
      e.target.classList.add("liked");
      e.target.style.opacity = 0.9;
    }
  };
  //   Handle Like Cart Click
  const handleArtworkCart = function (e) {
    e.stopPropagation();
  };

  const filter = (value) => {
    const filteredProducts = products.filter(product =>product.category === value)
    setOriginalRows(filteredProducts)
  }

  const convertPrice = (product) => {
    return (product.price && currencyContext.currencySelected === "₦")
    ? Math.round(((product.price) + (product.price * 0.35) + (20 * currencyContext.rate)))
    : Math.round(((product.price / currencyContext.rate) + ((product.price * 0.35)/currencyContext.rate) + 20))
  }

  const filterPrice = () => {
    let minValue = minPriceRangeValue
    let maxValue = maxPriceRangeValue
    // // console.log('value-k', value);
    // // console.log('value-rows', products);
    const filteredProducts = products.filter(product => (convertPrice(product) >= minValue &&  convertPrice(product) <= maxValue))
    // console.log('kkk', filteredProducts);
    setOriginalRows(filteredProducts)
  }

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      setUserLoggedIn(true);
    }
  }, []);

  //   USEEFFECT
  useEffect(() => {
    const allColors = [
      "white",
      "black",
      "yellow",
      "blue",
      "red",
      "green",
      "brown",
      "purple",
      "violet",
      "cyan",
    ];
    setAllColors(allColors);
  }, []);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  useEffect(() => {
    setOriginalRows(products);
    if(category){
      filter(category)
  }
  let minPrice;
  let maxPrice;
  let step;
  if(currencyContext.currencySelected === '$'){
    step = 1
    minPrice = 1
    maxPrice = 1000
  }else {
    step = 1000
    minPrice = 1 * currencyContext.rate
    maxPrice = 1000 * currencyContext.rate
  }
    // console.log('cate',category);
    setMinPriceRangeValue(minPrice)
    setMaxPriceRangeValue(maxPrice)
    setStepPriceRangeValue(step)
  }, [products,category]);

  return (
    <div className="artworks-sort-container">
      <Header />
      <div className="artworks-sort-container--inner">
        {/* Artwork sort main */}
        <main className="artworks-sort-main-section section--right">
          <div className="artist-works--inner">
            {/* Title Sort */}
            <div className="title-sort">SORT</div>

            {/* Next */}
            {/*  Boxes Section*/}
            <div className="artwork-box-section">
              {/* Using the current items from the pagination pluugin, loop through given the artwork as the center as iteration*/}
              {pageLayout === "grid" ? (
                <div className="grid-box-layout">
                  {/* GRID LAYOUT */}
                  <Masonry
                    breakpointCols={artworksSortBreakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {/* Sort Bar / Sort Menu */}
                    <div className="sort-bar">
                      <div className="sort-bar--inner">
                        {/* Pge Content Sorting */}
                        <div className="pagecontent-sorting">
                          <div
                            ref={paintingLinkRef}
                            onClick={handlePaintingLinkClick}
                            className="actions-link painting-link active-link"
                          >
                            Painting
                          </div>

                          {/* Category B */}
                          <div
                            ref={categoryBLinkRef}
                            onClick={
                                handleCategoryBLinkClick  
                              }
                            className="actions-link category-b-link"
                          >
                            Prints
                          </div>

                          {/* Category C */}
                          <div
                            ref={categoryCLinkRef}
                            onClick={handleCategoryCLinkClick}
                            className="actions-link category-c-link"
                          >
                            Drawing
                          </div>

                          {/* Sculpture */}
                          {/* <div
                            ref={sculptureLinkRef}
                            onClick={sculptureLinkClick}
                            className="actions-link sculpture-link"
                          >
                            Sculpture
                          </div> */}

                          {/* Design */}
                          <div
                            ref={designLinkRef}
                            onClick={designLinkClick}
                            className="actions-link design-link"
                          >
                            Photography
                          </div>
                        </div>

                        {/* Popular or Recent Sorting */}
                        {/* <div className="popular-or-recent-sorting"> */}
                        {/* Most Recent */}
                        {/* <div
                            ref={mostRecentLinkRef}
                            onClick={mostRecentLinkClick}
                            className="actions-link most-recent-link active-link"
                          >
                            Most Recent
                          </div> */}

                        {/* Most Popular */}
                        {/* <div
                            ref={mostPopularLinkRef}
                            onClick={mostPopularLinkClick}
                            className="actions-link most-popular-link"
                          >
                            Most Popular
                          </div>
                        </div> */}

                        {/* Artist Box View navigation  */}
                        <div className="artist-view-navigation">
                          {/* Style checkbox */}
                          {/* <div className="artwork-style-dropdown"> */}
                            {/* STYLE */}
                            {/* <label
                              htmlFor="results-per-page"
                              className="form-label  artwork-style-form-label"
                            >
                              STYLE:
                            </label>
                            <div className="form-group form-group-dropdown mb-3">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) =>
                                  setArtworkStyle(Number(e.target.value))
                                }
                                style={{ cursor: "pointer" }}
                                value={artworkStyle}
                              >
                                <option defaultValue="all">ALL</option>
                              </select>
                            </div>
                          </div> */}
                          {/* Price ranges */}
                          <div className="price-ranges-section">
                            <div className="price-range-title-value">
                              <p className="artist-price-range-title">PRICE</p>
                              {/* Price ranges */}
                              <div className="rangeValues"></div>
                            </div>
                            <section className="range-slider">
                              <input
                                value={minPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step={stepPriceRangeValue}
                                type="range"
                                className="slider-1"
                                onChange={getVals}
                              />
                              <input
                                value={maxPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-2"
                                onChange={getVals}
                              />
                            </section>
                          </div>

                          {/* SELECT MATCHING COLORS */}
                          {/* <div className="matching-colors artist-artwork-matching-colors">
                            <div className="mb-1 matching-colors-title artist-matching-colors-title">
                              Color
                            </div> */}
                            {/* Matching colors div*/}
                            {/* <div className="matching-colors-select">
                              <div className="matching-colors-select-inner "> */}
                                {/* Mapping through the colors*/}
                                {/* {allColors &&
                                  allColors.map((color, i) => (
                                    <div
                                      key={i}
                                      className={`color artist-artwork-color color-${i}-${color}`}
                                      style={{ backgroundColor: color }}
                                      onClick={(e) =>
                                        handleMatchingColorClick(e, color)
                                      }
                                    ></div>
                                  ))}
                              </div>
                            </div>
                          </div> */}

                          {/* Results per page checkbox */}
                          <div className="results-per-page artworks-results-per-page">
                            {/* RESULTS PER PAGE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label artwork-results-per-page-form-label"
                            >
                              RESULTS PER PAGE:
                            </label>
                            <div className="form-group form-group-dropdown">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) => {
                                  setResultsPerPage(Number(e.target.value));
                                  setCurrentPage(1);
                                }}
                                style={{ cursor: "pointer" }}
                                value={resultsPerPage}
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option defaultValue="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                          </div>

                          {/* Layout Selected Section */}
                          <div className="layout-select-section">
                            <div className="layout-select-title artist-layout-select-title">
                              VIEW
                            </div>
                            <div className="layout-select-options">
                              {/* Layout Grid */}
                              <div className="layout-btn layout-grid">
                                <button onClick={(e) => setPageLayout("grid")}>
                                  GRID
                                </button>
                              </div>

                              {/* Layout Full width */}
                              <div className="layout-btn layout-full-width artist-layout-grid-btn">
                                <button onClick={(e) => setPageLayout("full")}>
                                  Full Width
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Artist Artwork Boxes for the Grid layout section */}
                    {/* array of JSX items */}
                    {originalRows && !isEmpty(originalRows) ? (
                      currentItems?.map((artwork, i) => (
                        <div
                          key={i + 1}
                          className={`grid-artwork-box`}
                          id={`artwork-box-${i + 1}`}
                        >
                          <a
                            href={
                              artwork.artist.user_id &&
                              artwork.artist.user_id.username
                                ? `/artwork/${artwork.artist.user_id.username}/${artwork.slug}`
                                : `/artworks`
                            }
                            // href={`/artworks/${originalRows[artwork].artist.user_id.firstname}/${originalRows[artwork].title}`}
                          >
                            {/* <img
                              src={
                                originalRows &&
                                require("../../../../src/images/" +
                                  originalRows[artwork].displayImgSrc)
                              }
                              alt="display-img"
                              className="display-img"
                            /> */}

                            <img
                              alt="display-img"
                              className="display-img"
                              src={`https://ik.imagekit.io/pg3ftznydl/${artwork.photo.substring(
                                47
                              )}`}
                            />
                          </a>
                          {/* Box Footer Section */}
                          <div className="grid-artwork-box-footer">
                            {/* Artwork Name */}
                            <div className="artwork-name">{artwork.title}</div>

                            {/* Artist Name */}
                            <div className="artist-name">
                              Artist:{" "}
                              {artwork.artist.user_id &&
                              artwork.artist.user_id.firstname
                                ? artwork.artist.user_id.firstname +
                                  " " +
                                  artwork.artist.user_id.lastname
                                : "Unavailable"}
                            </div>

                            {/* Lower Side of footer */}
                            <div className="lower-box-footer">
                              {/* Artwork Price */}
                              <CurrencyContext.Consumer>
                                {(currencyContext) => (
                                  <div className="artwork-price">
                                    {currencyContext.currencySelected}{" "}
                                    {displayPrice(artwork, currencyContext)}
                                  </div>
                                )}
                              </CurrencyContext.Consumer>

                              {/* Action Buttons */}
                              {userLoggedIn &&  <div className="action-buttons">
                                {/* Like Button */}
                                {/* <div className="like-button">
                                  <img
                                    onClick={handleArtworkLike}
                                    src={likeBtn}
                                    alt="like-btn"
                                  />
                                </div> */}

                                {/* Cart Button */}
                                <div className="cart-button">
                                  <img
                                    onClick={()=>handleAddToCart(artwork)}
                                    src={cartBtn}
                                    alt="cart-btn"
                                  />
                                </div>
                              </div>}
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="artwork--loader-wrapper">
                        <LoadingSpinner />
                      </div>
                    )}
                  </Masonry>
                </div>
              ) : (
                <div className="full-box-layout">
                  {/* FULL WIDTH BOX LAYOUT */}
                  <div className="full-box-layout--inner artist-full-box-layout--inner">
                    {/* Sort Bar / Sort Menu */}
                    <div className="sort-bar" style={{ height: "max-content" }}>
                      <div className="sort-bar--inner">
                        {/* Pge Content Sorting */}
                        <div className="pagecontent-sorting">
                          <div
                            ref={paintingLinkRef}
                            onClick={handlePaintingLinkClick}
                            className="actions-link painting-link active-link"
                          >
                            Painting
                          </div>

                          {/* Category B */}
                          <div
                            ref={categoryBLinkRef}
                            onClick={handleCategoryBLinkClick}
                            className="actions-link category-b-link"
                          >
                            Prints
                          </div>

                          {/* Category C */}
                          <div
                            ref={categoryCLinkRef}
                            onClick={handleCategoryCLinkClick}
                            className="actions-link category-c-link"
                          >
                            Drawings
                          </div>

                          {/* Sculpture */}
                          <div
                            ref={sculptureLinkRef}
                            onClick={sculptureLinkClick}
                            className="actions-link sculpture-link"
                          >
                            Sculpture
                          </div>

                          {/* Design */}
                          <div
                            ref={designLinkRef}
                            onClick={designLinkClick}
                            className="actions-link design-link"
                          >
                            Design
                          </div>
                        </div>

                        {/* Popular or Recent Sorting */}
                        {/* <div className="popular-or-recent-sorting"> */}
                        {/* Most Recent */}
                        {/* <div
                            ref={mostRecentLinkRef}
                            onClick={mostRecentLinkClick}
                            className="actions-link most-recent-link active-link"
                          >
                            Most Recent
                          </div> */}

                        {/* Most Popular */}
                        {/* <div
                            ref={mostPopularLinkRef}
                            onClick={mostPopularLinkClick}
                            className="actions-link most-popular-link"
                          >
                            Most Popular
                          </div> */}
                        {/* </div> */}

                        {/* Artist Box View navigation  */}
                        <div className="artist-view-navigation">
                          {/* Style checkbox */}
                          <div className="artwork-style-dropdown">
                            {/* STYLE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label  artwork-style-form-label"
                            >
                              STYLE:
                            </label>
                            <div className="form-group form-group-dropdown mb-3">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) =>
                                  setArtworkStyle(Number(e.target.value))
                                }
                                style={{ cursor: "pointer" }}
                                value={artworkStyle}
                              >
                                <option defaultValue="all">ALL</option>
                              </select>
                            </div>
                          </div>
                          {/* Price ranges */}
                          <div className="price-ranges-section">
                            <div className="price-range-title-value">
                              <p className="artist-price-range-title">PRICE</p>
                              {/* Price ranges */}
                              <div className="rangeValues"></div>
                            </div>
                            <section className="range-slider">
                              <input
                                value={minPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-1"
                                onChange={getVals}
                              />
                              <input
                                value={maxPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-2"
                                onChange={getVals}
                              />
                            </section>
                          </div>

                          {/* SELECT MATCHING COLORS */}
                          <div className="matching-colors artist-artwork-matching-colors">
                            <div className="mb-1 matching-colors-title artist-matching-colors-title">
                              Color
                            </div>
                            {/* Matching colors div*/}
                            <div className="matching-colors-select">
                              <div className="matching-colors-select-inner ">
                                {/* Mapping through the colors*/}
                                {allColors &&
                                  allColors.map((color, i) => (
                                    <div
                                      key={i}
                                      className={`color artist-artwork-color color-${i}-${color}`}
                                      style={{ backgroundColor: color }}
                                      onClick={(e) =>
                                        handleMatchingColorClick(e, color)
                                      }
                                    ></div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          {/* Results per page checkbox */}
                          <div className="results-per-page artworks-results-per-page">
                            {/* RESULTS PER PAGE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label artwork-results-per-page-form-label"
                            >
                              RESULTS PER PAGE:
                            </label>
                            <div className="form-group form-group-dropdown">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) => {
                                  setResultsPerPage(Number(e.target.value));
                                  setCurrentPage(1);
                                }}
                                style={{ cursor: "pointer" }}
                                value={resultsPerPage}
                              >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option defaultValue="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                          </div>

                          {/* Layout Selected Section */}
                          <div className="layout-select-section">
                            <div className="layout-select-title artist-layout-select-title">
                              VIEW
                            </div>
                            <div className="layout-select-options">
                              {/* Layout Grid */}
                              <div className="layout-btn layout-grid artist-layout-grid">
                                <button onClick={(e) => setPageLayout("grid")}>
                                  GRID
                                </button>
                              </div>

                              {/* Layout Full width */}
                              <div className="layout-btn layout-full-width artist-layout-full-width-btn">
                                <button onClick={(e) => setPageLayout("full")}>
                                  Full Width
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Artist Artwork Boxes for the Full Width layout section */}
                    <div className="artist-full-width-box-container artworks-sort-full-width-box-container ">
                      {originalRows && !isEmpty(originalRows) ? (
                        currentItems.map((artwork, i) => (
                          <div
                            key={i + 1}
                            className={`full-artwork-box artworks-sort-artwork-box`}
                            id={`artwork-box-${i + 1}`}
                          >
                            {/* <a
                              href={`/artworks/${originalRows[artwork].artist.user_id.firstname}/${originalRows[artwork].title}`}
                            > */}
                            {/* <img
                                src={
                                  originalRows &&
                                  require("../../../../src/images/" +
                                    originalRows[artwork].displayImgSrc)
                                }
                                alt="display-img"
                                className="artworks-sort-full-display-img"
                              /> */}
                            <img
                              alt=""
                              className="artworks-sort-full-display-img"
                              src={`https://ik.imagekit.io/pg3ftznydl/${artwork.photo.substring(
                                47
                              )}`}
                            />

                            {/* </a> */}
                            {/* Box Footer Section */}
                            <div className="full-artwork-box-footer">
                              {/* Artwork Name */}
                              <div className="artwork-name">
                                {artwork.title}
                              </div>

                              {/* Artist Name */}
                              <div className="artist-name">
                                {/* {(product.artist.user_id && product.artist.user_id.firstname)
                      ? product.artist.user_id.firstname +
                        " " +
                        product.artist.user_id.lastname
                      : "Artist Name"} */}
                                Artist:{" "}
                                {artwork.artist.user_id &&
                                artwork.artist.user_id.firstname
                                  ? artwork.artist.user_id.firstname +
                                    " " +
                                    artwork.artist.user_id.lastname
                                  : "Unavailable"}
                              </div>

                              {/* Lower Side of footer */}
                              <div className="lower-box-footer">
                                {/* Artwork Price */}
                                <div className="artwork-price">
                                  NGN
                                  {numberWithCommas(artwork.price)}
                                </div>

                                {/* Action Buttons */}
                                <div className="action-buttons">
                                  {/* Like Button */}
                                  <div className="like-button">
                                    <img
                                      onClick={handleArtworkLike}
                                      src={likeBtn}
                                      alt="like-btn"
                                      className="full-like-btn"
                                    />
                                  </div>

                                  {/* Cart Button */}
                                  <div className="full-cart-button">
                                    <img
                                      onClick={handleArtworkCart}
                                      src={cartBtn}
                                      alt="cart-btn"
                                      className="full-cart-btn"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <LoadingSpinner />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* End */}
            {/* Footer / Pagination */}

            <div className="collection-view-footer">
              <div className="collection-view-footer--inner">
                <div className="pagination-section">
                  {originalRows && !isEmpty(originalRows) && (
                    <Pagination
                      paginate={paginate}
                      rowsPerPage={resultsPerPage}
                      totalPosts={originalRows.length}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ArtworksSort;
