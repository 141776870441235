import React, { useRef, useState, useEffect } from "react";
import "./Favourites.css";
import likeBtn from "../../../images/like-icon.svg";
import cartBtn from "../../../images/cart-icon.svg";
import { Pagination } from "../../Pagination/Pagination";
import Masonry from "react-masonry-css";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import LoadingSpinner from "../../loading-spinner/LoadingSpinner";
import isEmpty from "../../../validation/is-empty";
import { numberWithCommas } from "../../../utils/numberWithCommas";

function createData(artworks) {
  return {
    //   Note that artworks is an array / list of artworks
    ...artworks,
  };
}

const rows = [
  createData({
    artworkName: "The flying tornado in the storm",
    galleryName: "Michelangelo",
    displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    price: "500000",
  }),

  createData({
    artworkName: "The flying ship",
    galleryName: "Picasso",
    displayImgSrc: "register.jpg",
    price: "400000",
  }),
  createData({
    artworkName: "The flying horse in the dessert that gallops",
    galleryName: "Daniel",
    displayImgSrc: "sell.jpg",
    price: "900000",
  }),
  createData({
    artworkName: "The flying car",
    galleryName: "Yahiko",
    displayImgSrc: "register.jpg",
    price: "1200000",
  }),
  createData({
    artworkName: "The flying plane in the sky that never falls until time ends",
    galleryName: "Nagato",
    displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    price: "300000",
  }),
  createData({
    artworkName: "The flying plane",
    galleryName: "Nagato",
    displayImgSrc: "register2.jpg",
    price: "500000",
  }),
  createData({
    artworkName: "The flying cart",
    galleryName: "Levi Ackermann",
    displayImgSrc: "register.jpg",
    price: "2100000",
  }),
  createData({
    artworkName: "The flying horse",
    galleryName: "Yahiko",
    displayImgSrc: "register.jpg",
    price: "1200000",
  }),
  createData({
    artworkName: "The flying plane",
    galleryName: "Nagato",
    displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    price: "300000",
  }),
  createData({
    artworkName: "The flying plane",
    galleryName: "Nagato",
    displayImgSrc: "register2.jpg",
    price: "500000",
  }),
  createData({
    artworkName: "The flying cart",
    galleryName: "Levi Ackermann",
    displayImgSrc: "register.jpg",
    price: "2100000",
  }),
  createData({
    artworkName: "The flying horse",
    galleryName: "Yahiko",
    displayImgSrc: "register.jpg",
    price: "1200000",
  }),
  createData({
    artworkName: "The flying plane",
    galleryName: "Nagato",
    displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
    price: "300000",
  }),
  createData({
    artworkName: "The flying plane",
    galleryName: "Nagato",
    displayImgSrc: "register2.jpg",
    price: "500000",
  }),
  createData({
    artworkName: "The flying cart",
    galleryName: "Levi Ackermann",
    displayImgSrc: "register.jpg",
    price: "2100000",
  }),
];

function Favourites() {
  // Functions, States and Variables.
  // States
  const [originalRows, setOriginalRows] = useState(null);
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [pageLayout, setPageLayout] = useState("grid");
  const [currentItems, setCurrentItems] = useState(null);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      const currentRows = originalRows?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  //   Column Breakpoint
  const artworksSortBreakpointColumnsObj = {
    default: 5,
    2060: 4,
    1580: 3,
    1190: 2,
    860: 1,
    650: 1,
  };

  // Functions

  //   BUTTON ACTIONS

  //   Handle Like Button Click
  const handleArtworkLike = function (e) {
    e.stopPropagation();
    if (e.target.classList.contains("liked")) {
      e.target.classList.remove("liked");
      e.target.style.opacity = 0.6;
    } else {
      e.target.classList.add("liked");
      e.target.style.opacity = 0.9;
    }
  };
  //   Handle Like Cart Click
  const handleArtworkCart = function (e) {
    e.stopPropagation();
  };

  useEffect(() => {
    setOriginalRows(rows);
  }, []);
  return (
    <div className="favourites-container">
      {/* Layout Selected Section */}
      <div className="favourites-layout-select-section">
        <div className="layout-select-title favourites-layout-select-title">
          VIEW
        </div>
        <div className="favourites-layout-select-options">
          {/* Layout Grid */}
          <div className="layout-btn layout-grid favourites-layout-grid">
            <button onClick={(e) => setPageLayout("grid")}>GRID</button>
          </div>

          {/* Layout Full width */}
          <div className="layout-btn layout-full-width favourites-layout-full-width-btn">
            <button onClick={(e) => setPageLayout("full")}>Full Width</button>
          </div>
        </div>
      </div>

      {/*Favourites Container RESULTS PER PAGE */}
      <div className="favourites-results-per-page-wrapper">
        <label
          htmlFor="favourites-results-per-page-input"
          className="form-label favourites-results-per-page-form-label"
        >
          RESULTS PER PAGE:
        </label>
        <div className="form-group form-group-dropdown">
          <div className="left-arrow-section">
            <img src={arrowUp} alt="arrow-up" />
            <img src={arrowDown} alt="arrow-down" />
          </div>
          <select
            name="results-per-page"
            id="favourites-results-per-page-input"
            aria-describedby="resultsPerPage"
            className="form-control form-dropdown"
            onChange={(e) => {
              setResultsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
            style={{ cursor: "pointer" }}
            value={resultsPerPage}
          >
            <option defaultValue="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        </div>
      </div>

      {/* Favourites main section */}
      <main className="favourites-container-main-section">
        {pageLayout === "grid" ? (
          <div className="grid-box-layout">
            {/* GRID LAYOUT */}
            <Masonry
              breakpointCols={artworksSortBreakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {originalRows && !isEmpty(originalRows) ? (
                currentItems?.map((item, i) => (
                  <div
                    key={i + 1}
                    className={`grid-artwork-box`}
                    id={`artwork-box-${i + 1}`}
                  >
                    <a
                      href={`/artworks/${item.galleryName}/${item.artworkName}`}
                    >
                      <img
                        src={
                          originalRows &&
                          require("../../../../src/images/" +
                            item.displayImgSrc)
                        }
                        alt="display-img"
                        className="display-img"
                      />
                    </a>
                    {/* Box Footer Section */}
                    <div className="grid-artwork-box-footer">
                      {/* Artwork Name */}
                      <div className="artwork-name">{item.artworkName}</div>

                      {/* Curator Name */}
                      <div className="artist-name">
                        Curator: {item.galleryName}
                      </div>

                      {/* Lower Side of footer */}
                      <div className="lower-box-footer">
                        {/* Artwork Price */}
                        <div className="artwork-price">
                          NGN
                          {numberWithCommas(item.price)}
                        </div>

                        {/* Action Buttons */}
                        <div className="action-buttons">
                          {/* Like Button */}
                          <div className="like-button">
                            <img
                              onClick={handleArtworkLike}
                              src={likeBtn}
                              alt="like-btn"
                            />
                          </div>

                          {/* Cart Button */}
                          <div className="cart-button">
                            <img
                              onClick={handleArtworkCart}
                              src={cartBtn}
                              alt="cart-btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="artwork--loader-wrapper">
                  <LoadingSpinner />
                </div>
              )}
            </Masonry>
          </div>
        ) : (
          <div className="full-box-layout">
            {/* FULL WIDTH BOX LAYOUT */}
            <div className="favourites-full-box-layout--inner">
              {/* Curator Artwork Boxes for the Full Width layout section */}
              <div className="favourites-sort-full-width-box-container ">
                {originalRows && !isEmpty(originalRows) ? (
                  currentItems.map((item, i) => (
                    <div
                      key={i + 1}
                      className={`full-artwork-box artworks-sort-artwork-box favourites-sort-artwork-box`}
                      id={`artwork-box-${i + 1}`}
                    >
                      <a
                        href={`/artworks/${item.galleryName}/${item.artworkName}`}
                      >
                        <img
                          src={
                            originalRows &&
                            require("../../../../src/images/" +
                              item.displayImgSrc)
                          }
                          alt="display-img"
                          className="artworks-sort-full-display-img favourites-sort-full-display-img"
                        />
                      </a>
                      {/* Box Footer Section */}
                      <div className="full-artwork-box-footer">
                        {/* Artwork Name */}
                        <div className="artwork-name">{item.artworkName}</div>

                        {/* Curator Name */}
                        <div className="artist-name">
                          Curator: {item.galleryName}
                        </div>

                        {/* Lower Side of footer */}
                        <div className="lower-box-footer">
                          {/* Artwork Price */}
                          <div className="artwork-price">
                            NGN
                            {numberWithCommas(item.price)}
                          </div>

                          {/* Action Buttons */}
                          <div className="action-buttons">
                            {/* Like Button */}
                            <div className="like-button">
                              <img
                                onClick={handleArtworkLike}
                                src={likeBtn}
                                alt="like-btn"
                                className="full-like-btn"
                              />
                            </div>

                            {/* Cart Button */}
                            <div className="full-cart-button">
                              <img
                                onClick={handleArtworkCart}
                                src={cartBtn}
                                alt="cart-btn"
                                className="full-cart-btn"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="artwork--loader-wrapper">
                    <LoadingSpinner />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </main>
      {/* Footer / Pagination */}

      <div className="collection-view-footer">
        <div className="collection-view-footer--inner">
          <div className="collection-footer">
            <div className="pagination-section">
              {originalRows && !isEmpty(originalRows) && (
                <Pagination
                  paginate={paginate}
                  rowsPerPage={resultsPerPage}
                  totalPosts={originalRows.length}
                  currentPage={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Favourites;
