import React, { useState, useRef, useEffect } from "react";
import "./Curators.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import arrowRight from "../../images/arrow-right.png";
import searchIcon from "../../images/search.png";
import Footer from "../LandingPage/Footer/Footer";
import { Pagination } from "../Pagination/Pagination";
import Header from "../LandingPage/Header/Header";
import { isEmpty } from "lodash";

function Curators() {
  function createData(id, curatorName, displayImgSrc) {
    return {
      id,
      curatorName,
      displayImgSrc,
    };
  }

  const rows = [
    createData(1, "Eclair", "Pic 2015-01-23 17-42-22.jpg"),
    createData(2, "Cupcake", "register2.jpg"),
    createData(3, "Donut", "register.jpg"),
    createData(4, "Frozen yoghurt", "register2.jpg"),
    createData(5, "Gingerbread", "register.jpg"),
    createData(6, "Honeycomb", "register.jpg"),
    createData(7, "Ice cream sandwich", "Pic 2015-01-23 17-42-22.jpg"),
    createData(8, "Jelly Bean", "register.jpg"),
    createData(9, "KitKat", "Pic 2015-01-23 17-42-22.jpg"),
    createData(10, "Lollipop", "register2.jpg"),
    createData(11, "Donak", "Pic 2015-01-23 17-42-22.jpg"),
    createData(12, "Marshmallo", "Pic 2015-01-23 17-42-22.jpg"),
    createData(13, "Nougat", "register.jpg"),
    createData(14, "Oreo", "register2.jpg"),
  ];
  //  Implement the footerpagination and border
  // Functions and Variables
  const [originalRows, setOriginalRows] = useState(rows);
  const [sortByLocation, setSortByLocation] = useState("all");
  const [searchedValue, setSearchedValue] = useState("");
  const [resultsPerPage, setResultsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState(null);
  const [filteredRows, setFilteredRows] = useState(null);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      // Handle on change or oninput of the search bar
      const filteredCurators = originalRows?.filter((row) => {
        return row.curatorName
          .toLowerCase()
          .includes(searchedValue.toLowerCase());
      });
      setFilteredRows(filteredCurators);

      const currentRows = filteredCurators?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage, searchedValue]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  // Refs
  const searchResultTextRef = useRef();
  // Functions

  const handleSearchOnChange = function (e) {
    // Set the search data state to the search value
    setSearchedValue(e.target.value);
    // let searchResultText = document.querySelector(".search-result-text");
    // // Handle Search Result Text View when search is not !empty
    // if (e.target.value !== "") {
    //   searchResultText.classList.remove("none");
    // }

    // // Remove searched text view when when search is === empty
    // if (e.target.value === "") {
    //   searchResultText.classList.add("none");
    // }
  };

  //   Handles the hover effect on the curators box
  const hoverEffectHandler = function () {
    if (!isEmpty(originalRows)) {
      originalRows?.map((row, i) => {
        document
          .getElementById(`curator-box-${i + 1}`)
          ?.addEventListener("mouseover", (e) => {
            e.stopPropagation();
            // alert("me");
            if (e.target.tagName === "IMG" || e.target.tagName === "DIV") {
              //   Remove the hidden class from the view box section
              e.target.parentElement.lastChild.classList.remove("hidden");
            }
          });
        document
          .getElementById(`curator-box-${i + 1}`)
          ?.addEventListener("mouseleave", (e) => {
            e.stopPropagation();
            //   Add the hidden class to the view box section
            e.target.lastChild.classList.add("hidden");
          });
      });
    }
  };

  useEffect(() => {
    hoverEffectHandler();
  }, [filteredRows]);

  useEffect(() => {
    console.log(currentItems);
  }, [currentItems]);
  return (
    <div className="curators">
      <Header />
      <div className="curators--inner">
        {/* Curators Header */}
        <div className="curators--header">
          <div className="curators--inner-title">Browse Curators</div>
          <div className="curators--header-inner">
            {/* Sort By Check box */}
            <div className="sort-by curators--header-column-one">
              {/* LOCATIONS */}
              <label
                htmlFor="sort-by"
                className="form-label curators-form-label"
              >
                FILTER BY LOCATION:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="sort-by"
                  id="sort-by"
                  aria-describedby="sortByLocation"
                  className="form-control form-dropdown"
                  onChange={(e) => setSortByLocation(e.target.value)}
                  value={sortByLocation}
                >
                  <option value="all">All Locations</option>
                  <option value="nigeria">Nigeria</option>
                  <option value="ghana">Ghana</option>
                  <option value="south-africa">South Africa</option>
                </select>
              </div>
            </div>

            {/* Search bar */}
            <div className="search-bar curators--header-column-two">
              {/* SEARCH BAR */}
              <div className="search-bar-section">
                <label
                  htmlFor="search-bar"
                  className="form-label curators-form-label"
                >
                  SEARCH FOR A CURATOR:
                </label>
                <div className="form-group form-group-dropdown mb-3">
                  <div className="left-search-icon-section">
                    <img src={searchIcon} alt="search" />
                  </div>
                  <input
                    name="search-bar"
                    id="search-bar"
                    type="search"
                    aria-describedby="searchBar"
                    className="form-control form-search"
                    placeholder="Search..."
                    value={searchedValue}
                    onChange={(e) => {
                      handleSearchOnChange(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Results per page checkbox */}
            <div className="results-per-page curators--header-column-three">
              {/* RESULTS PER PAGE */}
              <label
                htmlFor="results-per-page"
                className="form-label curators-form-label"
              >
                RESULTS PER PAGE:
              </label>
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="results-per-page"
                  id="results-per-page"
                  aria-describedby="resultsPerPage"
                  className="form-control form-dropdown"
                  onChange={(e) => {
                    setResultsPerPage(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  value={resultsPerPage}
                >
                  <option defaultValue="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* SEARCH RESULT TEXT THAT SHOWS WHEN SEARCH VALUE IS NOT EMPTY */}
        <div ref={searchResultTextRef} className="search-result-text none">
          {/* <div className="result-text-row-one">
                        <p>Search Result for "{searched}"</p>
                    </div>
                    <div className="result-text-row-two">
                        <p>{originalRows.length} Results</p>
                    </div> */}
        </div>

        {/* CURATOR BODY */}
        <div className="curator--body">
          <div className="curator--body-inner">
            {currentItems?.map((curator, i) => (
              <div
                key={i + 1}
                className={`curator-box`}
                id={`curator-box-${i + 1}`}
              >
                <img
                  src={require("../../../src/images/" + curator?.displayImgSrc)}
                  alt="display-img"
                />
                <a
                  href={`/curators/${curator?.curatorName}`}
                  className="curator-box-footer"
                >
                  <div className="curator-name">{curator?.curatorName}</div>
                  <div className="arrow-image">
                    <img
                      src={arrowRight}
                      alt="right-arrow"
                      className="right-arrow-img"
                    />
                  </div>
                </a>
                <div className="hover-view-section hidden">
                  <a href={`/curators/${curator?.curatorName}`}>VIEW</a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer / Pagination */}
        <div className="collection-view-footer">
          <div className="collection-view-footer--inner">
            <div className="collection-footer">
              <div className="pagination-section">
                {/* {!isEmpty(originalRows) && (
                  <Pagination
                    itemsPerPage={resultsPerPage}
                    setCurrentItems={setCurrentItems}
                    currentItems={currentItems}
                    rows={filteredRows}
                    itemsPerPageUnchangeable={false}
                  />
                )} */}

                {originalRows && !isEmpty(originalRows) && (
                  <Pagination
                    paginate={paginate}
                    rowsPerPage={resultsPerPage}
                    totalPosts={filteredRows?.length}
                    currentPage={currentPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Curators;
