import {
  SET_CURRENT_ARTIST,
  GET_ALL_USERS,
  GET_ALL_ARTISTS,
  GET_AN_ARTIST,
  REGISTER_CURATOR,
  GET_PRIVILEGE,
  GET_COLLECTIONS,
  GET_CURATOR,
  CREATE_COLLECTION,
  DELETE_USER,
  DELETE_COLLECTION,
  CLEAR_CURRENT_PROFILE,
  GET_ME,
  GET_USER_PHOTO,
  GET_ALL_CURATORS,
  CURATOR_PUSHED,
  STILL_ME,
  GETTING_CURATOR_ID
} from "../Constants";

const initialState = {
  artist: {},
  users: {},
  rising: [],
  curator: {},
  isLoading: false,
  privilege: "",
  collections: [],
  curators:[],
  profile: {},
  curator_id: ""
  // photo:""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_ARTIST:
      return {
        ...state,
        artist: action.payload
      };
    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case GET_ALL_ARTISTS:
      return {
        ...state,
        users: action.payload
      };
    case GET_AN_ARTIST:
      return {
        ...state,
        user: action.payload
      };
    case REGISTER_CURATOR:
      return {
        ...state,
        users: action.payload
      };
    case GET_PRIVILEGE:
      return {
        ...state,
        privilege: action.payload
      };
    case GET_COLLECTIONS:
      return {
        ...state,
        collections: action.payload
      };
    case GET_CURATOR:
      return {
        ...state,
        curator: action.payload
      };
    case CREATE_COLLECTION:
      return {
        ...state,
        isLoading: !action.payload
      };
    case DELETE_COLLECTION:
      return {
        ...state,
        isLoading: !action.payload
      };
    case DELETE_USER:
      return {
        ...state,
        isLoading: !action.payload
      };
    case CLEAR_CURRENT_PROFILE:
      return {
        ...state,
        artist: {},
        users: {},
        curator: {},
        privilege: "",
        collections: []
      };
    case GET_USER_PHOTO:
      return {
        ...state,
        photo: action.payload
      }
    case GET_ALL_CURATORS:
      return {
        ...state,
        curators: action.payload
      }
    case CURATOR_PUSHED:
      return {
        ...state,
        curator: action.payload
      }
    case STILL_ME:
      return {
        ...state,
        profile: action.payload
      }
    case GETTING_CURATOR_ID:
      return{
        ...state,
        curator_id: action.payload
      }
    
    default:
      return state;
  }
}
