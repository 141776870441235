import axios from 'axios';
import { api, SENDGRID_API_KEY } from '../../api/config';
import _ from 'lodash';
import {
  GET_ERRORS,
  GET_ALL_USERS,
  GET_ALL_ARTISTS,
  SET_CURRENT_ARTIST,
  GET_PRIVILEGE,
  GET_COLLECTIONS,
  GET_CURATOR,
  CREATE_COLLECTION,
  EDIT_COLLECTION,
  DELETE_COLLECTION,
  GET_USER_PHOTO,
  GET_ALL_CURATORS,
  DELETE_USER,
  CURATOR_PUSHED,
  GET_ME,
  STILL_ME,
  GETTING_CURATOR_ID,
  GET_AN_ARTIST,
} from '../Constants';
import isEmpty from '../../validation/is-empty';
import { toggleLoadingOff } from './productAction';

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["accesstoken"] = token;

// Register artist
export const registerArtist = userData => dispatch => {
  axios
    .post(api + 'snowafrica/artist/create', userData)
    .then(res => {
      const data = {
        bio: res.data.data.bio,
        location: res.data.data.location,
      };
      // console.log(res);
      dispatch(setCurrentArtist(data));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Get Privilege
export const getPrivilege = userData => dispatch => {
  dispatch({
    type: GET_PRIVILEGE,
    payload: userData,
  });
};


// Get Collections
export const getCollections = userData => dispatch => {
  axios
    .post(api + 'snowafrica/getcuratorlists', userData)
    .then(res => {
      dispatch({
        type: GET_COLLECTIONS,
        payload: res.data.data.reverse(),
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Get Curator
export const getCurator = userData => dispatch => {
  axios
    .post(api + 'snowafrica/curator')
    .then(res => {
      if (!isEmpty(res.data.data)) {
        dispatch({
          type: GET_CURATOR,
          payload: res.data.data,
        });

        dispatch({
          type: GETTING_CURATOR_ID,
          payload: res.data.data._id,
        });
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Create Collections
export const createCollection = (userData, data) => dispatch => {
  dispatch({
    type: CREATE_COLLECTION,
    payload: false,
  });
  axios
    .post(api + 'snowafrica/curatorlist/create', userData)
    .then(res => {
      // console.log(res.data);
      if (res.data) {
        dispatch({
          type: CREATE_COLLECTION,
          payload: true,
        });
        dispatch(getCollections(data));
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Edit Collections
export const editCollection = data => dispatch => {
  const updateData = {
    // query:
  };
  axios
    .put(api + 'snowafrica/curatorlist/', updateData)
    .then(res => {
      // console.log(res.data);
      if (res.data) {
        dispatch({
          type: EDIT_COLLECTION,
          payload: true,
        });
        dispatch(getCollections(data));
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Delete Collections
export const deleteCollection = (data) => dispatch => {
  console.log('data',data)
  // console.log('yes',userData)
  axios.post(api + 'snowafrica/product/delete', data)
    .then(res => {
      window.location.reload();
      // console.log('res',res.data);
      // dispatch({
      //   type: DELETE_COLLECTION,
      //   payload: true,
      // });
      // if (res.data) {
      //   dispatch({
      //     type: DELETE_COLLECTION,
      //     payload: true,
      //   });
        // dispatch(getCollections(data));
     // }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Rising stars
export const toggleRising = data => dispatch => {
  console.log(data);
  axios
  .put(api + 'snowafrica/artist/rising/', data)
  .then(res => {
    window.location.reload();
  })
  .catch(err => {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  });
};

//get rising stars


// Delete User
export const deleteUser = data => dispatch => {
  axios
  .delete(api + 'snowafrica/users/delete/'+data)
  .then(res => {
    window.location.reload();
  })
  .catch(err => {
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  });
};

// Register curator
export const registerCurator = userData => dispatch => {
  const data = {
    firstname: 'James',
    email: 'simonreuben23@gmail.com',
  };
  axios
    .post(api + 'snowafrica/curator/create', userData)
    .then(res => {
      // console.log(res);
    })
    .catch(err => {
      // console.log({ err });
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Get artist
export const getArtist = (userData, history) => dispatch => {
  axios
    .post(api + 'snowafrica/artist', {})
    .then(res => {
      // console.log(res.data);
      if (res.data != null && res.data.data != null) {
        const data = {
          bio: res.data.data.bio,
          location: res.data.data.location,
          artist_id: res.data.data._id,
        };
        dispatch(setCurrentArtist(data));
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};

// Get users
export const getAllUsers = (userData, history) => dispatch => {
  axios
    .get(api + 'snowafrica/users')
    // .get( api + "snowafrica/curator/all")
    .then(res => {
      // console.log(res.data);
      if (res.data != null) {
        dispatch({
          type: GET_ALL_USERS,
          payload: res.data,
        });
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err, //response.data
      });
    });
};

// Get artists
export const getAllArtists = (userData, history) => dispatch => {
  axios
    .get(api + 'snowafrica/users/artists')
    .then(res => {
      // console.log(res.data);
      if (res.data != null) {
        dispatch({
          type: GET_ALL_ARTISTS,
          payload: res.data,
        });
      }
    })
    .catch(err => {
      // console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err, //response.data
      });
    });
};

// Get an artists
export const getAnArtist = data => dispatch => {
  axios
  .post(api + 'snowafrica/artist/details/', data)
  .then(res => {
    console.log(res)
    dispatch({
      type: GET_AN_ARTIST,
      payload: res.data,
    });
  })
  .catch(err => {
    console.log({ err });
  });
};

// Set Logged in user
export const setCurrentArtist = data => {
  return {
    type: SET_CURRENT_ARTIST,
    payload: data,
  };
};

export const getPhoto = () => dispatch => {
  axios
    // .get(api + "snowafrica/users")
    .get(api + 'snowafrica/users/photo')
    .then(res => {
      dispatch({
        type: GET_USER_PHOTO,
        payload: res.data,
      });
    })
    .catch(err => {
      // console.log("ERROR", err)
    });
};

export const updatePictureURL = photo => dispatch => {
  const data1 = {
    photo: photo,
  };

  axios
    .put(api + 'snowafrica/user/', data1)
    .then(res => {
      // console.log("AFTER PUTTING URL", res)
      dispatch(getPhoto());
    })
    .catch(err => {
      // console.log("ERROR DURING UPDATE", err)
    });
};

export const uploadProfilePicture = data => dispatch => {
  axios
    .post(api + 'snowafrica/upload', data)
    .then(res => {
      dispatch(updatePictureURL(res.data.Location));
    })
    .catch(err => {});
};

export const getUserProfile = () => dispatch => {
  axios
    .get(api + 'snowafrica/artist')
    .then(res => {
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};

export const getAllCurators = () => dispatch => {
  axios
    .get(api + 'snowafrica/curator/all')
    .then(res => {
      dispatch({
        type: GET_ALL_CURATORS,
        payload: res.data.data,
      });
    })
    .catch(err => {
      // console.log("FAILED TO GET ALL CURATOR", err)
    });
};

export const curatorPusher = curator => dispatch => {
  // console.log("CURATOR PUSHER", curator)
  dispatch({
    type: CURATOR_PUSHED,
    payload: curator,
  });
};

export const getMe = () => dispatch => {
  var token = localStorage.getItem('jwtToken', token);
  var accesstoken = "JWT " + token;
  axios.defaults.headers.common["accesstoken"] = accesstoken;
  axios
    .get(api + 'snowafrica/user/me')
    .then(res => {
      console.log("RESPONSE FROM PROFILE",res.data.data)
      dispatch({
        type: GET_ME,
        payload: res.data.data.cart,
      });
      dispatch({
        type: STILL_ME,
        payload: res.data.data,
      });
      dispatch(toggleLoadingOff());
    })
    .catch(err => {
      console.log('ERROR FROM PROFILE', err);
    });
};

export const updateAddressPhone = data => dispatch => {
  axios
    .put(api + 'snowafrica/user/address', data)
    .then(res => {
      dispatch(getMe());
    })
    .catch(err => {
      console.log('ERROR', err);
    });
};

export const changeToAdmin = data => dispatch => {
  // console.log(data);
  axios
    .post(api + 'snowafrica/user/createAdmin', data)
    .then(res => {
      dispatch(getAllUsers());
    })
    .catch(err => {
      console.log({ err });
    });
};
