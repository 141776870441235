import { useEffect, useState } from "react";
import "./NewsletterForm.css";
import { decode } from "html-entities";

function NewsletterForm({
  status,
  message,
  onValidated,
  setSuccessHandlerObj,
  successHandlerObj,
  errorHandlerObj,
  setErrorHandlerObj,
}) {
  // Functions, states and variables
  // States
  const [email, setEmail] = useState("");

  const handleSubmitWaitlistEmail = (e) => {
    setErrorHandlerObj({ hasError: false, message: "" });
    setSuccessHandlerObj({ isSuccess: false, message: "" });

    if (email === "") {
      e.preventDefault();
      setErrorHandlerObj({
        hasError: true,
        message: "Please enter a valid email address",
      });

      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });
    e.preventDefault();
    // return email && email.indexOf("@") > -1 && isFormValidated;
    if (email && email.indexOf("@") > -1) {
      return isFormValidated;
    }
  };

  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split("-") ?? null;
    if ("0" !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setEmail("");

      // Remove success message after 20 seconds
      setTimeout(() => {
        setSuccessHandlerObj({ isSuccess: false, message: "" });
      }, 20000);
    }
  }, [successHandlerObj]);

  useEffect(() => {
    if (status === "success") {
      // Open User Info Modal For user to fill in expected features and their full name
      setSuccessHandlerObj({
        isSuccess: true,
        message: "You have successfully subscribed to our newsletter!",
      });
    }
  }, [status]);

  return (
    <section>
      <div className="mailing--email-form-container">
        <form className={`waitlist-form-container `}>
          <input
            type="email"
            id="mailing-email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <button
            onClick={handleSubmitWaitlistEmail}
            disabled={status === "sending"}
            className="mailing-email-signup-btn"
          >
            {status === "sending" ? "Signing up..." : "Sign up"}
          </button>
        </form>
      </div>

      <div className={"app_form_info"}>
        {status === "error" || errorHandlerObj.hasError ? (
          <div
            className={"app_form_error"}
            dangerouslySetInnerHTML={{
              __html: errorHandlerObj.message || getMessage(message),
            }}
          />
        ) : null}
        {status === "success" &&
          status !== "error" &&
          !errorHandlerObj.hasError && (
            <div
              className={`app_form_success ${
                successHandlerObj.isSuccess ? "" : "none"
              }`}
            >
              {successHandlerObj.message}
            </div>
          )}
      </div>
    </section>
  );
}

export default NewsletterForm;
