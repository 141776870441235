import { useEffect, useState } from "react";

function useDisplayMessage() {
  // Functions, States and
  // States
  const [errorHandlerObj, setErrorHandlerObj] = useState({
    hasError: false,
    message: "",
  });
  const [successHandlerObj, setSuccessHandlerObj] = useState({
    isSuccess: false,
    message: "",
  });
  // useEffects
  // If response is failure, remove success message
  useEffect(() => {
    if (errorHandlerObj.hasError) {
      setSuccessHandlerObj({ isSuccess: false, message: "" });
    }
  }, [errorHandlerObj]);

  // If response is success, remove failure message
  useEffect(() => {
    if (successHandlerObj.isSuccess) {
      setErrorHandlerObj({ hasError: false, message: "" });
    }
  }, [successHandlerObj]);

  return {
    errorHandlerObj,
    successHandlerObj,
    setErrorHandlerObj,
    setSuccessHandlerObj,
  };
}

export default useDisplayMessage;
