import axios from "axios";
import { api } from "../../api/config";
import _ from "lodash"
import { 
    CREATE_TRANSACTION, 
    FIND_TRANSACTION_BY_ID, 
    ADDED_ORDER_ITEM_TO_ORDER_CART,
    GET_ALL_TRANSACTIONS ,
    SUCCESS_CREATING_TRANSACTION,
    LOADED_TRANSACTION_OBJECT
} from "../Constants";
import { updateProduct } from "./productAction";
import { getMe } from './profilesAction';
import { updateOrderCart } from "./orderActions";

const token = localStorage.getItem("jwtToken");
axios.defaults.headers.common["accesstoken"] = token;

export const createTransaction = 
(
    data,
    products,
    navigate,
    setErrorHandlerObj,
    setIsLoading
  ) => dispatch => {
    let items  = data.items
//  console.log("TRANSACTION ID",data)
 axios
    .post(api + "snowafrica/transaction/create", data)
    .then(res => {
        items.map(item => {
            axios
                .post(api + 'snowafrica/user/removeFromCart', {id:item.product})
                .then(res => {
                })
                .catch(err => {
                console.log('ERROR', err);
                });
            
        })
        setIsLoading(true);
        const query = {
            _id: res.data.data.product
        }
        const update = {
            status: "sold"
        }
        const data = {
            query,
            update
        }
        dispatch(updateOrderCart(products))
        dispatch(updateProduct(data))
        dispatch({
            type: SUCCESS_CREATING_TRANSACTION
        })
        dispatch(getMe());
        setIsLoading(false);
        navigate('/order-review');
    })
    .catch( err => {
        console.log(err)
        setIsLoading(false);
        setErrorHandlerObj({
          hasError: true,
          message:
            err?.response?.data ||
            "Something Went Wrong. Please Check your Connection and try again",
        });
    })
}


export const findTransactionByQuery = () => dispatch => {

}

export const getAllTransactions = () => dispatch => {
    axios
        .get(api + "snowafrica/transaction/all")
        .then(res => {
            // console.log("RESPONSE FROM TRANSACTIONS", res.data.data)
            dispatch({
                type: GET_ALL_TRANSACTIONS,
                payload: res.data.data
            })
        })
        .catch( err => {
            console.log("ERROR", err)
        })
}

export const preTransactionDetail = (data) => dispatch => {
    dispatch({
        type: LOADED_TRANSACTION_OBJECT,
        payload: data
    })
}