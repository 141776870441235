import isEmpty from '../../validation/is-empty';
import {
  SET_CURRENT_USER,
  SET_ADMIN_LAYOUT,
  CLEAR_CURRENT_PROFILE,
  GET_CURRENCY_RATE,
  GET_RISING,
  SET_CURRENCY_RATE,
} from '../Constants';

const initialState = {
  isAuthenticated: false,
  user: {},
  rising:[],
  rate:'',
  isAdmin: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
      case GET_RISING:
        return {
          ...state,
          rising: action.payload
        };
    case SET_ADMIN_LAYOUT:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case GET_CURRENCY_RATE:
      return {
        ...state,
        rate: action.payload,
      };
    case SET_CURRENCY_RATE:
      return {
        ...state,
        rate: action.payload,
      };
    default:
      return state;
  }
}
