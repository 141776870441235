import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "./Artist.css";
import arrowDown from "../../images/arrow-down.png";
import arrowUp from "../../images/arrow-up.png";
import likeBtn from "../../images/like-icon.svg";
import cartBtn from "../../images/cart-icon.svg";
import { Pagination } from "../Pagination/Pagination";
import Masonry from "react-masonry-css";
import Top from "../LandingPage/Header/Top";
import isEmpty from "../../validation/is-empty";
import { numberWithCommas } from "../../utils/numberWithCommas";

function createData(
  id,
  artistName,
  displayImgSrc,
  backgroundImgSrc,
  artistBioText,
  artworks
) {
  return {
    id,
    artistName,
    displayImgSrc,
    backgroundImgSrc,
    artistBioText,
    //   Note that artworks is an array / list of artworks
    artworks,
  };
}

const rows = [
  createData(
    1,
    "Daniel Adeneye",
    "Pic 2015-01-23 17-42-22.jpg",
    "sell.jpg",
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque nisi quisquam quam dignissimos. Rem a maxime dicta aperiam explicabo molestiae earum similique. Excepturi natus sequi totam sint est. Quos, quasi.",
    [
      {
        artworkName: "The flying tornado in the storm",
        curatorName: "Michelangelo",
        displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
        price: "500000",
      },
      {
        artworkName: "The flying ship",
        curatorName: "Picasso",
        displayImgSrc: "register.jpg",
        price: "400000",
      },
      {
        artworkName: "The flying horse in the dessert that gallops",
        curatorName: "Daniel",
        displayImgSrc: "sell.jpg",
        price: "900000",
      },
      {
        artworkName: "The flying car",
        curatorName: "Yahiko",
        displayImgSrc: "register.jpg",
        price: "1200000",
      },
      {
        artworkName:
          "The flying plane in the sky that never falls until time ends",
        curatorName: "Nagato",
        displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
        price: "300000",
      },
      {
        artworkName: "The flying plane",
        curatorName: "Nagato",
        displayImgSrc: "register2.jpg",
        price: "500000",
      },
      {
        artworkName: "The flying cart",
        curatorName: "Levi Ackermann",
        displayImgSrc: "register.jpg",
        price: "2100000",
      },
      {
        artworkName: "The flying horse",
        curatorName: "Yahiko",
        displayImgSrc: "register.jpg",
        price: "1200000",
      },
      {
        artworkName: "The flying plane",
        curatorName: "Nagato",
        displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
        price: "300000",
      },
      {
        artworkName: "The flying plane",
        curatorName: "Nagato",
        displayImgSrc: "register2.jpg",
        price: "500000",
      },
      {
        artworkName: "The flying cart",
        curatorName: "Levi Ackermann",
        displayImgSrc: "register.jpg",
        price: "2100000",
      },
      {
        artworkName: "The flying horse",
        curatorName: "Yahiko",
        displayImgSrc: "register.jpg",
        price: "1200000",
      },
      {
        artworkName: "The flying plane",
        curatorName: "Nagato",
        displayImgSrc: "Pic 2015-01-23 17-42-22.jpg",
        price: "300000",
      },
      {
        artworkName: "The flying plane",
        curatorName: "Nagato",
        displayImgSrc: "register2.jpg",
        price: "500000",
      },
      {
        artworkName: "The flying cart",
        curatorName: "Levi Ackermann",
        displayImgSrc: "register.jpg",
        price: "2100000",
      },
    ]
  ),
];
function Artist() {
  // FUNCTIONS AND VARIABLES
  const { artistName } = useParams();

  // STATES
  const [originalRows, setOriginalRows] = useState(null);
  const [majorPageSortBasis, setMajorPageSortBasis] = useState("painting");
  const [subsidiaryPageSortBasis, setSubsidiaryPageSortBasis] =
    useState("recent");
  const [resultsPerPage, setResultsPerPage] = useState("5");
  const [artworkStyle, setArtworkStyle] = useState("all");
  const [pageLayout, setPageLayout] = useState("grid");
  const [minPriceRangeValue, setMinPriceRangeValue] = useState(1000);
  const [maxPriceRangeValue, setMaxPriceRangeValue] = useState(5000000);
  const [allColors, setAllColors] = useState();
  const [matchingColorsList, setMatchingColorsList] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);

  // Pagination Update
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * resultsPerPage;
  const indexOfFirstPost = indexOfLastPost - resultsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (originalRows) {
      const currentRows = originalRows.artworks?.slice(
        indexOfFirstPost,
        indexOfLastPost
      );

      setCurrentItems(currentRows);
    }
  }, [originalRows, currentPage, resultsPerPage]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  //Ref
  const paintingLinkRef = useRef();
  const categoryBLinkRef = useRef();
  const categoryCLinkRef = useRef();
  const sculptureLinkRef = useRef();
  const designLinkRef = useRef();
  const mostRecentLinkRef = useRef();
  const mostPopularLinkRef = useRef();

  //   Column Breakpoint
  const breakpointColumnsObj = {
    default: 4,
    2060: 3,
    1520: 2,
    1040: 1,
  };

  // FUNCTIONS

  // HANDLE SIDEBAR NAV LINKS
  // Handle Cashflows Link Click
  const handlePaintingLinkClick = function () {
    // Set Major Page Content Sort to Painting art content
    setMajorPageSortBasis("painting");
    // Handle color change on active class active
    paintingLinkRef.current.classList.add("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Category B Link Click
  const handleCategoryBLinkClick = function () {
    // Set Major Page Content Sort to Category B art content
    setMajorPageSortBasis("category-b");

    categoryBLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Category C Link Click
  const handleCategoryCLinkClick = function () {
    // Set Major Page Content Sort to Category C art content
    setMajorPageSortBasis("category-c");

    categoryCLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Sculpture Link Click
  const sculptureLinkClick = function () {
    // Set Major Page Content Sort to Sculpture art content
    setMajorPageSortBasis("sculpture");
    // Handle color change on active class active
    sculptureLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    designLinkRef.current.classList.remove("active-link");
  };

  // Handle Design Link Click
  const designLinkClick = function () {
    // Set Major Page Content Sort to Design art content
    setMajorPageSortBasis("design");
    // Handle color change on active class active
    designLinkRef.current.classList.add("active-link");
    paintingLinkRef.current.classList.remove("active-link");
    categoryBLinkRef.current.classList.remove("active-link");
    categoryCLinkRef.current.classList.remove("active-link");
    sculptureLinkRef.current.classList.remove("active-link");
  };

  // Handle Most Recent Link Click
  const mostRecentLinkClick = function () {
    // Set Subsidiary Page Content Sort to Most Recent page content
    setSubsidiaryPageSortBasis("recent");
    // Handle color change on active class active
    mostRecentLinkRef.current.classList.add("active-link");
    mostPopularLinkRef.current.classList.remove("active-link");
  };

  // Handle Most Popular Link Click
  const mostPopularLinkClick = function () {
    // Set Subsidiary Page Content Sort to Most Popular page content
    setSubsidiaryPageSortBasis("popular");
    // Handle color change on active class active
    mostPopularLinkRef.current.classList.add("active-link");
    mostRecentLinkRef.current.classList.remove("active-link");
  };

  //   Handle Matching colors click
  const handleMatchingColorClick = function (e, color) {
    let matchingColors = [];
    e.stopPropagation();
    // Check if the clicked color has already been selected before selecting
    if (e.target.classList.contains("color-selected")) {
      e.target.classList.remove("color-selected");
      //   filter out the color from the list of selected colors
      matchingColors = matchingColorsList.filter((col) => {
        return color !== col;
      });
      //   Then set the state to the colors left after filtering
      setMatchingColorsList([...matchingColors]);
      //   setMatchingColorsList(matchingColors);
    } else {
      // If it hasn't been selected
      e.target.classList.add("color-selected");
      //   Add the new color to the list of selected colors by setting the state
      setMatchingColorsList([...matchingColorsList, color]);
    }
    // setMatchingColorsList(matchingColors);
  };

  // PRICE RANGES SCRIPT
  function getVals() {
    // Get slider values
    var slide1 = parseFloat(document.querySelector(".slider-1").value);
    var slide2 = parseFloat(document.querySelector(".slider-2").value);
    // Neither slider will clip the other, so make sure we determine which is larger
    if (slide1 > slide2) {
      var tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    var displayElement = document.querySelector(".rangeValues");
    displayElement.innerHTML =
      "NGN " +
      numberWithCommas(minPriceRangeValue) +
      " - NGN" +
      numberWithCommas(maxPriceRangeValue);
    setMinPriceRangeValue(slide1);
    setMaxPriceRangeValue(slide2);
  }

  window.onload = function () {
    // Initialize Sliders
    var sliderSections = document.getElementsByClassName("range-slider");
    for (var x = 0; x < sliderSections.length; x++) {
      var sliders = sliderSections[x].getElementsByTagName("input");
      for (var y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].oninput = getVals;
          // Manually trigger event first time to display values
          sliders[y].oninput();
        }
      }
    }
  };

  //   BUTTON ACTIONS

  //   Handle Like Button Click
  const handleArtworkLike = function (e) {
    e.stopPropagation();
    if (e.target.classList.contains("liked")) {
      e.target.classList.remove("liked");
      e.target.style.opacity = 0.6;
    } else {
      e.target.classList.add("liked");
      e.target.style.opacity = 0.9;
    }
  };
  //   Handle Like Cart Click
  const handleArtworkCart = function (e) {
    e.stopPropagation();
  };

  //   USEEFFECT
  useEffect(() => {
    const allColors = [
      "white",
      "black",
      "yellow",
      "blue",
      "red",
      "green",
      "brown",
      "purple",
      "violet",
      "cyan",
    ];

    setOriginalRows(rows[0]);
    setAllColors(allColors);
  }, []);

  return (
    <div className="artist-container">
      <Top />
      <div className="artist-container--inner">
        {/* Left / Artist Column */}
        <div className="artist-left-column artist-bio">
          <div className="artist-bio--inner">
            {/* Left */}
            <div className="artist-bio--left artist-background-img-container">
              {/* Artist Background Image */}
              <img
                src={
                  originalRows &&
                  require("../../../src/images/" +
                    originalRows.backgroundImgSrc)
                }
                alt="img"
                className="artist-background-img"
              />
              {/* No of  Artworks */}
              <div className="artist-no-of-artworks">
                <p>{originalRows && originalRows.artworks.length} Art works</p>
              </div>
            </div>

            {/* Right */}
            <div className="artist-bio--right">
              {/* Artist Display Image */}
              <div className="artist-display-img-container">
                <img
                  src={
                    originalRows &&
                    require("../../../src/images/" + originalRows.displayImgSrc)
                  }
                  alt="img"
                  className="artist-display-img"
                />
              </div>

              <div className="artist-bio--inner">
                <div className="artist-bio-text">
                  {/* Artisst Name */}
                  <div className="artist-fullname">
                    {originalRows && originalRows.artistName}
                  </div>
                  {/* Artiist Bio Text */}
                  <div className="artist-about">
                    {originalRows && originalRows.artistBioText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right / Artist Works */}
        <div className="artist-right-column artist-works">
          <div className="artist-works--inner">
            {/* Title Sort */}
            <div className="title-sort">SORT</div>

            {/* Next */}
            {/*  Boxes Section*/}
            <div className="artwork-box-section">
              {/* Using the current items from the pagination pluugin, loop through given the artwork as the center as iteration*/}
              {pageLayout === "grid" ? (
                <div className="grid-box-layout">
                  {/* GRID LAYOUT */}
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {/* Sort Bar / Sort Menu */}
                    <div className="sort-bar">
                      <div className="sort-bar--inner">
                        {/* Pge Content Sorting */}
                        <div className="pagecontent-sorting">
                          <div
                            ref={paintingLinkRef}
                            onClick={handlePaintingLinkClick}
                            className="actions-link painting-link active-link"
                          >
                            Painting
                          </div>

                          {/* Category B */}
                          <div
                            ref={categoryBLinkRef}
                            onClick={handleCategoryBLinkClick}
                            className="actions-link category-b-link"
                          >
                            Category B
                          </div>

                          {/* Category C */}
                          <div
                            ref={categoryCLinkRef}
                            onClick={handleCategoryCLinkClick}
                            className="actions-link category-c-link"
                          >
                            Category C
                          </div>

                          {/* Sculpture */}
                          <div
                            ref={sculptureLinkRef}
                            onClick={sculptureLinkClick}
                            className="actions-link sculpture-link"
                          >
                            Sculpture
                          </div>

                          {/* Design */}
                          <div
                            ref={designLinkRef}
                            onClick={designLinkClick}
                            className="actions-link design-link"
                          >
                            Design
                          </div>
                        </div>

                        {/* Popular or Recent Sorting */}
                        <div className="popular-or-recent-sorting">
                          {/* Most Recent */}
                          <div
                            ref={mostRecentLinkRef}
                            onClick={mostRecentLinkClick}
                            className="actions-link most-recent-link active-link"
                          >
                            Most Recent
                          </div>

                          {/* Most Popular */}
                          <div
                            ref={mostPopularLinkRef}
                            onClick={mostPopularLinkClick}
                            className="actions-link most-popular-link"
                          >
                            Most Popular
                          </div>
                        </div>

                        {/* Artist Box View navigation  */}
                        <div className="artist-view-navigation">
                          {/* Style checkbox */}
                          <div className="artwork-style-dropdown">
                            {/* STYLE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label  artwork-style-form-label"
                            >
                              STYLE:
                            </label>
                            <div className="form-group form-group-dropdown mb-3">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) =>
                                  setArtworkStyle(Number(e.target.value))
                                }
                                style={{ cursor: "pointer" }}
                                value={artworkStyle}
                              >
                                <option defaultValue="all">ALL</option>
                              </select>
                            </div>
                          </div>
                          {/* Price ranges */}
                          <div className="price-ranges-section">
                            <div className="price-range-title-value">
                              <p className="artist-price-range-title">PRICE</p>
                              {/* Price ranges */}
                              <div className="rangeValues"></div>
                            </div>
                            <section className="range-slider">
                              <input
                                value={minPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-1"
                                onChange={getVals}
                              />
                              <input
                                value={maxPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-2"
                                onChange={getVals}
                              />
                            </section>
                          </div>

                          {/* SELECT MATCHING COLORS */}
                          <div className="matching-colors artist-artwork-matching-colors">
                            <div className="mb-1 matching-colors-title artist-matching-colors-title">
                              Color
                            </div>
                            {/* Matching colors div*/}
                            <div className="matching-colors-select">
                              <div className="matching-colors-select-inner ">
                                {/* Mapping through the colors*/}
                                {allColors &&
                                  allColors.map((color, i) => (
                                    <div
                                      key={i}
                                      className={`color artist-artwork-color color-${i}-${color}`}
                                      style={{ backgroundColor: color }}
                                      onClick={(e) =>
                                        handleMatchingColorClick(e, color)
                                      }
                                    ></div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          {/* Results per page checkbox */}
                          <div className="results-per-page artworks-results-per-page">
                            {/* RESULTS PER PAGE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label artwork-results-per-page-form-label"
                            >
                              RESULTS PER PAGE:
                            </label>
                            <div className="form-group form-group-dropdown">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) => {
                                  setResultsPerPage(Number(e.target.value));
                                  setCurrentPage(1);
                                }}
                                style={{ cursor: "pointer" }}
                                value={resultsPerPage}
                              >
                                <option defaultValue="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                          </div>

                          {/* Layout Selected Section */}
                          <div className="layout-select-section">
                            <div className="layout-select-title artist-layout-select-title">
                              VIEW
                            </div>
                            <div className="layout-select-options">
                              {/* Layout Grid */}
                              <div className="layout-btn layout-grid">
                                <button onClick={(e) => setPageLayout("grid")}>
                                  GRID
                                </button>
                              </div>

                              {/* Layout Full width */}
                              <div className="layout-btn layout-full-width artist-layout-grid-btn">
                                <button onClick={(e) => setPageLayout("full")}>
                                  Full Width
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Artist Artwork Boxes for the Grid layout section */}
                    {/* array of JSX items */}
                    {currentItems?.map((artwork, index) => (
                      <div
                        key={index + 1}
                        className={`grid-artwork-box`}
                        id={`artwork-box-${index + 1}`}
                      >
                        <a
                          href={`/artworks/${artistName}/${artwork.artworkName}`}
                        >
                          <img
                            src={require("../../../src/images/" +
                              artwork.displayImgSrc)}
                            alt="display-img"
                            className="display-img"
                          />
                        </a>
                        {/* Box Footer Section */}
                        <div className="grid-artwork-box-footer">
                          {/* Artwork Name */}
                          <div className="artwork-name">
                            {artwork.artworkName}
                          </div>

                          {/* Artist Name */}
                          <div className="artist-name">
                            Curator: {artwork.curatorName}
                          </div>

                          {/* Lower Side of footer */}
                          <div className="lower-box-footer">
                            {/* Artwork Price */}
                            <div className="artwork-price">
                              NGN
                              {numberWithCommas(artwork.price)}
                            </div>

                            {/* Action Buttons */}
                            <div className="action-buttons">
                              {/* Like Button */}
                              <div className="like-button">
                                <img
                                  onClick={handleArtworkLike}
                                  src={likeBtn}
                                  alt="like-btn"
                                />
                              </div>

                              {/* Cart Button */}
                              <div className="cart-button">
                                <img
                                  onClick={handleArtworkCart}
                                  src={cartBtn}
                                  alt="cart-btn"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
              ) : (
                <div className="full-box-layout">
                  {/* FULL WIDTH BOX LAYOUT */}
                  <div className="full-box-layout--inner artist-full-box-layout--inner">
                    {/* Sort Bar / Sort Menu */}
                    <div className="sort-bar" style={{ height: "max-content" }}>
                      <div className="sort-bar--inner">
                        {/* Pge Content Sorting */}
                        <div className="pagecontent-sorting">
                          <div
                            ref={paintingLinkRef}
                            onClick={handlePaintingLinkClick}
                            className="actions-link painting-link active-link"
                          >
                            Painting
                          </div>

                          {/* Category B */}
                          <div
                            ref={categoryBLinkRef}
                            onClick={handleCategoryBLinkClick}
                            className="actions-link category-b-link"
                          >
                            Category B
                          </div>

                          {/* Category C */}
                          <div
                            ref={categoryCLinkRef}
                            onClick={handleCategoryCLinkClick}
                            className="actions-link category-c-link"
                          >
                            Category C
                          </div>

                          {/* Sculpture */}
                          <div
                            ref={sculptureLinkRef}
                            onClick={sculptureLinkClick}
                            className="actions-link sculpture-link"
                          >
                            Sculpture
                          </div>

                          {/* Design */}
                          <div
                            ref={designLinkRef}
                            onClick={designLinkClick}
                            className="actions-link design-link"
                          >
                            Design
                          </div>
                        </div>

                        {/* Popular or Recent Sorting */}
                        <div className="popular-or-recent-sorting">
                          {/* Most Recent */}
                          <div
                            ref={mostRecentLinkRef}
                            onClick={mostRecentLinkClick}
                            className="actions-link most-recent-link active-link"
                          >
                            Most Recent
                          </div>

                          {/* Most Popular */}
                          <div
                            ref={mostPopularLinkRef}
                            onClick={mostPopularLinkClick}
                            className="actions-link most-popular-link"
                          >
                            Most Popular
                          </div>
                        </div>

                        {/* Artist Box View navigation  */}
                        <div className="artist-view-navigation">
                          {/* Style checkbox */}
                          <div className="artwork-style-dropdown">
                            {/* STYLE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label  artwork-style-form-label"
                            >
                              STYLE:
                            </label>
                            <div className="form-group form-group-dropdown mb-3">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) =>
                                  setArtworkStyle(Number(e.target.value))
                                }
                                style={{ cursor: "pointer" }}
                                value={artworkStyle}
                              >
                                <option defaultValue="all">ALL</option>
                              </select>
                            </div>
                          </div>
                          {/* Price ranges */}
                          <div className="price-ranges-section">
                            <div className="price-range-title-value">
                              <p className="artist-price-range-title">PRICE</p>
                              {/* Price ranges */}
                              <div className="rangeValues"></div>
                            </div>
                            <section className="range-slider">
                              <input
                                value={minPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-1"
                                onChange={getVals}
                              />
                              <input
                                value={maxPriceRangeValue}
                                min="1000"
                                max="5000000"
                                step="1000"
                                type="range"
                                className="slider-2"
                                onChange={getVals}
                              />
                            </section>
                          </div>

                          {/* SELECT MATCHING COLORS */}
                          <div className="matching-colors artist-artwork-matching-colors">
                            <div className="mb-1 matching-colors-title artist-matching-colors-title">
                              Color
                            </div>
                            {/* Matching colors div*/}
                            <div className="matching-colors-select">
                              <div className="matching-colors-select-inner ">
                                {/* Mapping through the colors*/}
                                {allColors?.map((color, i) => (
                                  <div
                                    key={i}
                                    className={`color artist-artwork-color color-${i}-${color}`}
                                    style={{ backgroundColor: color }}
                                    onClick={(e) =>
                                      handleMatchingColorClick(e, color)
                                    }
                                  ></div>
                                ))}
                              </div>
                            </div>
                          </div>

                          {/* Results per page checkbox */}
                          <div className="results-per-page artworks-results-per-page">
                            {/* RESULTS PER PAGE */}
                            <label
                              htmlFor="results-per-page"
                              className="form-label artwork-results-per-page-form-label"
                            >
                              RESULTS PER PAGE:
                            </label>
                            <div className="form-group form-group-dropdown">
                              <div className="left-arrow-section">
                                <img src={arrowUp} alt="arrow-up" />
                                <img src={arrowDown} alt="arrow-down" />
                              </div>
                              <select
                                name="results-per-page"
                                id="results-per-page"
                                aria-describedby="resultsPerPage"
                                className="form-control form-dropdown"
                                onChange={(e) =>
                                  setResultsPerPage(Number(e.target.value))
                                }
                                style={{ cursor: "pointer" }}
                                value={resultsPerPage}
                              >
                                <option defaultValue="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                              </select>
                            </div>
                          </div>

                          {/* Layout Selected Section */}
                          <div className="layout-select-section">
                            <div className="layout-select-title artist-layout-select-title">
                              VIEW
                            </div>
                            <div className="layout-select-options">
                              {/* Layout Grid */}
                              <div className="layout-btn layout-grid artist-layout-grid">
                                <button onClick={(e) => setPageLayout("grid")}>
                                  GRID
                                </button>
                              </div>

                              {/* Layout Full width */}
                              <div className="layout-btn layout-full-width artist-layout-full-width-btn">
                                <button onClick={(e) => setPageLayout("full")}>
                                  Full Width
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Artist Artwork Boxes for the Full Width layout section */}
                    <div className="artist-full-width-box-container">
                      {currentItems?.map((artwork, index) => (
                        <div
                          key={index + 1}
                          className={`full-artwork-box artist-full-artwork-box`}
                          id={`artwork-box-${index + 1}`}
                        >
                          <a
                            href={`/artworks/${artistName}/${artwork.artworkName}`}
                          >
                            <img
                              src={require("../../../src/images/" +
                                artwork.displayImgSrc)}
                              alt="display-img"
                              className="full-display-img"
                            />
                          </a>
                          {/* Box Footer Section */}
                          <div className="full-artwork-box-footer">
                            {/* Artwork Name */}
                            <div className="artwork-name">
                              {artwork.artworkName}
                            </div>

                            {/* Artist Name */}
                            <div className="artist-name">
                              Curator: {artwork.curatorName}
                            </div>

                            {/* Lower Side of footer */}
                            <div className="lower-box-footer">
                              {/* Artwork Price */}
                              <div className="artwork-price">
                                NGN
                                {numberWithCommas(artwork.price)}
                              </div>

                              {/* Action Buttons */}
                              <div className="action-buttons">
                                {/* Like Button */}
                                <div className="like-button">
                                  <img
                                    onClick={handleArtworkLike}
                                    src={likeBtn}
                                    alt="like-btn"
                                    className="full-like-btn"
                                  />
                                </div>

                                {/* Cart Button */}
                                <div className="full-cart-button">
                                  <img
                                    onClick={handleArtworkCart}
                                    src={cartBtn}
                                    alt="cart-btn"
                                    className="full-cart-btn"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* End */}
            {/* Footer / Pagination */}

            <div className="collection-view-footer">
              <div className="collection-view-footer--inner">
                <div className="collection-footer">
                  <div className="pagination-section">
                    {originalRows?.artworks &&
                      !isEmpty(originalRows?.artworks) && (
                        <Pagination
                          paginate={paginate}
                          rowsPerPage={resultsPerPage}
                          totalPosts={originalRows.artworks.length}
                          currentPage={currentPage}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Artist;
