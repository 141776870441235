import React, { useState } from "react";
import arrowDown from "../../../images/arrow-down.png";
import arrowUp from "../../../images/arrow-up.png";
import "./PaymentInfo.css";

function PaymentInfo() {
  // Variables and states
  const [transferMode, setTransferMode] = useState("bank-transfer");
  const [userBank, setUserBank] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");

  // Functions
  // Handle Save data
  const handleSaveData = function (e) {
    e.preventDefault();
    let result = {
      transferMode,
      bank: userBank,
      accountName,
      accountNumber,
      password,
    };
    console.log("payment-info", result);
  };

  // handle Cancel changes
  const handleCancelChanges = function (e) {
    e.preventDefault();
    setTransferMode("bank-transfer");
    setUserBank("");
    setAccountName("");
    setAccountNumber("");
    setPassword("");
  };

  return (
    <div className="payment-info-main">
      <div className="payment-info-main-inner">
        <div className="payment-info--form">
          <form>
            {/* First Row of form ccontaaining dropdown */}
            <div className="form-row-one">
              {/* Form / Mode of transfer */}
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="transfer-mode"
                  id="transfer-mode"
                  aria-describedby="transferMode"
                  className="form-control form-dropdown"
                  onChange={(e) => setTransferMode(e.target.value)}
                  value={transferMode}
                >
                  <option value="bank-transfer">Bank Transfer</option>
                  <option value="mastercard">Mastercard</option>
                  <option value="paypal">Paypal</option>
                  <option value="american-express">American Express</option>
                </select>
              </div>

              {/* Bank Selection */}
              <div className="form-group form-group-dropdown mb-3">
                <div className="left-arrow-section">
                  <img src={arrowUp} alt="arrow-up" />
                  <img src={arrowDown} alt="arrow-down" />
                </div>
                <select
                  name="bank-selection"
                  id="bank-selection"
                  aria-describedby="bankSelection"
                  className="form-control form-dropdown"
                  onChange={(e) => setUserBank(e.target.value)}
                  value={userBank}
                >
                  <option defaultValue={"Select"}>Select your bank</option>
                  <option value="GTB">GTBank</option>
                  <option value="UBA">UBA</option>
                  <option value="ZENITH">Zenith Bank</option>
                  <option value="WEMA">Wema Bank</option>
                  <option value="KUDA">Kuda Bank</option>
                  <option value="POLARIS">Polaris Bank</option>
                </select>
              </div>
            </div>

            {/* Horizontal dividerb line */}
            <div className="horizontal-line"></div>

            {/* Form Row two */}
            <div className="form-row-two">
              {/* Account Name */}
              <div className="form-group mb-3">
                <label htmlFor="account-name" className="form-label">
                  ACCOUNT NAME:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="account-name"
                  aria-describedby="accountName"
                  onChange={(e) => setAccountName(e.target.value)}
                  value={accountName}
                />
              </div>

              {/* Account Number */}
              <div className="form-group mb-3">
                <label htmlFor="account-number" className="form-label">
                  ACCOUNT NUMBER:
                </label>
                <input
                  type="text"
                  // pattern="[0-9]+"
                  className="form-control"
                  id="account-number"
                  aria-describedby="accountNumber"
                  // onInput={(e) =>
                  //   (e.target.value = e.target.value
                  //     .replace(/[^0-9.]/g, "")
                  //     .replace(/(\..*)\./g, "$1"))
                  // }
                  onChange={(e) => setAccountNumber(e.target.value)}
                  value={accountNumber}
                />

                {/* Password */}
                <div className="form-group mb-3 mt-5">
                  <label htmlFor="user-password" className="form-label">
                    PASSWORD:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="user-password"
                    aria-describedby="userPassword"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              </div>
            </div>

            {/* SAVE AND CANCEL */}
            <div className="payment-save-cancel-section">
              <button
                className="btn-inner payment-changes-save-btn"
                onClick={handleSaveData}
              >
                SAVE
              </button>
              <button
                className="btn-inner payment-changes-cancel-btn"
                onClick={handleCancelChanges}
              >
                Cancel changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
